import StageContainer from '../../components/StageContainer';

export default {
    id: "stage-container",
    components: [
        StageContainer,
        {
            type: "RenderContainer"
        }, {
            "type": "Tween",
            "events": {
                "show-stage": {
                    to: {
                        y: 0
                    },
                    time: 500,
                    easing: "Quadratic.Out"
                },
                "hide-stage": {
                    to: {
                        y: -2000
                    },
                    time: 500,
                    easing: "Quadratic.In"
                }
            }
        }, {
            "type": "RelayLinker",
            "linkId": "stage",
            "events": {
            }
        }
    ],
    properties: {
        id: "stage-container",
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: -2000
    }
};