import CHARACTERS from "@/shared/data/characters.json";
import dpeService from "@/shared/dpeService";
import outfits from "@/shared/data/outfits";
import sounds from "@/shared/data/sounds.json";

/* eslint-disable no-prototype-builtins */
const
    sortCollectibles = (collection) => {
        const
            arr = Object.keys(collection),
            characters = {};

        arr.reduce((characters, key) => {
            const
                item = collection[key],
                characterItems = characters[item.character] = characters[item.character] ?? {};

            characterItems[key] = item.default ? 1 : 0;

            return characters;
        }, characters);

        return characters;
    },
    allCollectibles = {
        outfits: sortCollectibles(outfits),
        sounds: sortCollectibles(sounds)
    },
    characters = {
        state: () => ({
            characters: []
        }),

        actions: {
            initializeCharacters ({commit, dispatch, state}) {
                return {
                    'characters.collectibles': ({value, player}) => {
                        commit('addPlayerCollectibles', {
                            player,
                            collectibles: value
                        });
                        dispatch('stampCharacters'); //auto-updating lastPlayedState to reflect new character collectibles.
                    },
                    'characters.outfit': ({value, player}) => commit('setPlayerOutfit', {
                        player,
                        outfit: value
                    }),
                    'characters.pairing': ({value}) => {
                        if (value?.player) { //check for dupes
                            state.characters.filter((character) => character.player === value.player).forEach((character) => {
                                commit('setCharacterPlayer', {
                                    character: character.character,
                                    player: null
                                });
                            });
                        }
                        commit('setCharacterPlayer', value);
                    }
                };
            },

            restoreLastPlayedState ({commit}) {
                const
                    characters = dpeService.currentPlaythrough?.lastPlayedState?.characters;

                if (characters) {
                    // Fix up collectibles.
                    commit('setCharacters', characters.map((characterData) => ({
                        ...characterData,
                        collectibles: Object.keys(allCollectibles).reduce((collectibles, key) => {
                            const
                                originalCollectibleData = characterData.collectibles[key];

                            collectibles[key] = {
                                ...allCollectibles[key][characterData.character],
                                ...(Array.isArray(originalCollectibleData) ? originalCollectibleData.reduce((data, item) => { // capture original storage method that was an array.
                                    data[item] = 1;
                                    return data;
                                }, {}) : originalCollectibleData)
                            };
                            return collectibles;
                        }, {})
                    })));
                } else { // Generate new characters.
                    const
                        characters = Object.keys(CHARACTERS),
                        pucks = ['A', 'B', 'C', 'D'];

                    commit('setCharacters', characters.map((character, index) => ({
                        player: null, // Assigned by AssignPlayer
                        outfit: '', // Assigned by CharacterPicker
                        collectibles: Object.keys(allCollectibles).reduce((collectibles, key) => {
                            collectibles[key] = allCollectibles[key][character];
                            return collectibles;
                        }, {}), // Assigned by ___
                        character,
                        characterName: CHARACTERS[character].name,
                        puck: pucks[index]
                    })));
                }
            },

            stampCharacters ({getters, state}) {
                if (getters.getIsReferee) {
                    dpeService.updateLastPlayedState('characters', state.characters);
                }
            },

            clearCharacters ({commit}) {
                commit('setCharacters', []);
            },

            assignCharacterPlayer ({commit}, {character, player}) {
                if (!character) {
                    throw new Error('Must assign character.');
                }

                commit('setCharacterPlayer', {character, player});
            },

            updatePlayerOutfit ({dispatch}, {outfit}) {
                dispatch('proposeUpdate', {
                    'characters.outfit': outfit
                });
            },

            addPlayerCollectibleOutfit ({dispatch}, outfitId) {
                return dispatch('proposeUpdate', {
                    [`characters.collectibles.outfits.${outfitId}`]: 1
                });
            },

            addPlayerCollectibleSound ({dispatch}, soundId) {
                return dispatch('proposeUpdate', {
                    [`characters.collectibles.sounds.${soundId}`]: 1
                });
            },

            removePlayerCollectibleOutfit ({dispatch}, outfitId) {
                return dispatch('proposeUpdate', {
                    [`characters.collectibles.outfits.${outfitId}`]: 0
                });
            },

            removePlayerCollectibleSound ({dispatch}, soundId) {
                return dispatch('proposeUpdate', {
                    [`characters.collectibles.sounds.${soundId}`]: 0
                });
            },

            updatePlayerCharacter ({dispatch}, pair) {
                dispatch('proposeUpdate', {
                    'characters.pairing': pair
                });
            }
        },

        mutations: {
            addCharacter (state, character) {
                state.characters.push(character);
            },

            setCharacterPuck (state, {character, puck}) {
                for (let i = 0; i < state.characters.length; i++) {
                    if (state.characters[i].character === character) {
                        state.characters[i].puck = puck;
                    }
                }
            },

            setCharacterPlayer (state, {character, player}) {
                for (let i = 0; i < state.characters.length; i++) {
                    if (state.characters[i].character === character) {
                        state.characters[i].player = player;
                        return;
                    }
                }
            },

            setPlayerOutfit (state, {player, outfit}) {
                for (let i = 0; i < state.characters.length; i++) {
                    if (state.characters[i].player === player) {
                        state.characters[i].outfit = outfit;
                        return;
                    }
                }
            },

            addPlayerCollectibles (state, {player, collectibles}) {
                for (let i = 0; i < state.characters.length; i++) {
                    const
                        characterData = state.characters[i];

                    if (characterData.player === player) {
                        const
                            lastSet = characterData.collectibles,
                            formattedCollectibles = {};

                        Object.keys(allCollectibles).forEach((key) => {
                            const
                                lastCollection = Array.isArray(lastSet[key]) ? lastSet[key].reduce((obj, value) => {
                                    obj[value] = 1;
                                    return obj;
                                }, {}) : lastSet[key] ?? {};

                            formattedCollectibles[key] = {
                                ...allCollectibles[key][characterData.character],
                                ...lastCollection, // old set
                                ...collectibles[key] // new set
                            };
                        });

                        characterData.collectibles = formattedCollectibles;
                        return;
                    }
                }
            },

            setCharacters (state, characters) {
                state.characters = characters;
            }
        },

        getters: {
            getCharacters (state) {
                return state.characters;
            },

            getUniquePucks (state, getters) {
                return state.characters.map((character) => {
                    if (!character.puck || !getters[`getPuck${character.puck}`]?.connected) {
                        return null;
                    }

                    return character;
                });
            },

            getUniquePlayers (state) {
                return state.characters.map((a, index, arr) => {
                    let count = 0;

                    if (!a.player) {
                        return null;
                    }

                    arr.forEach((b) => {
                        if (b.player === a.player) {
                            count += 1;
                        }
                    });

                    return count > 1 ? null : a;
                });
            },

            isPlayerPuckMapComplete (state, getters) {
                return getters.getUniquePlayers.indexOf(null) === -1 && getters.getUniquePucks.indexOf(null) === -1;
            }
        }
    };

export {characters};