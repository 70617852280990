import img_perf_stage_0_thumb from '@/components/gamemodes/performance/assets/images/perf-stage-0_thumb.png';
import img_perf_stage_1_thumb from '@/components/gamemodes/performance/assets/images/perf-stage-1_thumb.png';

export default {
    "images": [
        img_perf_stage_0_thumb,
        img_perf_stage_1_thumb
    ],
    "frames": [
        [ 0, 0, 400, 187, 0, 200, 93.5],
        [ 0, 0, 400, 187, 1, 200, 93.5]
    ],
    "animations": {
        "stage-basic": 0,
        "stage-lights": 1
    }
};