/**
 * @class ComponentExample
 * @uses platypus.Component
 */
import {Entity, createComponentClass, union} from 'platypus';

export default createComponentClass({
    
    id: 'SampleIndicator',
    
    properties: {
        "iconState": null,
        "arrowRotation": 0
    },
    
    publicProperties: {
    },
    
    initialize: function (/*definition, callback*/) {
        this.arrows = null;
        this.background = null;
        this.icons = null;
    },

    events: {
        "load": function ( ) {
            this.arrows = this.owner.parent.addEntity({
                "type": "sample-arrows",
                "properties": {
                    "x": 0,
                    "y": 0,
                    "z": 0,
                    "renderParent": this.owner,
                    "rotation": this.arrowRotation
                }
            });
            this.background = this.owner.parent.addEntity({
                "type": "sample-background",
                "properties": {
                    "x": 0,
                    "y": 0,
                    "z": 1,
                    "renderParent": this.owner,
                    "rotation": this.arrowRotation
                }
            });
            this.icons = this.owner.parent.addEntity({
                "type": "sample-icons",
                "properties": {
                    "x": 0,
                    "y": 0,
                    "z": 2,
                    "renderParent": this.owner,
                    "state": this.iconState
                }
            });
        },
        "highlight": function (activate) {
            const bounceSequence = [
                    {
                        "to": {"scaleX": 1.2, "scaleY": 1.2},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 1, "scaleY": 1},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }, {
                        "to": {"scaleX": 1.2, "scaleY": 1.2},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 1, "scaleY": 1},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }, {
                        "to": {"scaleX": 1.2, "scaleY": 1.2},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 1, "scaleY": 1},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }
                ],
                bounceSequenceForIcons = [
                    {
                        "to": {"scaleX": 0.9, "scaleY": 0.9},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 0.6, "scaleY": 0.6},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }, {
                        "to": {"scaleX": 0.9, "scaleY": 0.9},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 0.6, "scaleY": 0.6},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }, {
                        "to": {"scaleX": 0.9, "scaleY": 0.9},
                        "time": 750,
                        "easing": "Quadratic.InOut"
                    }, {
                        "to": {"scaleX": 0.6, "scaleY": 0.6},
                        "time": 750,
                        "easing": "Quadratic.InOut",
                        "repeat": -1
                    }
                ];

            if (activate) {
                this.background.state.set('active', true);
                this.background.triggerEvent('tween', bounceSequence);
                this.icons.triggerEvent('tween', bounceSequenceForIcons);
            } else {
                this.background.state.set('active', false);
                this.background.triggerEvent('stop-tween');
                this.icons.triggerEvent('stop-tween');
                this.background.scaleX = 1;
                this.background.scaleY = 1;
                this.icons.scaleX = 0.6;
                this.icons.scaleY = 0.6;
            }
        }
    },
    
    methods: {
        
        
    },
    
    publicMethods: {
        
    },

    getAssetList: function () {
        const images =  Entity.getAssetList({
            type: 'sample-arrows'
        });

        union(images, Entity.getAssetList({
            type: 'sample-background'
        }));
        
        union(images, Entity.getAssetList({
            type: 'sample-icons'
        }));

        return images;
    }
});
  