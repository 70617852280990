import EventEmitter from 'events';
import store from '../store/index';

class VuexStorePuck extends EventEmitter {
    constructor (deviceName, {index = -1, getter = 'getRelayedPositions', historyRead = ''} = {}) {
        super();

        this.deviceName = deviceName;
        this.index = index;

        if (index === -1) {
            if (historyRead) {
                this.lastIndex = -1;
                this.stopPuckRelay = store.watch((state, getters) => getters[getter], (history) => {
                    if (history.length) {
                        let updated = false;

                        for (let i = 0; i < history.length; i += 2) {
                            const
                                index = history[i + 1];

                            if (index > this.lastIndex) {
                                const
                                    value = history[i],
                                    pucks = ['A', 'B', 'C', 'D'],
                                    data = value.buffer ? new Int16Array(value.buffer) : value;
            
                                this.lastIndex = index;
                                updated = true;
                                for (let i = 0; i < pucks.length; i++) {
                                    this.emitPuckData(data, i, pucks[i]);
                                }
                            }
                        }

                        if (updated) {
                            store.dispatch(historyRead, this.lastIndex);
                        }
                    }
                });
            } else {
                this.stopPuckRelay = store.watch((state, getters) => getters[getter], (value) => {
                    const
                        pucks = ['A', 'B', 'C', 'D'],
                        data = value.buffer ? new Int16Array(value.buffer) : value;
    
                    for (let i = 0; i < pucks.length; i++) {
                        this.emitPuckData(data, i, pucks[i]);
                    }
                });
            }
        } else {
            this.stopPuckRelay = store.watch((state, getters) => getters[getter], (value) => {
                const
                    data = value.buffer ? new Int16Array(value.buffer) : value;

                this.emitPuckData(data, this.index);
            });
        }
    }

    destroy () {
        this.stopPuckRelay();
    }

    emitPuckData (data, i, suffix = '') {
        const
            device = this.deviceName,
            extra = data[(i * 2) + 1],
            rotation = extra & 0x1ff,
            connected = i === 0 ? 1 : !!(extra >> 15),
            button = (extra >> 13) & 3;

        this.emit('updated', {
            control: `PuckRotation${suffix}`,
            device,
            type: 'Dial',
            value: rotation
        });
        this.emit('updated', {
            control: `PuckConnection${suffix}`,
            device,
            type: 'Button',
            value: connected
        });
        this.emit('updated', {
            control: `PuckButton${suffix}`,
            device,
            type: 'Amount',
            value: button
        });
        this.emit('updated', {
            control: `PuckPosition${suffix}`,
            device,
            type: 'Amount',
            value: data[(i * 2) + 0]
        });
    }

    setState (...args) {
        for (let i = 0; i < args.length; i++) {
            const
                instructions = {
                    ...args[i]
                };

            if ((this.index !== -1) && (!instructions.pucks)) {
                if (args.length === 1) {
                    instructions.pucks = [this.index];
                } else {
                    instructions.pucks = [i];
                }
            }

            store.commit('setPuckStateInstructions', instructions);
        }
    }

    setLED (...args) {
        for (let i = 0; i < args.length; i++) {
            const
                instructions = {
                    ...args[i]
                };

            if ((this.index !== -1) && (!instructions.pucks)) {
                if (args.length === 1) {
                    instructions.pucks = [this.index];
                } else {
                    instructions.pucks = [i];
                }
            }

            store.commit('setPuckLEDInstructions', instructions);
        }
    }
}

export default VuexStorePuck;
