<template>
    <div class="start-up-display">
        <header class="title-container">
            <h2 class="header-blob chapter-title">
                {{'Chapter ' + chapterNumber + ": " + chapterTitle}}
            </h2>
            <h3 class="subtitle">
                <em>Performance</em>
            </h3>
        </header>
        
        <p class="start-note"><b>Grab your sound boards and get ready to put on a performance!</b> The music begins once you click the "Start Button" 	&rarr;</p>
        
        <button class="start-btn mtb-btn action bottom-ready visible" :onClick="() => {startGame();}">Start Game</button>
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';

export default {
    name: 'StartUp',
    props: {
        theGame: Object,
        chapterNumber: String,
        chapterTitle: String
    },
    computed: {
    },
    data () {
        return {
        };
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {
        startGame () {
            this.theGame.triggerEvent('attempt-start-game');
        }
    },
    watch: {
        
    }
};
</script>

<style scoped lang="scss">
.start-up-display {
    z-index: 1001;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;

    &.start-btn {
        pointer-events: all;
    }

    /*&:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10.5vw;
        background-color: rgba(0,0,0,0.8);
        backdrop-filter: blur(10px);
        pointer-events: all;
    }*/
    
    
    .title-container {
        width: 40vw;
        height: 10vw;
        position: absolute;
        margin-left: -20vw;
        left: 50%;
        top: 6vw;
        z-index: 5;

        .subtitle {
            color: #1B204B;
            background-color: #40D1C3;
            display: block;
            padding: 1.5em 1.5em 1em 4em;
            border-radius: 0 0 1.5em 1.5em;
            font-weight: bold;
            width: max-content;
            position: absolute;
            left: 50%;
            bottom: -1em;
            transform: translateX(-40%);
            z-index: -1;
            
            &:after {
                content: '';
                display: block;
                width: 3em;
                height: 1.999995em;
                background-image: url('~@/assets/ui/mtb-ui_game-type-icons_performance.svg'); 
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                
                position: absolute;
                left: 1em;
                top: 58%;
                transform: translateY(-50%);
            }
        }
    }


    .start-note {
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.85em;
        line-height: 1.5;
        text-align: center;
        background: #1b204b;
        color: #6c78c4;
        padding: 0.75em 1.5em;
        border-radius: 1.5em;
        width: 44vw;
        margin: auto;
        z-index: 1;
        font-style: italic;

        b {
            color: #fee888;
        }
    }
}

</style>