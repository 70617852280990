import Stage from '../../components/Stage';

export default {
    id: "stage",
    components: [
        Stage,
        {
            type: "RenderSprite",
            spriteSheet: "stages",
            "animationMap": {
                "stage-basic": "stage-basic",
                "stage-lights": "stage-lights"
            }
        }, {
            "type": "RelayLinker",
            "linkId": "stage",
            "events": {
            }
        }
    ],
    properties: {
        renderParent: "stage-container",
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0
    }
};