<template>
    <div class="container">
        <div class="feedback-image" :class="feedbackQuality"></div>
        
        <button class="retry-button mtb-btn action replay-text" v-on:click="retry()">Retry</button>
        <button class="watch-replay-button mtb-btn action review-text" v-on:click="watchReplay()">Watch Replay</button>
        
        <GroupButton
            v-if="vueData.mode === 'replay-watched'"
            class="next-level-button"
            :buttonClasses="'mtb-btn action bottom-ready visible'"
            confirmationModel="leader"
            :onAgreement="onComplete"
            title="Next Level"
            :milestone="true"
            :decisionId="decisionId"
            decision="continue"
        />
    </div>
</template>

<script>

import GroupButton from '@/components/GroupButton.vue';
//import {mapGetters} from 'vuex';

export default {
    name: 'ReplayPrompt',
    components: {
        GroupButton
    },
    props: {
        decisionId: String,
        theGame: Object,
        vueData: Object,
        onComplete: Function
    },
    computed: {
        feedbackQuality () {
            const feedbackClass = {};
            switch (this.vueData.results.lastRunRank) {
            case 'very-good':
                feedbackClass.veryGood = true;
                break;
            case 'good':
                feedbackClass.good = true;
                break;
            case 'bad':
                feedbackClass.bad = true;
                break;
            case 'very-bad':
                feedbackClass.veryBad = true;
                break;
            }

            return feedbackClass;
        }
    },
    data () {
        return {
        };
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {
        retry () {
            this.theGame.triggerEvent('retry');
        },
        watchReplay () {
            this.theGame.triggerEvent('start-replay');
        }
    },
    watch: {
        
    }
};
</script>

<style scoped lang="scss">
    .container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    .feedback-image {
        position: absolute;
        width: 65vw;
        height: 40vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;

        &.veryGood {
            background-image: url(~@/assets/ui/animations/awesome_static.svg);
        }

        &.good {
           background-image: url(~@/assets/ui/animations/good-but_static.svg);
        }

        &.bad {
            background-image: url(~@/assets/ui/animations/oops_static.svg);
        }

        &.veryBad {
             background-image: url(~@/assets/ui/animations/oh-no_static.svg);
        }
    }

    .retry-button {
        position: absolute;
        font-size: 1em;
        left: 1.5vw;
        bottom: 1.5vw;
    }

    .watch-replay-button {
        position: absolute;
        left:50%;
        margin-left: -7vw;
        bottom: 1.5vw;
        font-size: 1em;
    }

    .next-level-button {
        cursor: pointer;
        position: absolute;
        bottom: 6vw;
        right: 0;
        top: auto;

        :deep .mtb-btn.action.bottom-ready {
            top: 0;
            width: max-content;
            height: fit-content;

            transform: translateX(15%) scale(1.25);
            pointer-events: all;

            &:hover {
                animation: none;
                transform: translateX(0) scale(1.25);
            }
        }
    }


</style>