import {DpeClient, Logger as DpeLogger} from '@makefully/dpe-client';
import {Logger} from './logService';
import env from '../../env.json';

const
    BATCH_TIMING = 200,
    P2P_BATCH_TIMING = 15,
    wait = (time) => new Promise((resolve) => setTimeout(resolve, time)),
    isEqual = (x, y) => {
        const
            ok = Object.keys,
            tx = typeof x,
            ty = typeof y;

        return x && y && tx === 'object' && tx === ty ? (ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key]))) : (x === y);
    },
    mutate = (...crumbs) => (state) => {
        const
            copy = crumbs.slice(),
            newState = copy.pop();

        while (copy.length > 1) {
            const
                key = copy.shift();

            state = state[key] = state[key] || (typeof copy[0] === 'number' ? [] : {});
        }

        // If there's a key and an actual change, send the data.
        if (copy[0] && !isEqual(state[copy[0]], newState)) {
            state[copy[0]] = newState;
            return true;
        } else {
            return false;
        }
    };

let console = null;

DpeLogger.globallyDisabled = true;

class DPEService extends DpeClient {

    constructor () {
        super(env);

        console = new Logger('dpe');
        DpeLogger.prototype.log = (...args) => {
            console.log(...args);
        };

        this.localStateUpdates = [];
        this.gameStateUpdates = [];

        this.currentPlaythrough = null;
        this.currentSessionId = null;
        this.players = null;

        this.busy = false;

        this.iteration = 0;
        this.lastTransitionSent = '';

        window.dpeService = this; // for debugging

        this.on("sessionLeft", ({sessionId, playerId}) => {
            this.logger.log(`Player ${playerId} left session ${sessionId}`);
            console.warn(`Player ${playerId} left session ${sessionId}`);
        });
        
        this.on("sessionEnded", ({sessionId}) => {
            this.logger.log(`Session ${sessionId} was ended by another client`);
            console.warn(`Session ${sessionId} was ended by another client`);
        });
    }

    async acquirePlaythroughId (roomCode, me, cancel = () => false) {
        let response = null;

        await this.rooms.join(roomCode, me); // Currently this has no return object.

        // Poll the room status to get a playthrough id
        while (!cancel() && (!response || typeof response.playthroughId === 'undefined' || response.players.length < 4)) { // hard-coding 4 atm... should probably make this customizable.
            await wait(1000);
            response = await this.rooms.status(roomCode);
        }

        if (cancel()) {
            throw new Error('Player cancelled.');
        }

        return response;
    }

    async acquireSessionId (roomCode, playthroughId, me) {
        const
            sessions = (await this.presence.activeSessionsForPlaythrough(playthroughId)).sort((a, b) => b.lastPlayed - a.lastPlayed);

        while (sessions.length) {
            const
                sessionId = sessions.shift().sessionId,
                session = await this.presence.getSession(sessionId);

            if (session.roomCode === roomCode && session.isActive) {
                return {
                    activeRooms: [], // Not defined this route, but could be from the session data I guess.
                    sessionId,
                    ...session,
                    players: session.players.map((player) => player.playerId)
                };
            }
        }

        // Earlier session not found.
        {
            const
                {requestId, errors, activeRooms} = await this.createSession(roomCode, playthroughId, me);

            if (errors.length > 0) {
                errors.forEach((value) => console.error(value));
                console.warn(`Failed to get sessionId for "${playthroughId}"`, errors);
                throw new Error(`Failed to get sessionId for "${playthroughId}"`);
            } else {
                let response = null;

                while (!response || response.sessionId === null) {
                    await wait(1000);
                    response = await this.getRequestStatus(requestId);
                }

                return {
                    ...response,
                    activeRooms
                };
            }
        }
    }

    async joinGame ({boardCode, me, playthrough, cancel}) {
        const
            ptId = playthrough?.playthroughId ?? (await this.acquirePlaythroughId(boardCode, me, cancel))?.playthroughId, // if undefined, we start a new playthrough.
            {sessionId, players, activeRooms} = await this.acquireSessionId(boardCode, ptId, me);

        this.id = me = me || this.id;

        await this.joinSession(sessionId, me, players.filter((player) => player !== me));

        console.log('Joined game.', this.state);
        this.currentPlaythrough = playthrough;
        this.currentSessionId = sessionId;
        this.players = players;
        this.iteration = this.state[me].gameState.id ?? 0;

        this.groupP2PChanges();
        this.groupChanges(); // Begin update loop for room.

        {
            const
                inMyRoom = [...new Set(activeRooms.filter((room) => room.roomCode === boardCode).map((room) => room.playerId))],
                gameData = {
                    playthroughId: ptId,
                    sessionId,
                    players,
                    inMyRoom,
                    inOtherRooms: activeRooms.filter((room) => (room.roomCode !== boardCode) && inMyRoom.indexOf(room.playerId) === -1).reduce((obj, room) => {
                        const
                            arr = obj[room.roomCode] = obj[room.roomCode] ?? [];

                        if (arr.indexOf(room.playerId) === -1) {
                            arr.push(room.playerId);
                        }

                        return obj;
                    }, {})
                };

            this.emit('game-started', gameData);

            return gameData;
        }
    }

    async leaveGame (allDone = false) {
        this.leaveSession();

        if (allDone) {
            this.presence.endSession(this.currentSessionId);
        }

        this.localStateUpdates.length = 0;
        this.gameStateUpdates.length = 0;
        this.currentPlaythrough = null;
        this.currentSessionId = null;
        this.busy = false;
        this.players = null;

        console.log('Left game.', this.state);

        this.emit('game-ended');
    }

    updateGameState (...crumbs) {
        if (!this.currentSessionId) {
            console.warn('Cannot update game state outside of game.');
            return;
        }

        this.gameStateUpdates.push(mutate(...crumbs));
    }

    updateLocalState (...crumbs) {
        if (!this.currentSessionId) {
            console.warn('Cannot update local state outside of game.');
            return;
        }

        this.localStateUpdates.push(mutate(...crumbs));
    }

    updateLastPlayedState (...crumbs) {
        if (!this.currentPlaythrough) {
            throw new Error('Not currently in a playthrough.');
        }

        const
            newState = crumbs.pop(),
            currentState = this.currentPlaythrough.lastPlayedState || {};
        let state = this.currentPlaythrough.lastPlayedState = currentState;

        while (crumbs.length > 1) {
            const
                key = crumbs.shift();

            state = state[key] = state[key] || (typeof crumbs[0] === 'number' ? [] : {});
        }

        if (crumbs[0]) {
            state[crumbs[0]] = newState;
        }

        return this.playthroughs.setLastPlayedState(this.currentPlaythrough.playthroughId, currentState);
    }

    /**
     * This triggers for every update so we can see immediate/unbatched updates if needed.
     */
    async intermediateGameUpdate () {
        const
            gameStateUpdates = JSON.parse(JSON.stringify(this.state[this.state.me].gameState ?? {})),
            gameStateChange = this.gameStateUpdates.reduce((previous, mutation) => mutation(gameStateUpdates) || previous, false),
            snapshot = JSON.stringify(gameStateUpdates);

        if (gameStateChange && this.lastTransitionSent !== snapshot) {
            const
                iteratedUpdate = {
                    ...gameStateUpdates,
                    id: (this.iteration += 1)
                };

            this.emit('transitional-game-state', iteratedUpdate);
            this.lastTransitionSent = snapshot;
        }
    }

    async forceUpdate () {
        if (this.busy || !this.currentSessionId) { // we'll be back in 200ms, so just ignore.
            return false;
        } else {
            this.busy = true;

            const
                localStateUpdatesCopy = this.localStateUpdates.slice(),
                gameStateUpdatesCopy = this.gameStateUpdates.slice(),
                localStateUpdates = this.state[this.state.me].localState ?? {},
                localStateChange = localStateUpdatesCopy.reduce((previous, mutation) => mutation(localStateUpdates) || previous, false),
                gameStateUpdates = this.state[this.state.me].gameState ?? {},
                gameStateChange = gameStateUpdatesCopy.reduce((previous, mutation) => mutation(gameStateUpdates) || previous, false);

            this.localStateUpdates.length = 0;
            this.gameStateUpdates.length = 0;
        
            if (localStateChange) {
                try {
                    await this.setLocalState(localStateUpdates);
                    this.emit('update-local-state', localStateUpdates);
                } catch (e) {
                    console.warn('Unable to update DPE local state.', e);
                    // Restore updates needed since they were unsuccessful.
                    this.localStateUpdates.unshift(...localStateUpdatesCopy);
                }
            }
            if (gameStateChange) {
                try {
                    const
                        iteratedUpdate = {
                            ...gameStateUpdates,
                            id: (this.iteration += 1)
                        };

                    await this.setGameState(iteratedUpdate);
                    this.emit('update-game-state', iteratedUpdate);
                } catch (e) {
                    console.warn('Unable to update DPE game state.', e);
                    // Restore updates needed since they were unsuccessful.
                    this.gameStateUpdates.unshift(...gameStateUpdatesCopy);
                }
            }

            this.busy = false;
            return localStateChange || gameStateChange;
        }
    }

    async groupChanges () {
        if (this.currentSessionId) {
            await this.forceUpdate();
            await wait(BATCH_TIMING);
            this.groupChanges();
        }
    }
    
    async groupP2PChanges () {
        if (this.currentSessionId) {
            this.intermediateGameUpdate();
            await wait(P2P_BATCH_TIMING);
            this.groupP2PChanges();
        }
    }
}

export default new DPEService();

/**
 * dpeService plugin
 *
 * @param {*} app
 * @param {*} options
 */
/*export default function (app, options) {
    const
        dpeService = new DPEService(),
        key = options?.key ?? 'dpeService';

    app.provide(key, dpeService);
    app.config.globalProperties[`$${key}`] = dpeService;
}*/
