<template>
    <Transition name="circle-wipe-fast">
        <div v-show="showMe" class="reward-bg">
            <button class="reward-close-button mtb-btn action close" :onClick="() => {nextNewSound();}"></button>
            <article class="reward-container">
                <div class="sound-symbol" :class="newSoundIcon"></div>
       
                <button
                    class="mtb-btn action play-sound"
                    @click="playSound()"
                >Play Sound</button>
                
                <h2 class="new-sound-title">You Got A New Sound!</h2>
                <p class="new-sound-text">Get ready to level up your musical skills! You can review all your sounds in the "My Stuff" area of your character profile.</p>
                
                <lottie-animation
                    ref="sparkle"
                    class="lottie sparkle"
                    :animationData="sparkle_anim"
                    :loop="true"
                    :autoPlay="true"
                />
                <lottie-animation
                    ref="sunburst"
                    class="lottie sun-burst"
                    :animationData="sunburst_anim"
                    :loop="true"
                    :autoPlay="true"
                />
            </article>
        </div>
    </Transition>
</template>

<script setup>
import sparkle_anim from '@/assets/ui/animations/sparkle-pow.json';
import sunburst_anim from '@/assets/ui/animations/sun-burst.json';
</script>

<script>
import {Howl} from 'howler';
//import {mapGetters} from 'vuex';
    

// "red-a": aud_whistle_1,
// "red-b": aud_whistle_2,

// "purple-a": aud_tap_1,
// "purple-b": aud_tap_2,

// "teal-a": aud_perc_1,
// "teal-b": aud_perc_2,

// "yellow-a": aud_xylo_1,
// "yellow-b": aud_xylo_2,

// UI audio
import PURPLE_A from '@/assets/audio/MTB_TAP_1.mp3';
import PURPLE_B from '@/assets/audio/MTB_TAP_2.mp3';
import RED_A from '@/assets/audio/MTB_WHISTLE_1.mp3';
import RED_B from '@/assets/audio/MTB_WHISTLE_2.mp3';
import TEAL_A from '@/assets/audio/MTB_PERC_1.mp3';
import TEAL_B from '@/assets/audio/MTB_PERC_2.mp3';
import YELLOW_A from '@/assets/audio/MTB_XYLO_1.mp3';
import YELLOW_B from '@/assets/audio/MTB_XYLO_2.mp3';

export default {
    name: 'RewardSound',
    props: {
        theGame: Object,
        vueData: Object
    },
    computed: {
        // ...mapGetters({
        //     mySounds: 'getMyMissionSounds'
        // }),
        newSoundIcon () {
            const result = {};
            //reload
            result[this.vueData.sound.newSounds[this.newSoundIndex]] = true;
            return result;
        }
    },
    data () {
        return {
            //newSounds: [],
            showMe: false,
            newSoundIndex: 0,
            soundMap: {
                "red-a": RED_A,
                "red-b": RED_B,
                "purple-a": PURPLE_A,
                "purple-b": PURPLE_B,
                "teal-a": TEAL_A,
                "teal-b": TEAL_B,
                "yellow-a": YELLOW_A,
                "yellow-b": YELLOW_B,
            }
        };
    },
    beforeMount () {
    },
    mounted () {
        this.showMe = true;
        this.soundSFX  = new Howl({
            src: this.soundMap[this.vueData.sound.newSounds[this.newSoundIndex]],
            autoplay: false,
            loop: false,
            volume: 0.5
        });
    },
    beforeUnmount () {
        this.showMe = false;
    },
    methods: {
        nextNewSound () {
            this.newSoundIndex += 1;
            if (this.newSoundIndex >= this.vueData.sound.newSounds.length) {
                this.newSoundIndex = 0;
                //this.newSounds = [];
                this.theGame.completeReward();
            }
            //May want to have a graphical effect to denote the change in sounds?
        },
        playSound () {
            this.soundSFX.stop();
            this.soundSFX.play();
        }
    },
    watch: {
        // mySounds: {
        //     handler (sounds, oldSounds) {
        //         let x = 0,
        //             y = 0,
        //             isNew = true;

        //         for (x = 0; x < sounds.length; x++) {
        //             isNew = true;
        //             if (oldSounds) {
        //                 for (y = 0; y < oldSounds.length; y++) {
        //                     if (sounds[x] === oldSounds[y]) {
        //                         isNew = false;
        //                         break;
        //                     }
        //                 }
        //             }
        //             if (isNew) {
        //                 this.newSounds.push(sounds[x]);
        //             }
        //         }
        //     },
        //     immediate: true
        // }
    }
};
</script>

<style scoped lang="scss">
.reward-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    backdrop-filter: blur(20px);
}

.reward-close-button {
    right: 3vw;
    position: absolute;
    top: 5vw;
    z-index: 100;
}

.reward-container {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25vw;
    margin-top: -25vw;
    width: 50vw;
    height: 50vw;
    z-index: 1;
    
    .lottie {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: -2em;
        
        &.sparkle {
            transform: scale(1.5);
            opacity: 0.5;
        }
        
            
        &.sun-burst {
            transform: scale(2);
            opacity: 0.2;
        }
    }
}
.sound-symbol {
    position: absolute;
    margin-left: -15vw;
    left: 50%;
    margin-top: -15vw;
    top: 40%;
    width: 30vw;
    height: 30vw;
    border-radius: 15vw;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80%;
    
    animation: attentionPulse 1.25s ease-in-out 0s infinite normal forwards;

    &.purple-a {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_purple-bird-1.png);   
    }

    &.purple-b {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_purple-bird-2.png);   
    }

    &.red-a {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_red-bird-1.png);   
    }

    &.red-b {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_red-bird-2.png);   
    }

    &.yellow-a {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_yellow-bird-1.png);   
    }

    &.yellow-b {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_yellow-bird-2.png);   
    }

    &.teal-a {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_teal-bird-1.png);   
    }

    &.teal-b {
        background-image: url(~@/components/gamemodes/performance/assets/images/mtb_sample-icon_teal-bird-2.png);   
    }
}

.play-sound {
    position: absolute;
    left: 50%;
    bottom: 13vw;
    width: 20vw;
    margin-left: -10vw;
    padding: 0.75em 1.5em 0.75em 3.5em !important;
    
    &:before {
        content: "";
        width: 2em;
        height: 2em;
        display: inline-block;
        position: absolute;
        left: 1.5em;
        top: 50%;
        transform: translateY(-55%);
        background-image: url(~@/assets/ui/mtb-ui_play-sound-icon.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
    }
        
}

.play-sound-button-symbol {
    position: absolute;
    left: 3vw;
    bottom: 0.5vw;
    width: 5vw;
    height: 3vw;
    background-color: white;
}

.play-sound-button-text {
    position: absolute;
    right: 1vw;
    top: 0vw;
    height: 100%;
    width: 10vw;
    line-height: 4vw;
    color: white;
}

.new-sound-title {
    position: absolute;
    width: 100%;
    height: 20vw;
    left: 0;
    bottom: -8vw;
    text-align: center;
    font-size: 3em;
}

.new-sound-text {
    position: absolute;
    width: 50vw;
    height: 20vw;
    margin-left: -25vw;
    left: 50%;
    bottom: -14vw;
    text-align: center;
    font-size: 0.9em;
    line-height: 2vw;
}


</style>