import ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_3_PNG from '@/assets/gamemodes/practice/boards/solutions/solution_lvl-3.png';

export default {
    "board": {
        "dimensions": [
            3,
            4
        ],
        "art": null
    },
    "initialPositions": [
        {
            "region": 20101,
            "rotation": 0
        },
        {
            "region": 20104,
            "rotation": 0
        },
        {
            "region": 20112,
            "rotation": 0
        },
        {
            "region": 20109,
            "rotation": 0
        }
    ],
    "solutions": [
        [
            [
                20102,
                0
            ],
            [
                20103,
                0
            ],
            [
                20108,
                0
            ],
            [
                20110,
                0
            ]
        ],
        [
            [
                20103,
                0
            ],
            [
                20102,
                0
            ],
            [
                20107,
                0
            ],
            [
                20106,
                0
            ]
        ],
        [
            [
                20104,
                0
            ],
            [
                20106,
                0
            ],
            [
                20103,
                0
            ],
            [
                20107,
                0
            ]
        ],
        [
            [
                20108,
                0
            ],
            [
                20110,
                0
            ],
            [
                20102,
                0
            ],
            [
                20103,
                0
            ]
        ],
        [
            [
                20112,
                0
            ],
            [
                20109,
                0
            ],
            [
                20101,
                0
            ],
            [
                20104,
                0
            ]
        ]
    ],
    "clues": {
        "team": [
            ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_3_PNG
        ],
        "individual": [
            [
                "On Turn 2, all four of you are making a square formation together.",
                "On Turn 3, be at the work bench."
            ],
            [
                "On Turn 2, swap places with Bowie.",
                "On Turn 3 you and Lee are both on the black and yellow mat.",
                "On Turn 4, stay on the black and yellow mat."
            ],
            [
                "On Turn 1, everyone but you moves away from the side walls towards the middle of the room; you move towards the work bench.",
                "On Turn 3, take Bowie’s place."
            ],
            [
                "On Turns 1, 2, and 3 you will be on the black and yellow mat.",
                "On Turn 3, you take the spot Kit was just in."
            ]
        ]
    },
    "players": null
};