import EventEmitter from 'events';
import {Logger} from './logService';
import PeerConnections from './peer-connections';
import dpeService from './dpeService';

const
    USE_P2P = true, //Can turn off to disable p2p event relay.
    arraysKindaMatch = (a, b) => a.length === b.length && a.every((value) => b.indexOf(value) >= 0),
    isEqual = (x, y) => {
        const
            ok = Object.keys,
            tx = typeof x,
            ty = typeof y;

        return x && y && tx === 'object' && tx === ty ? (ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key]))) : (x === y);
    };
let console = null;

class PeerService extends EventEmitter {
    constructor () {
        super();

        this.connections = null;
        this.state = {};
        this.connectedToAllPeers = false;
        this.stateChanges = [];

        console = new Logger('connection');

        dpeService.on('game-started', ({/*sessionId,*/players}) => {
            const
                otherPlayers = players.filter((value) => value !== dpeService.state.me),
                peerConnections = this.connections = new PeerConnections(dpeService),
                disconnectPeers = () => {
                    console.warn(`Game ended. Removing all peers.`);
                    peerConnections.destroy();
                    this.connections = null;
                    this.connectedToAllPeers = false;
                    this.state = {};
                    dpeService.off('fieldChange', onFieldChange);
                    dpeService.off('game-ended', disconnectPeers);
                    dpeService.off('transitional-game-state', transmitGameState);
                    //dpeService.off('update-local-state', transmitLocalState);
                    console.log(`Game ended. Removed all peers.`);
                    this.emit('game-ended', {otherPlayers});
                },
                onFieldChange = ({field, value, player}) => {
                    const
                        changes = [];

                    this.stateChanges.forEach((event) => {
                        const
                            crumbs = event.split('.');

                        if (player === crumbs[0] && field === crumbs[1]) {
                            let last = this.state[player]?.[field],
                                current = value;

                            for (let i = 2; i < crumbs.length; i++) {
                                last = last?.[crumbs[i]];
                                current = current?.[crumbs[i]];
                            }

                            if (!isEqual(last, current)) {
                                changes.push(() => {
                                    this.emit(event, {
                                        field: crumbs,
                                        oldValue: last,
                                        value: current
                                    });
                                });
                            }
                        }
                    });

                    if (!this.state[player]) {
                        this.state[player] = {};
                    }
                    this.state[player][field] = value;

                    changes.forEach((emit) => {
                        emit();
                    });
                },
                /*transmitLocalState = (value) => {
                    this.connections.send('state-change', {
                        field: 'localState',
                        value
                    });
                },*/
                transmitGameState = (value) => {
                    this.connections.send('state-change', {
                        field: 'gameState',
                        value
                    });
                };

            dpeService.on('fieldChange', onFieldChange);
            dpeService.on('game-ended', disconnectPeers);
            dpeService.on('transitional-game-state', transmitGameState);
            //dpeService.on('update-local-state', transmitLocalState);

            peerConnections.on('peer-connect', (connectedPeer) => {
                console.warn(`Connected ${connectedPeer.id}`);
                this.emit('peer-connect', connectedPeer);

                connectedPeer.on('state-change', ({data}) => {
                    this.emit('peer-updated', {
                        field: data.field,
                        state: data.value,
                        peer: connectedPeer.id
                    });
                });
                this.connectedToAllPeers = USE_P2P && this.connections && arraysKindaMatch(this.connections.peers.map((value) => value.id), otherPlayers);
            });

            peerConnections.on('peer-disconnect', (connectedPeer) => {
                console.warn(`Disconnected ${connectedPeer.id}`);
                this.emit('peer-disconnect', connectedPeer);
                this.connectedToAllPeers = USE_P2P && this.connections && arraysKindaMatch(this.connections.peers.map((value) => value.id), otherPlayers);
            });

            this.emit('game-started', {otherPlayers});
        });

        window.peerService = this; // for debugging
    }

    on (event, ...args) {
        super.on(event, ...args);

        if (event.indexOf('.') > 0) {
            this.stateChanges.push(event);
        }
    }

    off (event, ...args) {
        const
            index = this.stateChanges.indexOf(event);

        super.off(event, ...args);

        if (index >= 0) {
            this.stateChanges.splice(index, 1);
        }
    }
}

/**
* logService plugin
*
* @param {*} app
* @param {*} options
*/
export default function (app, options) {
    const
        key = options?.key ?? 'peerService',
        peerService = new PeerService();

    app.provide(key, peerService);
    app.config.globalProperties[`$${key}`] = peerService;
}