<template>
    <section class="section-wrapper board-picker">
        <header class="section-nav-header">
            <button class="mtb-btn nav-action back-btn" @click="goback">Back</button>
            <h2>
                <i>
                    <span v-if="playthroughData">Continue </span>
                    <span v-else>Start New </span>
                    Playthrough (step 2/2):
                </i>
                <em>Choose Your Player Pieces</em>
            </h2>
            <p class="board-code">
                <em>Current Set Code: </em>
                {{ boardCode }}
            </p>
        </header>
      
        <div class="puck-picker-wrapper">
            <PuckPicker
                v-for="(character, idx) in characters"
                :key="idx"
                :character="character"
                :puckData="$store.getters.getUniquePucks[idx]"
                :id="character + '-container'"
                :choseOutfit="choseOutfit"
                @show-menu-event="showCharacterMenu"
            />
        </div>
        
        <Transition name="peak-up">
            <article v-if="allPucksReady || allPucksReady && allPlayersReady" class="ready-wrapper">
                <h2 class="ready-header">
                    Your Team Is Assigned!
                </h2>

                <GroupButton
                    :cancellable="true"
                    :enabled="allPucksReady && allPlayersReady"
                    :onAgreement="confirmCharacterSelection"
                    title="I’m Ready To Play!"
                    decisionId="start-game"
                    confirmationModel="all"
                    :milestone="true"
                    :buttonClasses="'mtb-btn action big-ready'"
                    :showInfo="true"
                    @voteUpdate="updateReadys"
                />
            </article>
        </Transition>
        
        <Transition name="popup">
            <CharacterMenu
                v-if="characterMenuOpen"
                :isPlayer="false"
                :whichCharacter="menuCharacter"
                @close-event="closeCharacterMenu"

            />
        </Transition>
    </section>
</template>

<script>
import CharacterMenu from "@/components/character-ui/CharacterMenu.vue";
import GroupButton from "@/components/GroupButton.vue";
import PuckPicker from "@/components/connect-ux/PuckPicker.vue";
import VuexStorePuck from "@/shared/VuexStorePuck"; // just for testing atm.
import dpeService from '@/shared/dpeService';
import {mapGetters} from 'vuex';

export default {
    name: 'CharacterSelection',
    components: {
        PuckPicker,
        GroupButton,
        CharacterMenu
    },
    computed: mapGetters({
        allPucksReady: 'isPlayerPuckMapComplete',
        allPlayersReady: 'isEveryonePresent'
    }),
    data () {
        return {
            boardCode: this.$store.getters.getBoardCode,
            activeSession: this.$store.getters.getActiveSession,
            characters: ['red-bird', 'purple-bird', 'teal-bird', 'yellow-bird'],
            readys: [],
            me: this.$store.getters.getPlayerInfo,
            playthroughData: this.$store.getters.getActivePlaythrough,
            characterMenuOpen: false,
            menuCharacter: null
        };
    },
    watch: {
        readys: function (newReadys) {
            console.log('fire', newReadys);
            document.querySelectorAll('.puck-picker-container').forEach((el) => {
                el.classList.remove('is-ready');
            });
            
            newReadys.forEach((ready) => {
                document.getElementById(ready.character + '-container').classList.add('is-ready');
            });
        }
    },
    async mounted () {
        this.$connectionConsole.log('PTD', this.playthroughData);

        const
            loadSave = this.playthroughData.loadSave;

        // Just for testing atm.
        window.puck1 = new VuexStorePuck('Puck1', {index: 0});
        window.puck2 = new VuexStorePuck('Puck2', {index: 1});
        window.puck3 = new VuexStorePuck('Puck3', {index: 2});
        window.puck4 = new VuexStorePuck('Puck4', {index: 3});

        //Check for saves and restore saved session data.
        if (loadSave) {
            const
                me = dpeService.state.me,
                myState = loadSave.state[me],
                character = loadSave.characters.filter((character) => character.player === me)[0];

            dpeService.setGameState(myState.gameState);
            dpeService.setLocalState(myState.localState);
            if (character) {
                if (character.character) {
                    this.$store.dispatch('assignCharacterPlayer', {
                        character: character.character,
                        player: me
                    });
                }
            }
        }
        
        await this.$store.dispatch('appReady');
    },
    methods: {
        async confirmCharacterSelection () {
            await this.$store.dispatch('appBusy', {message: 'Continuing Game...'});
            
            // These are set outside of the session if their values are needed here...
            this.$connectionConsole.log('Teacher Configuration', this.playthroughData.config);
            this.$connectionConsole.log('Last Play State', this.playthroughData.lastPlayedState);

            // Save in case we need to reload game.
            this.$store.dispatch('stampCharacters');

            this.$gameConsole.log('Characters Selected', this.$store.getters.getCharacters.reduce((obj, character) => ({
                ...obj,
                [character.character]: character.player
            }), {}));

            setTimeout(() => {
                this.$router.push({
                    name: 'Continue',
                    query: {
                        entrance: 'start'
                    }
                });
            }, 2000);
        },

        async goback () {
            await this.$store.dispatch('leaveGame');
            this.$router.back();
        },
        
        updateReadys (not_readys) {
            let players = JSON.parse(JSON.stringify(this.$store.getters.getCharacters));
            
            console.log(not_readys, players);
            
            not_readys.forEach((who) => {
                players = players.filter(
                    function (entry) {
                        return entry.player && entry.player !== who;
                    });
            });
            
            console.log(players);
            this.readys = players;
        },

        choseOutfit (outfit) {
            this.$store.dispatch('updatePlayerOutfit', {
                outfit
            });
        },
        
        showCharacterMenu (bird) {
            this.characterMenuOpen = true;
            this.menuCharacter = bird;
        },
        
        closeCharacterMenu () {
            this.characterMenuOpen = false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.puck-picker-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.mtb-btn.back-btn {
    z-index: 10;
}
    
.ready-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $mtb-black;
    overflow: hidden;
    
    .ready-header {
        color: $mtb-white;
        font-size: 2em;
        position: absolute;
        top: 3.5vw;
        left: 13vw;
        z-index: 3;
        font-weight: bold;
        transition: transform 0.5s;
    }

    :deep .group-button-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        
        transform: translateY(-100%);
        transition: transform 0.5s;

        &.show {
            transform: translateY(0);
        }

        .big-ready {
            z-index: 1;
            top: 3.5vw;
        }

        #info-area {
            position: absolute;
            left: auto;
            top: 3vw;
            right: 0;
            padding: 1.5em 2em;
            border-radius: 3em 0 0 3em;
            background: #242226;
        }
    }

}
    
.board-code {
    color: #1b204b;
    font-size: 3.5vw;
    line-height: 1;
    text-align: center;
    padding: 1vw;
    border-radius: 4vw 0 0 4vw;
    background: #f8f8f8;
    position: absolute;
    right: 0;
    top: 3.5vw;
    padding: 0.25em 0.5em 0.25em 0.75em;
    letter-spacing: 0.05em;
    font-weight: bold;
    
    > em {
        position: absolute;
        color: $mtb-white;
        position: absolute;
        font-size: 1.25vw;
        letter-spacing: 0;
        display: block;
        top: 0;
        transform: translateY(-150%);    
    }
}
</style>
