import mtb_sample_icon_purple_bird_1 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_purple-bird-1.png';
import mtb_sample_icon_purple_bird_2 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_purple-bird-2.png';
import mtb_sample_icon_red_bird_1 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_red-bird-1.png';
import mtb_sample_icon_red_bird_2 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_red-bird-2.png';
import mtb_sample_icon_teal_bird_1 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_teal-bird-1.png';
import mtb_sample_icon_teal_bird_2 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_teal-bird-2.png';
import mtb_sample_icon_yellow_bird_1 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_yellow-bird-1.png';
import mtb_sample_icon_yellow_bird_2 from '@/components/gamemodes/performance/assets/images/mtb_sample-icon_yellow-bird-2.png';



export default {
    "images": [
        mtb_sample_icon_teal_bird_2,
        mtb_sample_icon_yellow_bird_1,
        mtb_sample_icon_yellow_bird_2,
        mtb_sample_icon_purple_bird_1,
        mtb_sample_icon_purple_bird_2,
        mtb_sample_icon_red_bird_1,
        mtb_sample_icon_red_bird_2,
        mtb_sample_icon_teal_bird_1
    ],
    "frames": [
        [ 0, 0, 200, 200, 0, 100, 100],
        [ 0, 0, 200, 200, 1, 100, 100],
        [ 0, 0, 200, 200, 2, 100, 100],
        [ 0, 0, 200, 200, 3, 100, 100],
        [ 0, 0, 200, 200, 4, 100, 100],
        [ 0, 0, 200, 200, 5, 100, 100],
        [ 0, 0, 200, 200, 6, 100, 100],
        [ 0, 0, 200, 200, 7, 100, 100],
        [ 0, 0, 0, 0, 0, 0, 0]
    ],
    "animations": {
        "teal-bird-2": 0,
        "yellow-bird-1": 1,
        "yellow-bird-2": 2,
        "purple-bird-1": 3,
        "purple-bird-2": 4,
        "red-bird-1": 5,
        "red-bird-2": 6,
        "teal-bird-1": 7,
        "none": 8
    }
};