import GameManager from '../../components/GameManager';

export default {
    "id": "action-layer",
    "components": [{
        "type": "HandlerController"
    }, {
        "type": "HandlerLogic",
        "alwaysOn": true
    }, {
        "type": "HandlerRender"
    }, {
        "type": "Camera",
        "width": 1706,
        "height": 1280,
        "overflow": true,
        "x": 0,
        "y": 0,
        "z": 1,
        "transitionX": 0,
        "transitionY": 0,
        "transitionAngle": 0
    }, {
        "type": "PuckConnector"
    }, GameManager, {
        "type": "EntityContainer",
        "entities": [
            //{type: 'fill-track', properties: {'id': 'fill-track'}}
            {type: 'debug-camera', properties: {}},
            {type: 'lakitu', properties: {}},
            {type: 'band', properties: {}},
            {type: 'band-arrow', properties: {}},
            {type: 'course', properties: {}},
            {type: 'background', properties: {}}
        ],
        "childEvents": ["world-loaded", "unload-layer", "layer-loaded", "layer-live", "begin-pan", "end-pan"]
    }, {
        "type": "RelayLinker",
        "linkId": "stage",
        "events": {
            "course-complete": ["show-stage"],
            "retry": "hide-stage",
            "prep-for-replay": "setup-birds",
            "playback-complete": "finished-song",
            "rl-update-replay-bird": "update-replay-bird"
        }
    }, {
        "type": "RelayLinker",
        "linkId": "results",
        "events": {
            "rl-set-meter": "set-meter",
            "rl-show-meter": "show-meter",
            "rl-hide-meter": "hide-meter",
            "rl-reset-meter": "reset-meter"
        }
    }, {
        "type": "RelayLinker",
        "linkId": "course",
        "events": {
            "setup-thumbnail": "update-thumbnail-run-text"
        }
    }],
    "properties": {
        "RED": 0xea1818,
        "BLUE": 0x0148ff,
        "YELLOW": 0xfcd308,

        "PURPLE": 0x810bd6,
        "ORANGE": 0xf65d0a,
        "GREEN": 0x82ce0b,
        "BROWN": 0x8c5c17,

        "WHITE": 0xffffff,
        "BLACK": 0x000000
    }
};
