<template>
    <div class="clues-container" :class="clueSetMode">
        <article class="team-clues">
            <h3 class="header-blob little clues-header">Our Clues</h3>
            <figure :class="{embiggen: embiggened}">
                <img @click="embiggen()" :src="clueData.team[0]" alt="" />
                <figcaption @click="embiggen()">...where you should finish this game.</figcaption>
                <button v-if="embiggened" @click="deEmbiggen()" class="mtb-btn action close">Close</button>
            </figure>
        </article>
        <article class="individual-clues clue-book">
            <h3 class="header-blob little on-yellow clues-header" >My Clues</h3>
            <ol class="clues scrollable with-bar">
                <li
                    class="clue"
                    v-for="(clue, index) in clueData.individual"
                    :key="index"
                >
                    <span>{{index + 1}}</span>
                    <em>{{clue}}</em>
                </li>
            </ol>
        </article>
    </div>
</template>

<script>
export default {
    name: 'ClueSet',
    components: {
        
    },
    props: {
        clueData: null
    },
    data () {
        return {
            embiggened: false
        };
    },
    computed: {
        clueSetMode () {
            const result = {};
            result[this.clueData.mode] = true;
            return result;
        }
    },
    mounted () {
        
    },
    methods: {
        embiggen () {
            this.embiggened = true;
            console.log('make big');
        },
        deEmbiggen () {
            this.embiggened = false;
            console.log('make small');
        }
    }
};
</script>

<style scoped lang="scss">
    .clues-container {
        position: absolute;
        top: 50%;
        left: 10vw;
        right: 0;
        height: 40vw;
        z-index: 1;
        pointer-events: none;
        
        transform: translateY(-70%);
        
        display: none;
        color: #2B2B2B;

        &.normal {
            display: block;
        }
        &.stacked {
            display: block;
        }
        
        > * {
            transition: all 0.5s;
            pointer-events: all;
        }
    }

    .clues-header {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        
        .team-clues & {
            transform: translate(-50%, -142%);
        }
    }

    .team-clues {
        position: absolute;
        width: 33vw;
        height: 25vw;
        background: url(~@/assets/gamemodes/practice/ui/mtb-ui_clue-post-its.svg) 50% 50% no-repeat;

        .normal & {
            left: 10vw;
            top: 12vw;
        }
        .stacked & {
            left: 3vw;
            top: 8vw;
            transform: scale(0.8);
            transform-origin: left top;
        }
        
        button.close {
            position: absolute;
            top: -2vw;
            right: -2vw;
        }

        figure {
            width: 55%;
            left: 50%;
            position: absolute;
            transform: translateX(-55%);
            top: 12%;
            bottom: 24%;
            transition: all 0.5s;
            
            &:hover {
                cursor: pointer;
            }
            
            img {
                width: 100%;
                border-radius: 0.5em;
            }
            
            figcaption {
                font-size: 0.75em;
                display: block;
                width: 75%;
                line-height: 1.3;
                background: #FFCD62;
                color: $mtb-black;
                margin: 1em auto 0;
                padding: 0.75em;
                text-align: center;
                display: block;
                position: relative;
                border-radius: 1.5em;
                border: 0.2em solid $mtb-black;
                pointer-events: none;
            }
            
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 4%;
                top: -1vw;
                width: 3vw;
                height: 4vw;
                background: url(~@/assets/gamemodes/practice/ui/mtb-ui_paper-clip.svg) 50% 50% no-repeat;
                
                pointer-events: none;
            }
            
            &.embiggen {
                position: fixed;
                z-index: 100;
                width: 56%;
                
                &:after {
                    opacity: 0;
                }
                
                img {
                    box-shadow: 0 0 3em rgb(0 0 0 / 75%);
                    border-radius: 1.5em;
                }
                
                figcaption {
                    transform: scale(1.2);
                    margin-top: -1em;
                }
            }
        }
        
    }

    
    .clue-book {
        .normal & {
            left: 48vw;
            top: 3vw;
        }
        .stacked & {
            left: 4vw;
            top: 23vw;
            transform: scale(0.8);
            transform-origin: left bottom;
        }
    }

</style>