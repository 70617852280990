<template>
    <div ref="puck-ble" class="puck-ble">
        <h3>Bluetooth</h3>
        <div class="bits" v-for="(row, i) in bits" :key="i">
            <span v-for="(bit, j) in row" :key="j">{{bit ? "❎" : "⬜"}}</span>
        </div>
        <ul>
            <li v-if="puckA">Puck A <span>{{puckA.position}} @ {{puckA.rotation}}°</span> <span>{{puckA.connected ? '✅' : '⬜'}}{{['0️⃣', '1️⃣', '2️⃣', '3️⃣'][puckA.button]}}</span> <span>({{puckA.character}})</span></li>
            <li v-if="puckB">Puck B <span>{{puckB.position}} @ {{puckB.rotation}}°</span> <span>{{puckB.connected ? '✅' : '⬜'}}{{['0️⃣', '1️⃣', '2️⃣', '3️⃣'][puckB.button]}}</span> <span>({{puckB.character}})</span></li>
            <li v-if="puckC">Puck C <span>{{puckC.position}} @ {{puckC.rotation}}°</span> <span>{{puckC.connected ? '✅' : '⬜'}}{{['0️⃣', '1️⃣', '2️⃣', '3️⃣'][puckC.button]}}</span> <span>({{puckC.character}})</span></li>
            <li v-if="puckD">Puck D <span>{{puckD.position}} @ {{puckD.rotation}}°</span> <span>{{puckD.connected ? '✅' : '⬜'}}{{['0️⃣', '1️⃣', '2️⃣', '3️⃣'][puckD.button]}}</span> <span>({{puckD.character}})</span></li>
        </ul>
        <ul>
            <li
                v-for="(character, idx) in $store.getters.getCharacters"
                :key="idx"
                :style="getStyle(character.player)"
            >
                {{character.character}}: <span>{{character.puck ? `Puck ${character.puck}` : ''}}</span><span>{{character.player}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "PuckBLE",
    computed: {
        ...mapGetters({
            puckA: 'getPuckA',
            puckB: 'getPuckB',
            puckC: 'getPuckC',
            puckD: 'getPuckD',
            relayedPositions: 'getRelayedPositions'
        }),
        bits () {
            const
                bits = [[], [], [], []],
                data = this.relayedPositions,
                length = data.length || Object.keys(data).length; // somehow this comes in as an object instead of an array.

            for (let i = 0; i < length; i++) {
                const
                    offset = (i % 2) * 16,
                    row = i >> 1,
                    number = data[i];
                
                for (let j = 0; j < 16; j++) {
                    bits[row][offset + j] = (number >> (15 - j)) & 1;
                }
            }

            return bits;
        }
    },
    methods: {
        getStyle (id) {
            return `background: #${id?.substring(0, 6) ?? '000000'};`;
        }
    }
};

</script>


<style lang="scss">

.puck-ble h3 {
    text-align: center;
    margin: 1em 0 0.5em 0;
    border-bottom: 0.1em solid $mtb-white;
}

.puck-ble li {
    border: 0.1em solid yellow;
    text-align: center;
    margin: 0.4em;
    font-size: 0.8em;
    display: inline-block;
    border-radius: 1em;
    padding: 1em;
    vertical-align: top;
}

.puck-ble ul span {
    display: block;
    margin-top: 0.4em;
    color: #ffffaa;
}

.bits {
    line-height: 1.05em;
}

.bits span {
    margin: 0 -0.15em;
}

</style>