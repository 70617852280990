/**
 * @class ComponentExample
 * @uses platypus.Component
 */
import {createComponentClass} from 'platypus';


export default createComponentClass({
    
    id: 'Lakitu',
    
    properties: {
        
    },
    
    publicProperties: {
        offsetX: 0,
        offsetY: 0,
    },
    
    initialize: function (/*definition, callback*/) {
        this.focus = null;
        this.vOffset = 0;
    },

    events: {
        "camera-update": function (cameraData) {
            const viewport = cameraData.viewport;

            this.vOffset = -viewport.height / 5;

        },
        "peer-entity-added": function (entity) {
            if (entity.type === 'map-center') {
                this.owner.x = entity.x;
                this.owner.y = entity.y;
            }
        },
        "layer-loaded": function () {
            this.owner.parent.triggerEvent('follow', {"entity": this.owner, "mode": "locked"});
        },
        "focus-on": function (entity) {
            this.focus = entity;
            this.offsetX = this.owner.x - entity.x;
            this.offsetY = this.owner.y - (entity.y + this.vOffset);

            this.owner.triggerEvent('tween', [
                {
                    "to": {"offsetX": 0, "offsetY": 0},
                    "time": 1500,
                    "easing": "Quadratic.InOut"
                }
            ]);
        },
        "handle-logic": function () {
            if (this.focus) {
                this.owner.x = this.focus.x + this.offsetX;
                this.owner.y = this.focus.y + this.vOffset + this.offsetY;
            }
        }
    }
});
  