const
    decisions = {
        state: () => ({
            decisions: {}
        }),

        actions: {
            initializeDecisions ({commit}) {
                return {
                    'decisions.votes': ({value, player}) => commit('setPlayerDecisions', {
                        decisions: value,
                        player
                    })
                };
            },

            updateDecisions ({dispatch}, decisions) {
                const
                    update = {};
                    
                Object.keys(decisions).forEach((key) => {
                    update[`decisions.votes.${key}`] = decisions[key];
                });

                return dispatch('immediateUpdate', update);
            },

            clearDecisions ({commit}) {
                commit('setDecisions', {});
            }
        },

        mutations: {
            setPlayerDecisions (state, {decisions, player}) {
                state.decisions = {
                    ...state.decisions,
                    [player]: {
                        ...state.decisions[player],
                        ...decisions
                    }
                };
            },

            setDecisions (state, decisions) {
                state.decisions = decisions;
            }
        },

        getters: {
            getDecisions (state) {
                return state.decisions;
            },

            getDecisionsByCharacter (state) {
                const
                    decisions = state.decisions,
                    combined = {};

                // eslint-disable-next-line guard-for-in
                for (const player in decisions) {
                    // eslint-disable-next-line guard-for-in
                    for (const decision in decisions[player]) {
                        combined[decision] = combined[decision] || {};
                        combined[decision][player] = decisions[player][decision];
                    }
                }

                return combined;
            }
        }
    };

export {decisions};