<template>
    <div id="player-login">
        <form v-if="autoLoginTried && !playerId">
            <span class="input-group">
                <label>
                    Username
                </label>
                <input type="text" v-model="username" />
                <label>
                    Password
                </label>
                <input type="password" v-model="password"
                        @change="updatePassword($event)" />
            </span>
            <button
                :class="{ active: canSubmit }"
                class="mtb-btn form-submit"
                type="submit"
                @click="onFormSubmit($event)"
            >
                Lets Play!
            </button>
            <div class="on-error" v-if="errorMessage">{{errorMessage}}</div>
        </form>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "PlayerLogin",
    data () {
        return {
            errorMessage: '',
            password: '',
            canSubmit: false,
            username: null,
            refresher: 0
        };
    },
    components: {},
    computed: mapGetters({
        autoLoginTried: 'hasTriedAutoLogin',
        playerId: 'getPlayerId'
    }),

    created () {
    },

    mounted () {
    },
    
    methods: {
        updatePassword (e) {
            if (e.target.value.length < 4) {
                this.canSubmit = false;
                return false;
            }
            
            this.canSubmit = true;

            return true;
        },
        
        async onFormSubmit (e) {
            e.preventDefault();

            await this.login({
                username: this.username,
                password: this.password
            });
        },

        async login (credentials) {
            try {
                await this.$store.dispatch('loginPlayer', credentials);
                return true;
            } catch (e) {
                console.warn(e);
                this.errorMessage = e.message;
                return false;
            }
        }
    },
};

</script>
<style scoped lang="scss">
    #player-login {
        width: 20vw;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        label {
            text-transform: uppercase;
            color: #808080;
            font-weight: bold;
            margin-top: 1em;
        }
    }
    
    .mtb-btn {
        width: 50%;
        margin: 2em auto;
    }

    .on-error {
        background: red;
        padding: 1em;
        border-radius: 1em;
        text-align: center;
    }
</style>
