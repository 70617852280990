<template>
    <figure>
        <img :src="avatars[me?.avatar] ?? avatars['avatar-1']" />
        <figcaption>{{ me?.name ?? 'Player' }}'s Avatar</figcaption>
    </figure>
</template>

<script>
import avatars from '@/shared/data/avatars';

export default {
    name: 'PlayerAvatar',
    props: {
        me: [Object, String] // accept string, but set to default until object is received.
    },
    data () {
        return {
            avatars
        };
    }
};
</script>

<style scoped lang="scss">
figure {
    border-radius: 100%;
    overflow: hidden;

    img {
        width: 100%;
    }

    figcaption {
        position: absolute;
        left: -999em;
    }
}
</style>
