export default {
    "id": "sample-background",
    "components": [
        {
            "type": "RenderSprite",
            "spriteSheet": "sample-indicator",
            "animationMap": {
                "active": "mtb_music-mode_sample-node_active",
                "default": "mtb_music-mode_sample-node"
            }
        }, {
            "type": "Tween"
        }],
    "properties": {
        x: 0,
        y: 0,
        z: 30,
        scaleX: 1,
        scaleY: 1
        
    }
};