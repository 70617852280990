import * as Tone from 'tone';
import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'SoundTrack', //TODO: Change the name of the component!
    
    properties: {
    },
    
    publicProperties: {
        "musicPlaying": false,
        "audioReady": false
    },
    
    initialize: function (/*definition, callback*/) {
        this.fillKey = this.owner.parent.parent.settings.level.sampleInformation.fillSample;
        this.processedCourseData = this.processCourse();
        this.samples = null;
        this.players = {};
        this.instrumentKeys = this.processedCourseData.samples;
        
            
        this.distortionA = new Tone.Distortion(1);
        this.distortionB = new Tone.Chebyshev(4);
    
        // document.getElementById('stage')?.addEventListener('click', async () => {
        //     await Tone.start();
        //     console.log('audio is ready');

        //     if (!this.samples) {
        //         this.scheduleSounds();
        //     }
            
        // });

    },

    events: {
        "ready-audio": async function () {
            if (!this.audioReady) {
                await Tone.start();
                
                if (!this.samples) {
                    this.scheduleSounds();
                }
            } else {
                this.audioIsReady();
            }
        },

        "play-correctly": function () {
            let x = 0;
            console.log(">>PLAYING CORRECTLY<<");

            for (x = 0; x < this.instrumentKeys.length; x++) {
                //Hook up all the correct instruments and disconnect all the bad ones.
                this.players[this.instrumentKeys[x]].toDestination();
                this.players["bad-" + this.instrumentKeys[x]].disconnect();
            }

            this.players[this.fillKey].toDestination();

            if (!this.musicPlaying) {
                this.musicPlaying = true;
                Tone.Transport.start();
            }
            
        },
        "play-poorly": function (wrongHow) {
            const samplesToPlay = [...wrongHow.correctSamples];
            let x = 0;
            
            console.log(">>PLAYING POoOoOooRLY<<");

            for (x = 0; x < this.instrumentKeys.length; x++) {
                //Turn off everything.
                this.players[this.instrumentKeys[x]].disconnect();
                this.players["bad-" + this.instrumentKeys[x]].disconnect();
            }

            //Turn on bad fill when we're playing incorrectly.
            this.players["bad-" + this.fillKey].chain(this.distortionA, this.distortionB, Tone.Destination);

            for (x = 0; x < wrongHow.samples.missing.length; x++) {
                //If a sample is missing, remove it from the list to play.
                samplesToPlay.splice(samplesToPlay.indexOf(wrongHow.samples.missing[x]), 1);
            }


            for (x = 0; x < wrongHow.samples.extra.length; x++) {
                if (!this.players[wrongHow.samples.extra[x]]) {
                    continue; //This sample isn't used in this song, ignore it.
                }

                //If we're playing extra samples, they should play incorrectly.
                samplesToPlay.push(wrongHow.samples.extra[x]);
            }

            for (x = 0; x < samplesToPlay.length; x++) {
                this.players["bad-" + samplesToPlay[x]].chain(this.distortionA, this.distortionB, Tone.Destination);
            }

            if (!this.musicPlaying) {
                this.musicPlaying = true;
                Tone.Transport.start();
            }


            // if (wrongHow.direction) {
            //     this.players[this.fillKey].disconnect();
            //     this.players["bad-" + this.fillKey].chain(this.distortionA, this.distortionB, Tone.Destination);
            //     onlyMissing = false;
            //     console.log("-Wrong Direction- Distort Fill");
            // }

            // for (x = 0; x < wrongHow.samples.missing.length; x++) {
            //     // if (!this.players[wrongHow.samples.missing[x]]) {
            //     //     continue; //This sample isn't used in this song, ignore it.
            //     // }

            //     this.players[wrongHow.samples.missing[x]].disconnect();
            //     console.log("-Missing Sample- Disconnect " + wrongHow.samples.missing[x]);
            // }

            // for (x = 0; x < wrongHow.samples.extra.length; x++) {
            //     if (!this.players[wrongHow.samples.extra[x]]) {
            //         continue; //This sample isn't used in this song, ignore it.
            //     }

            //     this.players[wrongHow.samples.extra[x]].disconnect();
            //     this.players['bad-' + wrongHow.samples.extra[x]].chain(this.distortionA, this.distortionB, Tone.Destination);
            //     onlyMissing = false;
            //     console.log("-Extra Sample- Distort " + wrongHow.samples.missing[x]);
            // }

            // ////////////////////////////////////////////////////////////////////
            // //PROBLEM: What do we do in the case the direction is correct, and all playing audio is correct,
            // //but there is missing audio? Currently I make the fill track sound bad. (see below)
            // ////////////////////////////////////////////////////////////////////
            // if (onlyMissing) {
            //     this.players[this.fillKey].disconnect();
            //     this.players["bad-" + this.fillKey].chain(this.distortionA, this.distortionB, Tone.Destination);
            //     console.log("-Correct *but* Missing Samples- Distort Fill");
            // }

            // if (!this.musicPlaying) {
            //     this.musicPlaying = true;
            //     Tone.Transport.start();
            // }
        },
        "stop-soundtrack": function () {
            if (this.musicPlaying) {
                console.log("Stop the Music!");
                this.musicPlaying = false;
                Tone.Transport.pause();
            }
        },

        "changed-edge": function () {
            console.log("Changed edge at: " + Tone.Transport.seconds);
        },
        "restart-song": function () {
            Tone.Transport.seconds = 0;
        }
       
    },
    
    methods: {
        audioIsReady: function () {
            console.log('audio is ready');
            this.audioReady = true;
            this.owner.parent.triggerEvent('start-run');
        },
        scheduleSounds: function () {
            this.samples = new Tone.ToneAudioBuffers(this.owner.parent.parent.settings.audio, () => {
                const musicalUnit = this.owner.parent.parent.settings.level.musicalUnitTime / 1000;
                let x = 0,
                    sample = null,
                    player = null,
                    badPlayer = null,
                    myArrangement = null;

                // eslint-disable-next-line guard-for-in
                for (sample in this.owner.parent.parent.settings.audio) {
                    player = new Tone.Player();
                    player.buffer = this.samples.get(sample);
                    player.loop = true;
                    player.sync();
                    player.toDestination();

                    if (sample === this.fillKey) {
                        player.start(0);
                        //player.volume.value = -10;
                    } else {
                        myArrangement = this.processedCourseData.arrangement[sample];
                        if (!myArrangement) {
                            continue;
                        }
                        for (x = 0; x < myArrangement.length; x++) {
                            if (x === 0) {
                                if (myArrangement[x]) {
                                    player.start(0);
                                }

                            } else if (x === myArrangement.length - 1 && myArrangement[x]) {
                                if (!myArrangement[x - 1]) {
                                    player.start(x * musicalUnit);
                                }
                                player.stop((x + 1) * musicalUnit);

                            } else if (myArrangement[x] && !myArrangement[x - 1]) {
                                player.start(x * musicalUnit);
                            } else if (!myArrangement[x] && myArrangement[x - 1]) {
                                player.stop(x * musicalUnit);
                            }
                        }
                    }

                    this.players[sample] = player;

                    //Make a bad player for each sample. They simply loop through the whole song and we can turn them on and off as need be.
                    badPlayer = new Tone.Player();
                    badPlayer.buffer = this.samples.get(sample);
                    badPlayer.loop = true;
                    badPlayer.sync();
                    badPlayer.chain(this.distortionA, this.distortionB, Tone.Destination);
                    badPlayer.start(0);
                    this.players['bad-' + sample] = badPlayer;
                }

                this.audioIsReady();
            });
        },
        processCourse: function () {
            const samples = this.owner.parent.parent.settings.level.sampleInformation.samplesAvailable,
                arrangement = {},
                course = this.owner.parent.courseData;
            let x = 0,
                y = 0,
                z = 0,
                sampleKey = null,
                courseSamples = null,
                edgeLength = 0,
                progress = 0;

            for (x = 0; x < samples.length; x++) {
                arrangement[samples[x]] = [];
            }

            // Will create something like this. 1s indicate the track is playing, 0s indicate that it is silent.
            // arrangement = {
            //     "a": [0, 0, 1, 1, 0],
            //     "b": [1, 0, 1, 0, 0],
            //     "c": [0, 0, 1, 1, 1],
            //     "d": [0, 1, 0, 0, 1]
            // }
            for (x = 0; x < course.length; x++) {
                courseSamples = course[x].samples;
                edgeLength = course[x].length;

                for (y = 0; y < edgeLength; y++) {
                    // eslint-disable-next-line guard-for-in
                    for (sampleKey in arrangement) {
                        arrangement[sampleKey][progress] = 0;
                    }

                    for (z = 0; z < courseSamples.length; z++) {
                        arrangement[courseSamples[z]][progress] = 1;
                    }
                    progress += 1;
                }
            }

            samples.push(this.fillKey);

            return {samples, arrangement};
        }
        
    },
    
    publicMethods: {
        
        
    }
});
