

export default {
    id: "success-meter-bar",
    components: [
        {
            type: "RenderSprite",
            spriteSheet: "success-meter",
            "animationMap": {
                "default": "bar"
            }
        }, {
            "type": "RelayLinker",
            "linkId": "band",
            "events": {
            }
        }
    ],
    properties: {
        renderParent: "the-success-meter",
        scaleX: 1,
        scaleY: 1,
        x: -1,
        z: -1
    }
};