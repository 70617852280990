<template>
    <section id="character-menu" class="modal-wrapper" :class="[whichCharacter, {'is-player': isPlayer}]">
        <button class="mtb-btn action close" @click="closeMenu()">Close</button>

        <figure ref="rig" class="rig-container" @click="toggleEmote">
            <figcaption class="header-blob">
                <em>{{ characterInfo.name }}</em>
                <b>{{ characterInfo.role }}</b>
            </figcaption>
        </figure>

        <div class="content-wrapper">
            <nav v-if="isPlayer" class="mtb-nav character-nav">
                <button @click="setTab('sounds')" class="nav-tabs" :class="{'active': onTab === 'sounds'}">My Sounds</button>
                <button @click="setTab('outfits')" class="nav-tabs" :class="{'active': onTab === 'outfits'}">My Outfits</button>
                <button @click="setTab('details')" class="nav-tabs" :class="{'active': onTab === 'details'}">Characteristics</button>
            </nav>

            <article v-if="onTab === 'sounds'" id="sounds-container" class="content-container">
                <SoundsGrid :characterData="characterData" />
            </article>

            <article v-if="onTab === 'outfits'" id="outfits-container" class="content-container">
                <ul class="my-outfits content-grid">
                    <li class="my-outfit content-grid-item">Outfit</li>
                    <li class="my-outfit content-grid-item">Outfit</li>
                    <li class="my-outfit content-grid-item">Outfit</li>
                    <li class="my-outfit content-grid-item">Outfit</li>
                    <li class="my-outfit content-grid-item">Outfit</li>
                    <li class="my-outfit content-grid-item">Outfit</li>
                </ul>
            </article>

            <article v-if="onTab === 'details'" id="details-container" class="content-container no-blob">
                <div class="blob-wrap">
                    <header>
                        <h3>{{ characterInfo.instrument.label }}</h3>
                        <img @click="playInstrument" :src="instrumentIcon" alt="Character's Intrument" />
                    </header>

                    <div class="copy">
                        <p v-for="(paragraph, index) in characterInfo.characteristics" :key="index">{{ paragraph }}</p>
                    </div>
                </div>

                <div class="blob-wrap" :class="characterInfo.tool.id">
                    <header>
                        <h3>{{ characterInfo.tool.label }}</h3>
                        <img :src="toolIcon" alt="Character's Tool" />
                    </header>
                    
                    <div class="copy">
                        <p v-for="(paragraph, index) in characterInfo.tool.description" :key="index">{{ paragraph }}</p>
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import {mapGetters} from 'vuex';
import SoundsGrid from './SoundsGrid.vue';

import * as PIXI from 'pixi.js';
import {
    TextureAtlas,
    Spine
} from "pixi-spine";
import {
    AtlasAttachmentLoader,
    SkeletonJson
} from "@pixi-spine/runtime-3.8";
    
import character_info from '@/shared/data/characters';
import character_rigs from '@/shared/data/character_rigs';

import outfits from "@/shared/data/outfits";
    
import backpack_icon from '@/assets/character/tools/mtb_tool-icon_backpack.svg';
import gifts_icon from '@/assets/character/tools/mtb_tool-icon_gifts.svg';
import journal_icon from '@/assets/character/tools/mtb_tool-icon_journal-app.svg';
import recording_icon from '@/assets/character/tools/mtb_tool-icon_recording-device.svg';
    
import xylophone_icon from '@/assets/character/instruments/mtb_instrument-icon_xylophone.svg';
import tt_icon from '@/assets/character/instruments/mtb_instrument-icon_tambourine_triangle.svg';
import tap_icon from '@/assets/character/instruments/mtb_instrument-icon_tapdance.svg';
import whistle_icon from '@/assets/character/instruments/mtb_instrument-icon_whistle.svg';
    
export default {
    name: 'CharacterMenu',
    components: {
        SoundsGrid
    },
    props: {
        whichCharacter: {
            type: String,
            required: true
        },
        isPlayer: { 
            type: Boolean,
            default: false
        },
        activeTab: {
            type: String,
            default: "details"
        }
    },
    data () {
        return {
            rig: null,
            emote: "idle",
            onTab: this.activeTab,
            characterInfo: character_info[this.whichCharacter],
            rigData: null, 
            rigAtlas: null,
            rigSpritesheet: null
        };
    },
    computed: {
       ...mapGetters({
            characters: 'getCharacters'
        }),
        characterData () {
            return this.characters.filter((character) => character.character === this.whichCharacter)?.[0];
        },
        foundOutfitIds () {
            return Object.keys(this.characterData.collectibles.outfits).filter((key) => this.myCharacter.collectibles.outfits[key] === 1);
        },
        foundOutfits () {
            return this.foundOutfitIds.map((id) => outfits[id]);
        },
        toolIcon () {
            let icon = null;
            
            switch (this.characterInfo.tool.id) {
                case "backpack":
                    icon = backpack_icon;
                    
                    break;
                case "gifts":
                     icon = gifts_icon;

                    break;
                case "journal-app":
                     icon = journal_icon;

                    break;
                case "recording-device":
                    icon = recording_icon;
                    
                    break;
            }
            
            return icon;       
        },
        instrumentIcon () {
            let instrument = null;
            
            switch (this.characterInfo.instrument.id) {
                case "whistler":
                    instrument = whistle_icon;
                    
                    break;
                case "tap-dance":
                     instrument = tap_icon;

                    break;
                case "tambourine-triangle":
                     instrument = tt_icon;

                    break;
                case "xylophone":
                    instrument = xylophone_icon;
                    
                    break;
            }
            
            return instrument;       
        }
    },
    created () {
    },
    mounted () {  
        this.makeBird(this.whichCharacter);
    },
    beforeUnmount() {
        setTimeout(() => {
            this.rig.destroy(true); // destroy pixi app before unmount to prevent memory issues on hot reload. Timeout is to prevent visual flash before component is gone.
        }, 500);
        
    },
    methods: {
        setTab(tab) {
           this.onTab = tab;
        },
        closeMenu() {
            this.$emit('close-event');
        },
        toggleEmote () {
            if (this.emote === "idle") {
                this.emote = "excited";
            } else {
                this.emote = "idle";
            }
            
            this.rig.stage.children[0].state.setAnimation(0, this.emote, true);
            
        },
        playInstrument () {
            switch (this.whichCharacter) {
                case "purple-bird":
                    this.emote = "tapdance_1_b";
                    
                    break;
                case "yellow-bird":
                    this.emote = "xylophone_1";

                    break;
                case "red-bird":
                    this.emote = "whistle_1";

                    break;
                case "teal-bird":
                    this.emote = "tambourine";
                    
                    break;
            }
            
            this.rig.stage.children[0].state.setAnimation(0, this.emote, true);
        },
        makeBird(bird) {
            switch (bird) {
                case "purple-bird":
                    this.rigData = character_rigs.purple.data;
                    this.rigAtlas = character_rigs.purple.atlas;
                    this.rigSpritesheet = character_rigs.purple.ss;
                    
                    break;
                case "yellow-bird":
                    this.rigData = character_rigs.yellow.data;
                    this.rigAtlas = character_rigs.yellow.atlas;
                    this.rigSpritesheet = character_rigs.yellow.ss;

                    break;
                case "red-bird":
                    this.rigData = character_rigs.red.data;
                    this.rigAtlas = character_rigs.red.atlas;
                    this.rigSpritesheet = character_rigs.red.ss;

                    break;
                case "teal-bird":
                    this.rigData = character_rigs.teal.data;
                    this.rigAtlas = character_rigs.teal.atlas;
                    this.rigSpritesheet = character_rigs.teal.ss;
                    
                    break;
            }
            
            let rawSkeletonData = this.rigData,
                rawAtlasData = this.rigAtlas,
                spritesheet = this.rigSpritesheet;

            let bird_rig = this.rig = new PIXI.Application({
                width: 700,
                height: 900,
                backgroundAlpha: 0
            });

            let rig_container = this.$refs.rig;

            rig_container.appendChild(bird_rig.view);

            var spineAtlas = new TextureAtlas(rawAtlasData, function(texture, callback) {
                callback(
                    new PIXI.BaseTexture(spritesheet, {
                        alphaMode: PIXI.ALPHA_MODES.PMA
                    })
                );
            });

            var spineAtlasLoader = new AtlasAttachmentLoader(spineAtlas)
            var spineJsonParser = new SkeletonJson(spineAtlasLoader);

            spineJsonParser.scale = 0.8;

            var spineData = spineJsonParser.readSkeletonData(rawSkeletonData);

            const animation = new Spine(spineData);

            bird_rig.stage.addChild(animation);

            animation.position.set(bird_rig.renderer.width * 0.5, bird_rig.renderer.height - (animation.height * 0.15));
            
            animation.skeleton.setSkinByName("original");
            animation.skeleton.setSlotsToSetupPose();
            
            animation.state.setAnimation(0, "idle", true);

            // add the animation to the scene and render...
            bird_rig.stage.addChild(animation);
        } 
    
    }
};
</script>

<style scoped lang="scss">
#character-menu {
    z-index: 1001;

    &:after {
        content: '';
        display: block;
        background: $mtb-red-bird-color;
        position: absolute;
        z-index: -1;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: -10vw;
        
        @media (min-aspect-ratio: 13/8) {
            margin-top: -6vw;
        }
    }
    
    .content-wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        @media (min-aspect-ratio: 13/8) {
            transform-origin: right center;
            transform: translateY(-50%) scale(0.8);
        }
        
        @media (min-aspect-ratio: 2/1) {
            transform-origin: right center;
            transform: translateY(-50%) scale(0.7);
        }
        
        @media (min-aspect-ratio: 7/3) {
            transform-origin: right center;
            transform: translateY(-50%) scale(0.6);
        }
        
    }

    .character-nav {
        margin: 3vw 6vw 0;
        position: relative;
        z-index: 1;
        top: 0;
    }

    .mtb-btn.close {
        position: absolute;
        right: 1.5vw;
        top: 1.5vw;
        z-index: 1;
    }
    
    &.red-bird {
        &:after { background: $mtb-red-bird-color; }
        .content-container { background: $mtb-red-bird-color-dark; }
        .blob-wrap { background: $mtb-red-bird-color-dark !important; }
        :deep .content-grid-item { background: $mtb-red-bird-color !important; }
    }
    &.purple-bird {
        color: $mtb-ui-dark-purp;
        
        &:after { background: $mtb-purple-bird-color; }  
        .content-container { background: $mtb-purple-bird-color-dark; }
        .blob-wrap { color: $mtb-ui-dark-purp; background: $mtb-purple-bird-color-dark !important; }
        :deep .content-grid-item { background: $mtb-purple-bird-color !important; } 
    }
    &.teal-bird {
        &:after { background: $mtb-teal-bird-color; }
        .content-container { background: $mtb-teal-bird-color-dark; }
        .blob-wrap { background: $mtb-teal-bird-color-dark !important; }
        :deep .content-grid-item { background: $mtb-teal-bird-color !important; } 
    }
    &.yellow-bird {
        &:after { background: $mtb-yellow-bird-color; }
        .content-container { background: $mtb-yellow-bird-color-dark; }
        .blob-wrap { background: $mtb-yellow-bird-color-dark !important; }
        :deep .content-grid-item { background: $mtb-yellow-bird-color !important; } 
    }
}

.content-container { 
    display: block;
    position: relative;
    width: 62vw;
    height: auto;
    min-height: 45vw;
    margin: 2vw 3vw;
    border-radius: 4.5vw;
    background: $mtb-red-bird-color-dark;
    
    &.no-blob {
        background: none !important;
        
        .blob-wrap {
            background: $mtb-red-bird-color-dark;
            margin-bottom: 2vw;
            border-radius: 4.5vw;
            padding: 3vw;
            display: flex;
            align-items: center;
            
            header {
                width: 16vw;
                height: 16vw;
                background: $mtb-white;
                border: 0.15em solid $mtb-ui-dark-purp;
                border-radius: 100%;
                position: relative;
                flex: 0 0 auto;
                
                img {
                    width: 100%;
                    margin: -1em auto 0;
                    display: block;
                    position: relative;
                    z-index: 1;
                }
                
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 0;
                    top: 0.5em;
                    bottom: 0.5em;
                    left: 0.5em;
                    right: 0.5em;

                    border: 0.15em solid $mtb-ui-dark-purp;
                    border-radius: inherit;
                    background: $mtb-ui-yellow;
                    color: $mtb-ui-dark-purp;
                    box-shadow: inset 0 0 0 0.2em #f3cf50;
                }
                
                h3 {            
                    position: absolute;
                    font-size: 0.875em;
                    font-weight: bold;
                    width: fit-content;
                    min-width: 80%;
                    border-radius: 1.5vw;
                    left: 50%;
                    text-align: center;
                    line-height: 1;
                    padding: 0.5em 1em;
                    bottom: -0.5em;
                    z-index: 1;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    background-color: $mtb-ui-dark-purp;
                    color: $mtb-ui-yellow;
                }
            }
            
            p {
                display: block;
                line-height: 1.4;
                margin: 0 5% 1vw 10%;     
            }
            
            &.gifts header img {
                width: 90%;
                margin: 0em auto 0;
            }
            
            &.backpack header img {
                width: 94%;
                margin: -0.75em auto 0;
            }
        }
    }
        
    .content-grid {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        
        .content-grid-item {
            width: 15.5vw;
            height: 15.5vw;
            flex: 0 0 auto;
            margin: 1vw;
            border-radius: 1.5vw;
            display: flex;
            align-items: center;
            justify-content: center;
            
            background: $mtb-red-bird-color;
        }
    }
}

.rig-container {
    position: absolute;
    right: 0vw;
    width: 34vw;
    top: 50%;
    padding-bottom: 3vw;
    transform: translateY(-60%);
    
    @media (min-aspect-ratio: 13/8) {
        transform-origin: left center;
        transform: translateY(-60%) scale(0.8);
    }
    
    @media (min-aspect-ratio: 2/1) {
        transform-origin: left center;
        transform: translateY(-60%) scale(0.7);
    }
    
    @media (min-aspect-ratio: 7/3) {
        transform-origin: left center;
        transform: translateY(-60%) scale(0.6);
    }

    figcaption {
        position: absolute;
        bottom:-2em;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);

        em {
            color: $mtb-white;
            text-transform: capitalize;
        }

        b {
            font-size: 0.7em;

        }
    }

    :deep canvas {
        width: 100%;
        overflow: hidden;
        pointer-events: none;
    }
}
</style> 