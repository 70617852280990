import ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_PURPLE_BIRD_PNG from '@/assets/character/static/mtb_bird-static_purple-bird.png';
import ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_RED_BIRD_ALT_PNG from '@/assets/character/static/mtb_bird-static_red-bird_alt.png';
import ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_RED_BIRD_PNG from '@/assets/character/static/mtb_bird-static_red-bird.png';
import ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_TEAL_BIRD_PNG from '@/assets/character/static/mtb_bird-static_teal-bird.png';
import ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_YELLOW_BIRD_PNG from '@/assets/character/static/mtb_bird-static_yellow-bird.png';

export default {
    "purple_static_base": {
        "id": "purple_static_base",
        "character": "purple-bird",
        "title": "Classic",
        "default": "yes",
        "image": ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_PURPLE_BIRD_PNG,
        "spine": ""
    },
    "red_static_alt": {
        "id": "red_static_alt",
        "character": "red-bird",
        "title": "Rock",
        "default": "",
        "image": ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_RED_BIRD_ALT_PNG,
        "spine": ""
    },
    "red_static_base": {
        "id": "red_static_base",
        "character": "red-bird",
        "title": "Classic",
        "default": "yes",
        "image": ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_RED_BIRD_PNG,
        "spine": ""
    },
    "teal_static_base": {
        "id": "teal_static_base",
        "character": "teal-bird",
        "title": "Classic",
        "default": "yes",
        "image": ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_TEAL_BIRD_PNG,
        "spine": ""
    },
    "yellow_static_base": {
        "id": "yellow_static_base",
        "character": "yellow-bird",
        "title": "Classic",
        "default": "yes",
        "image": ASSETS_CHARACTER_STATIC_MTB_BIRD_STATIC_YELLOW_BIRD_PNG,
        "spine": ""
    }
};