/**
 * @class ComponentExample
 * @uses platypus.Component
 */
import {Entity, Vector, createComponentClass, union} from 'platypus';
import {Graphics} from 'platypus/node_modules/pixi.js';
import sounds from '@/shared/data/sounds.json';

export default createComponentClass({
    
    id: 'Course',
    
    properties: {
        "courseData": null
    },
    
    publicProperties: {
    },
    
    initialize: function (/*definition, callback*/) {
        const gfx = new Graphics();

        let x = 0,
            point = null,
            lastSampleIndicator = null,
            theState = null,
            lastUsedSamples = [],
            icon = null,
            thumbnailState = null;

        
        this.courseData = this.owner.parent.courseData;

        this.processedCourseData = this.processCourse();
        this.points = this.processedCourseData.points;
        this.samples = this.processedCourseData.samples;
        this.thumbnail = null;

        this.sampleIndicators = [];
        
        //Drawing the course
        gfx.lineStyle({"width": 28, "color": this.owner.parent.WHITE, "join": "bevel"});
        gfx.moveTo(this.points[0].x, this.points[0].y);

        for (x = 0; x < this.points.length; x++) {
            point = this.points[x];
            gfx.lineTo(point.x, point.y);

            if (x < this.points.length - 1) {
                if (this.hasSameContents(lastUsedSamples, this.samples[x])) {
                    this.sampleIndicators[x] = lastSampleIndicator;
                } else {
                    lastUsedSamples = this.samples[x];
                    theState = {};
                    
                    if (this.samples[x][0]) {
                        icon = sounds[this.samples[x][0]].icon;
                        theState['back-' + icon] = true;
                    }
                    if (this.samples[x][1]) {
                        icon = sounds[this.samples[x][1]].icon;
                        theState['front-' + icon] = true;
                    }

                    this.sampleIndicators[x] = this.owner.parent.addEntity({
                        "type": "sample-indicator",
                        "properties": {
                            x: this.points[x].x + this.owner.x,
                            y: this.points[x].y + this.owner.y,
                            z: this.owner.z + 30,
                            iconState: theState,
                            arrowRotation: (this.processedCourseData.vectors[x].getAngle() * 180 / Math.PI) - 90
                        }
                    });
                    lastSampleIndicator = this.sampleIndicators[x];
                }
            } else  {
                thumbnailState = {};
                thumbnailState[this.owner.parent.parent.settings.level.stage] = true;

                this.thumbnail = this.owner.parent.addEntity({
                    "type": "stage-thumbnail",
                    "properties": {
                        x: this.points[x].x + this.owner.x,
                        y: this.points[x].y + this.owner.y,
                        z: this.owner.z + 31,
                        state: thumbnailState
                    }
                });
            }
        }
        
        this.owner.container.addChild(gfx);
    },

    events: {
        "layer-live": function () {
            this.owner.triggerEvent('rl-course-info', this.processedCourseData);
        },
        "change-highlighted-indicator": function (oldIndex, newIndex) {
            if (this.sampleIndicators[oldIndex] && this.sampleIndicators[newIndex] && this.sampleIndicators[oldIndex] === this.sampleIndicators[newIndex]) {
                //If we're trying to unhighlight and highlight the same thing, do nothing;
                return;
            }

            if (oldIndex !== -1 && this.sampleIndicators[oldIndex]) {
                this.sampleIndicators[oldIndex].triggerEvent('highlight', false);
            }
            
            if (this.sampleIndicators[newIndex]) {
                this.sampleIndicators[newIndex].triggerEvent('highlight', true);
            }
        },
        "update-thumbnail-run-text": function (runNumber) {
            const runText = "(Run " + runNumber + ")";

            this.thumbnail.triggerEvent('set-run-text', runText);
        }
        
    },
    
    methods: {
        processCourse: function () {
            const points = [],
                samples = [],
                vectors = [],
                vectorLengths = [];
            let x = 0,
                direction = null,
                nextPoint = null,
                theCourse = null;
            
            if (!this.courseData) {
                console.warn("Course.js: There is no course data!");
                return null;
            }
            
            points[0] = new Vector(0, 0);
            theCourse = this.courseData;

            for (x = 0; x < theCourse.length; x++) {
                direction = new Vector(theCourse[x].dx, theCourse[x].dy);
                direction.normalize();

                vectors[x] = direction;
                vectorLengths[x] = theCourse[x].length;
                
                nextPoint = new Vector(0, 0);
                nextPoint.x = points[x].x + (direction.x * theCourse[x].length * this.owner.parent.parent.settings.level.musicalUnitLength);
                nextPoint.y = points[x].y + (direction.y * theCourse[x].length * this.owner.parent.parent.settings.level.musicalUnitLength);
                points.push(nextPoint);

                samples[x] = [...theCourse[x].samples];
            }

            return {vectors, vectorLengths, points, samples, "origin": new Vector(this.owner.x, this.owner.y)};
        },
        // findBisectingVector: function (vA, vB) {
        //     const normal = new Vector(0, 0, 1),
        //         flippedA = new Vector(-vA.x, -vA.y, 0);
        //     let angleBetween = 0,
        //         cross = null;

        //     angleBetween = flippedA.signedAngleTo(vB, normal);
           
        //     flippedA.rotate(angleBetween / 2, 'z');
            
        //     flippedA.normalize();

        //     cross = vA.getCrossProduct(flippedA);
        //     if (cross.z >= 0) {
        //         return flippedA;
        //     } else {
        //         return flippedA.multiply(-1);
        //     }
        // },

        // distillInputs: function (inputs) {
        //     const distilled =  [],
        //         directions = [],
        //         results = {
        //             samples: null,
        //             directions: null
        //         },
        //         distilledSmush = [];
        //     let x = 0,
        //         y = 0,
        //         inputSet = null,
        //         words = null,
        //         lastSmush = '';

        //     for (x = 0; x < inputs.length; x++) {
        //         inputSet = inputs[x][0];
        //         distilledSmush[x] = '';
        //         directions[x] = [0, 0];
        //         for (y = 0; y < inputSet.length; y++) {
        //             words = inputSet[y].split('-');
        //             if (words.length > 1) {
        //                 if (!distilled[x]) {
        //                     distilled[x] = [];
        //                 }
        //                 distilled[x].push(words[0]);
        //                 distilledSmush[x] += words[0];

        //                 switch (words[1]) {
        //                 case 'up':
        //                     directions[x][1] -= 1;
        //                     break;
        //                 case 'down':
        //                     directions[x][1] += 1;
        //                     break;
        //                 case 'right':
        //                     directions[x][0] += 1;
        //                     break;
        //                 }
        //             } else {
        //                 if (!distilled[x]) {
        //                     distilled[x] = null;
        //                 }

        //                 switch (words[0]) {
        //                 case 'up':
        //                     directions[x][1] -= 1;
        //                     break;
        //                 case 'down':
        //                     directions[x][1] += 1;
        //                     break;
        //                 case 'right':
        //                     directions[x][0] += 1;
        //                     break;
        //                 }
        //             }
        //         }
        //     }

        //     lastSmush = distilledSmush[0];
        //     for (x = 1; x < distilledSmush.length; x++) {
        //         if (distilledSmush[x] === lastSmush) {
        //             distilled[x] = null;
        //         } else {
        //             lastSmush = distilledSmush[x];
        //         }
        //     }

        //     results.samples = distilled;
        //     results.directions = directions;

        //     return results;
        // },

        hasSameContents: function (arrayA, arrayB) {
            let x = 0,
                y = 0,
                found = false;
    
            if (arrayA === arrayB) {
                return true;
            }
    
            if (arrayA.length !== arrayB.length) {
                return false;
            }
    
            for (x = 0; x < arrayA.length; x++) {
                found = false;
                for (y = 0; y < arrayB.length; y++) {
                    if (arrayA[x] === arrayB[y]) {
                        found = true;
                        break;
                    }
                }
    
                if (!found) {
                    return false;
                }
            }
    
            return true;
        }
        
    },
    
    publicMethods: {
        // getBisector: function (index) {
        //     return this.bisectors[index];
        // }
    },

    getAssetList: function () {
        const images =  Entity.getAssetList({
            type: 'sample-indicator'
        });

        union(images, Entity.getAssetList({
            type: 'stage-thumbnail'
        }));

        return images;
    }

});
  