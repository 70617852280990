import EventEmitter from 'events';
import {Logger} from './logService';
import PeerConnection from './peer-connection.js';

/**
 *
 */
export default class PeerConnections extends EventEmitter {
    constructor (room) {
        const
            players = room.players,
            id = room.state.me,
            myIndex = players.indexOf(id);

        super();

        this.destroyed = false;
        this.console = new Logger('connection');
        this.peers = [];
        this.room = room;
        this.id = id;
        this.maxTries = 3;

        players.forEach((key, index) => {
            if (key !== this.id) {
                this.pair(key, myIndex < index);
            }
        });
    }

    setP2P (id, value) {
        this.room.updateGameState('p2p', id, value);
    }

    /**
     *
     *
     * @param {*} id
     * @returns
     */
    pair (id, letMeMakeTheOffer) {
        const
            onDisconnect = (onClose) => {
                onClose();

                if (tries > 0) {
                    tries -= 1;
                    this.console.log(`Trying again to peer connect with "${id}"... (${tries} tr${tries === 1 ? 'y' : 'ies'} remaining)`);
                    checkSignal();
                } else {
                    this.console.warn(`Tried to peer connect with "${id}" but failed.`);
                }
            },
            makeOffer = () => {
                const
                    onFieldChange = ({field, value, player}) => {
                        if ((field === 'gameState') && (player === id)) {
                            const
                                accept = value.p2p?.[this.id]?.accept;

                            if (accept) {
                                this.peers.push(peer);
                                peer.signal(accept);
                                this.setP2P(id, {
                                    connected: true
                                });
                                this.room.off('fieldChange', onFieldChange);
                                this.emit('peer-connect', peer);
                            }
                        }
                    },
                    onClose = () => {
                        this.room.off('fieldChange', onFieldChange);
                        peer.off('close', onClose);
                        this.remove(peer.id);
                    },
                    peer = new PeerConnection((offer) => {
                        this.setP2P(id, {
                            offer
                        });
                    }, onDisconnect.bind(null, onClose), id);

                this.room.on('fieldChange', onFieldChange);
                peer.on('close', onClose);
            },
            acceptOffer = (signal) => {
                const
                    onFieldChange = ({field, value, player}) => {
                        if ((field === 'gameState') && (player === id) && (value.p2p?.[this.id]?.connected)) {
                            this.peers.push(peer);
                            this.setP2P(id, {
                                connected: true
                            });
                            this.room.off('fieldChange', onFieldChange);
                            this.emit('peer-connect', peer);
                        }
                    },
                    onClose = () => {
                        this.room.off('fieldChange', onFieldChange);
                        peer.off('close', onClose);
                        this.remove(peer.id);
                    },
                    peer = new PeerConnection((accept) => {
                        this.setP2P(id, {
                            accept
                        });
                    }, onDisconnect.bind(null, onClose), id, signal);

                this.room.on('fieldChange', onFieldChange);
                peer.on('close', onClose);
            },
            checkSignal = () => {
                if (!this.destroyed && this.room.state[id]) {
                    const
                        signal = this.room.state[id].gameState.p2p?.[this.id]?.offer;

                    if (signal) {
                        acceptOffer(signal);
                    } else if (letMeMakeTheOffer) {
                        makeOffer();
                    } else { // I need to wait for an offer to materialize
                        const
                            onFieldChange = ({field, value, player}) => {
                                if ((field === 'gameState') && (player === id) && (value.p2p?.[this.id]?.offer)) {
                                    this.room.off('fieldChange', onFieldChange);
                                    acceptOffer(value.p2p?.[this.id]?.offer);
                                }
                            };

                        this.room.on('fieldChange', onFieldChange);
                    }
                }
            };
        let tries = this.maxTries;

        checkSignal();
    }

    findPeer (id) {
        const peers = this.peers;

        for (let i = 0; i < peers.length; i++) {
            if (peers[i].id === id) {
                return peers[i];
            }
        }

        return null;
    }

    send (event, data, timestamp) {
        const
            message = {
                event,
                data,
                timestamp: timestamp || Date.now()
            },
            peers = this.peers;

        for (let i = 0; i < peers.length; i++) {
            peers[i].send(message);
        }
    }

    remove (id) {
        const peers = this.peers;

        for (let i = 0; i < peers.length; i++) {
            if (peers[i].id === id) {
                const peer = peers.splice(i, 1)[0];

                this.console.log(`Disconnecting Peer #${i + 1}: ${peer.id}`);

                peer.emit('disconnect');
                peer.destroy();
                this.emit('peer-disconnect', peer);
                this.setP2P(peer.id, {
                    connected: false
                });

                return true;
            }
        }

        return false;
    }

    destroy () {
        const peers = this.peers;
        let i = peers.length;

        this.destroyed = true;

        while (i--) {
            const peer = peers[i];

            this.console.log(`Disconnecting Peer #${i + 1}: ${peer.id}`);

            peer.emit('disconnect');
            peer.destroy();
            this.emit('peer-disconnect', peer);
            this.setP2P(peer.id, {
                connected: false
            });
        }
        peers.length = 0;
    }
}