import bonk from '@/components/gamemodes/performance/assets/audio/bonk.mp3';

export default {
    "id": "activate-audio-button",
    "components": [{
        "type": "RenderSprite",
        "spriteSheet": "play-button"
    }, {
        "type": "AudioSFX",
        "audioMap": {
            "pressed": bonk
        }
    }, {
        "type": "Tween",
        "events": {
            "bob-button": {
                to: {
                    scaleX: 0.4,
                    scaleY: 0.4
                },
                time: 500,
                repeat: 5,
                yoyo: true,
                easing: "Quadratic.InOut"
            }
        }
    }, {
        type: "Timeline",
        timelines: {
            "layer-live": [6000, "again"],
            "again": ["bob-button", 8000, "again"]
        }
    }],
    "properties": {
        interactive: true,
        scaleX: 0.5,
        scaleY: 0.5,
        x: 0,
        y: 100,
        z: 20,
        hitArea: {
            x: -1000,
            y: -1000,
            width: 2000,
            height: 2000
        }
    }
};