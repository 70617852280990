export default {
    "id": "sample-icons",
    "components": [{
        "type": "RenderSprite",
        "spriteSheet": "samples",
        "animationMap": {
            "back-red-icon-0": "red-bird-1",
            "back-red-icon-1": "red-bird-2",
            "back-purple-icon-0": "purple-bird-1",
            "back-purple-icon-1": "purple-bird-2",
            "back-teal-icon-0": "teal-bird-1",
            "back-teal-icon-1": "teal-bird-2",
            "back-yellow-icon-0": "yellow-bird-1",
            "back-yellow-icon-1": "yellow-bird-2",
            "default": "none"
        }
    }, {
        "type": "RenderSprite",
        "spriteSheet": "samples",
        "animationMap": {
            "front-red-icon-0": "red-bird-1",
            "front-red-icon-1": "red-bird-2",
            "front-purple-icon-0": "purple-bird-1",
            "front-purple-icon-1": "purple-bird-2",
            "front-teal-icon-0": "teal-bird-1",
            "front-teal-icon-1": "teal-bird-2",
            "front-yellow-icon-0": "yellow-bird-1",
            "front-yellow-icon-1": "yellow-bird-2",
            "default": "none"
        }
    }, {
        "type": "Tween"
    }],
    "properties": {
        x: 0,
        y: 0,
        z: 30,
        scaleX: 0.6,
        scaleY: 0.6
    }
};