import SampleIndicator from '../../components/SampleIndicator';

export default {
    "id": "sample-indicator",
    "components": [
        {
            "type": "RenderSprite",
            "spriteSheet": "sample-indicator",
            "animationMap": {
                "default": "none"
            }
        }, {
            "type": SampleIndicator
        }, {
            "type": "Tween"
        }],
    "properties": {
        x: 0,
        y: 0,
        z: 30,
        scaleX: 1,
        scaleY: 1,
        opacity: 1
    }
};