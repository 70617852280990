<template>
    <div class="gameplay-box" :class="notesVisibility">
        <button @click="readComic" class="reread-intro-button">
            <em>RE-READ INTRO</em>
        </button>
        <h2 class="header-blob chapter-title">
            <em>Chapter {{minigameData.chapter}}: {{minigameData.title}}</em>
            <i class="mission-objective">Objective: {{missionObjective}}</i>
        </h2>
        
        <nav class="bubble-buttons">
            <button class="bubble-button my-stuff" @click="showCharacterMenuTab('sounds')">
                <em class="bubble-button-label">My Stuff</em>
            </button>

            <button class="bubble-button tool" :class="toolClass" @click="showCharacterMenuTab('details')">
                <em class="bubble-button-label">Tool: {{ characterInfo.tool.label }}</em>
            </button>

            <button class="bubble-button notes" :class="{new: areNewClues}" @click="showNotes()">
                <em class="bubble-button-label">My Notes</em>
            </button>
        </nav>

        <article class="clue-book">
            <!-- Removing the ability to hide notes for now. There really isn't a good use case for hiding them...
            <button class="mtb-btn action close" @click="hideNotes">Hide Notes</button>-->
            
            <div class="clues-page shared-clues">
                <h3 class="header-blob little on-yellow clues-header" >OUR CLUES!</h3>
                <ol class="clues scrollable with-bar">
                    <li
                        class="clue"
                        v-for="(clue, index) in sharedClues" :key="index"
                    >
                        <span class="clue-dot" :class="{new: clue.new}"></span>
                        <em>{{clue.text}}</em>
                    </li>
                </ol>
            </div>
            
            <div class="clues-page personal-clues">
                <h3 class="header-blob little on-yellow clues-header" >MY CLUES!</h3>
                <ol class="clues scrollable with-bar">
                    <li
                        class="clue"
                        v-for="(clue, index) in personalClues" :key="index"
                    >
                        <span class="clue-dot" :class="{new: clue.new}"></span>
                        <em>{{clue.text}}</em>
                    </li>
                </ol>
            </div>
        </article>
    </div>
</template>

<script>
import characterData from '@/shared/data/characters';
import {mapGetters} from 'vuex';
import {useToast} from "vue-toastification";

export default {
    name: 'MainMenu',
    props: {
        vueData: Object,
        theGame: Object,
        clueData: Object,
        messageData: Object,
        minigameData: Object,
        missionObjective: String
    },
    computed: {
        ...mapGetters({
            myClues: 'getMyMissionClues',
            ourClues: 'getOurMissionClues',
            missionClues: 'getMissionClues',
            myMessages: 'getMyMissionMessages',
            ourMessages: 'getOurMissionMessages',
            missionMessages: 'getMissionMessages'
        }),
        characterInfo () {
            return characterData[this.me.character];
        },
        notesVisibility () {
            const result = {};
            if (this.notesShowing) {
                result.notesShowing = true;
            }

            return result;
        },
        goToLabelClasses () {
            const result = {};
            if (this.vueData.player.locked) {
                result.locked = true;
            }
            return result;
        },
        goToButtonClasses () {
            const result = {};
            if (this.vueData.player.locked) {
                result.locked = true;
            } else if (!this.vueData.player.regionName) {
                result.invalid = true;
            }
            return result;
        },
        toolClass () {
            const
                str = this.characterInfo.tool.label;

            return str.split(" ").map((c) => c.toLowerCase()).join("-");
        }
    },
    data () {
        return {
            notesShowing: true,
            personalClues: [],
            sharedClues: [],
            areNewClues: false,
            me: this.$store.getters.getPlayerInfo,
            toast: useToast()
        };
    },
    beforeMount () {
    },
    mounted () {
    },
    beforeUnmount () {
        this.toast.clear();
    },
    methods: {
        showNotes () {
            this.notesShowing = true;
            this.$gameConsole.log('Mission: Reviewed Clues');
        },
        hideNotes () {
            let x = 0;
            this.notesShowing = false;
            this.areNewClues = false;

            for (x = 0; x < this.personalClues.length; x++) {
                this.personalClues[x].new = false;
            }

            for (x = 0; x < this.sharedClues.length; x++) {
                this.sharedClues[x].new = false;
            }
        },
        readComic () {
            this.$emit('show-comic');
            this.$gameConsole.log('Mission: Reviewed Comic');
        },
        showCharacterMenuTab (tab) {
            this.$emit('show-menu-tab', tab);
            switch (tab) {
            case 'details':
                this.$gameConsole.log('Mission: Reviewed Tools');
                break;
            case 'sounds':
                this.$gameConsole.log('Mission: Reviewed Sounds');
                break;
            }
        },
        goToPressed () {
            if (this.vueData.player.regionName) {
                this.theGame.playerButtonPressed();
            }
        }
    },
    watch: {
        missionClues: {
            handler (clues, oldClues) {
                console.log('GOT MISSION CLUES!!!!!!!!!!!!!!!!!!');
                if (clues || oldClues) {
                    console.log('CLUEZ!!!');
                }
            },
            immediate: true
        },
        myClues: {
            handler (clues, oldClues) {
                this.personalClues = (clues || []).map((clue) => {
                    let isNew = true;

                    if (oldClues) {
                        for (let y = 0; y < oldClues.length; y++) {
                            if (clue === oldClues[y]) {
                                isNew = false;
                                break;
                            }
                        }
                    }
                    this.areNewClues ||= isNew;
                    return {
                        text: this.clueData[clue],
                        new: isNew
                    };
                });
            },
            immediate: true
        },
        ourClues: {
            handler (clues, oldClues) {
                this.sharedClues = (clues || []).map((clue) => {
                    let isNew = true;

                    if (oldClues) {
                        for (let y = 0; y < oldClues.length; y++) {
                            if (clue === oldClues[y]) {
                                isNew = false;
                                break;
                            }
                        }
                    }
                    this.areNewClues ||= isNew;
                    return {
                        text: this.clueData[clue],
                        new: isNew
                    };
                });
            },
            immediate: true
        },
        missionMessages: {
            handler (messages, oldMessages) {
                console.log('GOT MISSION MESSAGES!!!!!!!!!!!!!!!!!!');
                if (messages || oldMessages) {
                    console.log('Message!!!');
                }
            },
            immediate: true
        },
        myMessages: {
            handler (messages, oldMessages) {
                let x = 0,
                    y = 0,
                    isNew = true;
                
                for (x = 0; x < messages.length; x++) {
                    isNew = true;
                    if (oldMessages) {
                        for (y = 0; y < oldMessages.length; y++) {
                            if (messages[x] === oldMessages[y]) {
                                isNew = false;
                                break;
                            }
                        }
                    }
                    if (isNew) {
                        this.toast.info(
                            this.messageData[messages[x]], { 
                                position: "bottom-right",
                                timeout: false,
                                showCloseButtonOnHover: true,
                                bodyClassName: "mtb-toast",
                                toastClassName: "toast-for-" + this.theGame.localPlayer.character
                            }
                        );
                    }
                }
            },
            immediate: true
        },
        ourMessages: {
            handler (messages, oldMessages) {
                let x = 0,
                    y = 0,
                    isNew = true;
                
                for (x = 0; x < messages.length; x++) {
                    isNew = true;
                    if (oldMessages) {
                        for (y = 0; y < oldMessages.length; y++) {
                            if (messages[x] === oldMessages[y]) {
                                isNew = false;
                                break;
                            }
                        }
                    }
                    if (isNew) {
                        this.toast.info(
                            this.messageData[messages[x]], { 
                                position: "bottom-right",
                                timeout: false,
                                showCloseButtonOnHover: true,
                                bodyClassName: "mtb-toast",
                                toastClassName: "toast-for-all"
                            }
                        );
                    }
                }
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="scss">
.gameplay-box {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
}

.reread-intro-button {
    position: absolute;
    right: 4vw;
    top: 3.5vw;
    padding-top: 5.5vw;
    width: fit-content;
    background: url('~@/assets/ui/mtb-ui_comic-icon.svg') 50% top no-repeat;
    background-size: 70%;
    
    cursor: pointer;

    em {
        color: $mtb-white;
        font-size: 0.75em;
        text-align: center;
        white-space: nowrap;
    }
    
    &:hover {
        animation: jelly 0.75s ease 0s 1 normal forwards;
    }
}

.chapter-title {
    position: absolute;
    left: 50%;
    top: 2.5vw;
    transform: translateX(-50%);
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    
    > em {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        padding: 1em 1.75em 0.75em;
        border-radius: 64% 1.25em 2.75em 2em;
        background: #1B204B;
    }
    
    .mission-objective {
        display: block;
        font-size: 0.437em;
        background-color: #40D1C3;
        color: #1b204b;
        position: absolute;
        z-index: -1;
        bottom: 0;
        top: auto;
        line-height: 1.3;
        padding: 2em 1.5em 0.75em 1.5em;
        border-radius: 1.5em;
        width: 75%;
        left: 50%;
        transform: translate(-50%, 70%);
    }
}

.notesShowing {
    .clue-book {
        transform: translateY(0);
    }

    .bubble-button {
        transform: scale(0.6);
        transform-origin: bottom center;

        &.my-stuff {
            left: 21%;
            top: auto;
            bottom: 21vw;
        }

        &.tool {
            left: 21%;
            top: auto;
            bottom: 7vw;
        }

        &.notes {
            top: 150%;
        }
    }
}


.bubble-button {
    position: absolute;
    width: 20vw;
    height: 20vw;
    border-radius: 100%;
    cursor: pointer;
    top: 55%;
    margin-top: -10vw;
    margin-left: -10vw;
    box-shadow: 0 0 1.5em 0.75em rgb(0 0 0 / 50%);

    background: $mtb-white;
    border: 0.15em solid $mtb-ui-dark-purp;

    transition: all 0.5s;

    &.my-stuff {
        left: 30%;

        &:after {
            background-image: url('~@/assets/ui/mtb-ui_my-stuff-icon.svg');
            background-size: 95%;
        }
    }
    &.tool {
        left: 55%;

        &.backpack::after {
            background-image: url('~@/assets/character/tools/mtb_tool-icon_backpack.svg');
        }

        &.gift-giving::after {
            background-image: url('~@/assets/character/tools/mtb_tool-icon_gifts.svg');

        }

        &.journal-app::after {
            background-image: url('~@/assets/character/tools/mtb_tool-icon_journal-app.svg');

        }

        &.recording-device::after {
            background-image: url('~@/assets/character/tools/mtb_tool-icon_recording-device.svg');

        }
    }
    &.notes {
        left: 80%;

        &:after {
            background-image: url('~@/assets/ui/mtb-ui_notes-icon.svg');
            background-size: 80%;
        }

        &.new {
            &:before {
                content: 'New Clues!';
                display: block;
                text-indent: -999em;
                width: 8vw;
                height: 8vw;
                position: absolute;
                right: 0;
                top: -2vw;
                background: url('~@/assets/ui/mtb-ui_new-icon.svg') 50% 50% no-repeat;
                z-index: 1;
                
                animation: pulse 1.5s infinite;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0.5em;
        bottom: 0.5em;
        left: 0.5em;
        right: 0.5em;

        border: 0.15em solid $mtb-ui-dark-purp;
        border-radius: inherit;
        background-color: $mtb-ui-yellow;
        background-position: 50% 40%;
        background-repeat: no-repeat;
        background-size: 80%;
        color: $mtb-ui-dark-purp;
        box-shadow: inset 0 0 0 0.2em #f3cf50;
    }

    .bubble-button-label {
        position: absolute;
        width: fit-content;
        min-width: 80%;
        border-radius: 1.5vw;
        left: 50%;
        text-align: center;
        line-height: 1.4;
        padding: 0.5em 1.5em;
        bottom: -0.5em;
        z-index: 1;
        white-space: nowrap;
        transform: translateX(-50%);
        background-color: $mtb-ui-dark-purp;
        color: $mtb-ui-yellow;
    }
}

.clue-book {
    position: absolute;
    width: 68vw;
    height: 48vw;
    max-height: 70vh;
    padding: 2.5em 2.5em 1.5em 2.5em;
    right: 4vw;
    bottom: -3vw;
    background-position: 50% 0;
    background-size: cover;
    transform: translateY(110%);
    transition: transform 0.5s;

    .close {
        position: absolute;
        z-index: 10;
        right: -1vw;
        top: -2vw;
        transform: scale(0.8);
    }

    .clues-page {
        position: absolute;
        width: 25vw;
        height: 100%;
        margin-left: -10vw;
        overflow-y: auto;

        &.shared-clues {
            position: absolute;
            left: 20%;
        }
        &.personal-clues {
            position: absolute;
            left: 72%;
        }

        .clue-dot {
            width: 1.5vw;
            height: 1.5vw;
            float: left;
            margin: 0.5em;
            border-radius: 1vw;
            background-color: rgb(255, 196, 85);
            margin-top: 0.3vw;

            &.new {
                width: 2vw;
                height: 2vw;
                background: url('~@/assets/ui/mtb-ui_new-icon.svg') 50% 50% no-repeat;
                animation: pulse 1.5s infinite;
                margin-top: 0;
            }
        }
    }
}

.navigation {
    position: absolute;
    width: 5vw;
    height: 5vw;
    right: 0;
    bottom: 5vw;
}

.go-to-label {
    position: absolute;
    right: 0;
    top: -3vw;
    width: 16vw;
    height: 7vw;
    background-color: yellow;
    color: black;
    line-height: 1.2em;
    text-align: center;

    &.locked {
        background-color: yellowgreen;
    }
}

.go-to-button {
    width: 18vw;
    height: 5vw;
    position: absolute;
    right: 0vw;
    bottom: -3.5vw;
    background-color: yellowgreen;
    text-align: center;
    line-height: 5vw;
    border-top-left-radius: 2.5vw;
    border-bottom-left-radius: 2.5vw;
    padding-left: 3vw;
    cursor: pointer;
    color: black;

    &.locked {
        background-color: red;
    }

    &.invalid {
        background-color: grey;
        color: white;
        cursor: default;
    }
}
    
@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    
    50% {
        transform: scale(1.3);
    }
}

</style>