import store from '../store/index';

const
    // eslint-disable-next-line no-process-env
    consoleToo = false, //process.env.NODE_ENV === 'development',
    categoryMap = {
        connection: 'updateConnectionLog',
        dpe: 'updateDPELog',
        game: 'updateGameLog',
        default: 'updateGameLog'
    },
    submitLog = (category = 'default', type, ...args) => {
        let log = '',
            divider = '';
        
        while (args.length) {
            const
                value = args[0];

            if (typeof value === 'string') {
                args.shift();
                log += divider + value;
                divider = ' ';
            } else if (value instanceof Error) {
                log += divider + `${value.name}(${value.code}): ${value.message}`;
                break;
            } else {
                break;
            }
        }

        if (!log) {
            log = type.toUpperCase();
        }

        store.dispatch(categoryMap[category], {
            type,
            log,
            [args.length === 1 ? 'data' : 'list']: args.length === 1 ? args[0] : args
        });
        if (consoleToo) {
            const
                message = `[${category}] ${log}`;

            if (console[type]) {
                console[type](message, ...args);
            } else {
                console.log(type.toUpperCase(), message, ...args);
            }
        }
    };

// eslint-disable-next-line one-var
export const
    Logger = class Logger {
        constructor (category) {
            this.category = category;
            this.developmentMode = consoleToo;
        }
    
        log (...args) {
            submitLog(this.category, 'info', ...args);
        }
    
        warn (...args) {
            submitLog(this.category, 'warn', ...args);
        }
    
        error (...args) {
            submitLog(this.category, 'error', ...args);
        }
    },

    /**
     * logService plugin
     *
     * @param {*} app
     * @param {*} options
     */
    createLoggerPlugin = function (options) {
        return function (app) {
            const
                key = options?.key ?? 'default',
                logService = new Logger(key);

            app.provide(key, logService);
            app.config.globalProperties[`$${key}Console`] = logService;
        };
    };
