import mtb_music_mode_node_arrows from '@/components/gamemodes/performance/assets/images/mtb_music-mode_node-arrows.png';
import mtb_music_mode_sample_node from '@/components/gamemodes/performance/assets/images/mtb_music-mode_sample-node.png';
import mtb_music_mode_sample_node_active from '@/components/gamemodes/performance/assets/images/mtb_music-mode_sample-node_active.png';
import mtb_music_mode_sample_node_missing from '@/components/gamemodes/performance/assets/images/mtb_music-mode_sample-node_missing.png';
import mtb_music_mode_sample_node_missing_pointer from '@/components/gamemodes/performance/assets/images/mtb_music-mode_sample-node_missing_pointer.png';

export default {
    "images": [
        mtb_music_mode_node_arrows,
        mtb_music_mode_sample_node,
        mtb_music_mode_sample_node_active,
        mtb_music_mode_sample_node_missing,
        mtb_music_mode_sample_node_missing_pointer
    ],
    "frames": [
        [ 0, 0, 37, 50, 0, 18, -89],
        [ 0, 0, 200, 200, 1, 100, 100],
        [ 0, 0, 200, 200, 2, 100, 100],
        [ 0, 0, 200, 200, 3, 100, 100],
        [ 0, 0, 200, 200, 4, 100, 100],
        [ 0, 0, 0, 0, 0, 0, 0]
    ],
    "animations": {
        "mtb_music-mode_node-arrows": 0,
        "mtb_music-mode_sample-node": 1,
        "mtb_music-mode_sample-node_active": 2,
        "mtb_music-mode_sample-node_missing": 3,
        "mtb_music-mode_sample-node_missing_pointer": 4,
        "none": 5
    }
};