<template>
    <ul class="chooser-container">
        <li v-if="recent" class="recent game">
            <em class="tag">Active Game</em>
            <img :src="recentGame.thumbnail" :alt="recentGame.title" />
            <h3>
                Chapter {{recentGame.chapter}}:
                <em>{{recentGame.title}}</em>
                <b :class="recentGame.mode">{{recentGame.mode}} mode</b>
            </h3>
            <GroupButton
                :onAgreement="onChoiceMade"
                :confirmationModel="confirmationModel"
                title="Rejoin"
                decisionId="next-minigame"
                :decision="recent"
            />
        </li>

        <li v-for="(game, index) in story" :key="index" class="game">
            <img :src="game.thumbnail" :alt="game.title" />
            <h3>
                Chapter {{game.chapter}}:
                <em>{{game.title}}</em>
                <b :class="game.mode">{{game.mode}} mode</b>
            </h3>
            <GroupButton
                :onAgreement="onChoiceMade"
                :confirmationModel="confirmationModel"
                title="Launch"
                decisionId="next-minigame"
                :decision="game.order"
            />
        </li>
    </ul>
</template>

<script>
import GroupButton from './GroupButton.vue';
import story from '@/shared/data/story';

export default {
    name: 'QuickPlayGameChooser',
    components: {
        GroupButton
    },
    props: {
        onChoiceMade: Function,
        confirmationModel: {
            type: String,
            default: 'all'
        },
        recent: Object
    },
    data () {
        return {
            story
        };
    },
    computed: {
        recentGame () {
            if (this.recent) {
                return story[this.getProgress(this.recent)];
            } else {
                return null;
            }
        }
    },
    async mounted () {
    },
    beforeUnmount () { // Needed to clean up server-side voting.
    },
    methods: {
        getProgress (playthrough) { // Reflect that teacher configuration of progress overrides student progression.
            return playthrough.config?.progress ?? playthrough.lastPlayedState?.progress ?? 0;
        }
    },
    watch: {
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chooser-container {
    background: #4C5693;
    padding-top: 0.5em;
    
    .game {
        background: #37417C;
        margin: 0.5em 0.5em 1.25em;
        min-height: 2em;
        line-height: 1.4;
        border-radius: 1em;
        position: relative;
        padding: 1em 3em 1em 0.75em;

        h3 {
            font-size: 0.625em; // 10px at 1024
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 1;

            em {
                font-size: 1.4em; // 14px at 1024
                display: block;
                font-weight: bold;
            }

            b {
                background: $mtb-ui-dark-purp;
                color: #FFE888;
                display: inline-block;
                border-radius: 1.5em;
                padding: 0.5em 1em 0.5em 3.25em;
                margin-top: 0.25em;
                position: relative;
                text-transform: capitalize;
                    
                &:before {
                    content: "";
                    display: block;
                    width: 2.25em;
                    height: 1.5em;
                    position: absolute;
                    left: 0.75em;
                    top: 50%;
                    transform: translateY(-50%);

                    background-image: url('~@/assets/ui/mtb-ui_game-type-icons_mission.svg'); 
                    background-repeat: no-repeat;
                    background-position: 50% bottom;
                    background-size: cover;
                }

                &.practice {
                    &:before {
                        background-image: url('~@/assets/ui/mtb-ui_game-type-icons_practice.svg'); 
                    }
                }

                &.performance {
                    &:before {
                        background-image: url('~@/assets/ui/mtb-ui_game-type-icons_performance.svg'); 
                    }
                }
            }
        }

        img {
            width: 6em;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5em;
            border: 0.1em solid #252E65;
            border-radius: 0.5em;
            position: relative;
            z-index: 1;
        }

        &.recent {  
            border-radius: 1em;   
            background: $mtb-white;
            border: 0.15em solid $mtb-ui-dark-purp;
            
            h3 {
                color: #3D3F5D;
            }
            
            .tag {
                background: $mtb-ui-dark-purp;
                color: $mtb-ui-yellow;
                
                position: absolute;
                z-index: 2;
                border-radius: 1.5em;
                padding: 0.5em 1.25em;
                font-size: 0.625em;
                top: -1.25em;
                left: -0.75em;
            }
            
            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: 0;
                top: 0.25em;
                bottom: 0.25em;
                left: 0.25em;
                right: 0.25em;
                
                border: 0.15em solid $mtb-ui-dark-purp;
                border-radius: inherit;
                background: $mtb-ui-yellow;
                color: $mtb-ui-dark-purp;
                box-shadow: inset 0 0 0 0.2em #f3cf50;
            }
        }

        :deep(.group-button-container) {
            top: auto;
            left: auto;
            right: -0.5em;
            bottom: -0.75em;
            width: fit-content;
            height: fit-content;
            z-index: 1;
            
            .mtb-btn {
                font-size: 0.5965em;  // 10px(ish) at 1024
            }
            
            #info-area {
                display: none;
            }
        }

    }

    &.horizontal {
        padding-right: 2vw;
        
        li {
            display: inline-block;
        }
    }
}
</style>
