const
    comics = {
        state: () => ({
            viewed: {}
        }),

        actions: {
            initializeComics ({commit, getters}) {
                return {
                    'comics.viewed': ({value, player}) => {
                        if (player === getters.getPlayerId) {
                            commit('setViewedComics', value);
                        }
                    }
                };
            },

            reportView ({dispatch}, cutsceneId) {
                return dispatch('makeLocalUpdate', {
                    [`comics.viewed.${cutsceneId}`]: true
                });
            },

            clearComics ({commit}) {
                commit('setViewedComics', {});
            }
        },

        mutations: {
            setViewedComics (state, value) {
                state.viewed = value;
            }
        },

        getters: {
            getViewedComics (state) {
                return state.viewed;
            }
        }
    };

export {comics};