export default {
    id: "success-meter-back",
    components: [
        {
            type: "RenderSprite",
            spriteSheet: "success-meter",
            "animationMap": {
                "default": "back"
            }
        }
    ],
    properties: {
        renderParent: "the-success-meter",
        scaleX: 1,
        scaleY: 1,
        z: -2
    }
};