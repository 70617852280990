import ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_2_PNG from '@/assets/gamemodes/practice/boards/solutions/solution_lvl-2.png';

export default {
    "board": {
        "dimensions": [
            3,
            4
        ],
        "art": null
    },
    "initialPositions": [
        {
            "region": 20007,
            "rotation": 0
        },
        {
            "region": 20011,
            "rotation": 0
        },
        {
            "region": 20010,
            "rotation": 0
        },
        {
            "region": 20006,
            "rotation": 0
        }
    ],
    "solutions": [
        [
            [
                20008,
                0
            ],
            [
                20012,
                0
            ],
            [
                20011,
                0
            ],
            [
                20010,
                0
            ]
        ],
        [
            [
                20004,
                0
            ],
            [
                20008,
                0
            ],
            [
                20010,
                0
            ],
            [
                20011,
                0
            ]
        ],
        [
            [
                20008,
                0
            ],
            [
                20007,
                0
            ],
            [
                20006,
                0
            ],
            [
                20012,
                0
            ]
        ],
        [
            [
                20007,
                0
            ],
            [
                20003,
                0
            ],
            [
                20002,
                0
            ],
            [
                20008,
                0
            ]
        ],
        [
            [
                20006,
                0
            ],
            [
                20004,
                0
            ],
            [
                20001,
                0
            ],
            [
                20007,
                0
            ]
        ]
    ],
    "clues": {
        "team": [
            ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_2_PNG
        ],
        "individual": [
            [
                "On your first turn, move towards the couch.",
                "On your second turn, you cannot be on the circle rug.",
                "On Turn 3, Lee is in front of you."
            ],
            [
                "On your first turn move off the rug.",
                "You will never be on the same side of the room as the desk.",
                "On Turn 3, you should be between Kit and Bowie."
            ],
            [
                "On your first turn, stay on the rug, but do not move closer to the fireplace.",
                "On Turn 2, You and Lee swap places",
                "Turns 3 and 4, you stay side by side with Ziggy, but change rows."
            ],
            [
                "Take the place of Kit on your first turn.",
                "On your 3rd turn, you’ll be closer to the couch, but still can’t sit on it.",
                "On your 4th turn, take Bowie’s place."
            ]
        ]
    },
    "players": null
};