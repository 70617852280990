<template>
    <div class="decision-tracker">
        <h3>Decisions</h3>
        <ol>
            <li v-for="(decision, key) in decisions" :key="key">{{ key }}: <span v-for="(vote, player) in decision" :key="player" :style="getStyle(player)"> {{ vote === null ? '_' : typeof vote === 'boolean' ? vote ? '✅' : '❎' : vote }} </span></li>
        </ol>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "DecisionTracker",
    computed: {
        ...mapGetters({
            decisions: 'getDecisionsByCharacter'
        })
    },
    methods: {
        getStyle (id) {
            return `background: #${id.substring(0, 6)};`;
        }
    }
};

</script>
<style lang="scss">
.decision-tracker {
    h3 {
        text-align: center;
        margin: 1em 0 0.5em 0;
        border-bottom: 0.1em solid $mtb-white;
    }

    li {
        margin: 0 1em;

        span {
            display: inline-block;
            padding: 0.2em 1em;
            border-radius: 0.5em;
            margin: 0.2em;
        }
    }
}
</style>