<template>
    <Transition name="circle-wipe">
        <CharacterMenu
            v-if="characterMenuOpen"
            :isPlayer="false"
            :whichCharacter="menuCharacter"
            @close-event="closeCharacterMenu"
            
        />
    </Transition>
    <article class="story-hero">
        <header>
            <h3>Play Story Mode!</h3>
            <button class="mtb-btn action right-arrow push-right push-down" @click="joinGame()">Start New Playthrough</button>
        </header>
        <nav class="character-stage">
            <button class="purple-bird" @click="showCharacterMenu('purple-bird')">Purple Menu</button>
            <button class="teal-bird" @click="showCharacterMenu('teal-bird')">Teal Menu</button>
            <button class="red-bird" @click="showCharacterMenu('red-bird')">Red Menu</button>
            <button class="yellow-bird" @click="showCharacterMenu('yellow-bird')">Yellow Menu</button>
        </nav>
    </article>
    <!--<aside class="quick-play-menu" :class="{ 'show': quickPlayVisible }">
        <button class="toggle-tab" @click="toggleQuickPlay()">Launch a Quick Play Game</button>
        <QuickPlayGameChooser
            :recent="quickPlays?.[0]"
            :onChoiceMade="joinGame"
            confirmationModel="async"
            class="horizontal scrollable with-bar"
        >
        </QuickPlayGameChooser>
        <div class="blocker"></div>
    </aside>-->
    <article class="my-plays" v-if="storyPlaythroughs.length > 0">
        <h3><span>or</span> Continue Playing:</h3>
        <ul class='playthrough-list'>
            <li v-for="(playthrough, index) in storyPlaythroughs" :key="index" @click="joinGame(playthrough)">
                <ul class="players-list">
                    <li
                        v-for="(player, index) in playthrough.players"
                        :key="index"
                        :title="player?.name ?? player"
                    >
                        <PlayerAvatar class="avatar" :me="player"></PlayerAvatar>
                    </li>
                </ul>
                <div class="playthrough-details" v-for="(minigame, index) in [story[getProgress(playthrough)]]" :key="index">
                    <h4>
                        Chapter {{minigame.chapter}}:
                        <em> {{minigame.title}}</em>
                        <b :class="minigame.mode">{{minigame.mode}} mode</b>
                    </h4>
                    
                    <span class="last-played">
                        <em class="progress">
                            <span>Progress:</span>
                            {{Math.floor(getProgress(playthrough) * 100 / story.length)}}%
                        </em>
                        <i>Last Played: {{new Date(playthrough.lastPlayed).toLocaleDateString()}}</i>
                    </span>
                    <div v-if="playthrough.config">
                        Configuration: {{playthrough.config}}
                    </div>

                    <ol v-if="savesByPlaythrough[playthrough.playthroughId]">
                        <li
                            v-for="(save, key) in savesByPlaythrough[playthrough.playthroughId]"
                            :key="key"
                            @click="loadSavedGame(save)"
                        >
                            {{ key }}
                            <button @click="removeSave($event, key)">X</button>
                        </li>
                    </ol>
                </div>
            </li>
        </ul>
    </article>
</template>

<script>
import CharacterMenu from "@/components/character-ui/CharacterMenu.vue";
import PlayerAvatar from "@/components/PlayerAvatar.vue";
//import QuickPlayGameChooser from "@/components/QuickPlayGameChooser";

import story from "@/shared/data/story";

export default {
    name: 'MyPlays',
    components: {
        PlayerAvatar,
        //QuickPlayGameChooser,
        CharacterMenu
    },
    props: {
    },
    data () {
        return {
            loadSave: null,
            story,
            me: this.$store.getters.getPlayerInfo,
            playthroughs: this.$store.getters.getPlaythroughs,
            saves: null,
            quickPlayVisible: false,
            characterMenuOpen: false,
            menuCharacter: null
        };
    },
    computed: {
        storyPlaythroughs () {
            return this.playthroughs?.filter((playthrough) => !playthrough.lastPlayedState?.quickPlay);
        },
        quickPlays () {
            return this.playthroughs?.filter((playthrough) => !!playthrough.lastPlayedState?.quickPlay);
        },
        savesByPlaythrough () {
            const
                filtered = {},
                saves = this.saves || {};
            
            for (const key in saves) {
                if (Object.prototype.hasOwnProperty.call(saves, key)) {
                    const
                        playthroughId = saves[key]?.playthrough?.playthroughId;

                    if (playthroughId) {
                        if (!filtered[playthroughId]) {
                            filtered[playthroughId] = {};
                        }
                        filtered[playthroughId][key] = saves[key];
                    }
                }
            }

            return filtered;
        }
    },
    async mounted () {
        console.log('My Playthroughs', this.playthroughs);

        // Dev: get local saves.
        this.saves = localStorage.getItem('mtb-saves') || '{}';

        try {
            this.saves = JSON.parse(this.saves);
        } catch (err) {
            console.warn('Error reading saves.', err);
            this.saves = {};
        }
    },

    methods: {
        async joinGame (playthrough) {
            if (playthrough) {
                if (!isNaN(+playthrough)) {
                    this.$store.commit('setPlaythroughConfiguration', {
                        quickPlay: true,
                        progress: +playthrough
                    });
                } else if (this.loadSave) {
                    this.$store.commit('setPlaythroughConfiguration', {
                        ...playthrough,
                        loadSave: this.loadSave
                    });
                } else {
                    this.$store.commit('setPlaythroughConfiguration', playthrough);
                }
            } else {
                this.$store.commit('setPlaythroughConfiguration', null);
            }

            this.$router.push({
                name: 'ConnectBoard'
            });
        },
        loadSavedGame (savedGame) {
            console.log('Loading saved game.', savedGame);
            this.loadSave = savedGame;
        },
        removeSave (e, savedGame) {
            e.preventDefault();
            e.stopPropagation();

            delete this.saves[savedGame];
            localStorage.setItem('mtb-saves', JSON.stringify(this.saves));
        },
        toggleQuickPlay () {
            this.quickPlayVisible = !this.quickPlayVisible;
        },
        getProgress (playthrough) { // Reflect that teacher configuration of progress overrides student progression.
            return playthrough.config?.progress ?? playthrough.lastPlayedState?.progress ?? 0;
        },
        showCharacterMenu (bird) {
            this.characterMenuOpen = true;
            this.menuCharacter = bird;
        },
        closeCharacterMenu () {
            this.characterMenuOpen = false;
        }
    }
};
</script>

<style scoped lang="scss">
.my-plays {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 16vw;
    width: 40vw;
    
    > h3 {
        padding-left: 15%;
        font-size: 1.5em;
        line-height: 3vw;
        font-family: "Love Ya";
        color: #FFE888;
        
        span {
            color: #4C5693;
            font-size: 0.75em;
        }
    }

    .playthrough-list {
        position: absolute;
        left: 0;
        right: 0;
        top: 3.25vw;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 0.25vw;

        > li {
            padding: 1vw;
            background: #1B204B;
            border-radius: 4vw  0 0 4vw;
            margin-bottom: 0.5vw;
            height: 8vw;
            position: relative;
            right: 0;
            left: 0;
            width: 100%;
            border: 0.25em solid #1B204B;
            box-shadow: 0 0.1em 0.3em 0.1em rgb(0 0 0 / 40%);
            
            transform: translateX(6%);
            
            transition: left 0.5s;

            &:hover {
                cursor: pointer;
                left: -6%;
                background: #CC2662;
                border-color: #A50C43;
                
                .playthrough-details {
                    h4 {
                        b {
                            background-color: #A50C43;
                            color: $mtb-white;
                        }
                    }
                    .last-played {
                        right: 0;
                        
                        i, .progress {
                            color: $mtb-white;
                        }
                    }
                }
            }

            .playthrough-details {
                position: absolute;
                display: block;
                width: 70%;
                top: 0;
                bottom: 0;
                right: 6%;
                padding: 1vw 1vw 1vw 0;
                line-height: 1.4;
                
                h4 {
                    font-size: 0.625em; // 10px at 1024
                    display: inline-block;
                    vertical-align: middle;

                    em {
                        font-size: 1.4em; // 14px at 1024
                        display: block;
                        font-weight: bold;
                    }

                    b {
                        background: #060A33;
                        color: #FFE888;
                        display: inline-block;
                        border-radius: 1.5em;
                        padding: 0.5em 1em 0.5em 3.25em;
                        margin-top: 0.25em;
                        position: relative;
                        text-transform: capitalize;
                        
                        &:before {
                            content: "";
                            display: block;
                            width: 2.25em;
                            height: 1.5em;
                            position: absolute;
                            left: 0.75em;
                            top: 50%;
                            transform: translateY(-50%);

                            background-image: url('~@/assets/ui/mtb-ui_game-type-icons_mission.svg');
                            background-repeat: no-repeat;
                            background-position: 50% bottom;
                            background-size: cover;
                        }

                        &.practice {
                            &:before {
                                background-image: url('~@/assets/ui/mtb-ui_game-type-icons_practice.svg');
                            }
                        }

                        &.performance {
                            &:before {
                                background-image: url('~@/assets/ui/mtb-ui_game-type-icons_performance.svg');
                            }
                        }
                    }
                }
                
                .last-played {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 6%;
                    width: 50%;
                    text-align: right;
                    
                    transition: right 0.5s;
                    
                    > * {
                        display: block;
                    }
                    
                    .progress {
                        font-family: "Love Ya";
                        color: #FFE888;
                        font-size: 2.5em;
                        line-height: 0.6em;
                        margin-bottom: 0.2em;
                        
                        span {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }
                    
                    i {
                        font-size: 0.625em;
                        color: #6976BF;
                    }
                }

                ol {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: rgba(0,0,0,0.6);
                    padding: 0 0.3em;

                    li > button {
                        padding: 0 0.2em;
                        border-radius: 0.5em;
                    }
                }
            }
        }
    }

    .players-list {
        padding: 0.5vw 0.5vw 1vw 0.5vw;
        width: fit-content;
        position: absolute;
        z-index: 1;
        
        li {
            display: inline-block;
            position: relative;
            width: 4.5vw;
            height: 4.5vw;
            border-radius: 100%;
            background: #ccc;
            margin-right: 0.5vw;
            border-radius: 100%;
            overflow: hidden;
            margin-right: -4vw;
            
            transition: margin 0.5s;
        }
        
        &:hover {
            background-color: #CC2662;
            border-radius: 1.5em;
            
            li {
                margin-right: 0.5vw;
            }
        }
    }
}
.story-hero {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    
    min-height: 65vw;
    
    background-image: url('~@/assets/character/mtb_start-game-hero_lights.png'), url('~@/assets/character/mtb_start-game-hero_stage.png');
    background-position: 50% 0,  50% 50%;
    background-repeat:  no-repeat,  no-repeat;
    background-size: 107%, cover;
    
    header {
        position: absolute;
        z-index: 1;
        width: 58vw;
        top: 6vw;
        position: absolute;
        left: 4vw;

        h3 {
            font-family: 'Love Ya';
            font-size: 7vw;
            color: #FFE888;
            margin-bottom: 1vw;
        }
    }

    .character-stage {
        position: absolute;
        width: 60vw;
        height: 30.1vw;
        top: 50%;
        transform: translateY(-55%);
        left: 1vw;
        
        background: url(~@/assets/character/mtb_start-game-hero_characters.png) 50% 50% no-repeat;
        background-size: contain;
        
        button {
            position: absolute;
            background: none;
            text-indent: -999em;
            cursor: pointer;
            
            &.purple-bird {
                left: 3%;
                right: 74%;
                top: 29%;
                bottom: 12%;
            }
            
            &.yellow-bird {
                left: 77%;
                right: 0;
                top: 21%;
                bottom: 15%;
            }
            
            &.red-bird {
                left: 52%;
                right: 23%;
                top: 8%;
                bottom: 12%;
            }
            
            &.teal-bird {
                left: 27%;
                right: 48%;
                top: 33%;
                bottom: 18%;
            }
            
        }
    }
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        pointer-events: none;
        background: url('~@/assets/character/mtb_start-game-hero_chairs.png') 50% bottom no-repeat;
        background-size: 100%;
        
    }
}

aside.quick-play-menu {
    position: absolute;
    right: 0;
    text-align: left;
    z-index: 1;
    width: 100%;
    bottom: 0;
    white-space: nowrap;

    transform: translateY(100%);
    transition: transform 0.5s;


    &.show {
        transform: translateY(0);

        .toggle-tab {
            background: #4C5693;

            &:after {
                content: "[-]"
            }
        }
        
        .blocker{
            opacity: 1;
            backdrop-filter: blur(20px);
            pointer-events: all;
        }
    }

    .toggle-tab {
        font-family: "Love Ya";
        color: #FFE888;
        font-size: 1.125em;
        line-height: 3em;
        background: #1B204B;
        padding: 0 3em 0 1em;
        position: absolute;
        left: 0;
        top: -3em;
        border-radius: 0 1.5em 0 0;

        &:hover {
            cursor: pointer;
        }

        &:after {
            content: "[+]";
            display: block;
            font-size: 1.25em;
            color: inherit;
            position: absolute;
            right: 1em;
            top: 0;
        }
    }
    
    .blocker {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0, 0.5);
        opacity: 0;
        z-index: -1;
        height: 120vh;
        filter: none;

        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s;
    }
}
</style>
