// @ denotes that character is at a specific location
// # denotes that character is at one of several possible locations in the same group

import * as PIXI from 'pixi.js';
import {Controller} from '@makefully/engagefully';
import Images from '@/shared/data/images';
import VuexStorePuck from '@/shared/VuexStorePuck';
import boards from '@/shared/data/boards.js';
import puckIds from '@/shared/data/puckIds.json';
import store from '@/store/index';

const
    getPuck = (cId) => {
        const
            entry = puckIds.filter(({id}) => cId === id)[0];

        if (entry) {
            return entry;
        } else {
            console.warn(`No puck index defined for "${cId}".`);
            return null;
        }
    };
class Mission {
    constructor (vue, {board}, vueData, {clues, messages, gameEvents, quests = {}, regionData, scenes, startRegions}, {character, puck}, players, mapCanvas, restoreData) {
        let x = 0,
            key = null;

        this.board = board;
        this.vue = vue;
        this.vueData = vueData;

        this.restoreData = restoreData;
        this.restoreRegion = null;

        this.puckPlacement = null;
        this.startRegions = {...startRegions};
        this.setupRegionData = puckIds.filter((puckData) => {
            let found = false;

            regionData.forEach((category) => {
                if ((puckData.category === category) && (!puckData.range)) { // get everything from category except ranges.
                    found = true;
                }
            });

            return found;
        });
        this.regionData = {};
        this.eventData = gameEvents;
        this.clueText = clues;
        this.messages = messages;
        this.scenes = scenes;
        this.quests = quests[character]?.map((quest) => {
            const
                first = quest[0],
                last = quest[1],
                title = quest[2] ?? `${first}-then-${last}`;
            let begun = false,
                complete = false;

            return (state) => {
                if (!begun && state[first]) {
                    begun = true;
                    this.vue.$gameConsole.log('Mission: Quest Begun', title);
                }
                if (begun && !complete && state[last]) {
                    complete = true;
                    this.vue.$gameConsole.log('Mission: Quest Complete', title);
                }
            };
        }) ?? [];

        this.controller = null;

        this.localPlayer = {
            character,
            puck,
            region: 0,
            rotation: 0,
            locked: false
        };
        this.puckLocationIsClear = true;

        this.pixiApp = null;
        this.mapContainer = null;
        this.viewport = {
            w: 1,
            h: 1,
            x: 0,
            y: 0
        };
        this.characterSprites = {};
        this.puckZoneSprites = {};
        this.sprites = {};
        this.cameraDimensions = {"width": 0, "height": 0};
        this.setupMap(mapCanvas);
        
        this.state = {};
        
        this.mode = null;

        this.events = [];
        this.eventsToExecute = [];
        this.eventExecuting = null;

        this.rewards = [];
        this.rewardsMode = null;
        this.rewarding = null;

        this.activeChainId = null;
        this.activeChainIndex = -1;

        this.players = players;

        this.nameMap = {};
        if (this.scenes.nameMap) {
            // eslint-disable-next-line guard-for-in
            for (key in this.scenes.nameMap) {
                this.nameMap[key] = this.scenes.nameMap[key];
            }
        }

        for (x = 0; x < players.length; x++) {
            this.nameMap[players[x].character] = players[x].characterName;
        }
        this.vueData.nameMap = this.nameMap;

        this.preprocessEvents();
        this.preprocessScenes();

        this.sceneToPlay = null;
        this.setSceneToPlay(null);

        this.activeScene = null;

        this.newSounds = null;

        this.readyForVue = false;

        this.resizeHandler = this.resizeHandler.bind(this);
        this.debounceResize = 0;
        window.addEventListener('resize', this.resizeHandler);

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //REMOVE THIS BEFORE YOU FINISH!
        //window.missionGame = this;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        
    }

    loaded () {
        console.log("||loaded: Game has loaded.");
        this.vueData.ready = true;
        this.stateUpdated();
    }

    stateUpdated () {
        let event = null,
            key = null,
            x = 0;

        if (!this.vueData.ready) {
            console.log("||stateUpdated: Haven't finished loading yet.");
            return;
        }

        //When restoring a game, make sure the localPlayer's puck is not locked in somewhere before we start processing events.
        if (!this.puckLocationIsClear) {
            for (key in this.state) {
                if (this.state[key] && key.indexOf(this.localPlayer.character + "-@-") === 0) {
                    console.log("||stateUpdated: Local player location hasn't been cleared.");
                    return; //Local player's puck still claims to be somwhere, bail.
                }
            }
            console.log("||stateUpdated: Local player location is clear.");
            this.puckLocationIsClear = true;
        }

        if (!this.controller) {
            console.log("||stateUpdated: Connecting the pucks.");
            this.connectPuck();
        }

        //Check to see if we should close out the current event chain.
        if (this.activeChainId && this.state["chain-" + this.activeChainId + "-complete"]) {
            console.log("||stateUpdated: End of chain - " + this.activeChainId);
            this.activeChainId = null;
            this.activeChainIndex = -1;
        }

        this.checkEventConditions();

        if (this.eventExecuting) {
            if (this.state[this.eventExecuting.id + "-complete"]) {
                this.eventExecuting = null;
                if (this.mode !== 'setup') {
                    if (this.activeChainId) {
                        this.setMode('waiting-on-chain');
                    } else {
                        this.setMode('waiting-on-input');
                    }
                }
            } else {
                return;
            }
        }

        if (this.eventsToExecute.length !== 0) {
            if (this.activeChainId) {
                for (x = 0; x < this.eventsToExecute.length; x++) {
                    if (this.eventsToExecute[x].chainId === this.activeChainId) {
                        event = this.eventsToExecute.splice(x, 1)[0];
                        break;
                    } else if (!this.isRelevantToMe(this.eventsToExecute[x])) {
                        //We can only non-chain events where localPlayer's location doesn't matter.
                        event = this.eventsToExecute.splice(x, 1)[0];
                        break;
                    }
                }
                this.executeEvent(event);
            } else {
                event = this.eventsToExecute.shift();
                this.executeEvent(event);
            }

        }

        if (this.mode !== 'setup' && !this.rewarding) {
            for (key in this.state) {
                if (this.state[key] && key.indexOf('rew@rd') === 0) {
                    this.processReward(key);
                }
            }
        }

    }

    readyScene (toPlay) {
        let characterLeft = null,
            characterRight = null,
            region = null;

        this.setSceneToPlay(toPlay);

        characterLeft = this.sceneToPlay.stageLeft || null;
        characterRight = this.sceneToPlay.stageRight || null;
        region = this.sceneToPlay.setting;

        this.focusCameraOn(region);

        if (characterLeft) {
            this.placeCharacter(characterLeft, this.regionData[region].stageLeft, true);
        }

        if (characterRight) {
            this.placeCharacter(characterRight, this.regionData[region].stageRight);
        }

    }

    sceneCompleted () {
        this.setSceneToPlay(null);
        //this.completeEvent(this.eventExecuting);
    }

    soundsReceived () {
        this.newSounds = null;
        this.vueData.sound.newSounds = null;
        //this.completeEvent(this.eventExecuting);
    }

    checkEventConditions () {
        let x = 0,
            y = 0,
            event = null,
            condition = null,
            not = false,
            conditionsMet = true;

        for (x = 0; x < this.events.length; x++) {
            event = this.events[x];
            if (this.state[event.id + '-complete'] ||
                ((event.chainId === this.activeChainId) && (event.chainIndex <= this.activeChainIndex))  ||
                this.eventsToExecute.includes(event) ||
                (event.scene && (this.scenes[event.scene].for !== this.localPlayer.character))) { //If the scene isn't for us, we don't try to execute it.
                continue;
            }

            conditionsMet = true;
            for (y = 0; y < event.conditions.length; y++) {
                not = false;
                condition = event.conditions[y];

                if (condition[0] === "!") {
                    not = true;
                    condition = condition.slice(1);
                }

                if ((not && this.state[condition]) || (!not && !this.state[condition])) {
                    conditionsMet = false;
                    break;
                }
            }

            if (conditionsMet) {
                this.eventsToExecute.push(event);
            }
        }

    }

    executeEvent (event) {
        this.eventExecuting = event;

        if (!this.activeChainId && this.isRelevantToMe(this.getEventById(this.eventExecuting.chainId))) {
            this.activeChainId = this.eventExecuting.chainId;
            console.log("||executeEvent: Start chain - " + this.activeChainId);
        }

        if (this.activeChainId === this.eventExecuting.chainId) {
            this.activeChainIndex = this.eventExecuting.chainIndex;
        }

        if (event.puckPlacement) {
            console.log("||executeEvent: Executing puck placement prompt.");
            const character = this.localPlayer.character,
                region = event.puckPlacement[character];

            this.focusCameraOn(region);
            this.showPuckZone(region);
            this.placeCharacter(character, this.regionData[region].puckPlacement);
            this.puckPlacement = event.puckPlacement;

            this.vueData.setup.regionName = this.regionData[region].name;
            this.vueData.setup.isRestore = event.isRestore;
            
            this.setMode('setup');
            this.completeEvent(event);
            
        } else if (event.setupComplete) {
            console.log("||executeEvent: Executing setup complete.");
            this.hidePuckZones();
            this.setMode('waiting-on-input');
            this.completeEvent(event);
        } else if (event.clues) {
            this.addClues(event.clues.all, true);
            this.addClues(event.clues[this.localPlayer.character]);

            this.completeEvent(event);

        } else if (event.messages) {
            //console.log(event.messages);
            this.fireMessages(event.messages.all, true);
            this.fireMessages(event.messages[this.localPlayer.character]);
            
            this.completeEvent(event);
        } else if (event.sound) {
            if (event.sound.who === this.localPlayer.character) {
                //this.addSounds(event.sounds[this.localPlayer.character]);
                this.addReward("sound", {"soundIds": event.sound.what});

                // this.addSounds(event.sounds[this.localPlayer.character]);
                // this.setMode('new-sound');
            }
            this.completeEvent(event);

        } else if (event.scene) {
            //Should only ever enter this code if the scene is for the local character.
            // if (this.sceneToPlay) {
            //     console.error('Attempting to play a scene when a scene is already active. Active: ' + this.sceneToPlay.id + ', Attempting to Play: ' + event.scene);
            // } else {
            //     this.readyScene(this.scenes[event.scene]);
            // }
            // this.setMode('scene');

            this.addReward("scene", {"sceneId": event.scene});
            this.completeEvent(event);

        } else if (event.gameOver) {
            this.setMode('game-over');
            //this.completeEvent(event);

        } else if (event.results || event.sharedResults) {
            this.completeEvent(event);
        }

    }

    completeEvent (event) {
        let localChanges = null,
            sharedChanges = null,
            x = 0;

        console.log("||completeEvent: Completed event is - " + event.id);

        if (event.results) {
            localChanges = {};
            for (x = 0; x < event.results.length; x++) {
                if (event.results[x][0] === "!") {
                    localChanges[event.results[x].slice(1)] = false;
                } else {
                    localChanges[event.results[x]] = true;
                }
            }
            store.dispatch('updateMissionLocal', localChanges);
        }
        
        if (event.sharedResults) {
            sharedChanges = {};
            for (x = 0; x < event.sharedResults.length; x++) {
                if (event.sharedResults[x][0] === "!") {
                    sharedChanges[event.sharedResults[x].slice(1)] = false;
                } else {
                    sharedChanges[event.sharedResults[x]] = true;
                }
            }
            store.dispatch('updateMissionGame', sharedChanges);
        }
    }

    updateState (changes) {
        let key = null,
            dirty = false,
            character = null,
            regionData = null;

        for (key in changes) {
            if (typeof this.state[key] !== "undefined") {
                if (this.state[key] !== changes[key]) {
                    this.state[key] = changes[key];
                    dirty = true;
                }
            } else {
                this.state[key] = changes[key];
                dirty = true;
            }
        }

        if (dirty) {

            //Kind of a hack, but I want to know where the other players are, so we watch the changes and update their locations accordingly.
            for (key in changes) {
                if (!key.includes("-@-")) {
                    continue;
                }
    
                for (character in this.vueData.locations) {
                    if (key.includes(character + "-@-")) {
                        if (changes[key]) {
                            this.vueData.locations[character].regionId = key.split('-@-')[1];
                            regionData = this.getRegionDataById(this.vueData.locations[character].regionId);
                            this.vueData.locations[character].regionName = regionData?.name;
                        } else if (key === (character + "-@-" + this.vueData.locations[character].regionId)) {
                            this.vueData.locations[character].regionId = null;
                            this.vueData.locations[character].regionName = null;
                        }
                        break;
                    }
                }
            }

            this.stateUpdated();
        }
    }

    progressUpdate (newValues, oldValues) {
        //data contains:
        //oldValue - the previous gamestate values
        //value - the new gamestate values

        const changed = this.findStateChange(newValues, oldValues);
        if (changed) {
            this.updateState(changed);
            this.quests.forEach((quest) => quest(changed));
        }
    }

    findStateChange (newValues) {
        const
            oldValues = this.state;
        let changed = null;

        //TML - Making an assumption that deleting a property from the gameState
        // is not something we care about.
        for (const key in newValues) {
            if (typeof newValues[key] !== "undefined") {
                if (newValues[key] !== oldValues[key]) {
                    if (!changed) {
                        changed = {};
                    }

                    changed[key] = newValues[key];
                }
            } else {
                if (!changed) {
                    changed = {};
                }

                changed[key] = newValues[key];
            }
        }

        console.log("||findStateChange: State changes are...");
        // eslint-disable-next-line guard-for-in
        for (const key in changed) {
            console.log(">>> " + key + ": " + changed[key]);
        }

        return changed;
    }

    addClues (clues, areShared) {
        if (clues) {
            const
                giveAndReport = (clueInfo) => {
                    store.dispatch('giveMissionClue', clueInfo);
                    this.vue.$gameConsole.log('Mission: Clue Given', clueInfo);
                };
                
            for (let x = 0; x < clues.length; x++) {
                giveAndReport({clueId: clues[x], mine: !areShared});
            }
        }
    }
            
    fireMessages (messages, areShared) {
        let x = 0;

        if (!messages) {
            return;
        }

        if (areShared) {
            for (x = 0; x < messages.length; x++) {
                store.dispatch('sendMissionMessage', {messageId: messages[x], mine: false});
            }
        } else {
            for (x = 0; x < messages.length; x++) {
                store.dispatch('sendMissionMessage', {messageId: messages[x], mine: true});
            }
        }
    }

    addSounds (sounds) {
        let x = 0;
        
        this.newSounds = [...sounds];
        this.vueData.sound.newSounds = this.newSounds;

        //To send the whole array at once?
        for (x = 0; x < this.newSounds.length; x++) {
            store.dispatch('giveMissionSound', this.newSounds[x]);
        }
    }

    preprocessEvents () {
        //WHAT THIS DOES:
        //-Create a startEvent which looks to see that the players are placed on their startRegions
        //-Gives all events an id if they don't have one
        //--Events without ids are given an id based on the index in the event list
        //-All events are broken down into subevents with '-complete' result added

        const promptInitialSetupEvent = {
                id: "prompt-initial-setup",
                conditions: [],
                results: ["prompt-initial-setup-complete", "chain-prompt-initial-setup-complete"],
                puckPlacement: null,
                chainId: "prompt-initial-setup",
                chainIndex: 0
            },
            initialSetupEvent ={
                id: "initial-setup",
                conditions: ["prompt-initial-setup-complete"],
                results: ["game-started", "initial-setup-complete", "chain-initial-setup-complete"],
                chainId: "initial-setup",
                chainIndex: 0,
                setupComplete: true
            };
        let x = 0,
            anEvent = null,
            character = null,
            key = null;


        promptInitialSetupEvent.puckPlacement = {...this.startRegions};
        // eslint-disable-next-line guard-for-in
        for (character in this.startRegions) {
            initialSetupEvent.conditions.push(character + "-@-" + this.startRegions[character]);
        }

        this.events.push(promptInitialSetupEvent);
        this.events.push(initialSetupEvent);

        //If they never got out of the initial setup, don't restore. Start from the beginning.
        if (this.restoreData && this.restoreData["initial-setup-complete"]) {
            console.log("||preprocessEvents: Using restoreData.");

            const characterAt = this.localPlayer.character + "-@-",
                promptRestoreSetupEvent = {
                    id: "prompt-restore-setup-",
                    conditions: [],
                    results: [],
                    puckPlacement: {},
                    chainId: null,
                    chainIndex: 0,
                    isRestore: true
                },
                restoreSetupEvent = {
                    id: "restore-setup-",
                    conditions: [],
                    results: ["game-started"],
                    chainId: null,
                    chainIndex: 0,
                    setupComplete: true
                },
                locationClear = {};

            let location = null,
                restoreEventIndex = 0;
            
            // eslint-disable-next-line guard-for-in
            for (key in this.restoreData) {
                if (this.restoreData[key] && key.indexOf(characterAt) === 0) {
                    location = key.slice(characterAt.length);
                }
                if (key.indexOf("prompt-restore-setup-") === 0) {
                    restoreEventIndex += 1;
                }
            }
            promptRestoreSetupEvent.id = promptRestoreSetupEvent.id + restoreEventIndex;
            promptRestoreSetupEvent.results.push("prompt-restore-setup-" + restoreEventIndex + "-complete");
            promptRestoreSetupEvent.chainId = promptRestoreSetupEvent.id;
            promptRestoreSetupEvent.results.push("chain-" + promptRestoreSetupEvent.chainId + "-complete");

            restoreSetupEvent.id = restoreSetupEvent.id + restoreEventIndex;
            restoreSetupEvent.conditions.push("prompt-restore-setup-" + restoreEventIndex + "-complete");
            restoreSetupEvent.results.push("restore-setup-" + restoreEventIndex + "-complete");
            restoreSetupEvent.chainId = restoreSetupEvent.id;
            restoreSetupEvent.results.push("chain-" + restoreSetupEvent.chainId + "-complete");

            if (location) {
                promptRestoreSetupEvent.puckPlacement[this.localPlayer.character] = location;
                restoreSetupEvent.conditions.push(this.localPlayer.character + '-@-' + location);

                locationClear[characterAt + location] = false;
                store.dispatch('updateMissionGame', locationClear);
                this.puckLocationIsClear = false;

                console.log("||preprocessEvents: Clearing local player location - " + location);
            } else {
                promptRestoreSetupEvent.puckPlacement[this.localPlayer.character] = this.startRegions[this.localPlayer.character];
                restoreSetupEvent.conditions.push(this.localPlayer.character + '-@-' + this.startRegions[this.localPlayer.character]);
            }

            this.events.push(promptRestoreSetupEvent);
            this.events.push(restoreSetupEvent);

            this.eventsToExecute.unshift(promptRestoreSetupEvent);

            this.updateState(this.restoreData);
        }

        for (x = 0; x < this.eventData.length; x++) {
            anEvent = this.eventData[x];
            if (!anEvent.id) {
                anEvent.id = "event-" + x;
            }

            anEvent.sharedResults = this.createRewardsChain(anEvent.id, null, anEvent, 0, true);
            anEvent.results = [anEvent.id + "-complete"];
            anEvent.chainId = anEvent.id;
            anEvent.chainIndex = 0;
            delete anEvent.scene;
            delete anEvent.clues;
            delete anEvent.messages;
            delete anEvent.sound;
            delete anEvent.gameOver;

            this.events.push(anEvent);
        }

    }

    createRewardsChain (originEventId, upChainEventId, event, chainIndex, isFirstLink, isOtherEnd) {
        let x = null;
        const newEvent = {
            id: originEventId,
            conditions: [(upChainEventId || originEventId) + "-complete"],
            chainId: originEventId,
            chainIndex: chainIndex ? chainIndex + 1 : 1
        };


        if (event.scene) {
            if (isOtherEnd) {
                newEvent.id += "-scene-end";
                newEvent.conditions.push('completed=rew@rd=scene=' + event.scene);
                newEvent.sharedResults = this.createRewardsChain(originEventId, newEvent.id, {sound: event.sound, clues: event.clues, messages: event.messages, gameOver: event.gameOver, results: event.results}, newEvent.chainIndex);
            } else {
                newEvent.id += "-scene-start";
                newEvent.scene = event.scene;
                newEvent.sharedResults = this.createRewardsChain(originEventId, newEvent.id, {scene: event.scene, sound: event.sound, clues: event.clues, messages: event.messages, gameOver: event.gameOver, results: event.results}, newEvent.chainIndex, false, true);
            }

        } else if (event.sound) {
            newEvent.id += "-sound";
            newEvent.sound = event.sound;
            newEvent.results = this.createRewardsChain(originEventId, newEvent.id, {clues: event.clues, messages: event.messages, gameOver: event.gameOver, results: event.results}, newEvent.chainIndex);
            
        } else if (event.clues) {
            newEvent.id += "-clues";
            newEvent.clues = event.clues;
            newEvent.results = this.createRewardsChain(originEventId, newEvent.id, {messages: event.messages, gameOver: event.gameOver, results: event.results}, newEvent.chainIndex);

        } else if (event.messages) {
            newEvent.id += "-messages";
            newEvent.messages = event.messages;
            newEvent.results = this.createRewardsChain(originEventId, newEvent.id, {gameOver: event.gameOver, results: event.results}, newEvent.chainIndex);

        } else if (event.gameOver) {
            newEvent.id += "-game-over";
            newEvent.gameOver = event.gameOver;
            newEvent.results = this.createRewardsChain(originEventId, newEvent.id, {results: event.results}, newEvent.chainIndex);

        } else {
            newEvent.id += "-results";
            //Nothing more to add to the chain, return the final results.
            if (event.results) {
                newEvent.results = [...event.results, newEvent.id + "-complete"];
                //return [...event.results, (upChainEventId || originEventId) + "-complete"];

            } else {
                newEvent.results = [newEvent.id + "-complete"];
                //return [(upChainEventId || originEventId) + "-complete"];

            }
            newEvent.results.push("chain-" + newEvent.chainId + "-complete");
        }

        if (isFirstLink) {
            newEvent.conditions = [];
            // eslint-disable-next-line guard-for-in

            for (x = 0; x < this.players.length; x++) {
                newEvent.conditions.push(originEventId + "-" + this.players[x].character + "-check-in");
            }
        }

        this.events.push(newEvent);

        if (isFirstLink) {
            return [originEventId + "-" + this.localPlayer.character + "-check-in"];
        } else {
            return [(upChainEventId || originEventId) + "-complete"];
        }
    }

    preprocessScenes () {
        let id = null;

        //Adding ids to all the scenes.
        
        // eslint-disable-next-line guard-for-in
        for (id in this.scenes) {
            this.scenes[id].id = id;
        }
    }

    async setupMap (canvas) {
        const
            ids = [],
            mapArt = boards.filter((board) => board.id === this.board)[0],
            mapContainer = this.mapContainer = new PIXI.Container(),
            zoneId = mapArt.zoneArt;
        let textures = null;

        
        this.pixiApp = new PIXI.Application({
            background: '#191818',
            view: canvas,
            resizeTo: canvas.parentElement
        });
        mapContainer.sortableChildren = true;
        this.pixiApp.stage.addChild(mapContainer);

        this.cameraDimensions.width = this.pixiApp.screen.width;
        this.cameraDimensions.height = this.pixiApp.screen.height;

        // Add map art
        PIXI.Assets.add(mapArt.id, mapArt.asset);
        ids.push(mapArt.id);
        this.sprites[mapArt.id] = null;

        PIXI.Assets.add(zoneId, mapArt.zoneArt);
        ids.push(zoneId);

        for (let x = 0; x < Images.length; x++) {
            PIXI.Assets.add(Images[x].id, Images[x].path);
            ids.push(Images[x].id);
            this.sprites[Images[x].id] = null;
        }

        textures = await PIXI.Assets.load(ids);

        this.zoneArt = new PIXI.Spritesheet(textures[zoneId], mapArt.zoneData);
        await this.zoneArt.parse();
        // eslint-disable-next-line guard-for-in
        for (const key in this.zoneArt.textures) {
            const
                keyName = `zone-${getPuck(key.substring(key.lastIndexOf('-') + 1, key.lastIndexOf('.'))).label}`;

            textures[keyName] = this.zoneArt.textures[key];
            this.sprites[keyName] = null;
        }

        // eslint-disable-next-line guard-for-in
        for (const id in this.sprites) {
            //console.log("Sprite Loaded: " + id);
            this.sprites[id] = PIXI.Sprite.from(textures[id]);
            this.sprites[id].anchor.set(0);
            this.sprites[id].x = 0;
            this.sprites[id].y = 0;

            for (let y = 0; y < Images.length; y++) {
                if (id === Images[y].id) {
                    const
                        anchorX = Images[y].originX / Images[y].width,
                        anchorY = Images[y].originY / Images[y].height;

                    this.sprites[id].anchor.set(anchorX, anchorY);
                    continue;
                }
            }

            if (id.indexOf('zone-') === 0) {
                this.puckZoneSprites[id.slice(5)] = this.sprites[id];
                this.sprites[id].alpha = 0;
                this.sprites[id].zIndex = 1;
            } else if (id.indexOf('puck-') === 0) {
                this.characterSprites[id.slice(5)] = this.sprites[id];
                this.sprites[id].alpha = 0;
                this.sprites[id].zIndex = 2;
                this.sprites[id].scale.x = +mapArt.birdScaleX || 1;
                this.sprites[id].scale.y = +mapArt.birdScaleY || 1;
            } else {
                this.sprites[id].zIndex = 0;
            }

            mapContainer.addChild(this.sprites[id]);
        }

        mapContainer.pivot.x = 0;
        mapContainer.pivot.y = 0;
        mapContainer.scale.x = +mapArt.scaleX;
        mapContainer.scale.y = +mapArt.scaleY;
        mapContainer.x = 0;
        mapContainer.y = 0;
        
        this.createRegionData();

        this.loaded();
    }

    createRegionData () {
        const
            num = (txt) => +(txt || 0);

        this.setupRegionData.reduce((regionData, puckData) => {
            regionData[puckData.label] = {
                id: puckData.label,
                region: num(puckData.id),
                name: puckData.title,
                camPos: [num(puckData.camX), num(puckData.camY)],
                puckPlacement: [num(puckData.puckX), num(puckData.puckY)],
                stageLeft: [num(puckData.leftX), num(puckData.leftY)],
                stageRight: [num(puckData.rightX), num(puckData.rightY)],
                zoneSprite: this.sprites[puckData.label],
                tags: puckData.tags ? puckData.tags.split(',') : []
            };
            return regionData;
        }, this.regionData);
    }

    getRegionData (region) {
        let key = null;
        for (key in this.regionData) {
            if (this.regionData[key].region === region) {
                return this.regionData[key];
            }
        }
        return null;
    }

    getRegionDataById (id) {
        let key = null;
        for (key in this.regionData) {
            if (this.regionData[key].id === id) {
                return this.regionData[key];
            }
        }
        return null;
    }
    

    focusCameraOn (region) {
        this.moveCamera(this.regionData[region].camPos[0], this.regionData[region].camPos[1]);
    }

    resizeHandler () {
        clearTimeout(this.debounceResize);

        this.moveCamera();
        this.debounceResize = setTimeout(() => this.moveCamera(), 100);
    }

    moveCamera (xPos = this.viewport.x, yPos = this.viewport.y) {
        if (this.mapContainer) {
            const
                mapScale = this.mapContainer.scale,
                screen = this.pixiApp.screen,
                viewport = this.viewport;

            viewport.x = xPos;
            viewport.y = yPos;
            viewport.w = screen.width / mapScale.x;
            viewport.h = screen.height / mapScale.y;

            let actualXPos = Math.max(xPos - viewport.w / 2, 0),
                actualYPos = Math.max(yPos - viewport.h / 2, 0);

            if (actualXPos + viewport.w > this.mapContainer.width) {
                actualXPos = this.mapContainer.width - viewport.w;
            }

            if (actualYPos + viewport.h > this.mapContainer.height) {
                actualYPos = this.mapContainer.height - viewport.h;
            }

            this.mapContainer.x = -actualXPos * mapScale.x;
            this.mapContainer.y = -actualYPos * mapScale.y;
        }
    }

    hideCharacters () {
        let character = null;

        // eslint-disable-next-line guard-for-in
        for (character in this.characterSprites) {
            this.characterSprites[character].alpha = 0;
        }
    }

    placeCharacter (character, location, flipFacing) {
        const
            sprite = this.characterSprites[character],
            scale = sprite.scale.y;

        sprite.alpha = 1;
        sprite.x = location[0];
        sprite.y = location[1];

        if (flipFacing) {
            sprite.scale.set(-scale, scale);
        } else {
            sprite.scale.set(scale, scale);
        }
    }

    hidePuckZones () {
        let zone = null;

        // eslint-disable-next-line guard-for-in
        for (zone in this.puckZoneSprites) {
            this.puckZoneSprites[zone].alpha = 0;
        }
    }

    showPuckZone (regionId) {
        this.puckZoneSprites[regionId].alpha = 1;
    }

    playerButtonPressed () {
        if (this.mode !== 'waiting-on-chain') {
            this.togglePositionLock();
        }
    }

    updatePosition (position) {
        if (this.localPlayer.locked && position.region !== this.localPlayer.region) {
            if (this.mode !== 'waiting-on-chain') {
                console.log("||updatePosition: Moved out of locked position - " + position.region);
                this.togglePositionLock(); //Unlock the player because they moved their puck!
            } else {
                console.log("||updatePosition: TRIED to move out of locked position, but in chain - " + position.region);
                return;
            }
        }

        console.log('PLAYER REGION IS : ' + position.region);
        this.localPlayer.region = position.region;
        this.localPlayer.rotation = position.rotation;

        this.vueData.player.region = position.region;
        this.vueData.player.regionId = this.getRegionData(position.region)?.id;
        this.vueData.player.regionName = this.getRegionData(position.region)?.name;

        if (this.mode === 'setup') {
            const region = this.regionData[this.puckPlacement[this.localPlayer.character]];
            if (this.localPlayer.region === region.region) {
                this.vueData.setup.inPosition = true;
            } else {
                this.vueData.setup.inPosition = false;
            }
        }
    }

    togglePositionLock () {
        const
            changes = {},
            regionData = this.getRegionData(this.localPlayer.region),
            regionId = regionData?.id,
            tags = regionData?.tags;

        if (this.localPlayer.locked) {
            this.localPlayer.locked = false;
            if (regionData) {
                changes[this.localPlayer.character + "-@-" + regionId] = false;
                tags.forEach((tag) => {
                    changes[this.localPlayer.character + "-#-" + tag] = false;
                });
            }

            this.vueData.player.locked = false;

            store.dispatch('updateMissionGame', changes);
        } else {
            const validPosition = this.isPositionValid();

            if (!validPosition) {
                //Tell them they can't lock their position where they currently are!
                console.log("Invalid Position - Can't Lock Here!");
                return;
            }

            this.localPlayer.locked = true;
            if (regionData) {
                changes[this.localPlayer.character + "-@-" + regionId] = true;
                tags.forEach((tag) => {
                    changes[this.localPlayer.character + "-#-" + tag] = true;
                });
            }

            this.vueData.player.locked = true;

            store.dispatch('updateMissionGame', changes);
            this.vue.$gameConsole.log('Mission: Position Locked', regionId);
        }
    }

    isPositionValid () {
        const regionData = this.getRegionData(this.localPlayer.region);
        if (this.mode === 'waiting-on-input' || this.mode === 'setup') {
            if (regionData) {
                return true;
            }
        }
        return false;
    }

    isRelevantToMe (event) {
        let x = 0,
            condition = null;

        if (event.relevantTo) {
            if (event.relevantTo === this.localPlayer.character) {
                return true;
            }
        } else {
            for (x = 0; x < event.conditions.length; x++) {
                condition = event.conditions[x];
                if (condition.indexOf(this.localPlayer.character + "-@-") === 0) {
                    return true;
                }
            }
        }

        
        return false;
    }

    connectPuck () {
        this.controller = new Controller([
            new VuexStorePuck('Puck')
        ]);

        this.controller.on(`PuckPosition${this.localPlayer.puck}`, ({value}) => {
            this.updatePosition({
                "region": value,
                "rotation": this.localPlayer.rotation
            });
        });
        this.controller.on(`PuckRotation${this.localPlayer.puck}`, ({value}) => {
            this.updatePosition({
                "region": this.localPlayer.region,
                "rotation": value
            });
        });
        this.controller.on(`PuckButton${this.localPlayer.puck}`, ({value}) => {
            if (value !== 0) {
                this.playerButtonPressed();
            }
        });
    }

    setMode (newMode) {
        console.log("||setMode: Mode is - " + newMode);
        this.mode = newMode;
        this.vueData.mode = newMode;
    }

    completeReward () {
        const change = {};
        let sceneState = null;

        if (!this.rewarding) {
            return;
        }

        change[this.rewarding] = false;
        
        if (this.rewardsMode === 'scene') {
            sceneState = 'completed=rew@rd=scene=' + this.sceneToPlay.id;
            change[sceneState] = true;
            this.sceneCompleted ();
        } else if (this.rewardsMode === 'sound') {
            this.soundsReceived();
        }

        this.rewarding = null;
        this.rewardsMode = null;
        this.vueData.rewardsMode = null;
        store.dispatch('updateMissionLocal', change);
    }

    processReward (rewardKey) {
        const split = rewardKey.split('='),
            type = split[1],
            sounds = [];
        let x = 0;

        console.log("||processReward: Rewards Mode is - " + rewardKey);

        split.splice(0, 2);

        this.rewardsMode = type;
        this.vueData.rewardsMode = type;
        this.rewarding = rewardKey;

        switch (type) {
        case 'scene':
            this.readyScene(this.scenes[split[0]]);
            break;
        case 'sound':
            for (x = 0; x < split.length; x++) {
                sounds.push(split[x]);
            }
            this.addSounds(sounds);
            break;
        }
    }

    addReward (type, details) {
        const reward = {};
        let name = null,
            x = 0;

        switch (type) {
        case 'scene':
            reward['rew@rd=scene=' + details.sceneId] = true;
            store.dispatch('updateMissionLocal', reward);
            break;
        case 'sound':
            name = 'rew@rd=sound=';
            for (x = 0; x < details.soundIds.length; x++) {
                if (x > 0) {
                    name += '=';
                }
                name += details.soundIds[x];
            }
            reward[name] = true;
            store.dispatch('updateMissionLocal', reward);
            break;
        }
    }

    setSceneToPlay (scene) {
        this.sceneToPlay = scene;
        this.vueData.scene.sceneToPlay = scene;
    }

    getEventById (id) {
        let x = 0;

        for (x = 0; x < this.events.length; x++) {
            if (this.events[x].id === id) {
                return this.events[x];
            }
        }
        return null;
    }

    finishGame () {
        //This is for all stuff we need to do after they've completed a level and are going to the next game.
        this.cleanUp();
    }

    cleanUp () {
        if (this.pixiApp) {
            this.pixiApp.destroy(true, true);
            this.pixiApp = null;
        }
        
        if (this.controller) {
            this.controller.removeAllListeners();
            this.controller = null;
        }

        window.removeEventListener('resize', this.resizeHandler);

        store.dispatch('clearMission', true);
    }
}

export default Mission;