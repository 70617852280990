<template>
    <article class="puck-picker-container" :class="[{'connected': puckData, 'is-leader': isReferee}, character]">
        <header class="puck-state">
            <h3 v-if="puckData">Player Piece Connected!</h3>
            <h3 v-else>Waiting For A Player Piece…</h3>

            <!--<figure v-if="puckData" class="battery-indicator">
                <img class="icon" src="@/assets/ui/mtb-ui_battery-indicator_full.svg">
                <img class="icon" src="@/assets/ui/mtb-ui_battery-indicator_75.svg">
                <img class="icon" src="@/assets/ui/mtb-ui_battery-indicator_50.svg">
                <img class="icon" src="@/assets/ui/mtb-ui_battery-indicator_25.svg">
                <img class="icon" src="@/assets/ui/mtb-ui_battery-indicator_25.svg">
                <figcaption>100%</figcaption>
            </figure>-->
        </header>

        <div class="character-options" v-if="puckData">
            <Carousel
                class="character-outfits"
                :mouseDrag="!enabled"
                :touchDrag="!enabled"
                :class="{disabled: !enabled}"
                @slide-end="handleSlideEnd"
                ref="outfit"
            >
                <Slide v-for="outfit in myOutfits" :key="outfit.id">
                    <div class="carousel__item character-outfit">
                        <img alt="{{ outfit.title }}" :src="outfit.image" />
                    </div>
                </Slide>

                <template #addons>
                    <Navigation />
                </template>
            </Carousel>

            <em class="character-name">{{ characterName }}</em>

            <button
                class="mtb-btn icon menu-icon"
                :class="character"
                @click="showCharacterMenu"
                title="Character Details"
            >
                Character Details
            </button>
        </div>
        <AssignPlayer
            :character="character"
            :characterProperties="puckData"
            :isReferee="isReferee"
        ></AssignPlayer>
    </article>
</template>

<script>
/* eslint-disable */
import {mapGetters} from 'vuex';
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import AssignPlayer from "@/components/connect-ux/AssignPlayer.vue";
import outfits from "@/shared/data/outfits";

export default {
    name: 'PuckPicker',
    components: {
        Carousel,
        Slide,
        Navigation,
        AssignPlayer
    },
    props: {
        character: String,
        puckData: Object,
        isReady: {
            type: Boolean,
            default: false
        },
        choseOutfit: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            characters: 'getCharacters',
            referee: 'getReferee'
        }),
        myCharacter () {
            return this.characters.filter((character) => character.character === this.character)?.[0];
        },
        characterName () {
            return this.myCharacter?.characterName ?? '';
        },
        outfit () {
            return this.myCharacter?.outfit ?? '';
        },
        myOutfitIds () {
            return Object.keys(this.myCharacter.collectibles.outfits).filter((key) => this.myCharacter.collectibles.outfits[key] === 1);
        },
        myOutfits () {
            return this.myOutfitIds.map((id) => outfits[id]);
        },
        enabled () {
            return this.myCharacter?.player === this.$store.getters.getPlayerId;
        },
        isReferee () {
            return this.myCharacter?.player === this.referee;
        }
    },
    data () {
        return {
            firstLoad: true
        };
    },
    methods: {
        handleSlideEnd ({currentSlideIndex}) {
            if (this.enabled) {
                this.choseOutfit(this.myOutfitIds[currentSlideIndex]);
            }
        },

        updateSlide (outfit) {
            const
                index = this.myOutfitIds.indexOf(outfit);

            if (index >= 0) {
                this.$refs.outfit.slideTo(index);
            }
        },
            
        showCharacterMenu() {
            this.$emit('show-menu-event', this.character);
        }
    },
    watch: {
        outfit (outfit) {
            if (!this.enabled) { // Don't update the outfit if I'm just receiving my own update.
                this.updateSlide(outfit);
            }
        },

        puckData () {
            if (this.firstLoad && this.outfit) {
                setTimeout(() => { // Give vue a chance to add outfit ref
                    this.updateSlide(this.outfit);
                }, 0);
            }
            this.firstLoad = false;
        }
    }

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.puck-picker-container {
    flex: 1 1 auto;
    width: 25%;
    background: #CECBCB;
    height: 75vh;
    border: #000 0.25em solid;
    border-width: 0 0.2em;
    transform: translateY(30%);
    transition: 0.5s transform;

    .puck-state {
        background: #BAB8B8;
        display: flex;
        height: 2.8em;

        h3 {
            font-weight: bold;
            padding: 0 0.8333em;
            font-size: 0.8333em;
            line-height: 3.25;
            color: #515151;
            width: 100%;
            text-align: center;
        }

        .battery-indicator {
            display: block;
            height: 1.8em;
            width: 20%;
            margin: 0.5em;
            border-radius: 0.5em;
            position: relative;
            background: rgba(0,0,0, 0.08) url(~@/assets/ui/mtb-ui_piece-icon.svg) 18% 50% no-repeat;
            background-size: 30%;

            > * {
                position: absolute;
            }

            .icon {
                display: block;
                width: 47%;
                right: 10%;
                top: 15%;
            }

            figcaption {
                display: block;
                width: 47%;
                font-size: 0.4em;
                font-weight: bold;
                right: 10%;
                top: 65%;
                text-align: center;
            }
        }
    }

    &.connected {
        transform: translateY(0);
        border-width: 0;
        
        &.is-leader {transform: translateY(-2.5vw)}

        .puck-state h3 {
            color: $mtb-white;
            width: 80%;
            text-align: left;
        }
    }

    &.red-bird {
        &.connected {
            background-color: $mtb-red-bird-color;

            .puck-state, .character-name {
                background: $mtb-red-bird-color-dark;
            }
        }
    }

    &.purple-bird {
        &.connected {
            background-color: $mtb-purple-bird-color;

            .puck-state, .character-name {
                background: $mtb-purple-bird-color-dark;
            }
        }
    }

    &.teal-bird {
        &.connected {
            background-color: $mtb-teal-bird-color;

            .puck-state, .character-name {
                background: $mtb-teal-bird-color-dark;
            }
        }
    }

    &.yellow-bird {
        &.connected {
            background-color: $mtb-yellow-bird-color;

            .puck-state, .character-name {
                background: $mtb-yellow-bird-color-dark;
            }
        }
    }
    
    .menu-icon {
        position: absolute;
        top: 1vw;
        right: 1vw;
    }
    
    &.is-ready {
        background-image: url(~@/assets/ui/mtb-ui_ready-stripes.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        
        :deep .assigned-player {
            background-color: #96C93D;
            
            .avatar {
                border-color:#96C93D; 
            }
            
            .remove-btn {
                color: #96C93D;
            }
            
            em {
                color: $mtb-white;
                span {
                    color: $mtb-white;
                }
            }
            
            .ready-flag {
                opacity: 1;
            }
        }
    }
    
    .character-options {
        position: relative;
        
        .character-name {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-60%, 50%);
            display: block;
            margin: 0;
            text-align: center;
            padding: 0.5em 1.5em;
            width: max-content;
            border-radius: 1.5em;
        }
    }

    .character-outfits.disabled {
        pointer-events: none;

        :deep button {
            display: none;
        }
    }
}
</style>
