import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'GameManager', //TODO: Change the name of the component!
    
   
    properties: {
    },
    
   
    publicProperties: {
        courseData: null
    },
    
    initialize: function (/*definition, callback*/) {
        this.courseData = [... this.owner.parent.settings.level.course];
        //Add the count in to the course
        this.courseData.unshift({
            samples: [],
            dx: 1,
            dy: 0,
            length: 1
        });

        this.band = null;
        this.lakitu = null;

        this.currentRun = -1;
        this.results = [];

        // eslint-disable-next-line no-undef
        this.vueData = platypus.game.vueData;
        // eslint-disable-next-line no-undef
        this.gameConsole = platypus.game.gameConsole;

        this.runWasSuccess = false;

        this.successVeryBad = 0.25;
        this.successBad = 0.50;
        this.successGood = 0.75;
        this.successVeryGood = 1;

        this.attemptingStart = false;


    },

    events: {
        "child-entity-added": function (entity) {
            if (entity.type === 'band') {
                this.band = entity;
            } else if (entity.type === 'lakitu') {
                this.lakitu = entity;
            } else if (entity.type === 'debug-camera') {
                this.debugCamera = entity;
            }
        },

        "layer-live": function () {
            this.setMode('start-up');
            this.owner.triggerEvent('setup-thumbnail', this.currentRun + 2);
            // eslint-disable-next-line no-undef
            platypus.game.triggerEvent('game-manager-ready', this.owner);
            
        },

        "attempt-start-game": function () {
            if (!this.attemptingStart) {
                this.attemptingStart = true;
                this.band.triggerEvent('ready-audio');
            }
        },

        "start-run": function () {
            this.attemptingStart = false;
            //this.owner.triggerEvent('follow', {"entity": this.lakitu, "mode": "locked"});
            this.lakitu.triggerEvent('focus-on', this.band);
            
            this.currentRun += 1;
            this.setMode('performing');

            this.owner.triggerEvent('rl-show-meter');
            //Need to tell band to unlock input, reset any records, reset score, all that jive.

            this.gameConsole.log('Performance: Started Run', {
                run: this.currentRun
            });
        },

        "course-complete": function () {
            let latestResults = null,
                rank = null;
            
            if (this.vueData.mode === 'performing') {
                latestResults = this.band.getCourseResults();

                this.results.push(latestResults);
                if (latestResults.score > this.successBad) {
                    this.runWasSuccess = true;
                    this.vueData.results.levelComplete = true;
                } else {
                    this.runWasSuccess = false;
                }

                this.vueData.debugLogData.push(
                    {
                        success: this.runWasSuccess,
                        log: latestResults.log.processedInput
                    }
                );

                this.vueData.replayLogData.log = latestResults.log.processedInput;
                this.vueData.replayLogData.selected = -1;


                if (latestResults.score <= this.successVeryBad) {
                    rank = "very-bad";
                } else if (latestResults.score <= this.successBad) {
                    rank = "bad";
                } else if (latestResults.score <= this.successGood) {
                    rank = "good";
                } else if (latestResults.score <= this.successVeryGood) {
                    rank = "very-good";
                }
                this.vueData.results.lastRunRank = rank;

                this.owner.triggerEvent('rl-show-meter', 'middle');
                this.owner.triggerEvent('rl-reset-meter');
                this.setMode('replay-prompt');
                this.gameConsole.log('Performance: Finished Run', {
                    run: this.currentRun,
                    score: latestResults.score
                });
            } else if (this.vueData.mode === 'replay') {
                //We saw the replay, now present them the option to watch it again, try the course again, or if they've successfully completed the course, to go to the next level
                this.setMode('replay-watched');
                this.gameConsole.log('Performance: Watched Replay', {
                    run: this.currentRun
                });
            }
            
        },

        "start-replay": function () {
            this.lakitu.triggerEvent('focus-on', this.band);
            this.setMode('replay');

            this.owner.triggerEvent('prep-for-replay', this.runWasSuccess);
            //At this point, we feed the band the old input and have it run through it.
            this.band.triggerEvent('play-from-log', this.results[this.results.length - 1].log.input);
        },

        "retry": function () {
            this.owner.triggerEvent('setup-thumbnail', this.currentRun + 2);
            this.band.triggerEvent('reset-band');
            this.owner.triggerEvent('rl-reset-meter');
            this.owner.triggerEvent('rl-hide-meter');
            this.setMode('debug');
            this.vueData.startingRetry = true;
        },

        "input-correct": function () {
            this.vueData.replayLogData.input = 'good';
        },

        "input-incorrect": function (/*how*/) {
            this.vueData.replayLogData.input = 'bad';
        },

        "input-none": function () {
            this.vueData.replayLogData.input = 'none';
        },

        "reset-replay-selector": function () {
            this.vueData.replayLogData.selected = -1;
        },

        "advance-replay-selector": function () {
            this.vueData.replayLogData.selected += 1;
        },

        "toggle-debug": function () {
            if (this.vueData.mode === 'performing') {
                this.setMode('debug');
                this.gameConsole.log('Performance: Viewed Debugger', {
                    run: this.currentRun
                });
            } else {
                if (this.vueData.startingRetry) {
                    this.vueData.startingRetry = false;
                    this.owner.triggerEvent('start-run');
                } else {
                    this.setMode('performing');
                }
            }
        },
        "update-success": function (success) {
            let successLevel = null;

            if (success <= this.successVeryBad) {
                successLevel = "very-bad";
            } else if (success <= this.successBad) {
                successLevel = "bad";
            } else if (success <= this.successGood) {
                successLevel = "good";
            } else if (success <= this.successVeryGood) {
                successLevel = "very-good";
            }

            this.owner.triggerEvent('rl-set-meter', success, successLevel);
        },
        "update-bird-state": function (character, update) {
            const bird = this.vueData.birdStates[character];
            let isChange = false;

            if (update.direction && update.direction !== bird.direction) {
                if (update.direction === 'none') {
                    bird.direction = null;
                } else {
                    bird.direction = update.direction;
                }
                
                isChange = true;
            }

            if (update.sample && update.sample !== bird.sample) {
                if (update.sample === 'none') {
                    bird.sample = null;
                } else {
                    bird.sample = update.sample;
                }
                isChange = true;
            }

            if (typeof update.correctness === "boolean" && update.correctness !== bird.correctness) {
                bird.correctness = update.correctness;
                isChange = true;
            }

            if (typeof update.isPlaying === "boolean" && bird.isPlaying !== update.isPlaying) {
                bird.isPlaying = update.isPlaying;
                isChange = true;
            }
            
            if (isChange) {
                this.owner.triggerEvent('rl-update-replay-bird', character, bird);
                if (this.vueData.mode === 'performing') {
                    this.gameConsole.log('Performance: Bird State', bird);
                }
            }
        },
        "update-band-correctness": function (correctness, whoIsPlaying) {
            let character = null;

            // eslint-disable-next-line guard-for-in
            for (character in whoIsPlaying) {
                this.owner.triggerEvent('update-bird-state', character, {"isPlaying": whoIsPlaying[character], "correctness": correctness});
            }

        },
        "start-count-in": function () {
            this.vueData.countingIn = true;
        },
        "end-count-in": function () {
            this.vueData.countingIn = false;
        }

    },
    
    methods: {
        setMode: function (newMode) {
            if (newMode === this.vueData.mode) {
                return;
            }

            if (this.vueData.mode === 'performing') {
                this.band.triggerEvent('pause');
            } else if (this.vueData.mode === 'debug' || this.vueData.mode === 'start-up') {
                this.lakitu.triggerEvent('focus-on', this.band);
            }

            if (newMode === 'performing') {
                this.band.triggerEvent('unpause');
            } else if (newMode === 'debug' || newMode === 'start-up') {
                this.debugCamera.triggerEvent('reset', this.band);
                this.lakitu.triggerEvent('focus-on', this.debugCamera);
            }

            this.vueData.mode = newMode;

        }
    },
    
    publicMethods: {
        
        
    }
});
