
import mtb_music_mode_speaker from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker.png';
import mtb_music_mode_speaker_arrow from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker-arrow.png';
import mtb_music_mode_speaker_arrow_playing from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker-arrow_playing.png';
import mtb_music_mode_speaker_arrow_playing_issue from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker-arrow_playing-issue.png';
import mtb_music_mode_speaker_playing from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker_playing.png';
import mtb_music_mode_speaker_playing_glow from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker_playing_glow.png';
import mtb_music_mode_speaker_playing_issue from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker_playing-issue.png';
import mtb_music_mode_speaker_playing_issue_glow from '@/components/gamemodes/performance/assets/images/mtb_music-mode_speaker_playing-issue_glow.png';

export default {
    "images": [
        mtb_music_mode_speaker_playing,
        mtb_music_mode_speaker_playing_glow,
        mtb_music_mode_speaker_playing_issue,
        mtb_music_mode_speaker_playing_issue_glow,
        mtb_music_mode_speaker_arrow,
        mtb_music_mode_speaker_arrow_playing,
        mtb_music_mode_speaker_arrow_playing_issue,
        mtb_music_mode_speaker
    ],
    "frames": [
        [ 0, 0, 400, 400, 0, 200, 200],
        [ 0, 0, 265, 265, 1, 132.5, 132.5],
        [ 0, 0, 400, 400, 2, 200, 200],
        [ 0, 0, 265, 265, 3, 132.5, 132.5],
        [ 0, 0, 400, 400, 4, 200, 200],
        [ 0, 0, 400, 400, 5, 200, 200],
        [ 0, 0, 400, 400, 6, 200, 200],
        [ 0, 0, 400, 400, 7, 200, 200],
        [ 0, 0, 0, 0, 0, 0, 0]
    ],
    "animations": {
        "mtb_music-mode_speaker_playing": 0,
        "mtb_music-mode_speaker_playing_glow": 1,
        "mtb_music-mode_speaker_playing-issue": 2,
        "mtb_music-mode_speaker_playing-issue_glow": 3,
        "mtb_music-mode_speaker-arrow": 4,
        "mtb_music-mode_speaker-arrow_playing": 5,
        "mtb_music-mode_speaker-arrow_playing-issue": 6,
        "mtb_music-mode_speaker": 7,
        "none": 8
    }
}