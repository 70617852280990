<template>
    <div class="filter-bar">
        <p class="filter-controls">
            <em class="label"> {{label}} </em>
            <button
               class="selector"
               @click="() => {toggleOptions()}"
            >
                <i class="selector-icon" :class="iconClass"></i>
                <em class="selector-label">{{selectorLabel}}</em>
            </button>
        </p>
        <Transition name="drop-down">
            <nav class="filter-dropdown" v-if="optionsShowing">
                <FilterDropdownOption
                     v-for="(option, index) in options" :key="index"
                    :class="{selected: index === selected}"
                    :filterParent="this"
                    :filterType="filterType"
                    :label="labels[index]"
                    :option="option"
                    :index="index"
                    :disabled="disabled[index]"
                ></FilterDropdownOption>
            </nav>
        </Transition>
    </div>
</template>

<script>
import FilterDropdownOption from "@/components/gamemodes/practice/FilterDropdownOption.vue";

export default {
    name: 'FilterDropdown',
    components: {
        FilterDropdownOption
    },
    props: {
        boardComponent: null,
        filterType: String,
        label: String
    },
    data () {
        return {
            optionsShowing: false,
            selected: 0,
            options: [],
            labels: [],
            disabled: []
        };
    },
    computed: {
        iconClass () {
            const result = {};
            result["icon-" + this.options[this.selected]] = true;

            return result;
        },
        selectorLabel () {
            return this.labels[this.selected];
        }
    },
    mounted () {
        
    },
    methods: {
        init () {
            //Go ahead and select this one.
            this.boardComponent.selectedFilterOption(this.filterType, this.options[this.selected]);
        },
        selectedOption (value) {
            this.boardComponent.selectedFilterOption(this.filterType, value);
        },
        toggleOptions () {
            this.optionsShowing = !this.optionsShowing;
        },

        ////////////////////////////////////////////////////////////////////NEW METHODS
        setSelected (index) {
            this.selected = index;
        },
        addOption (newOption) {
            this.options.push(newOption);
        },
        addLabel (newLabel) {
            this.labels.push(newLabel);
        },
        addDisabled (newDisabled) {
            this.disabled.push(newDisabled);
        },
        setDisabled (index, value) {
            this.disabled[index] = value;
        },
        setDisabledPastIndex (index) {
            let x = 0;
            for (x = 0; x < this.disabled.length; x++) {
                if (x > index) {
                    this.setDisabled(x, true);
                } else {
                    this.setDisabled(x, false);
                }
            }
        }

    }
};
</script>

<style scoped lang="scss">
$filter-bar-height: 6vw;

.filter-bar {
    display: block;
    width: 20vw;
    height: $filter-bar-height;
    position: absolute;
    background: $mtb-ui-purp;

    font-size: 0.733em;
    font-weight: bold;
    border-radius: 0 0 1.5em 1.5em;
    
    pointer-events: all;

    &.player-filter {
        width: 29vw;
        left: 2vw;
    }

    &.turn-filter {
        right: 2vw;
    }

}

.filter-controls {
    display: block;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1.5em;

    .label {
        width: 40%;
        display: block;
    }

    .selector {
        width: 60%;
        display: block;
        position: relative;
        background: $mtb-ui-dark-purp;
        color: $mtb-white;
        padding: 0.5em;
        border-radius: 1.5em;
        height: 2.25em;
        display: flex;
        align-items: center;

        &:after {
            content: "";
            width: 0;
            height: 0;
            display: inline-block;
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-top: 0.6em solid $mtb-white;
            margin-left: 0.4em;

            position: absolute;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);

            opacity: 0.3; 
        }

        .selector-icon {
            display: block;
            flex: 0 0 auto;
            width: 2vw;
            height: 2vw;
            border-radius: 100%;
        }

        .selector-label {
            display: block;
            flex: 0 0 auto;
            width: 12vw;
            height: 2vw;
            line-height: 2vw;
            padding-right: 2vw;
            text-align: center;
        }
    }
}

.filter-dropdown {
    position: relative;
    background: $mtb-ui-purp;
    border-radius: 0 0 1.5em 1.5em;
    border-radius: 0 0 1.5em 1.5em;
    overflow: hidden;
    width: 86%;
    margin: 0 auto;
    z-index: -1;
    padding-top: 2vw;
    top: calc($filter-bar-height - 2vw);

    &.visible {
    }
}

.icon-0 {
    background-color: gray;
}
.icon-1 {
    background-color: rgb(95, 95, 95);
}
.icon-2 {
    background-color: rgb(78, 78, 78);
}
.icon-3 {
    background-color: rgb(63, 63, 63);
}
.icon-4 {
    background-color: rgb(54, 54, 54);
}
.icon-5 {
    background-color: rgb(39, 39, 39);
}
    
.icon-all {
    background-image: url(~@/assets/character/icons/mtb_bird-icons_all.svg);
}
.icon-red-bird {
    background-image: url(~@/assets/character/icons/mtb_bird-icons_red-bird.svg);
}
.icon-purple-bird {
    background-image: url(~@/assets/character/icons/mtb_bird-icons_purple-bird.svg);
}
.icon-teal-bird {
    background-image: url(~@/assets/character/icons/mtb_bird-icons_teal-bird.svg);
}
.icon-yellow-bird {
    background-image: url(~@/assets/character/icons/mtb_bird-icons_yellow-bird.svg);
}

</style>