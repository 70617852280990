<template>
    <div class="log-bar" ref="logBar">
        <div class="chunk-container" v-for="(chunk, index) in logChunks" :key="index">
            <div class="then-connector" v-if="index !== 0">then</div>
            <LogChunk
                :chunkData="chunk"
                :selected="index === selected && showSelected"
                :feedback="feedback"
            ></LogChunk>
        </div>

        
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';
import LogChunk from './LogChunk.vue';

export default {
    name: 'LogBar',
    props: {
        theGame: Object,
        logChunks: Object,
        showSelected: Boolean,
        selected: Number,
        feedback: String
    },
    computed: {
    },
    data () {
        
    },
    beforeMount () {
    },
    mounted () {
    
    },
    methods: {
        
    },
    watch: {
        
    },
    components: {LogChunk}
};
</script>

<style scoped lang="scss">
    .log-bar {
        position: absolute;
        display: flex;
        padding: 0 1.5vw;
        top: 50%;
        transform: translateY(-40%);
    }

    .chunk-container {
        flex: 1 1 auto;
        display: flex;
    }

    .then-connector {
        width: 5vw;
        height: 2vw;
        background-color: #4C5693;
        color: $mtb-white;
        border-radius: 1vw;
        text-align: center;
        line-height: 2vw;
        float: left;
        margin-top: 1.75vw;
        margin-left: 0.5vw;
        margin-right: 0.5vw;
    }

</style>