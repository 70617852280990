<template>
    <div class="connect-ble-screen" :class="connected ? 'hide' : 'show'">
        <button
            class="mtb-btn connect-ble-btn"
            v-on:click="onConnect"
        >
            {{attemptingConnection ? "Connecting..." : "Connect A Player Piece"}}    
        </button>
    </div>
</template>

<script>
import {Controller} from '@makefully/engagefully';
import MakefullyPuck from './MakefullyPuck';
import {mapGetters} from 'vuex';
import {useToast} from "vue-toastification";

const
    COLORS = {
        'red-bird': 0xff1100,
        'purple-bird': 0xff00ff,
        'teal-bird': 0x00ffff,
        'yellow-bird': 0xff8800
    },
    ANGLE_CHANGE_THRESHOLD = 5,
    angles = [0, 0, 0, 0],
    reduceAngleNoise = function (data) {
        for (let i = 0; i < angles.length; i++) {
            const
                newAngle = data[(i * 2) + 1] & 0x1ff,
                diff = Math.abs(newAngle - angles[i]);

            if ((diff >= ANGLE_CHANGE_THRESHOLD) && (diff <= (360 - ANGLE_CHANGE_THRESHOLD))) {
                angles[i] = newAngle;
            }

            data[(i * 2) + 1] = (data[(i * 2) + 1] & 0xfe00) | (Math.floor(angles[i] / ANGLE_CHANGE_THRESHOLD) * ANGLE_CHANGE_THRESHOLD);
        }

        return data;
    };

export default {
    name: "PuckBLEConnect",
    data () {
        return {
            pucksConnected: [false, false, false, false],
            attemptingConnection: false,
            makefullyPuck: null,
            toast: useToast()
        };
    },
    computed: {
        ...mapGetters({
            characters: 'getCharacters',
            connected: 'getReferee',
            meReferee: 'getIsReferee',
            presence: 'getPlayersHere'
        }),
        characterPresence () {
            const
                ref = [null, null, null, null];

            this.characters.forEach(({player}, index) => {
                ref[index] = this.presence[player] || false;
            });
            
            return ref;
        }
    },
    props: {
        development: Boolean
    },
    mounted () {
        const
            makefullyPuck = this.makefullyPuck = window.makefullyPuck = new MakefullyPuck(),
            controller = new Controller([
                makefullyPuck
            ]);

        // Shouldn't be the referee on mount except possibly during development, but if I am bring the Connect Button back up...
        if (this.meReferee) {
            this.$store.dispatch('setMeAsReferee', false);
        }

        controller.on("MakefullyPuckConnection", (event) => {
            const
                isConnected = !!event.value;

            this.$connectionConsole.log(`MakefullyPuck Connection: ${event.value}`);
            this.attemptingConnection = false;

            // new referee model
            this.$store.dispatch('setMeAsReferee', isConnected);
        });

        controller.on("PuckNotification", (event) => {
            const
                data = event.value.buffer ? new Int16Array(event.value.buffer) : event.value;

            this.$store.dispatch('updateAuthoritativePositions', reduceAngleNoise(data));
            this.pucksConnected = [
                !!(data[1] >> 15),
                !!(data[3] >> 15),
                !!(data[5] >> 15),
                !!(data[7] >> 15)
            ];
        });

        // Handle page unload
        this.disconnectBLE = () => {
            try {
                this.makefullyPuck.disconnect();
            } catch (e) {
                // Safely ignore if not connected.
            }
        };
        window.addEventListener("beforeunload", this.disconnectBLE);
    },

    beforeUnmount () { // Needed to clean up BLE if we catch it.
        this.disconnectBLE();
        window.removeEventListener("beforeunload", this.disconnectBLE);
    },

    methods: {
        async onConnect () {
            if (this.attemptingConnection) {
                this.$connectionConsole.warn('Already trying to connect BLE.');
            }

            this.$connectionConsole.log('Connecting BLE...');
            this.attemptingConnection = true;

            try {
                await this.makefullyPuck.initialize(this.development ? '' : this.$store.getters.getBoardCode);

                this.$store.watch((state, getters) => getters.getPuckLEDInstructions, (newValue/*, oldValue*/) => {
                    this.makefullyPuck.setLED(newValue);
                });
                this.$store.watch((state, getters) => getters.getPuckStateInstructions, (newValue/*, oldValue*/) => {
                    this.makefullyPuck.setState(newValue);
                });
            } catch (error) {
                this.$connectionConsole.error(error);

                if (error.name === 'TypeError') { // Currently this means there is no "navigator.bluetooth"
                    this.toast.error(`This web browser does not support Bluetooth. Try connecting to the Pucks with another device.`, {
                        timeout: 5000
                    });
                } else if (error.name !== 'NotFoundError') {
                    this.toast.error(`${error.message} (${error.name})`, {
                        timeout: 2000
                    });
                }
            }
            this.attemptingConnection = false;
        },

        updatePucksWithPresence (value, previousValue) {
            value.forEach((here, index) => {
                if (here !== previousValue[index]) {
                    if (here) {
                        this.makefullyPuck.setLED({
                            state: 'static',
                            color: COLORS[Object.keys(COLORS)[index]],
                            pucks: [index]
                        });
                    } else {
                        this.makefullyPuck.setLED({
                            state: 'slow-blink',
                            color: COLORS[Object.keys(COLORS)[index]],
                            pucks: [index]
                        });
                    }
                }
            });
        }
    },

    watch: {
        meReferee (value) {
            // in case there were changes while not referee.
            if (value) {
                this.updatePucksWithPresence(this.characterPresence, []);
            } else {
                // Just in case
                this.disconnectBLE();
            }
        },
        characterPresence (value, previousValue) {
            if (this.meReferee) {
                this.updatePucksWithPresence(value, previousValue);
            }
        },
        pucksConnected (value, prev) {
            // If a new puck drops in, make sure it gets updated.
            if (value.toString() !== prev.toString() && this.meReferee) {
                this.updatePucksWithPresence(this.characterPresence, []);
            }
        }
    }
};

</script>


<style lang="scss">
.connect-ble-screen {
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
    
    .mtb-btn.connect-ble-btn {
        position: fixed !important;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(2);

        &:hover {
            color: #fff !important;
            transform: translate(-50%, -50%) scale(2.2);

            &:after {
                background-color: #96C93D;
            }
        }
    }


    &:after {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;

        position: fixed;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: rgba(0, 0, 0, 0.95);
        backdrop-filter: blur(20px);
    }
    
    &.hide {
        opacity: 0 !important;
        pointer-events: none !important;
    }
     
    .characterselection-view &,
    .continue-view &,
    .play-view & {
        opacity: 1;
        pointer-events: all;
    }
}
</style>