

export default {
    "id": "stage-thumbnail",
    "components": [
        {
            "type": "RenderSprite",
            "spriteSheet": "stage-thumbnails",
            "animationMap": {
                "stage-basic": "stage-basic",
                "stage-lights": "stage-lights"
            }
        }, {
            "type": "RenderText",
            "style": {
                "fontFamily": "Roboto Slab",
                "fontSize": "36px",
                "fill": "#FFFFFF",
                "align": "center",
                "verticalAlign": "center"
            },
            text: "Watch Performance",
            offsetX: 0,
            offsetY: 120,
            offsetZ: 1
        }, {
            "type": "RenderText",
            "style": {
                "fontFamily": "Roboto Slab",
                "fontSize": "24px",
                "fill": "#FFFFFF",
                "align": "center",
                "verticalAlign": "center"
            },
            offsetX: 0,
            offsetY: 155,
            offsetZ: 1,
            aliases: {
                "set-run-text": "set-text"
            }
        }
    ],
    "properties": {
        x: 0,
        y: 0,
        z: 31,
        scaleX: 1,
        scaleY: 1,
        opacity: 1
    }
};