<template>
    <button class="filter-option" :class="optionClass" :onClick="() => {selectOption()}">
        <i class="option-icon" :class="iconClass"></i>
        <em class="option-label">{{label}}</em>
    </button>
</template>

<script>

export default {
    name: 'FilterDropdownOption',
    components: {
        
    },
    props: {
        filterParent: null,
        filterType: String,
        label: String,
        option: String,
        index: Number,
        disabled: Boolean
    },
    computed: {
        optionClass () {
            const result = {};
            result.odd = this.index % 2 && true;
            result.disabled = this.disabled;
            return result;
        },
        iconClass () {
            const result = {};
            if (/\d/.test(this.option)) {
                result["turn-" + this.option] = true;
            } else {
                result[this.option] = true;
            }

            return result;
        }
    },
    mounted () {
        
    },
    methods: {
        selectOption () {
            if (this.disabled) {
                return;
            }
            this.filterParent.selectedOption(this.option);
            this.filterParent.toggleOptions();
        }
    }
};
</script>

<style scoped lang="scss">
.filter-option {
    display: block;
    cursor: pointer;
    width: 100%;
    background-color: $mtb-ui-dark-purp;
    color: $mtb-white;

    padding: 1em;
    display: flex;
    align-items: center;

    &.odd {
        background-color: $mtb-ui-purp;
    }

    &.selected {
        background-color: $mtb-ui-action;
    }

    &.disabled {
        background-color: #ccc;
        color: #aaa;
        cursor: default;
        
        .option-icon {
            opacity: 0.3;
        }
    }

    .option-icon {
        display: block;
        width: 2.5vw;
        height: 2.5vw;
        float: left;
        border-radius: 1vw;
        margin-right: 0.5em;
        background: url(~@/assets/character/icons/mtb_bird-icons_all.svg) 50% 50% no-repeat;
        background-size: contain;
        
        &.red-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_red-bird.svg);
        }
        &.purple-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_purple-bird.svg);
        }
        &.teal-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_teal-bird.svg);
        }
        &.yellow-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_yellow-bird.svg);
        }
        
        &.turn-0 {
           background-image: url('~@/assets/gamemodes/practice/ui/mtb-ui_start-flag.svg');  
        }
        
        &.turn-1 {
          background-image: url(~@/assets/gamemodes/practice/ui/mtb-ui_turn-icon_1.svg);
            
        }
        
        &.turn-2 {
          background-image: url(~@/assets/gamemodes/practice/ui/mtb-ui_turn-icon_2.svg);    
        }
        
        &.turn-3 {
          background-image: url(~@/assets/gamemodes/practice/ui/mtb-ui_turn-icon_3.svg);    
        }
        
        &.turn-4 {
          background-image: url(~@/assets/gamemodes/practice/ui/mtb-ui_turn-icon_4.svg);    
        }
    }

    .option-label {
        display: block;
    }

}

</style>