import dpeService from '@/shared/dpeService';
import router from '@/router/';

const
    TTL = 60 * 60 * 1000, // 1 hour
    INVALIDATION_POLL_RATE = 10 * 1000,
    player = {
        state: () => ({
            autoTried: false,
            token: null,
            player: null,
            playerId: null,
            playthroughs: [],
            loggedIn: false
        }),

        actions: {
            async initializeAuthentication ({commit, dispatch}) {
                const
                    initialCreds = sessionStorage.getItem('mtb-login');

                if (initialCreds) {
                    try {
                        const
                            {token, personId} = JSON.parse(initialCreds);

                        commit('setToken', token);
                        commit('setPlayerId', personId);

                        await dispatch('refreshAuthToken');
                        await dispatch('loadPlayerData');
                    } catch (e) {
                        console.warn('Stored credentials are invalid.', initialCreds);
                    }
                }

                commit('autoLoginTried');

                // auto-refresh token
                setInterval(async () => {
                    console.log('AUTO REFRESHING TOKEN');
                    dispatch('refreshAuthToken');
                }, TTL);
            },

            async refreshAuthToken ({dispatch, state}) {
                if (state.playerId && state.token) {
                    try {
                        const
                            {personId, token} = await dpeService.auth.refreshToken(state.playerId, state.token);

                        await dispatch('saveCredentials', {personId, token});
                        await dispatch('updateLoggedIn', !!(personId && token));
                    } catch (e) {
                        console.warn('Logging out due to error refreshing token.', e);
                        await dispatch('saveCredentials', null);
                        await dispatch('updateLoggedIn', false);
                    }
                } else {
                    await dispatch('updateLoggedIn', false);
                }
            },

            saveCredentials ({commit}, credentials) {
                sessionStorage.setItem('mtb-login', JSON.stringify(credentials));

                commit('setToken', credentials?.token ?? null);
                commit('setPlayerId', credentials?.personId ?? null);
            },

            logoutPlayer ({commit, dispatch}) {
                dispatch('updateLoggedIn', false);
                commit('setPlayer', null);
                commit('setPlaythroughs', []);
                dispatch('saveCredentials', null);
                router.push({
                    name: 'Login'
                });
            },

            async loginPlayer ({dispatch}, creds) {
                const
                    {personId, token} = await dpeService.auth.login(creds.username, creds.password);

                if (personId && token) {
                    await dispatch('saveCredentials', {personId, token});
                    await dispatch('updateLoggedIn', true);
                    await dispatch('loadPlayerData');
                }
            },

            async loadPlayerData ({dispatch}) {
                dispatch('loadPlaythroughs');
                dispatch('appStart');
            },

            updateLoggedIn ({commit, dispatch, state}, value) {
                clearTimeout(invalidationPoll);

                if (value) {
                    const
                        poll = async () => {
                            const
                                {isValid, secondsRemaining} = await dpeService.auth.getTokenStatus(state.playerId, state.token);

                            if (!isValid) {
                                dispatch('logoutPlayer');
                                console.warn('Logged out player due to logging in elsewhere.');
                            } else {
                                invalidationPoll = setTimeout(poll, Math.min(INVALIDATION_POLL_RATE, secondsRemaining * 1000));
                            }
                        };

                    invalidationPoll = setTimeout(poll, INVALIDATION_POLL_RATE);
                }

                commit('setLoggedIn', value);
            },

            async loadPlaythroughs ({commit, state}) {
                const
                    playerId = state.playerId,
                    playerData = {},
                    playthroughs = await dpeService.playthroughs.forPlayer(playerId),

                    // So we're only calling the server once per person:
                    getPlayerData = async (id) => {
                        if (!playerData[id]) {
                            playerData[id] = dpeService.people.get(id);
                        }
                        return playerData[id];
                    };

                // Go ahead and update this player's data while we're pulling player info.
                commit('setPlayer', {
                    player: playerId,
                    ...await getPlayerData(playerId)
                });
    
                // Flesh out playthroughs. (replace player ids with players' info)
                for (let i = 0; i < playthroughs.length; i++) {
                    const
                        playthrough = playthroughs[i];

                    playthrough.players = await Promise.all(playthrough.players.map(getPlayerData));
                }

                playthroughs.sort((a, b) => (b.lastPlayed || 0) - (a.lastPlayed || 0));

                commit('setPlaythroughs', playthroughs);
            }
        },

        mutations: {
            autoLoginTried (state) {
                state.autoTried = true;
            },

            setLoggedIn (state, value) {
                state.loggedIn = value;
            },
            setPlayer (state, value) {
                state.player = value;
            },
            setPlayerId (state, value) {
                state.playerId = value;
            },
            setPlaythroughs (state, value) {
                state.playthroughs = value;
            },
            setToken (state, value) {
                state.token = value;
            }
        },

        getters: {
            hasTriedAutoLogin (state) {
                return state.autoTried;
            },

            isAuthenticated (state) {
                return state.loggedIn;
            },

            getPlayerId (state) {
                return state.playerId;
            },
            
            getPlaythroughs (state) {
                return state.playthroughs;
            }
        }
    };
let invalidationPoll = 0;

export {player};