import purple_rig_data from '@/assets/character/animations/purple/PurpleBird.json';
import purple_rig_atlas from '@/assets/character/animations/purple/PurpleBird.atlas';
import purple_rig_ss from '@/assets/character/animations/purple/PurpleBird.png';
    
import yellow_rig_data from '@/assets/character/animations/yellow/YellowBird.json';
import yellow_rig_atlas from '@/assets/character/animations/yellow/YellowBird.atlas';
import yellow_rig_ss from '@/assets/character/animations/yellow/YellowBird.png';
    
import red_rig_data from '@/assets/character/animations/red/RedBird.json';
import red_rig_atlas from '@/assets/character/animations/red/RedBird.atlas';
import red_rig_ss from '@/assets/character/animations/red/RedBird.png';
    
import teal_rig_data from '@/assets/character/animations/teal/TealBird.json';
import teal_rig_atlas from '@/assets/character/animations/teal/TealBird.atlas';
import teal_rig_ss from '@/assets/character/animations/teal/TealBird.png';

export default {
    red: {
        data: red_rig_data,
        atlas: red_rig_atlas,
        ss: red_rig_ss
    },
    purple: {
        data: purple_rig_data,
        atlas: purple_rig_atlas,
        ss: purple_rig_ss
    },
    teal: {
        data: teal_rig_data,
        atlas: teal_rig_atlas,
        ss: teal_rig_ss
    },
    yellow: {
        data: yellow_rig_data,
        atlas: yellow_rig_atlas,
        ss: yellow_rig_ss
    }
};