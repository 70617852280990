<template>
    <Transition name="circle-wipe">
        <ComicViewer
            v-if="comicOpen"
            :comic="comic"
            @close-event="closeComic"  
        />
    </Transition>
    <div class="section-wrapper lander">
        <MyInfo />
        <MyPlays />
    </div>

    <nav class="character-menu-dev">        
        <button class="mtb-btn action" @click="showComic">Show Comic</button>
    </nav>
</template>

<script>
// @ is an alias to /src
import MyInfo from "@/components/lander/MyInfo.vue";
import MyPlays from "@/components/lander/MyPlays.vue";
    
//for dev without going into game
import ComicViewer from "@/components/comic/ComicViewer.vue";
import comicData from '@/shared/data/comics/transition-performance';
    
import {useToast} from "vue-toastification";

export default {
    name: "MainView",
    components: {
        MyPlays,
        MyInfo,
        ComicViewer
    },
    
    data () {
        return {
            comic: comicData,
            comicOpen: false,
            toast: useToast()
        };
    },
    
    created () {
    },

    async mounted () {
        window.toast = this.toast;
    },
    
    methods: {  
        showComic() {
            this.comicOpen = true;
        },
        closeComic() {
            this.comicOpen = false;
        }
        
    }
};
</script>

<style scoped lang="scss">
.character-menu-dev {
    display: flex;
    transform: scale(0.5);
    transform-origin: left;

    > button {
        margin: 0 1vw;
        display: none;
    }
}
</style>
