<template>
    <div class="debug-bar">
        <button
            class="start-game-button mtb-btn action bottom-ready"  
            :class="{'visible': startingRetry}" 
            :onClick="() => {toggleDebug();}"
        >
            Start Game
        </button>
        <div class="log-button-container">
            <div class="log-button" :class="{selected: selected === index}" @click="selectLog(index)" v-for="(log, index) in logData" :key="index">
                <div class="log-success-indicator" :class="{success: logData[index].success}"></div>
                <div class="log-name">{{'Run ' + (index + 1) + ' - Debug Logs'}}</div>
            </div>
            <div class="log-button faux">
                <div class="log-name">{{'Run In Progress'}}</div>
            </div>
        </div>
        <div class="log-container scrollable horizontal">
            <LogBar :theGame="theGame" :logChunks="selectedLog" :showSelected="false"></LogBar>
        </div>
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';
import LogBar from './LogBar.vue';

export default {
    name: 'DebugBar',
    props: {
        theGame: Object,
        logData: Object,
        startingRetry: Boolean
    },
    computed: {
        selectedLog () {
            if (this.logData.length) {
                return this.logData[this.selected].log;
            } else {
                return null;
            }
        }
    },
    data () {
        return {
            selected: 0
        };
    },
    beforeMount () {
    },
    mounted () {
        
    },
    methods: {
        selectLog (index) {
            this.selected = index;
            this.$gameConsole.log('Performance: Viewed Log', index);
        },
        toggleDebug () {
            if (this.startingRetry) {
                this.theGame.triggerEvent('toggle-debug');
            }
        }
    },
    watch: {
        
    },
    components: {LogBar}
};
</script>

<style scoped lang="scss">
    .debug-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 12vw;
        z-index: 1;
        
        :deep .chunk {
            border-color: transparent !important;
        }
    }

    .log-button-container {
        position: absolute;
        left: 4%;
        top: 0;
        width: 92%;
        height: 4vw;  
    }

    .log-button {
        width: max-content;
        height: 4vw;
        padding: 0 0.75em 0 0.5em;
        background-color: #1b204b;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        color: #626DB2;
        cursor: pointer;
        float: left;
        margin-right: 0.5vw;
        
        transition: height 0.3s, transform 0.3s;

        &.selected {
            background-color: #ffffff;
            color: #20233F;
            
            pointer-events: none;
        }
        
        &:hover {
            color: #fee888;
            height: 3.5vw;
            transform: translateY(-0.5vw);
        }

        &.faux {
            background-color: #141628;
            border-width: 0.15em;
            border-style: dashed;
            border-color: #7292A0;
            cursor: default;
            border-bottom: none;
            pointer-events: none;
            padding: 0 0.75em;
        }
    }

    .log-success-indicator {
        width: 2vw;
        height: 2vw;
        float: left;
        border-radius: 1vw;
        background-color: red;
        display: block;
        opacity: 1;
        margin: 0.4vw;

        &.success {
            background-color: green;
        }
    }

    .log-name {
        text-align: center;
        float: left;
        font-size: 1.25vw;
        line-height: 3vw;
    }

    .faux .log-name {
        color: #7292A0;
    }
    
    .log-container {
        position: absolute;
        bottom: 0;
        height: 9vw;
        background: #ffffff url(~@/assets/ui/mtb-ui_logo-grid-pattern.svg) top left repeat;
        box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.3);
        border: 0.4em solid #fff;
        border-bottom: none;
        border-radius: 1.5em 1.5em 0 0;
        width: 97%;
        left: 1.5%;
        
        &::-webkit-scrollbar {
            display: block;
            height: 0.75vw;
            margin-bottom: 1em;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(76, 86, 147, 0.3);
            border-radius: 2vw;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #4C5693;
            background: rgba(76, 86, 147, 1);
            cursor: pointer;
        }
    }

    .start-game-button {
        bottom: 11vw !important;
        z-index: 5;
    }

</style>