<template>
<button id="tester-toggle" :class="{ 'active': showPanel }" @click="togglePanel">Tester Toggle</button>
<div id="state-tester" :class="{ 'show': showPanel }" class="scrollable">
    <div class="test-set" v-for="porthole in portholes" :key="porthole.index">
        <h3>{{porthole.bird_color}} State</h3>
        <fieldset>
            <span class="input-group skinny">
                <label for="pb_state_ready" class="option-head inline">Ready:</label>
                <select name="pb_state_ready" @change="updateBirdState(porthole.bird_color, 'ready', $event.target.value)">
                    <option value="true">True</option>
                    <option value="false" selected>False</option>
                </select>
            </span>
            <span class="input-group">
                <label class="option-head inline">Emote:</label>
                
                <select name="pb_state_emote" @change="updateBirdState(porthole.bird_color, 'emote type', $event.target.value)">
                    <option value="idle" selected>idle</option>
                    <option value="pose">pose</option>
                    <option value="disappointed">disappointed</option>
                    <option value="excited">excited</option>
                    <option value="frustrated">frustrated</option>
                    <option value="thinking">thinking</option>
                    <option value="waiting">waiting</option>
                </select>
            </span>
            <span class="input-group thicc">
                <em class="option-head">Action BTN:</em>
                <label class="sub-head" for="pb_state_actiontext">Text</label>
                <input class="push" type="text" name="pb_state_actiontext" placeholder='default "Done"' @blur="updateBirdState(porthole.bird_color, 'actionBTN text', $event.target.value)" />

                <label for="pb_state_actionshow" class="sub-head">Show:</label>
                <select name="pb_state_actionshow" @change="updateBirdState(porthole.bird_color, 'actionBTN show', $event.target.value)">
                    <option value="true">True</option>
                    <option value="false" selected>False</option>
                </select>
            </span>
            <span class="input-group bottom-wide">
                <input type="text" name="pb_state_emote_text" placeholder='Bubble Text' @blur="updateBirdState(porthole.bird_color, 'emote text', $event.target.value)" />

                <input type="number" name="pb_state_emote_bt" placeholder='Bubble Timeout' @blur="updateBirdState(porthole.bird_color, 'emote timeout', $event.target.value)" />
            </span>
        </fieldset>
    </div>
</div>
</template>

<script>
/* eslint-disable */
    
export default {
    name: 'CharacterBarTester',
    components: {
    },
    props: {
        portholes: Object
    },
    data () {
        return {
            showPanel: false
        };
    },
    emits: ['update-portholes-event'], // I don't see where this is emitted, but the CharacterBar throws a warning for this event.
    created () {
    },
    mounted () {
    },
    methods: {
        togglePanel() {
            this.showPanel = !this.showPanel
        },
        booleanString(value) {
            value = value.toLowerCase() === "false" ? false : true;
            return value;
        },
        updateBirdState (...args) {
            this.$parent.updateBirdState(...args);
        }
    }
};
</script>

<style scoped lang="scss"> 
#tester-toggle {
    width: 2vw;
    height: 2vw;
    border-radius: 100%;
    position: absolute;
    bottom: 0.5vw;
    left: 1vw;
    font-size: 0.65em;
    font-weight: bold;
    background: transparent;
    border: 0.15em solid $mtb-white;
    color: $mtb-white;
    background: #4C5693 url(~@/assets/ui/mtb-dev-ui_beaker-icon.svg) no-repeat 50% 50%;
    background-size: 80%;
    text-indent: -999em;
    opacity: 0.3;
    
    transition: transform 0.5s;
    transform-origin: bottom left;
    
    &:hover {
        transform: scale(2);
        cursor: pointer;
        opacity: 1;
    }
    
    &.active {
        background-color: $mtb-white;
        opacity: 1;
        border-color: #a1adf6;
        background-blend-mode: difference;
    }
}

#state-tester {
    display: block;
    position: absolute;
    left: 10vw;
    width: 90vw;
    bottom: 0;
    background: #161a38;
    z-index: -1;
    padding: 10vh 2vw 1vw 9vw;
    height: 100vh;
    font-size: 0.8em;
    
    transform: translateY(100%);
    transition: transform 0.5s;
    
    &.show {
        transform: translateY(0);
    }
    
    .test-set {
        background: #0e122a;
        padding: 3vw 3vw 2vw;
        border-radius: 3vw;
        color: #a1adf5;
        margin-bottom: 1em;
        
        h3 {
            font-size: 1.25em;
            display: block;
            margin-bottom: 1em;
            
        }
        
        fieldset {
            display: flex;
            flex-wrap: wrap;
            
            > * {
                margin: 0 1vw 0 0;
                flex: 0 0 30%;
            }
            
            > .skinny {
                flex: 0 0 20%;
                
                select {
                    width: 50%;
                }
            }
            
            > .thicc {
                flex: 0 0 45%;
                margin-top: -2.05em;
                
                input[type=text] {
                    width: 37%;
                    margin-right: 1em;
                }
            }
            
            > .bottom-wide {
                flex: 0 0 80%;  
                display: flex;
                margin-left: 10%;
                
                > * {
                    text-align: center;
                }
                
                input[type=text] {
                    width: 175%;
                    margin-right: 1em;
                }
            }
            
            .option-head {
                display: block;
                margin-bottom: 1em;
                text-align: left;
                text-transform: uppercase;
                
                &.inline {
                    display: inline-block;
                    width: fit-content;
                }
            }
            
            .sub-head {
                display: inline-block;
                margin-bottom: 1em;
                width: fit-content;
                text-align: left;
            }
            
            .push {
                margin-bottom: 1em;
            }
        }
        
        input[type=text] {
            padding: 0.5em;
            border-radius: 1.5em;
            text-align: center;
            width: 65%;
            margin-left: 1em;
        }
        
        select {
            width: 40%;
            margin-left: 1em;
            padding: 0.5em;
            max-width: 8vw;
        }
    }
}
</style> 