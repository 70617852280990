<template>
    <header v-if="isDecisionLead" class="leader-bar">
        <h2>You Have The Controller.</h2>
        <button class="inline-info-btn" title="What does this mean?">&#x24D8;</button>
    </header>
    <GroupButton
        class="leader-bar-group-button"
        :buttonClasses="'mtb-btn action top-nav left'"
        confirmationModel="leader"
        :onAgreement="onQuit"
        title="Save & Quit"
        :milestone="true"
        :decisionId="`quit-minigame-${progress}`"
        decision="quit"
    />
    <Transition name="circle-wipe">
        <ComicViewer
            v-if="comicOpen"
            :comic="minigame?.cutscene"
            :wasViewed="closedFirstInstance"
            @close-event="closeComic"
        />
    </Transition>
    <div class="section-wrapper game-view" :class="{'is-leader': isDecisionLead}">
        <MissionGame
            v-if="minigame?.mode === 'mission'"
            :minigameData="minigame"
            @show-comic="showComic"
            @finish-game="finishGame"
        />
        <PerformanceGame
            v-if="minigame?.mode === 'performance'"
            :minigameData="minigame"
            @show-comic="showComic"
            @finish-game="finishGame"
        />
        <PracticeGame
            v-if="minigame?.mode === 'practice'"
            :minigameData="minigame"
            @show-comic="showComic"
            @finish-game="finishGame"
        />
    </div>
</template>

<script>
import ComicViewer from "@/components/comic/ComicViewer.vue";
import GroupButton from '@/components/GroupButton.vue';
import MissionGame from "@/components/gamemodes/mission/MissionGame.vue";
import PerformanceGame from "@/components/gamemodes/performance/PerformanceGame.vue";
import PracticeGame from "@/components/gamemodes/practice/PracticeGame.vue";
import dpeService from '@/shared/dpeService';
import {mapGetters} from 'vuex';
import story from '@/shared/data/story';

export default {
    name: "GameView",
    components: {
        GroupButton,
        MissionGame,
        PerformanceGame,
        PracticeGame,
        ComicViewer
    },
    props: {
    },
    data () {
        const
            activePlaythrough = this.$store.getters.getActivePlaythrough,
            lastPlayedState = activePlaythrough.lastPlayedState,
            progress = this.$store.getters.getProgress ?? 0;

        return {
            closedFirstInstance: false,
            quickPlay: lastPlayedState?.quickPlay || false,
            comicOpen: false,
            progress,
            minigame: story[progress]
        };
    },
    computed: {
        ...mapGetters({
            comicsViewed: 'getViewedComics',
            isDecisionLead: 'getIsReferee'
        }),
        cutsceneId () {
            return `comic-${this.minigame.order}`;
        },
        cutsceneViewed () {
            return !!this.comicsViewed[this.cutsceneId];
        }
    },
    mounted () {
        this.$store.dispatch('appReady');
        window.finishGame = () => this.finishGame(); // temp testing func.

        this.$gameConsole.log('Game Started', this.minigame?.mode);

        if (!this.cutsceneViewed) {
            this.showComic();
            this.$store.dispatch('reportView', this.cutsceneId);
        } else {
            this.closedFirstInstance = true;
        }
    },
    methods: {
        showComic () {
            this.comicOpen = true;
        },
        closeComic () {
            this.comicOpen = false;
            this.closedFirstInstance = true;
        },
        finishGame () {
            if (!this.quickPlay) {
                const
                    progress = (this.progress ?? 0) + 1;

                this.$store.dispatch('stampProgress', progress);
            }

            dpeService.updateLocalState('progression', `finished-minigame-${this.progress}`); // potential analytics data
            this.$gameConsole.log('Game Finished', this.minigame?.mode);
                                  
            this.$router.push({
                name: 'Continue',
                query: {
                    entrance: 'continue'
                }
            });
        },
        async onQuit () {
            await this.$store.dispatch('leaveGame', this.isDecisionLead);
            this.$router.push({
                name: 'Start'
            });
        }
    }
};

</script>
<style scoped lang="scss">
.leader-bar-group-button {
    top: 0;
    z-index: 3;
    right: 0;
    left: 0;
}
</style>
