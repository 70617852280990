// Panel 0
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL0_BKGRND from '@/assets/comics/1_tutorial-intro/panel-0/background.png';

// Panel 1
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL1_BKGRND from '@/assets/comics/1_tutorial-intro/panel-1/background.png';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL1_BUBBLE from '@/assets/comics/1_tutorial-intro/panel-1/bubble.svg';

// Panel 2
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL2_BKGRND from '@/assets/comics/1_tutorial-intro/panel-2/background.png';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL2_BUBBLE from '@/assets/comics/1_tutorial-intro/panel-2/bubble.svg';

// Panel 3
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_BKGRND from '@/assets/comics/1_tutorial-intro/panel-3/background.png';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_BUBBLE from '@/assets/comics/1_tutorial-intro/panel-3/bubble.svg';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_ZAPS from '@/assets/comics/1_tutorial-intro/panel-3/zaps.png';

// Panel 4
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BKGRND from '@/assets/comics/1_tutorial-intro/panel-4/background.png';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BUBBLE1 from '@/assets/comics/1_tutorial-intro/panel-4/bubble1.svg';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BUBBLE2 from '@/assets/comics/1_tutorial-intro/panel-4/bubble2.svg';
/*import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_ZAPS1 from '@/assets/comics/1_tutorial-intro/panel-4/zaps1.png';
import ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_ZAPS2 from '@/assets/comics/1_tutorial-intro/panel-4/zaps2.png';*/


export default {
    "name": "Tutorial Intro",
    "panels": [
        {
            'name': 'tutorial-intro-panel-0',
            'width': 750, // in pixels
            'height': 563, // in pixels
            'left': 100, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Tutorial Intro - Panel 0 Art',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL0_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 563 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'tutorial-intro-panel-1',
            'width': 1024, // in pixels
            'height': 576, // in pixels
            'left': 920, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Tutorial Intro - Panel 1 Art',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL1_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 576 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
                {
                    'type': 'bubble',
                    'text': 'Friends...Today is the Day!',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL1_BUBBLE,
                    'size': {
                        'width': 312, // pixels
                        'height': 196 // pixels
                    },
                    'position': {
                        'left': 25, // percent
                        'top': 8 // percent
                    },
                    animation: "pop-in",
                    delay: "0.5s"
                },
            ]
   
        },
        {
            'name': 'tutorial-intro-panel-2',
            'width': 707, // in pixels
            'height': 768, // in pixels
            'left': 2050, // pixel offset from left (x)
            'top': 170, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Tutorial Intro - Panel 1 Art',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL2_BKGRND,
                    'size': {
                        'width': 707, // pixels
                        'height': 768 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
                {
                    'type': 'bubble',
                    'text': '?',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL2_BUBBLE,
                    'size': {
                        'width': 406, // pixels
                        'height': 247 // pixels
                    },
                    'position': {
                        'left': 20, // percent
                        'top': 6.5 // percent
                    },
                    animation: "pop-in",
                    delay: "0.5s"
                },
            ]
   
        },
        {
            'name': 'tutorial-intro-panel-3',
            'width': 707, // in pixels
            'height': 768, // in pixels
            'left': 2860, // pixel offset from left (x)
            'top': 200, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Tutorial Intro - Panel 1 Art',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_BKGRND,
                    'size': {
                        'width': 707, // pixels
                        'height': 768 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
                {
                    'type': 'bubble',
                    'text': '?',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_BUBBLE,
                    'size': {
                        'width': 436, // pixels
                        'height': 200 // pixels
                    },
                    'position': {
                        'left': 40.6, // percent
                        'top': 53.3 // percent
                    },
                    animation: "pop-in",
                    delay: "0.5s"
                },
                {
                    'type': 'zap',
                    'text': '',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL3_ZAPS,
                    'size': {
                        'width': 225, // pixels
                        'height': 157 // pixels
                    },
                    'position': {
                        'left': 50.2, // percent
                        'top': 60.3 // percent
                    },
                    animation: "zap-in",
                    delay: "1s"
                },
            ]
   
        },
        {
            'name': 'tutorial-intro-panel-4',
            'width': 1024, // in pixels
            'height': 576, // in pixels
            'left': 3660, // pixel offset from left (x)
            'top': 300, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Tutorial Intro - Panel 3 Art',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 576 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
                {
                    'type': 'bubble',
                    'text': '?',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BUBBLE1,
                    'size': {
                        'width': 796, // pixels
                        'height': 219 // pixels
                    },
                    'position': {
                        'left': 7.6, // percent
                        'top': 7.3 // percent
                    },
                    animation: "pop-in",
                    delay: "0.5s"
                },
                {
                    'type': 'bubble',
                    'text': '?',
                    'asset': ASSETS_COMICS_1_TUTORIAL_INTRO_PANEL4_BUBBLE2,
                    'size': {
                        'width': 240, // pixels
                        'height': 150 // pixels
                    },
                    'position': {
                        'left': 50.7, // percent
                        'top': 63.4 // percent
                    },
                    animation: "pop-in",
                    delay: "1s"
                },
            ]
        }
    ] // panels

};