<template>
    <div class="game" v-if="vueData.gameReady">
        <TurnChangeModal
            v-if="turnModalTurn && turnModalType != ''"
            :modalType="turnModalType"
            :modalTurn="turnModalTurn"
            @closing="isNewTurn"
        />
        <CharacterBar
            ref="characterbar"
            :layout="'practice-bar'"
            @action-btn-event="positionSubmit"
        />
        <ClueSet :clueData="vueData.clueset" />
        <TimelineBar ref="timeline" :parentComponent="this" :theGame="theGame" :setupData="gameSetupData" />
        <GameBoard  ref="gameBoard" :parentComponent="this" :art="gameBoardArt" :setupData="gameSetupData" :theGame="theGame"/>

        <button
            v-if="vueData.state === 'waiting-on-game-setup'"
            class="mtb-btn action bottom-ready"
            :class="{visible: canSubmitLock,
                    locked: vueData.players[vueData.localPlayerIndex].locked }"
            @click="positionSubmit"
        >
            <em>{{setupButtonLabel}}</em>
            
            <i v-if="vueData.players[vueData.localPlayerIndex].locked" class="note">
                (Waiting On Others)
            </i>
        </button>
        
        <h4
            class="button-note"
            :class="{'review-open': boardMode === 'review'}"
            v-if="!canSubmitLock"
        >
            Psst! Try moving the pieces on the Board.
        </h4>
        
        <button
            v-if="vueData.state === 'turn-play'"
            class="mtb-btn action debug-btn"
            :class="{'review-open': boardMode === 'review', 'disable' : vueData.turn === 1}"
            @click="toggleDebugger()"
        >
            <em v-if="boardMode === 'review'">Close Tools</em>
            <em v-else>Debug Tools</em>
        </button>
        
        <button
            v-if="theGame && vueData.levelComplete"
            class="next-level-button mtb-btn action bottom-ready visible"
            :onClick="() => {goToNextLevel()}"
        >
            Next Level
        </button>
        
        <button v-if="!rewindStarting && !turnZeroHideRewind" class="mtb-btn action bottom-nav rewind-button" :class="rewindClass" :onClick="() => {rewind()}">{{rewindButtonLabel}}</button>

        <article class="rewind-prompt-container" v-if="rewindProposed" >
            <div
                v-if="rewindAccepted"
                class="rewind-prompt-box"
            >
                <h3 class="header-blob rewind-prompt-text">Proposing to rewind to <em>Turn {{rewindTo}}</em>.</h3>
                <p class="rewind-waiting-on-text">(Waiting on people!)</p>
                
                <nav>
                    <button class="mtb-btn" @click="() => {theGame.voteNoRewind()}">I Changed My Mind!</button>
                </nav>
            </div>
            <div
                v-if="rewindProposed && !rewindAccepted"
                class="rewind-prompt-box"
            >
                <h3 class="header-blob rewind-prompt-text">Rewind to replay <em>Turn {{rewindTo}}</em>?</h3>
                <p class="rewind-waiting-on-text">(Waiting on people!)</p>
                
                <nav>
                    <button class="mtb-btn cancel" :onClick="() => {theGame.voteNoRewind()}">Nevermind.</button>
                    <button class="mtb-btn confirm" :onClick="() => {theGame.voteYesRewind()}">Yes, Please!</button>
                </nav>
            </div>
        </article>
        <button @click="readComic" class="reread-intro-button">
            <em>RE-READ INTRO</em>
        </button>
    </div>
</template>

<script>
import CharacterBar from '@/components/character-ui/CharacterBar.vue';
import Choreography from './Choreography';
import ClueSet from "@/components/gamemodes/practice/ClueSet.vue";
import GameBoard from "@/components/gamemodes/practice/GameBoard.vue";
import TimelineBar from "@/components/gamemodes/practice/TimelineBar.vue";
import TurnChangeModal from "@/components/gamemodes/practice/TurnChangeModal.vue";
import boards from '@/shared/data/boards.js';
import {mapGetters} from 'vuex';
import {useToast} from "vue-toastification";

export default {
    name: 'PracticeGame',
    components: {
        ClueSet,
        GameBoard,
        TimelineBar,
        CharacterBar,
        TurnChangeModal
    },
    props: {
        minigameData: Object
    },
    computed: {
        ...mapGetters({
            locks: 'getPuckPositionLocksByCharacters',
            history: 'getPuckPositionHistoryByCharacters',
            isEveryonePresent: 'isEveryonePresent'
        }),
        rewindClass () {
            const result = {};
            if ((this.vueData.state === "game-review" && !this.vueData.levelComplete) || this.boardMode === "review") {
                result["rewind-visible"] = true;
            }
            return result;
        },
        rewindButtonLabel () {
            if (this.vueData.state === "game-review") {
                return "Replay Turn " + (this.vueData.lastCorrectTurn + 1);
            } else {
                return this.rewindButtonText;
            }
        },
        setupButtonLabel () {
            if (this.vueData.players[this.vueData.localPlayerIndex].locked) {
                return "Position Locked";
            }
            return "I'm Ready To Play";
        },
        rewindTo () {
            return this.rewindProposed ? this.vueData.rewindTo ?? -1 : -1;
        },
        gameSetupData () {
            return {...this.minigameData.level, players: this.$store.getters.getCharacters};
        },
        gameBoardArt () {
            return boards.filter((board) => board.id === this.minigameData.board)[0]?.asset;
        },
        canSubmitLock () {
            return this.vueData?.players[this.vueData.localPlayerIndex]?.validPosition;
        },
        needToUpdateCharacterBar () {
            return this.playersChanged || this.stateChanged;
        }
    },
    data () {
        return {
            vueData: {
                gameReady: false,
                state: null,
                levelComplete: false,
                turn: null,
                turnsInGame: null,
                rewindTo: null,
                localPlayerIndex: null,
                players: [{
                    isWaiting: false,
                    character: null,
                    validPosition: false,
                    locked: false,
                    position: {
                        region: 0,
                        rotation: 0
                    },
                    lockedPosition: {
                        region: 0,
                        rotation: 0
                    },
                    setupPosition: {
                        region: 0,
                        rotation: 0
                    }
                }, {
                    isWaiting: false,
                    character: null,
                    validPosition: false,
                    locked: false,
                    position: {
                        region: 0,
                        rotation: 0
                    },
                    lockedPosition: {
                        region: 0,
                        rotation: 0
                    },
                    setupPosition: {
                        region: 0,
                        rotation: 0
                    }
                }, {
                    isWaiting: false,
                    character: null,
                    validPosition: false,
                    locked: false,
                    position: {
                        region: 0,
                        rotation: 0
                    },
                    lockedPosition: {
                        region: 0,
                        rotation: 0
                    },
                    setupPosition: {
                        region: 0,
                        rotation: 0
                    }
                }, {
                    isWaiting: false,
                    character: null,
                    validPosition: false,
                    locked: false,
                    position: {
                        region: 0,
                        rotation: 0
                    },
                    lockedPosition: {
                        region: 0,
                        rotation: 0
                    },
                    setupPosition: {
                        region: 0,
                        rotation: 0
                    }
                }],
                lastCorrectTurn: null,
                clueset: {
                    mode: null,
                    team: null,
                    individual: null
                }
            },
            theGame: null,
            boardMode: 'hidden',
            localPlayerCharacter: this.$store.getters.getPlayerInfo.character,
            
            initialPositions: this.minigameData.level.initialPositions,
            needsToBeSetUp: null,
            playthroughData: this.$store.getters.getActivePlaythrough,
            portholes: null,
            proposeRewind: -1,
            rewindStarting: false,
            rewindProposed: false,
            rewindAccepted: false,
            selectedPlayer: -1,
            rewindButtonText: "",
            turn: -1,
            my_button_text: "",
            toast: useToast(),
            turnModalType: "",
            turnModalTurn: null,
            isMounted: false,

            playersChanged: false,
            stateChanged: false,
            turnZeroHideRewind: false
        };
        
    },
    emits: ['show-comic', 'finish-game'],
    mounted () {
        // These are set outside of the session if their values are needed here...
        console.log('Teacher Configurations', this.playthroughData.config);
        console.log('Last Play State', this.playthroughData.lastPlayedState);
        
        this.theGame = new Choreography({
            vueData: this.vueData,
            setupData: {
                // Mostly directly from level data
                ...this.minigameData.level,
                players: this.$store.getters.getCharacters
            },
            me: this.$store.getters.getPlayerInfo,
            vue: this
        });

                
        // // send game data to ports and trigger render
        // this.$refs.characterbar.renderPorts(this.vueData);

        // // get characterbar porthole info so you can talk to them
        // this.portholes = this.$refs.characterbar.getPortholesData();

        // this.$refs.characterbar.showBar();
        // this.isMounted = true;
    },
    beforeUnmount () {
        if (this.theGame) {
            this.theGame.finishGame(false);                                ///THIS IS DUPLICATED ELSEWHERE, SO NOT SURE IF WE NEED BOTH
            this.theGame = null;
        }
    },
    methods: {
        positionSubmit () {
            //this.$refs.characterbar.updateBirdState(this.localPlayerCharacter, 'waiting', true);
            this.theGame.submitPlayerPosition(this.vueData.localPlayerIndex);
        },
        updateRewindText (turn) {
            if (turn === 0) {
                this.turnZeroHideRewind = true;
            } else {
                this.turnZeroHideRewind = false;
            }
            this.rewindButtonText = "Replay Turn " + turn;
            this.proposeRewind = turn;
        },

        updateRewindState ({rewindStarting = this.rewindStarting, rewindProposed = this.rewindProposed, rewindAccepted = this.rewindAccepted}) {
            this.rewindStarting = rewindStarting;
            this.rewindProposed = rewindStarting && rewindProposed;
            this.rewindAccepted = rewindProposed && rewindAccepted;
            console.log('REWIND STATE', rewindStarting, rewindProposed, rewindAccepted);
        },

        rewind () {
            if (this.vueData.state === "game-review") {
                this.theGame.rewind(this.vueData.lastCorrectTurn + 1);
            } else {
                this.theGame.rewind(this.proposeRewind);
            }
        },
            
        isNewTurn () {
            console.log('sending to store');

            this.turnModalType = '';
            this.turnModalTurn = null;
            
            //update players
            // this.vueData.players.forEach((player) => {
            //     this.updatePlayer(player, "From isNewTurn");
            // });
                    
        },
            
        clearSetupHighlights () {
            document.querySelectorAll('.regions .col').forEach((region) => {
                region.classList.remove('highlight', 'wrong', 'correct');
            });
            
            document.querySelectorAll('.image-puck').forEach((puck) => {
                puck.classList.remove('mini-puck');
            });
        },

        goToNextLevel () {
            if (this.theGame) {
                this.theGame.finishGame(true);                                ///THIS IS DUPLICATED ELSEWHERE, SO NOT SURE IF WE NEED BOTH
                this.theGame = null;
            }
            this.$parent.finishGame();
        },
        
        toggleDebugger () {
            if (this.vueData.turn === 1) {
                this.toast.info("This is available after turn 1.", {
                    timeout: 2000
                });
                return;
            }
            
            if (this.boardMode === 'review' || this.boardMode === 'final-review') {
                this.theGame.hideDebugger();
            } else {
                const
                    turn = this.vueData.turn - 1;
                
                this.updateRewindText(turn - 1);
                this.theGame.showDebugger(false, turn);
                this.$gameConsole.log(`Practice: Viewed Debugger`, this.vueData.turn);
            }
        },
        
        updateCharacterBar () {
            const characterbar = this.$refs.characterbar,
                my_bird = this.localPlayerCharacter;
            let x = 0,
                player = null;
            

            if (!this.isMounted) {
                console.log('trying.');
                return;
            }

            for (x = 0; x < this.vueData.players.length; x++) {
                player = this.vueData.players[x];

                if (player.character === my_bird && player.isWaiting) {
                    characterbar.updateBirdState(
                        my_bird, 'undoLabel show', false
                    );

                    characterbar.updateBirdState(
                        my_bird, 'actionBTN show', false
                    );
                    characterbar.updateBirdState(player.character, 'waiting', true);
                    continue;
                } else {
                    characterbar.updateBirdState(player.character, 'waiting', false);

                }

                if (this.vueData.state === 'game-review') {
                    characterbar.updateBirdState(player.character, 'ready', false);
                    if (player.character === my_bird) {
                        characterbar.updateBirdState(my_bird, 'actionBTN show', false);
                    }

                } else if (this.vueData.state === 'waiting-on-game-setup') {
                    if (player.locked) {
                        const region_element = document.getElementById(`region_${player.position.region}`);

                        if (!region_element) {
                            console.warn(`Region "${player.position.region}" isn't available to highlight.`);
                        } else {
                            region_element.classList.add('highlight', player.character);

                            if (player.position.region !== player.setupPosition.region) {
                                region_element.classList.add('wrong',);

                            } else {
                                region_element.classList.add('correct');
                                document.querySelector('.character-' + player.character).classList.add('mini-puck');
                            }
                        }

                        if (player.character === my_bird) {
                            this.my_button_text = "Ready";

                            characterbar.updateBirdState(
                                my_bird, 'undoLabel show', true
                            );

                            characterbar.updateBirdState(
                                my_bird, 'actionBTN text', this.my_button_text
                            );

                            characterbar.updateBirdState(
                                my_bird, 'actionBTN show', true
                            );
                        }

                        characterbar.updateBirdState(player.character, 'ready', true);
                    } else {
                        if (player.character === my_bird) {
                            characterbar.updateBirdState(my_bird, 'actionBTN show', false);
                            characterbar.updateBirdState(my_bird, 'undoLabel show', false);
                            
                            this.clearSetupHighlights();
                        }

                        characterbar.updateBirdState(player.character, 'ready', false);
                    }
                    
                } else if (this.vueData.state === 'turn-play') {

                    if (player.locked) {
                        if (player.character === my_bird) {
                            this.my_button_text = "Ready";

                            characterbar.updateBirdState(
                                my_bird, 'undoLabel show', true
                            );

                            characterbar.updateBirdState(
                                my_bird, 'actionBTN text', this.my_button_text
                            );

                            characterbar.updateBirdState(
                                my_bird, 'actionBTN show', true
                            );
                        }
                        
                        characterbar.updateBirdState(player.character, 'ready', true);
                    } else {
                        if (player.character === my_bird) {
                            if (this.canSubmitLock) {
                                this.my_button_text = 'Done Turn ' + this.vueData.turn;

                                characterbar.updateBirdState(
                                    my_bird, 'undoLabel show', false
                                );
                                characterbar.updateBirdState(
                                    my_bird, 'actionBTN text', this.my_button_text
                                );
                                characterbar.updateBirdState(
                                    my_bird, 'actionBTN show', true
                                );
                            } else {
                                //If we put the puck somewhere we shouldn't be able to go. Hide the button.
                                characterbar.updateBirdState(
                                    my_bird, 'undoLabel show', false
                                );
                                characterbar.updateBirdState(
                                    my_bird, 'actionBTN show', false
                                );
                            }
                        }

                        characterbar.updateBirdState(player.character, 'ready', false);
                    }

                }
            }
        },
        readComic () {
            this.$emit('show-comic');
        },
        setBoardMode (mode) {
            this.boardMode = mode;
        },
        handleHistory (history) {
            if (history.starts?.[0]?.length) {
                const
                    nextTurn = history.starts[0].length,
                    wasLastTurn = (nextTurn >= this.vueData.turnsInGame),
                    lastGameState = this.vueData.state;

                this.needsToBeSetUp = history.ends[0].length !== history.starts[0].length ? history.starts.map((start) => start[start.length - 1]) : null;

                history.ends.forEach((ends, index) => {
                    this.theGame.unlockPlayerPosition(index); // We need to make sure that the last player to check in gets unlocked.
                    this.theGame.fixPlayerHistory(index, ends);

                    //this.game.players[index].prevPositions = ends;
                    //this.game.players[index].reset();
                });

                this.theGame.setTurn(nextTurn, this.needsToBeSetUp);
            
                /**
                 * We have three possible states after the above line runs:
                 *
                 * 1. "waiting-on-game-setup" - true for first play as well as immediately after a rewind.
                 * 2. "game-review" - true if "wasLastTurn" is true.
                 * 3. "turn-play" - true for normal gameplay.
                 *
                 * The Referee could run this block with no changes, in which case lastGameState will match this.vueData.state.
                 * I also think going from Turn 1 to Turn 2, etc, will keep the game in "turn-play" state.
                 */
                if (lastGameState === 'waiting-on-game-setup' && this.vueData.state === 'turn-play') {
                    //clean up board highlights from setup
                    document.querySelectorAll('.regions .col').forEach((region) => {
                        region.classList.remove('highlight', 'wrong', 'correct');
                    });

                    this.turnModalType = "game-start";
                } else if (lastGameState === 'turn-play' && this.vueData.state === 'game-review') {
                    this.turnModalType = "final-reviews";
                } else if (this.vueData.state === 'turn-play' && this.turn !== nextTurn) {
                    this.turnModalTurn = this.turn; // non-reactive
                    this.turnModalType = "turn-done";
                }

                this.turn = nextTurn;

                console.log('last turn: ', wasLastTurn, ". This turn: ", nextTurn);
            } else { // preload initial positions
                this.$store.dispatch('setStartPositions', this.initialPositions);
            }
        },
        handleLocks (locks) {
            locks.forEach((lock, index) => {
                const player = this.vueData.players[index];

                if (lock?.region && !player.locked) {
                    this.theGame.lockPlayerPosition(index, lock);

                } else if (!lock?.region && player.locked) {
                    this.theGame.unlockPlayerPosition(index);

                }
                    
                //this.updatePlayer(player, 'locks watcher');
                    
                // if everyone is locked in
                if (this.$store.getters.getIsReferee && locks.reduce((prev, lock) => prev && !!lock?.region, true)) {
                    const
                        startPositions = this.needsToBeSetUp;

                    // if it's setup, locations must be correct. If anyone is not on their starting spot, bail.
                    if ((this.vueData.state !== 'waiting-on-game-setup') || (locks.reduce((prev, lock, index) => prev && (lock.region === startPositions[index].region && lock.rotation === startPositions[index].rotation), true))) {
                        this.$store.dispatch('savePositionLocks');
                    }
                }
            });
        },
        readyForHistoryAndLocks () {
            // send game data to ports and trigger render
            this.$refs.characterbar.renderPorts(this.vueData);

            // get characterbar porthole info so you can talk to them
            this.portholes = this.$refs.characterbar.getPortholesData();

            this.$refs.characterbar.showBar();

            this.isMounted = true;

            this.handleHistory(this.history);
            this.handleLocks(this.locks);
        }
    },
    watch: {
        history: {
            handler (history) {
                if (this.theGame) {
                    this.handleHistory(history);
                }
            }
        },
        locks: {
            handler (locks) {
                if (this.theGame) {
                    this.handleLocks(locks);
                }
            }
        },
        'game.myEmote': {
            handler (myEmote) {
                const
                    my_bird = this.localPlayerCharacter,
                    characterbar = this.$refs.characterbar;
                        
                console.log(myEmote);
                        
                if (myEmote.type) {
                    characterbar.updateBirdState(my_bird, 'emote type', myEmote.type);
                    characterbar.updateBirdState(my_bird, 'emote text', myEmote.text);
                    characterbar.updateBirdState(my_bird, 'emote timeout', myEmote.timeout);
                }
            }
                        
        },
        isEveryonePresent (value) {
            if (value) {
                this.$store.dispatch('clientsReady');
            } else {
                this.$store.dispatch('clientsMissing');
            }
        },
        'vueData.players': {
            handler () {
                this.playersChanged = true;
            },
            deep: true
        },
        'vueData.state': {
            handler () {
                this.stateChanged = true;
            }
        },
        needToUpdateCharacterBar () {
            this.playersChanged = false;
            this.stateChanged = false;
            this.updateCharacterBar();
        }
    }
};

</script>

<style scoped lang="scss">
.next-level-button {
}

.rewind-button {
    display: none;
    z-index: 11;

    &.rewind-visible {
        display: block;
    }
}

.rewind-prompt-container {
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    
    background: rgba(0,0,0, 0.85);
    backdrop-filter: blur(1em);
    opacity: 1;
    pointer-events: all;
    
    .rewind-prompt-box {
        width: 50vw;
        height: 20vw;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        
        > nav {
            display: flex;
            padding-top: 3vw;
            justify-content: center;
            
            > button  {
                margin: 0 1vw;
            }
        }
        
        .header-blob {
            display: block;
            margin: 0 auto;
            
            > * {
                display: inline-block;
            }
        }
        
        .rewind-waiting-on-text {
            font-size: 1em;
            display: block;
            padding: 1em 0 0;
            
            text-align: center;
            font-style: italic;
        }
    }
}

.button-note {
    position: absolute;
    bottom: 1vw;
    right: 1vw;
    font-size: 0.75em;
    font-style: italic;
    width: 91vw;
    text-align: center;
    
    transition: width 0.3s;
    
    &.review-open {
        width: 70vw;
    }
}

.mtb-btn.action {
    
    &.debug-btn {
        position: absolute;
        top: 1.5vw;
        border-radius: 1.5em 0 0 1.5em;
        right: 0;
        padding-right: 4vw;
        border-right-width: 0;
        z-index: 10;
        
        transition: transform 0.3s, top 0.3s, right 0.3s;

        &.disable {
            background-color: #1b204b;
            border-color: #2a2f5a;
            color: #4c5693;

            &:hover {
                background-color: #1b204b;
                border-color: #2a2f5a;
                color: #4c5693;
                animation: none;
                transform: none;
            }
        }

        &.review-open {
            top: 50%;
            border-radius: 100%;
            right: 50%;
            transform: translate(49vw, -23.265vw);
            text-indent: -999em;
            border-width: 0.25em;
            padding: 0.6em 1.2em 0.6em;
            animation: none;

            background-image: url(~@/assets/ui/mtb-ui_cancel-icon.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 70%;
        }
    }
}

.reread-intro-button {
    position: absolute;
    left: 0.33vw;
    top: 1vw;
    z-index: 1000;
    padding-top: 5.5vw;
    transform: scale(0.9);
    width: fit-content;
    background: url('~@/assets/ui/mtb-ui_comic-icon.svg') 50% top no-repeat;
    background-size: 70%;
    
    cursor: pointer;

    em {
        color: $mtb-white;
        font-size: 0.75em;
        text-align: center;
        white-space: nowrap;
    }
    
    &:hover {
        animation: jelly 0.75s ease 0s 1 normal forwards;
    }
    
    .is-leader & {
        top: 3vw;
    }
}
</style>