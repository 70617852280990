<template>
    <Transition name="pop-up">
        <div id="turn-change-modal" class="mtb-modal" :class="modalType">
            <article class="modal-container">
                <header v-if="modalType === 'turn-done'" class="message-block">
                    <h2 class="header-blob">Turn {{ modalTurn }} Complete!</h2>
                    <p>Get ready for turn {{ modalTurn + 1 }}...</p>
                </header>
                
                <header v-if="modalType === 'game-start'" class="message-block">
                    <h2 class="header-blob">Starting Practice!</h2>
                </header>
            </article>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'TurnChangeModal',
    props: {
        modalType: String,
        modalTurn: Number
    },
    data () {
        return {
        };
    },
    computed: {
    },
    mounted () {
        setTimeout(() => {
            this.$emit('closing');
        }, 5000);
        
    },
    methods: {

    }
};
</script>

<style scoped lang="scss">
.message-block p {
    display: block;
    text-align: center;
    padding: 1em;
}
</style>