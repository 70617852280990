<template>
    <aside class="settings-panel panel">
        <button v-on:click="onToggle" :class="development ? 'show' : 'hide'" class="settings-toggle">📶</button>
        <div v-if="visible" class="debug-info">
            <div id="referee-speed">Referee Speed: {{refereeSpeed.time}}ms ({{refereeSpeed.transport}})</div>
            <PuckBLE></PuckBLE>
            <Carousel
                class="log-carousel"
            >
                <Slide v-for="logType in logTypes" :key="logType">
                    <LogPanel
                        :title="`${logType} Log`"
                        :logs="$store.getters[`get${logType}Log`]"
                    ></LogPanel>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
            <DecisionTracker></DecisionTracker>
            <StateSaver></StateSaver>
        </div>
        <PuckBLEConnect :development="development"></PuckBLEConnect>
    </aside>
</template>

<script>
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import DecisionTracker from "./DecisionTracker.vue";
import LogPanel from "./LogPanel.vue";
import PuckBLE from "@/components/PuckBLE/PuckBLE.vue";
import PuckBLEConnect from "@/components/PuckBLE/PuckBLEConnect.vue";
import StateSaver from "./StateSaver.vue";
import {mapGetters} from 'vuex';

export default {
    name: "MainView",
    components: {
        Carousel,
        Slide,
        Navigation,
        DecisionTracker,
        LogPanel,
        PuckBLE,
        PuckBLEConnect,
        StateSaver
    },
    data () {
        return {
            logTypes: ['Connection', 'DPE', 'Game'],
            visible: false
        };
    },
    props: {
        development: Boolean
    },
    computed: mapGetters({
        refereeSpeed: 'getRefereeSpeed'
    }),
    methods: {
        onToggle () {
            this.visible = !this.visible;

            window.addLog = (txt) => {
                this.$store.dispatch('updateConnectionLog', txt);
            };
        }
    }
};

</script>
<style scoped lang="scss">
.settings-panel {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 0.6em;
    z-index: 99999; /* In Space! Not needed but just se we know that it's the highest */
}
    
.debug-info {
    background: rgba(54, 54, 0, 0.95);
    padding: 1em 5em 1em 1em;
    border-radius: 0 0 0 1em;
    border-style: dotted;
    border-width: 0 0 0.3em 0.3em;
    border-color: rgba(255,255,0,0.5);
    
    /* must set width */
    width: 40vw;
    
    position: relative;
    z-index: 1;
}

.settings-toggle {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.1em;
    margin: 0;
    font-size: 3em;
    background: none;
    cursor: pointer;
    z-index: 100;
    
    display: none !important; /* Still appears on prod for some reason. Hiding for now. */ 
}

#referee-speed {
    position: fixed;
    top: 1em;
    left: 1em;
    font-family: monospace;
}
</style>