<template>
    <Transition name="circle-wipe-fast">
        <div v-show="showMe" class="game-over-bg">
            <div class="modal-animation">
                <lottie-animation
                    ref="anim"
                    :animationData="greatjob_anim"
                    :loop="true"
                    :autoPlay="true"
                />
            </div> 
            <h3 class="done-message">
                You have completed <em>Chapter {{minigameData.chapter}}: {{minigameData.title}}</em> 
            </h3>
            <GroupButton
                class="end-game-button"
                :buttonClasses="'mtb-btn action bottom-ready visible'"
                confirmationModel="all"
                :onAgreement="onComplete"
                title="Play Next Level"
                :milestone="true"
                :decisionId="decisionId"
                decision="continue"
            />
        </div>
    </Transition>
</template>

<script setup>
import greatjob_anim from '@/assets/ui/animations/great-job.json';
</script>

<script>
import GroupButton from '@/components/GroupButton.vue';
//import {mapGetters} from 'vuex';

export default {
    name: 'GameOver',
    components: {
        GroupButton
    },
    props: {
        decisionId: String,
        theGame: Object,
        vueData: Object,
        minigameData: Object
    },
    computed: {
    },
    data () {
        return {
            showMe: false
        };
    },
    beforeMount () {
    },
    mounted () {
        this.showMe = true;
    },
    beforeUnmount () {
        this.showMe = false;
    },
    methods: {
        onComplete () {
            this.theGame.finishGame();
            this.$emit('finish-game');
        }
    },
    watch: {
        
    }
};
</script>

<style scoped lang="scss">
.game-over-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 1;
    backdrop-filter: blur(20px);
    transition: all 1s ease-out;
    
    .modal-animation {
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
    }
}
    
.done-message {
    position: absolute;
    top: 70%;
    left: 50%;
    font-size: 0.75em;
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
    background: #1b204b;
    padding: 0.75em 1.5em;
    border-radius: 2.5em;
    width: fit-content;
    margin: auto;
    
    em {
        display: block;
        color: #fee888;
        font-size: 1.25em;
        padding-top: 0.25em;
    }
}

.end-game-button {
    cursor: pointer;
    position: absolute;
    bottom: 8vw;
    right: 0;
    top: auto;
    
    :deep .mtb-btn.action.bottom-ready {
        top: 0;
        width: max-content;
        height: fit-content;
        
        transform: translateX(15%) scale(1.25);
        pointer-events: all;

        &:hover {
            animation: none;
            transform: translateX(0) scale(1.25);
        }
    }
}


</style>