<template>
    <div class="chunk" ref="theChunk" :class="chunkClass" >
        <div class="chunk-glue" v-for="(entry, index) in chunkData" :key="index">
            <div class="plus-connector" v-if="index !== 0">+</div>
            <LogEntry :entryData="entry"></LogEntry>
        </div>
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';

import LogEntry from './LogEntry.vue';

export default {
    name: "LogChunk",
    props: {
        chunkData: Object,
        selected: Boolean,
        feedback: String
    },
    computed: {
        chunkClass () {
            const result = {
                selected: this.selected
            };

            if (this.selected) {
                if (this.feedback === 'good') {
                    result.good = true;

                } else if (this.feedback === 'bad') {
                    result.bad = true;
                }
            }
            

            return result;
        }
    },
    data () {
        return {};
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {},
    watch: {
        selected (newSelected) {
            if (newSelected) {
                this.$parent.$parent.scrollToSelected(this.$refs.theChunk.offsetLeft);
            }
        }

    },
    components: {LogEntry}
};
</script>

<style scoped lang="scss">
    .chunk {
        display: flex;
        border-style: solid;
        border-width: 0.5vw;
        border-radius: 2vw;
        padding: 0.4vw;
        margin-top: -0.5vw;

        &.selected {
            border-color: rgb(70, 70, 70);
        }

        &.selected.good {
            border-color: green;
        }

        &.selected {
            border-color: red;
        }
    }

    .plus-connector {
        float: left;
        text-align: center;
        line-height: 1.9vw;
        margin-left: 1vw;
        margin-right: 1vw;
        margin-top: 1vw;

        color: #4C5693;
        font-size: 2.5em;
        width: 2.75vw;
        height: 3.5vw;
        line-height: 3vw;
        font-weight: bold;
    }

    .chunk-glue {
        display: flex;
    }

</style>