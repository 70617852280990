// Panel 0
import ASSETS_COMICS_3_CHAPTER_2_PANEL0_BKGRND from '@/assets/comics/3_chapter-2/panel-0/background.png';

// Panel 1
import ASSETS_COMICS_3_CHAPTER_2_PANEL1_BKGRND from '@/assets/comics/3_chapter-2/panel-1/background.png';

// Panel 2
import ASSETS_COMICS_3_CHAPTER_2_PANEL2_BKGRND from '@/assets/comics/3_chapter-2/panel-2/background.png';

// Panel 3
import ASSETS_COMICS_3_CHAPTER_2_PANEL3_BKGRND from '@/assets/comics/3_chapter-2/panel-3/background.png';

// Panel 4
import ASSETS_COMICS_3_CHAPTER_2_PANEL4_BKGRND from '@/assets/comics/3_chapter-2/panel-4/background.png';

// Panel 5
import ASSETS_COMICS_3_CHAPTER_2_PANEL5_BKGRND from '@/assets/comics/3_chapter-2/panel-5/background.png';

// Panel 6
import ASSETS_COMICS_3_CHAPTER_2_PANEL6_BKGRND from '@/assets/comics/3_chapter-2/panel-6/background.png';

// Panel 7
import ASSETS_COMICS_3_CHAPTER_2_PANEL7_BKGRND from '@/assets/comics/3_chapter-2/panel-7/background.png';

// Panel 8
import ASSETS_COMICS_3_CHAPTER_2_PANEL8_BKGRND from '@/assets/comics/3_chapter-2/panel-8/background.png';


export default {
    "name": "Chapter 2",
    "panels": [
        {
            'name': 'chapter-2-panel-0',
            'width': 750, // in pixels
            'height': 563, // in pixels
            'left': 100, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Intructions Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL0_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 563 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-1',
            'width': 1175, // in pixels
            'height': 509, // in pixels
            'left': 950, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 1 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL1_BKGRND,
                    'size': {
                        'width': 1175, // pixels
                        'height': 509 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-2',
            'width': 750, // in pixels
            'height': 477, // in pixels
            'left': 950, // pixel offset from left (x)
            'top': 695, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 2 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL2_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 477 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-3',
            'width': 750, // in pixels
            'height': 750, // in pixels
            'left': 1810, // pixel offset from left (x)
            'top': 695, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 3 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL3_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 750 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-4',
            'width': 1075, // in pixels
            'height': 1041, // in pixels
            'left': 1810, // pixel offset from left (x)
            'top': 1520, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 4 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL4_BKGRND,
                    'size': {
                        'width': 1075, // pixels
                        'height': 1041 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-5',
            'width': 1024, // in pixels
            'height': 570, // in pixels
            'left': 3000, // pixel offset from left (x)
            'top': 1650, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 5 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL5_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 570 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-6',
            'width': 960, // in pixels
            'height': 540, // in pixels
            'left': 3030, // pixel offset from left (x)
            'top': 2320, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 6 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL6_BKGRND,
                    'size': {
                        'width': 960, // pixels
                        'height': 540 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-7',
            'width': 750, // in pixels
            'height': 750, // in pixels
            'left': 4108, // pixel offset from left (x)
            'top': 2320, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 7 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL7_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 750 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-2-panel-8',
            'width': 960, // in pixels
            'height': 634, // in pixels
            'left': 4960, // pixel offset from left (x)
            'top': 2400, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 2 - Panel 8 Art',
                    'asset': ASSETS_COMICS_3_CHAPTER_2_PANEL8_BKGRND,
                    'size': {
                        'width': 960, // pixels
                        'height': 634 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
    ] // panels

};