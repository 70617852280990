import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'StageContainer',
    
   
    properties: {
        
    },
    
   
    publicProperties: {
        
    },
    
    initialize: function (/*definition, callback*/) {
        
    },

    events: {
       
    },
    
    methods: {
       
        
    },
    
    publicMethods: {
        
        
    }
});
