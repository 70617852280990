import ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_4_PNG from '@/assets/gamemodes/practice/boards/solutions/solution_lvl-4.png';

export default {
    "board": {
        "dimensions": [
            3,
            4
        ],
        "art": null
    },
    "initialPositions": [
        {
            "region": 20101,
            "rotation": 0
        },
        {
            "region": 20102,
            "rotation": 0
        },
        {
            "region": 20103,
            "rotation": 0
        },
        {
            "region": 20104,
            "rotation": 0
        }
    ],
    "solutions": [
        [
            [
                20105,
                0
            ],
            [
                20106,
                0
            ],
            [
                20107,
                0
            ],
            [
                20108,
                0
            ]
        ],
        [
            [
                20109,
                0
            ],
            [
                20110,
                0
            ],
            [
                20111,
                0
            ],
            [
                20112,
                0
            ]
        ],
        [
            [
                20105,
                0
            ],
            [
                20106,
                0
            ],
            [
                20107,
                0
            ],
            [
                20108,
                0
            ]
        ]
    ],
    "clues": {
        "team": [
            ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_4_PNG
        ],
        "individual": [
            [
                "‘Ah! that accounts for it,’ said the Hatter. ‘He won’t stand beating. Now, if you only kept on good terms with him, he’d do almost anything you liked with the clock. For instance, suppose it were nine o’clock in the morning, just time to begin lessons...",
                "There was a dead silence instantly, and Alice thought to herself, ‘I wonder what they WILL do next! If they had any sense, they’d take the roof off.’ After a minute or two, they began moving about again, and Alice heard the Rabbit say, ‘A barrowful will do, to begin with.’",
                "The executioner’s argument was, that you couldn’t cut off a head unless there was a body to cut it off from: that he had never had to do such a thing before, and he wasn’t going to begin at HIS time of life."
            ],
            [
                "Player 1 do X PLEASE",
                "Player 1 do Y",
                "Player 1 do Z"
            ],
            [
                "Player 2 do X",
                "Player 2 do Y",
                "Player 2 do Z"
            ],
            [
                "Player 3 do X",
                "Player 3 do Y",
                "Player 3 do Z"
            ]
        ]
    },
    "players": null
};