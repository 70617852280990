import ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_PURPLE_BIRD_PNG from '@/assets/character/pucks/mtb_bird-pucks_purple-bird.png';
import ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_RED_BIRD_PNG from '@/assets/character/pucks/mtb_bird-pucks_red-bird.png';
import ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_TEAL_BIRD_PNG from '@/assets/character/pucks/mtb_bird-pucks_teal-bird.png';
import ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_YELLOW_BIRD_PNG from '@/assets/character/pucks/mtb_bird-pucks_yellow-bird.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_BAKER_PNG from '@/components/gamemodes/mission/assets/puck-baker.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_FUTURE_YELLOW_BIRD_PNG from '@/components/gamemodes/mission/assets/puck-future-yellow-bird.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_RACCOON_PNG from '@/components/gamemodes/mission/assets/puck-raccoon.png';

export default [
    {
        "id": "puck-red-bird",
        "path": ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_RED_BIRD_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "103"
    },
    {
        "id": "puck-purple-bird",
        "path": ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_PURPLE_BIRD_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "97"
    },
    {
        "id": "puck-teal-bird",
        "path": ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_TEAL_BIRD_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "100"
    },
    {
        "id": "puck-yellow-bird",
        "path": ASSETS_CHARACTER_PUCKS_MTB_BIRD_PUCKS_YELLOW_BIRD_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "107"
    },
    {
        "id": "puck-baker",
        "path": COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_BAKER_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "103"
    },
    {
        "id": "puck-future-yellow-bird",
        "path": COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_FUTURE_YELLOW_BIRD_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "107"
    },
    {
        "id": "puck-raccoon",
        "path": COMPONENTS_GAMEMODES_MISSION_ASSETS_PUCK_RACCOON_PNG,
        "width": "110",
        "height": "140",
        "originX": "55",
        "originY": "107"
    }
];