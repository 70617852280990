import AVATAR_1 from '../../assets/avatars/mtb-avatars_fpo_1.svg';
import AVATAR_2 from '../../assets/avatars/mtb-avatars_fpo_2.svg';
import AVATAR_3 from '../../assets/avatars/mtb-avatars_fpo_3.svg';
import AVATAR_4 from '../../assets/avatars/mtb-avatars_fpo_4.svg';

export default {
    'avatar-1': AVATAR_1,
    'avatar-2': AVATAR_2,
    'avatar-3': AVATAR_3,
    'avatar-4': AVATAR_4
};