<template>
    <li class="porthole" :class="[{ 'this-is-me': isMine, 'turn-play': state?.gameData?.state === 'turn-play', 'ready': state.ready}, state?.birdColor, layout]">
        <!-- "state.gameData.state" is specific to Practice Game, so we probably want to handle this differently. -->

        <figure v-if="layout === 'mission-bar' && !gameOver" class="location-container">
            <span class="location-marker" :class="{'show': showLocation}">
                <img ref="location-thumb" src="@/components/gamemodes/mission/assets/map.png" />
            </span>
            <figcaption class="location-caption" :class="{'show-location': showLocation}">
                
                <em>{{locationText}}</em>
                
                <button class="mtb-btn action" v-if="showLocationBtn" @click="mapLockPressed">{{locationBTNText}}</button>
            </figcaption>
        </figure>
        <figure @click="showCharacterMenu" ref="rig" class="rig-container">
            <figcaption class="emoter" :class="bubbleClasses">
                {{ state.emote.text }}
            </figcaption>
        </figure>
        <button class="character-action mtb-btn action" :class="{ 'show': state.actionBTN.show, 'ready': state.ready }" v-if="isMine" @click="actionPressed">
            <em>{{ state.actionBTN.text }}</em>

            <i v-if="state.undoLabel.show" class="undo-label" :title="state.undoLabel.text">
                <em>{{ state.undoLabel.text }}</em>
            </i>

            <lottie-animation ref="anim" class="waiting-animation" v-if="state.waitingOnRef" :animationData="inline_loader_anim" :loop="true" :autoPlay="true" :speed="2" />

            <lottie-animation ref="anim" class="ready-animation" v-if="state.ready" :animationData="ready_anim" :loop="false" :autoPlay="true" :speed="3" />
        </button>

        <em v-if="state.ready" title="Player is Ready" class="ready-indicator is-ready">
            <lottie-animation ref="anim" class="ready-animation" v-if="state.ready" :animationData="ready_anim" :loop="false" :autoPlay="true" :speed="3" />

            Ready
        </em>
        <em class="ready-indicator" v-else title="Player is Not Ready">

            <lottie-animation ref="anim" class="waiting-animation" v-if="state.waitingOnRef" :animationData="inline_loader_anim" :loop="true" :autoPlay="true" :speed="2" />
            <span v-if="!gameOver">...</span>
        </em>

        <div v-if="layout === 'performance-bar'" class="performance-feedback">
            <ul 
                v-if="performanceFeedback && performanceFeedback.isPlaying"
                class="feedback-nodes"
            >
                <li 
                    v-if="performanceFeedback.sample"
                    class="feedback-node sample" 
                    :class=" performanceFeedback.sample"
                ></li>
                <li 
                    v-if="performanceFeedback.sample && performanceFeedback.direction"
                    class="and-block"
                >+</li>
                <li 
                    v-if="performanceFeedback.direction"
                    class="feedback-node direction" :class="performanceFeedback.direction"
                ></li>
            </ul>
        </div>
    </li>
</template>

<script setup>
    /* eslint-disable */
    import inline_loader_anim from '@/assets/ui/animations/inline-loader-anim.json';
    import ready_anim from '@/assets/ui/animations/ready-anim.json';

</script>

<script>
    /* eslint-disable */
    import * as PIXI from 'pixi.js';
    import {
        TextureAtlas,
        Spine
    } from "pixi-spine";
    import {
        AtlasAttachmentLoader,
        SkeletonJson
    } from "@pixi-spine/runtime-3.8";

    import characterRigs from '@/shared/data/character_rigs';

    export default {
        name: 'CharacterPort',
        components: {},
        props: {
            isMine: {
                type: Boolean,
                default: false
            },
            state: {
                type: Object,
                required: true
            },
            layout: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                rig: null,
                portCircle: null,
                currentEmote: this.state.emote,
                bubbleClasses: "",
                bubbleTimeout: null,
                locationText: this.isMine ? 'You are traveling...' : 'is traveling...',
                locationBTNText: "Visit!",
                showLocation: false,
                showLocationBtn: false,
                gameOver: false,
                performanceFeedback: null
            };
        },
        computed: {
            playerLocationId() {
                if (this.layout === 'mission-bar') {
                    return this.state.gameData.vueData.player.regionId;
                }
            }
        },
        created() {},
        mounted() {
            if (this.rig) {
                this.rig.destroy(true);
            }

            if (this.portCircle) {
                this.portCircle.destroy();
            }

            this.makeBird(this.state.birdColor);

            clearTimeout(this.bubbleTimeout);
            
            if (this.layout === 'performance-bar') {
                this.performanceFeedback = this.state.gameData.birdStates[this.state.birdColor];
            }
        },
        beforeUnmount() {
            if (this.rig) {
                this.portCircle.destroy();
                this.rig.destroy(true);
            }
        },
        methods: {
            debounceFunc(fn, delay) {
                let newFn;

                return function(...args) {
                   if (newFn) {
                      clearTimeout(newFn);
                   }
                   newFn = setTimeout(() => {
                       fn(...args)
                   },delay);

                }
            },

            actionPressed() {
                this.$emit('action-btn-event');
                this.waitingOnRef = true;
            },
            showCharacterMenu() {
                this.$emit('show-menu-event', this.state.birdColor);
            },
            mapLockPressed() {
                this.$emit('map-lock-pressed');
            },
            updateLocationText(locked_state, region) {
                const travel_text = this.isMine ? 'You are traveling...' : 'is traveling...',
                      locked_text = locked_state ? 'at ' : 'near ';
                
                if (this.layout === 'mission-bar') {
                    if (region) {
                        this.locationText = locked_text + region;
                    } else {
                        this.locationText = travel_text;
                    }
                }
            },
            updateLocationBtnText(locked_state) {
                if (this.layout === 'mission-bar') {
                    if (locked_state) {
                        this.locationBTNText = "Leave?";
                    } else {
                        this.locationBTNText = "Visit!";
                    }
                }
            },
            makeBird(bird) {
                switch (bird) {
                    case "purple-bird":
                        this.rigData = characterRigs.purple.data;
                        this.rigAtlas = characterRigs.purple.atlas;
                        this.rigSpritesheet = characterRigs.purple.ss;

                        break;
                    case "yellow-bird":
                        this.rigData = characterRigs.yellow.data;
                        this.rigAtlas = characterRigs.yellow.atlas;
                        this.rigSpritesheet = characterRigs.yellow.ss;

                        break;
                    case "red-bird":
                        this.rigData = characterRigs.red.data;
                        this.rigAtlas = characterRigs.red.atlas;
                        this.rigSpritesheet = characterRigs.red.ss;

                        break;
                    case "teal-bird":
                        this.rigData = characterRigs.teal.data;
                        this.rigAtlas = characterRigs.teal.atlas;
                        this.rigSpritesheet = characterRigs.teal.ss;

                        break;
                }

                let rawSkeletonData = this.rigData,
                    rawAtlasData = this.rigAtlas,
                    spritesheet = this.rigSpritesheet,
                    w = 300,
                    h = 300;
                
                if (this.layout === 'performance-bar') {
                    h = 340;
                }

                let bird_rig = this.rig = new PIXI.Application({
                    width: w,
                    height: h,
                    backgroundAlpha: 0
                });

                let rig_container = this.$refs.rig;

                rig_container.appendChild(bird_rig.view);

                var spineAtlas = new TextureAtlas(rawAtlasData, function(texture, callback) {
                    callback(
                        new PIXI.BaseTexture(spritesheet, {
                            alphaMode: PIXI.ALPHA_MODES.PMA
                        })
                    );
                });

                this.portCircle = new PIXI.Graphics();

                this.portCircle.beginFill(0X151728, 1);

                if (this.isMine && this.layout != 'performance-bar') {
                    this.portCircle.lineStyle(14, 0x2A2F5A, 1);
                } else {
                    this.portCircle.lineStyle(0);
                }

                this.portCircle.drawCircle(bird_rig.renderer.width * 0.5, bird_rig.renderer.height * 0.55, bird_rig.renderer.width * 0.375);

                this.portCircle.endFill();

                bird_rig.stage.addChild(this.portCircle);

                var spineAtlasLoader = new AtlasAttachmentLoader(spineAtlas)
                var spineJsonParser = new SkeletonJson(spineAtlasLoader);

                spineJsonParser.scale = 0.375;

                if (bird === "yellow-bird") {
                    spineJsonParser.scale = 0.34;
                }

                var spineData = spineJsonParser.readSkeletonData(rawSkeletonData);

                const animation = new Spine(spineData);

                animation.skeleton.setSkinByName("original");
                animation.skeleton.setSlotsToSetupPose();
                
                bird_rig.stage.addChild(animation);

                animation.position.set(bird_rig.renderer.width * 0.53, bird_rig.renderer.height + (animation.height * 0.075));

                if (bird === "purple-bird") {
                    //nudge accounts for long beak
                    animation.position.set(bird_rig.renderer.width * 0.52, bird_rig.renderer.height + (animation.height * 0.075));
                }

                if (bird === "yellow-bird") {
                    animation.position.set(bird_rig.renderer.width * 0.48, bird_rig.renderer.height + (animation.height * 0.125));
                }
                
                if (this.layout === 'performance-bar') {
                    animation.position.set(bird_rig.renderer.width * 0.48, bird_rig.renderer.height - (animation.height * 0.1));
                }

                animation.state.setAnimation(0, "idle", true);

                // add the animation to the scene and render...
                bird_rig.stage.addChild(animation);
            },
            fireEmote(type) {
                const available_emotes = ["pose", "idle", "disappointed", "excited", "frustrated", "thinking", "waiting", "playinstrument"];
                //console.log(available_emotes.includes(type), type);

                if (!available_emotes.includes(type)) {
                    type = 'idle';
                    //console.log('This emote is not available. Setting to idle.')
                }
                
                this.currentEmote = type;
                
                if (type === "playinstrument") {
                    switch (this.state.birdColor) {
                        case "purple-bird":
                            type = "tapdance_1_b";

                            break;
                        case "yellow-bird":
                            type  = "xylophone_1";

                            break;
                        case "red-bird":
                            type  = "whistle_1";

                            break;
                        case "teal-bird":
                            type = "tambourine";

                            break;
                    }
                }

                this.rig.stage.children[1].state.setAnimation(0, type, true);
            }
        },
        watch: {
            state: {
                handler(update) {
                    if (update.emote.type != this.currentEmote) {
                        this.fireEmote(update.emote.type);
                    }

                    if (update.emote.text && update.emote.text != "") {
                        clearTimeout(this.bubbleTimeout);

                        let timeout = update.emote.timeout;

                        switch (timeout) {
                            case "default":
                                timeout = 5000;
                                break;
                            case "sticky":
                                timeout = null;
                                break;
                            case "":
                                timeout = 5000;
                                break;
                            default:
                                timeout = Number(timeout);
                        }

                        this.bubbleClasses = "show";

                        //console.log("firing: ", update.emote.text, " timeout: ", timeout);

                        if (timeout) {
                            this.bubbleTimeout = setTimeout(() => {
                                this.bubbleClasses = "";
                                update.emote.text = "";
                                update.emote.type = "idle";
                            }, timeout);
                        }

                        //console.log(this.bubbleTimeout);
                    } else if (update.emote.text === "") {
                        clearTimeout(this.bubbleTimeout);
                        this.bubbleClasses = "";
                    }
                    
                    
                    // game specific stuff
                    if (this.layout === 'mission-bar') {
                        let localPlayer = update.gameData.localPlayer,
                            player =  update.gameData.vueData.player,
                            locations = update.gameData.vueData.locations;

                        if (localPlayer.character === this.state.birdColor && player.regionName) {

                            this.updateLocationBtnText(localPlayer.locked);
                            this.updateLocationText(localPlayer.locked, player.regionName);
                            this.showLocation = true;
                            this.showLocationBtn = true;

                        } else if (locations[this.state.birdColor].regionId) {  

                            this.updateLocationText(true, locations[this.state.birdColor].regionName);
                            this.showLocation = true;

                        } else {

                            this.updateLocationText(false, locations[this.state.birdColor].regionName);
                            this.showLocation = false;
                            this.showLocationBtn = false;
                        }
                        
                        if (update.gameData.vueData.mode === "game-over") {
                             this.gameOver = true;
                        } else {
                             this.gameOver = false;
                        }
                    }
                    
                    if (this.layout === 'performance-bar') {
                        let the_bird = update.gameData.birdStates[this.state.birdColor];
                        if (the_bird.isPlaying && the_bird.correctness) {
                            this.state.emote.type = "playinstrument";
                            //console.log(this.state.birdColor + ' is playing');
                        } else if (the_bird.isPlaying && !the_bird.correctness) {
                            this.state.emote.type = "frustrated";
                            //console.log(this.state.birdColor + ' is frustrated');
                        } else {
                            this.state.emote.type = "idle";
                            //console.log(this.state.birdColor + ' is idle');
                        }
                    }
                },
                deep: true
            },
        },
    };

</script>

<style scoped lang="scss">
.porthole {
    position: relative;
    display: block;
    width: 9.5vw;
    height: 9.5vw;
    margin: 1vw 0.25vw;

    .character-action {
        font-size: 0.6em;
        position: absolute;
        bottom: -0.1em;
        left: -20vw;
        width: max-content;
        min-width: 9vw;
        margin-left: -4.5vw;
        z-index: 1;

        &.show {
            left: 50%
        }

        .undo-label {
            position: absolute;
            text-indent: -999em;
            width: 2.25em;
            height: 2.25em;
            right: -1.5em;
            top: 0;
            border-radius: 100%;
            border: 0.25em solid #A50C43;

            background: #cc2662 url(~@/assets/ui/mtb-ui_cancel-icon.svg) 50% 50% no-repeat;
            background-size: 60%;

            //transition: transform 0.3s;

            em {
                opacity: 0;
                pointer-events: none;
            }
        }

        &:hover {
            .undo-label {
                background-color: #A50C43;
                border-color: $mtb-white;
                //transform: scale(1.2);

                animation: jelly 0.75s ease 0s 1 normal forwards;
            }
        }

        &.ready {
            background-color: #96c93d;
            border-color: #639310;
            left: 55%;

            &:hover {
                background-color: #639310;
                border-color: $mtb-white;
            }

            .waiting-animation {
                background-color: #96c93d;
            }
        }
    }

    &.turn-play.this-is-me {
        .character-action {
            bottom: -0.4em;
        }
    }

    .ready-indicator {
        position: absolute;
        left: 50%;
        bottom: -0.1em;
        width: 90%;
        transform: translateX(-50%);
        background: #2A2F5A;
        font-size: 2em;
        line-height: 0.125em;
        font-weight: bold;
        padding: 0 0 0.65em;
        color: #4C5693;
        border-radius: 1.5em;
        text-align: center;
        pointer-events: none;
        overflow: hidden;

        &.is-ready {
            background-color: #96C93D;
            color: $mtb-white;
            font-size: 0.6875em;
            line-height: 1em;
            padding: 0.6em 1.2em 0.6em 1.5em;
            overflow: visible;
            left: 55%;
            width: 85%;
        }
    }

    .ready-animation {
        position: absolute;
        width: 4vw;
        height: 6vw;
        pointer-events: none;
        top: 50%;
        left: 0;
        z-index: 1;
        transform: translate(-50%, -50%);

        >svg {
            display: block;
        }
    }

    .waiting-animation {
        position: absolute;
        width: 6vw;
        height: 6vw;
        pointer-events: none;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: #1b204b;
        border-radius: 1.5em;

        :deep svg {
            display: block;
            width: 6vw !important;
            height: 3.375vw !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center center;
            transform: translate(-50%, -50%) !important;

        }
    }

    &.this-is-me {
        position: absolute;
        bottom: 1vw;
        left: 0.5vw;
        transform: scale(1.5);
        transform-origin: left bottom;
        margin-bottom: 0;

        &.ready .ready-indicator {
            opacity: 0;
        }

        .location-caption.show-location {
            padding-right: 4em !important;
        }
    }

    .rig-container {
        cursor: pointer;

        .emoter {
            display: block;
            position: absolute;
            max-width: 11vw;
            left: 0;
            top: 50%;
            background: $mtb-white;

            color: $mtb-ui-dark-purp;
            font-size: 0.625em;
            font-weight: bold;
            text-align: center;
            padding: 0.5em 0.75em;

            opacity: 0;
            pointer-events: none;


            transition: transform 0.5s, opacity 0.5s;
            transform: translate(7vw, -80%) rotate(-3deg) scale(0);
            transform-origin: center left;

            &.show {
                opacity: 1;
                animation: bubble-in 0.5s both;
            }

            @keyframes bubble-in {

                0%,
                100% {
                    transform: translate(7vw, -80%) rotate(-3deg) scale(1);
                }

                25% {
                    transform: translate(7vw, -90%) rotate(-9deg) scale(1.2);
                }

                50% {
                    transform: translate(7vw, -75%) rotate(9deg) scale(1.3);
                }

                75% {
                    transform: translate(7vw, -85%) rotate(-6deg) scale(1.2);
                }
            }

            &:after {
                position: absolute;
                right: 100%;
                top: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;

                border-top: 0.75em solid transparent;
                border-bottom: 0.75em solid transparent;
                border-right: 2em solid $mtb-white;
                transform: translate(30%, -15%) rotate(-30deg);
                z-index: -1;

                pointer-events: none;
            }
        }

        :deep canvas {
            width: 100%;
        }
    }

    &.performance-bar {

        width: 21.5vw;
        height: 10.5vw;
        margin: 0;

        &.this-is-me {
            position: relative;
            transform: none;
            bottom: 0;
            left: 0;
        }

        .performance-feedback {
            position: absolute;
            left: 6vw;
            right: 0;
            padding: 0.2em 0.375em 0.2em 0.825em;
            background: #2A2F5A;
            line-height: 1;
            font-weight: bold;
            color: #4C5693;
            border-radius: 1.5vw;
            text-align: center;
            pointer-events: none;
            overflow: hidden;
            bottom: auto;
            top: 62%;
            font-size: 3em;
            height: 60%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: -1;
            
            .feedback-nodes {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                
                .feedback-node {
                    flex: 0 0 auto;
                    width: 3.5vw;
                    height: 3.5vw;
                    
                    &.direction {
                        width: 3.75vw;
                        height: 3.75vw;
                    }
                }
                
                .and-block {
                    flex: 0 0 auto;
                    color: #4C5693;
                    font-size: 0.75em;
                    width: 2.75vw;
                    height: 3.5vw;
                    line-height: 3vw;
                    margin-right: -0.25em;
                }
            }
        }

        .ready-indicator,
        .character-action {
            opacity: 0;
            pointer-events: none;
        }

        .rig-container {
            :deep canvas {
                width: 10.5vw;
            }
        }
    }


    .location-container {
        position: absolute;
        bottom: 0;
        left: -0.25vw;
        height: 4.5vw;
        width: 3vw;
        z-index: 1;

        .location-marker {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            transform: scale(0);
            transform-origin: bottom center;

            transition: transform 0.3s;

            &.show {
                transform: scale(1);

                .location-caption {
                    padding: 0 1.5em 0 3em;
                }
            }

            >img {
                width: 3vw;
                height: 3vw;
                position: absolute;
                border-radius: 100%;
                background-color: #f4f4f4;
                overflow: hidden;
                border: 0.15em solid #f4f4f4;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                height: 0;
                width: 0;
                bottom: -0.7em;
                left: 50%;
                transform: translateX(-50%);
                border: solid transparent;
                border-top-color: #f4f4f4;
                border-width: 1em 0.7em;

                pointer-events: none;
            }
        }

        .location-caption {
            font-size: 0.6em;
            position: absolute;
            width: max-content;
            min-width: 9.5vw;
            height: 2.8em;
            bottom: -0.5em;
            left: 0.25vw;
            padding: 0 1.5em 0 1.5em;
            background-color: #1b204b;
            color: #fee888;
            border-radius: 1.5vw;
            z-index: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            transition: padding 0.3s;

            &.show-location {
                padding: 0 1.5em 0 3em;
            }
        }

        .mtb-btn.action {
            font-size: 0.8em;
            position: absolute;
            right: 0;
            transform: translateX(36%) !important;
        }
    }
}
</style>
