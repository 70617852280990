import ASSETS_THUMBS_MTB_MINIGAME_THUMB_0_PNG from '@/assets/thumbs/mtb_minigame-thumb_0.png';
import ASSETS_THUMBS_MTB_MINIGAME_THUMB_1_PNG from '@/assets/thumbs/mtb_minigame-thumb_1.png';
import ASSETS_THUMBS_MTB_MINIGAME_THUMB_2_PNG from '@/assets/thumbs/mtb_minigame-thumb_2.png';
import ASSETS_THUMBS_MTB_MINIGAME_THUMB_3_PNG from '@/assets/thumbs/mtb_minigame-thumb_3.png';
import ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG from '@/assets/thumbs/mtb_minigame-thumb_FPO.png';
import SHARED_DATA_COMICS_TUTORIAL_INTRO from '@/shared/data/comics/tutorial-intro';
import SHARED_DATA_COMICS_CHAPTER_1 from '@/shared/data/comics/chapter-1';
import SHARED_DATA_COMICS_CHAPTER_2 from '@/shared/data/comics/chapter-2';
import SHARED_DATA_COMICS_CHAPTER_3 from '@/shared/data/comics/chapter-3';
import SHARED_DATA_COMICS_PRACTICE_BOWIE from '@/shared/data/comics/practice-bowie';
import SHARED_DATA_COMICS_PRACTICE_KIT from '@/shared/data/comics/practice-kit';
import SHARED_DATA_COMICS_TRANSITION_PERFORMANCE from '@/shared/data/comics/transition-performance';
import SHARED_DATA_MISSION0 from '@/shared/data/mission0';
import SHARED_DATA_MISSION1 from '@/shared/data/mission1';
import SHARED_DATA_MISSION2 from '@/shared/data/mission2';
import SHARED_DATA_MISSION3 from '@/shared/data/mission3';
import SHARED_DATA_PERFORMANCE1 from '@/shared/data/performance1';
import SHARED_DATA_PERFORMANCE2 from '@/shared/data/performance2';
import SHARED_DATA_PERFORMANCE3 from '@/shared/data/performance3';
import SHARED_DATA_PERFORMANCE4 from '@/shared/data/performance4';
import SHARED_DATA_PRACTICE1 from '@/shared/data/practice1';
import SHARED_DATA_PRACTICE2 from '@/shared/data/practice2';
import SHARED_DATA_PRACTICE3 from '@/shared/data/practice3';
import SHARED_DATA_PRACTICE4 from '@/shared/data/practice4';

export default [
    {
        "order": "0",
        "chapter": "0",
        "mode": "mission",
        "title": "First Band Meeting",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_0_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION0
    },
    {
        "order": "1",
        "chapter": "1",
        "mode": "mission",
        "title": "Find your Sound",
        "cutscene": SHARED_DATA_COMICS_CHAPTER_1,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_1_PNG,
        "reward": "",
        "board": "bowies-house-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "2",
        "chapter": "1",
        "mode": "practice",
        "title": "Find your Sound",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_BOWIE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_1_PNG,
        "reward": "",
        "board": "bowies-house",
        "level": SHARED_DATA_PRACTICE1
    },
    {
        "order": "3",
        "chapter": "2",
        "mode": "mission",
        "title": "Time to Practice!",
        "cutscene": SHARED_DATA_COMICS_CHAPTER_2,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_2_PNG,
        "reward": "",
        "board": "bowies-house-map",
        "level": SHARED_DATA_MISSION2
    },
    {
        "order": "4",
        "chapter": "2",
        "mode": "practice",
        "title": "Time to Practice!",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_BOWIE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_2_PNG,
        "reward": "",
        "board": "bowies-house",
        "level": SHARED_DATA_PRACTICE2
    },
    {
        "order": "5",
        "chapter": "2",
        "mode": "performance",
        "title": "Time to Practice!",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_2_PNG,
        "reward": "first-fan",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE1
    },
    {
        "order": "6",
        "chapter": "3",
        "mode": "mission",
        "title": "Find a New Practice Space",
        "cutscene": SHARED_DATA_COMICS_CHAPTER_3,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_3_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION3
    },
    {
        "order": "7",
        "chapter": "3",
        "mode": "practice",
        "title": "Find a New Practice Space",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_3_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE3
    },
    {
        "order": "8",
        "chapter": "3",
        "mode": "performance",
        "title": "Find a New Practice Space",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_3_PNG,
        "reward": "",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE2
    },
    {
        "order": "9",
        "chapter": "4",
        "mode": "mission",
        "title": "Let’s Get a Show!",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "10",
        "chapter": "4",
        "mode": "practice",
        "title": "Let’s Get a Show!",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE4
    },
    {
        "order": "11",
        "chapter": "4",
        "mode": "performance",
        "title": "Let’s Get a Show!",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "first-show",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE3
    },
    {
        "order": "12",
        "chapter": "5",
        "mode": "mission",
        "title": "The Mimics",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "13",
        "chapter": "5",
        "mode": "practice",
        "title": "The Mimics",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE1
    },
    {
        "order": "14",
        "chapter": "5",
        "mode": "performance",
        "title": "The Mimics",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "disguise",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE4
    },
    {
        "order": "15",
        "chapter": "6",
        "mode": "mission",
        "title": "The Show Must Go On",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "16",
        "chapter": "6",
        "mode": "practice",
        "title": "The Show Must Go On",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE1
    },
    {
        "order": "17",
        "chapter": "6",
        "mode": "performance",
        "title": "The Show Must Go On",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE1
    },
    {
        "order": "18",
        "chapter": "7",
        "mode": "mission",
        "title": "A Band New Day",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "19",
        "chapter": "7",
        "mode": "practice",
        "title": "A Band New Day",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE1
    },
    {
        "order": "20",
        "chapter": "7",
        "mode": "performance",
        "title": "A Band New Day",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE1
    },
    {
        "order": "21",
        "chapter": "8",
        "mode": "mission",
        "title": "Battle of the Bands!",
        "cutscene": SHARED_DATA_COMICS_TUTORIAL_INTRO,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "town-map",
        "level": SHARED_DATA_MISSION1
    },
    {
        "order": "22",
        "chapter": "8",
        "mode": "practice",
        "title": "Battle of the Bands!",
        "cutscene": SHARED_DATA_COMICS_PRACTICE_KIT,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "kits-garage",
        "level": SHARED_DATA_PRACTICE1
    },
    {
        "order": "23",
        "chapter": "8",
        "mode": "performance",
        "title": "Battle of the Bands!",
        "cutscene": SHARED_DATA_COMICS_TRANSITION_PERFORMANCE,
        "thumbnail": ASSETS_THUMBS_MTB_MINIGAME_THUMB_FPO_PNG,
        "reward": "",
        "board": "stage-basic",
        "level": SHARED_DATA_PERFORMANCE1
    }
];