import {Vector, createComponentClass} from 'platypus';
import {Controller} from '@makefully/engagefully';
import VuexStorePuck from '@/shared/VuexStorePuck';


const EPSILON = 0.0001,
    INPUT_DEF = {
        "red-0": {"character": "red-bird", "direction": "up",      "sample": "red-a",       "audio": "clave-1"},
        "red-1": {"character": "red-bird", "direction": "right",   "sample": "red-a",       "audio": "clave-1"},
        "red-2": {"character": "red-bird", "direction": "up",      "sample": "red-b",       "audio": "clave-1-b"},
        "red-3": {"character": "red-bird", "direction": "down",   "sample": "red-b",       "audio": "clave-1-b"},

        "purple-0": {"character": "purple-bird", "direction": "up",      "sample": "purple-a",       "audio": "clave-2"},
        "purple-1": {"character": "purple-bird", "direction": "down",    "sample": "purple-a",       "audio": "clave-2"},
        "purple-2": {"character": "purple-bird", "direction": "right",      "sample": "purple-b",       "audio": "clave-2-b"},
        "purple-3": {"character": "purple-bird", "direction": "down",    "sample": "purple-b",       "audio": "clave-2-b"},

        "teal-0": {"character": "teal-bird", "direction": "right",      "sample": "teal-a",       "audio": "barril-1"},
        "teal-1": {"character": "teal-bird", "direction": "down",    "sample": "teal-a",       "audio": "barril-1"},
        "teal-2": {"character": "teal-bird", "direction": "up",      "sample": "teal-b",       "audio": "barril-1-b"},
        "teal-3": {"character": "teal-bird", "direction": "down",    "sample": "teal-b",       "audio": "barril-1-b"},

        "yellow-0": {"character": "yellow-bird", "direction": "up",   "sample": "yellow-a",       "audio": "barril-2"},
        "yellow-1": {"character": "yellow-bird", "direction": "right",    "sample": "yellow-a",       "audio": "barril-2"},
        "yellow-2": {"character": "yellow-bird", "direction": "right",   "sample": "yellow-b",       "audio": "barril-2-b"},
        "yellow-3": {"character": "yellow-bird", "direction": "down",    "sample": "yellow-b",       "audio": "barril-2-b"},

        "red-up": {"character": "red-bird", "direction": "up"},
        "red-right": {"character": "red-bird", "direction": "right"},
        "red-down": {"character": "red-bird", "direction": "down"},
        
        "purple-up": {"character": "purple-bird", "direction": "up"},
        "purple-right": {"character": "purple-bird", "direction": "right"},
        "purple-down": {"character": "purple-bird", "direction": "down"},
        
        "teal-up": {"character": "teal-bird", "direction": "up"},
        "teal-right": {"character": "teal-bird", "direction": "right"},
        "teal-down": {"character": "teal-bird", "direction": "down"},
        
        "yellow-up": {"character": "yellow-bird", "direction": "up"},
        "yellow-right": {"character": "yellow-bird", "direction": "right"},
        "yellow-down": {"character": "yellow-bird", "direction": "down"}
    },
    REGION_TO_INPUT = {
        "1001": "red-0",
        "1002": "red-1",
        "1003": "red-2",
        "1004": "red-3",

        "1101": "purple-0",
        "1102": "purple-1",
        "1103": "purple-2",
        "1104": "purple-3",

        "1201": "teal-0",
        "1202": "teal-1",
        "1203": "teal-2",
        "1204": "teal-3",

        "1301": "yellow-0",
        "1302": "yellow-1",
        "1303": "yellow-2",
        "1304": "yellow-3",
        
        "1051": "red-up",
        "1052": "red-right",
        "1053": "red-down",

        "1151": "purple-up",
        "1152": "purple-right",
        "1153": "purple-down",

        "1251": "teal-up",
        "1252": "teal-right",
        "1253": "teal-down",

        "1351": "yellow-up",
        "1352": "yellow-right",
        "1353": "yellow-down"
    },
    DIRECTION_TO_VECTOR = {
        "up": [0, -1],
        "right": [1, 0],
        "down": [0, 1]
    };

export default createComponentClass({
    
    id: 'Band',
    
    properties: {
        "inputs": null
    },
    
    publicProperties: {
        courseData: null,
        courseProgress: 0
    },
    
    initialize: function (/*definition, callback*/) {
        const players = this.owner.parent.parent.settings.players;
        let prop = null,
            x = 0;

        this.portionCorrect = 0;

        this.controller = new Controller([
            new VuexStorePuck('Puck', {getter: 'getAuthoritativePositions'})
        ]);

        for (x = 0; x < players.length; x++) {
            this.setupPlayerControls(players[x].character, players[x].puck);
        }

        this.startX = 0;
        this.startY = 0;

        this.inputLocked = false;
        this.inputChanged = false;
        this.lastPuckInput = {
            "red-bird": {"region": 0, "rotation": 0, "direction": null, "sample": null},
            "purple-bird": {"region": 0, "rotation": 0, "direction": null, "sample": null},
            "teal-bird": {"region": 0, "rotation": 0, "direction": null, "sample": null},
            "yellow-bird": {"region": 0, "rotation": 0, "direction": null, "sample": null}
        };
        this.preservedPuckInput = {
            "red-bird": {"region": 0, "rotation": 0},
            "purple-bird": {"region": 0, "rotation": 0},
            "teal-bird": {"region": 0, "rotation": 0},
            "yellow-bird": {"region": 0, "rotation": 0}
        };
        this.inputRecord = {
            "red-bird": [],
            "purple-bird": [],
            "teal-bird": [],
            "yellow-bird": []
        };

        this.rawInput = [0, 0];
        this.inputSamples = [];

        // eslint-disable-next-line guard-for-in
        for (prop in this.inputs) {
            const value  = this.inputs[prop];
            this.addEventListener(prop + "-triggered", () => {
                this.handleKeyboardInputs(value, true);
            });

            this.addEventListener(prop + "-released", () => {
                this.handleKeyboardInputs(value, false);
            });
        }

        this.courseLength = 0;
        this.courseLengthSansCountIn = 0;
        this.edgeLength = 0;

        this.edgeProgress = 0;

        this.currentEdge = null;
        this.currentEdgeIndex = -1;

        this.lengthUnit = this.owner.parent.parent.settings.level.musicalUnitLength;
        this.speed = this.owner.parent.parent.settings.level.musicalUnitLength / this.owner.parent.parent.settings.level.musicalUnitTime; // PIXELS / MILLISECOND

        this.playbackInput = null;
        this.playbackIndexes = {
            "red-bird": 0,
            "purple-bird": 0,
            "teal-bird": 0,
            "yellow-bird": 0
        };

        this.prevWrongHow = null;

        this.arrow = null;

        this.countIn = false;
        this.countInComplete = false;

        // eslint-disable-next-line no-undef
        this.gameConsole = platypus.game.gameConsole;
    },

    events: {
        "peer-entity-added": function (entity) {
            if (entity.type === "band-arrow") {
                this.arrow = entity;
            }
        },
        "load": function () {
            this.owner.triggerEvent('focus-camera', this.owner, true);
        },
        "course-info": function (courseData) {
            let x = 0;
            this.courseData = courseData;

            this.owner.x = this.courseData.origin.x;
            this.owner.y = this.courseData.origin.y;

            this.startX = this.owner.x;
            this.startY = this.owner.y;

            for (x = 0; x < this.courseData.vectorLengths.length; x++) {
                this.courseLength += this.courseData.vectorLengths[x] * this.lengthUnit;
            }

            this.courseLengthSansCountIn = this.courseLength - this.courseData.vectorLengths[0] * this.lengthUnit;

            this.resetCourseProgress();
        },
        "handle-logic": function (tick) {
            const delta = tick.delta,
                directionVector = new Vector(),
                wrongHow = {
                    correctSamples: null,
                    direction: true,
                    samples: null
                },
                whoIsPlaying = {
                    'red-bird': false,
                    'purple-bird': false,
                    'yellow-bird': false,
                    'teal-bird': false
                };
            let character = null,
                movement = 0,
                isInput = false,
                correctDirection = false,
                correctSamples = false,
                characterInput = null,
                hideArrow = false,
                finishedFirstEdge = false;

            if (this.playbackInput) {
                const progressValues = [];
                
                // eslint-disable-next-line guard-for-in
                for (character in this.playbackInput) {
                    characterInput = this.playbackInput[character];

                    while (this.playbackIndexes[character] < characterInput.length && characterInput[this.playbackIndexes[character]].progress <= this.courseProgress) {
                        this.applyInput(character, characterInput[this.playbackIndexes[character]].region, characterInput[this.playbackIndexes[character]].rotation, true);

                        if (progressValues.indexOf(characterInput[this.playbackIndexes[character]].progress) === -1) {
                            progressValues.push(characterInput[this.playbackIndexes[character]].progress);
                            this.owner.parent.triggerEvent('advance-replay-selector');
                        }

                        this.playbackIndexes[character] += 1;
                    }
                }
            }
            
            for (character in this.lastPuckInput) {
                if (this.lastPuckInput[character].region) {
                    isInput = true;
                    whoIsPlaying[character] = true;
                }
            }

            if ((isInput || this.countIn) && this.owner.audioReady) {
                wrongHow.correctSamples = [...this.courseData.samples[this.currentEdgeIndex]];

                if (this.countIn) {
                    directionVector.x = 1;
                    directionVector.y = 0;
                } else {
                    directionVector.x = this.rawInput[0];
                    directionVector.y = this.rawInput[1];
                }
                directionVector.normalize();

                if (directionVector.x === 0 && directionVector.y === 0) {
                    hideArrow = true;
                }
                this.arrow.rotation = directionVector.getAngle() * (180 / Math.PI);

                if (Math.abs(directionVector.x - this.currentEdge.x) <= EPSILON && Math.abs(directionVector.y - this.currentEdge.y) <= EPSILON) {
                    wrongHow.direction = false;
                    correctDirection = true;
                }

                wrongHow.samples = this.diffInContents(this.courseData.samples[this.currentEdgeIndex], this.inputSamples);
                if (!wrongHow.samples.extra.length && !wrongHow.samples.missing.length || this.countIn) {
                    //Playing the right samples! GOOD!
                    correctSamples = true;
                }

                
                movement = this.speed * delta;

                if (correctDirection && correctSamples) {
                    this.prevWrongHow = null;
                    if (!this.owner.state.get('good')) {
                        this.owner.state.set('good', true);
                        this.owner.state.set('bad', false);
                        this.arrow.state.set('good', true);
                        this.arrow.state.set('bad', false);
                        this.owner.triggerEvent('play-correctly');
                    }

                    if (!this.countIn) {
                        this.owner.parent.triggerEvent('update-band-correctness', true, whoIsPlaying);

                        this.portionCorrect += movement;
                        this.owner.parent.triggerEvent('update-success', this.portionCorrect / this.courseLengthSansCountIn);
                    }
                    
                } else {
                    if (this.differentWrongHow(wrongHow, this.prevWrongHow)) {
                        //If they're different, we do something.
                        this.prevWrongHow = wrongHow;
                        this.owner.state.set('bad', true);
                        this.owner.state.set('good', false);
                        this.arrow.state.set('bad', true);
                        this.arrow.state.set('good', false);
                        this.owner.triggerEvent('play-poorly', wrongHow);
                        
                    }

                    this.owner.parent.triggerEvent('update-band-correctness', false, whoIsPlaying);
                }

                if (hideArrow) {
                    //Player input direction is 0, so we shouldn't show the arrow
                    this.arrow.state.set('bad', false);
                    this.arrow.state.set('good', false);
                }

                this.edgeProgress += movement;
                if (this.edgeProgress >= this.edgeLength) {
                    this.currentEdgeIndex += 1;
                    if (this.currentEdgeIndex >= this.courseData.vectors.length) {
                        this.currentEdgeIndex = this.courseData.vectors.length - 1;
                        this.edgeProgress = this.edgeLength;
                    } else {
                        this.edgeProgress -= this.edgeLength;

                        this.currentEdge = this.courseData.vectors[this.currentEdgeIndex];
                        this.edgeLength = this.courseData.vectorLengths[this.currentEdgeIndex] * this.lengthUnit;

                        this.owner.triggerEvent('changed-edge', this.currentEdgeIndex - 1, this.currentEdgeIndex);
                        this.gameConsole.log('Performance: Track Progress', this.currentEdgeIndex);
                    }

                    if (this.countIn) {
                        finishedFirstEdge = true;
                    }
                }

                this.owner.x = this.courseData.origin.x + this.courseData.points[this.currentEdgeIndex].x + this.currentEdge.x * this.edgeProgress;
                this.owner.y = this.courseData.origin.y + this.courseData.points[this.currentEdgeIndex].y + this.currentEdge.y * this.edgeProgress;


                this.courseProgress += movement;
                if (this.courseProgress >= this.courseLength) {
                    this.courseProgress = this.courseLength;
                    //We finished! What do we do here????
                    this.owner.state.set('bad', false);
                    this.owner.state.set('good', false);
                    this.arrow.state.set('bad', false);
                    this.arrow.state.set('good', false);

                    if (!this.playbackInput) {
                        //We do this to end any playing sounds.
                        this.handlePuckMovement('red-bird', 0, 0);
                        this.handlePuckMovement('purple-bird', 0, 0);
                        this.handlePuckMovement('teal-bird', 0, 0);
                        this.handlePuckMovement('yellow-bird', 0, 0);
                    }

                    this.lockInput();
                    this.owner.triggerEvent('stop-soundtrack');
                    this.owner.parent.triggerEvent('course-complete');
                    if (this.playbackInput) {
                        this.owner.parent.triggerEvent('playback-complete');
                    }
                }
                
            } else if (this.inputChanged) {
                this.owner.triggerEvent('stop-soundtrack');
                this.prevWrongHow = null;
                this.owner.state.set('good', false);
                this.owner.state.set('bad', false);
                this.arrow.state.set('good', false);
                this.arrow.state.set('bad', false);
            }

            this.inputChanged = false;

            if (this.countIn && finishedFirstEdge) {
                //Count in complete, now the players take over.
                this.owner.parent.triggerEvent('end-count-in');
                this.countIn = false;
                this.countInComplete = true;
                this.inputChanged = true;
                if (!this.playbackInput) {
                    this.unlockInput();
                }
            }
        },
        "unpause": function () {
            if (this.countInComplete) {
                this.unlockInput();
            } else {
                this.owner.parent.triggerEvent('start-count-in');
                this.countIn = true;
            }
        },
        "pause": function () {
            if (this.countInComplete) {
                this.lockInput(true);
            }
        },
        "reset-band": function () {
            this.resetCourseProgress();
        },
        "play-from-log": function (playbackInput) {
            this.lockInput();
            this.resetCourseProgress();
            this.owner.parent.triggerEvent('reset-replay-selector');

            this.owner.parent.triggerEvent('start-count-in');
            this.countIn = true;
            this.playbackInput = playbackInput;
        }
    },
    
    methods: {
        differentWrongHow (newWrong, oldWrong) {
            let x = 0;

            if (!oldWrong) {
                return true;
            }

            if (newWrong.direction !== oldWrong.direction) {
                return true;
            }

            if (newWrong.samples.extra.length !== oldWrong.samples.extra.length ||
                newWrong.samples.missing.length !== oldWrong.samples.missing.length) {
                return true;
            }

            for (x = 0; x < newWrong.samples.extra.length; x++) {
                if (oldWrong.samples.extra.indexOf(newWrong.samples.extra[x]) === -1) {
                    return true;
                }
            }

            for (x = 0; x < newWrong.samples.missing.length; x++) {
                if (oldWrong.samples.missing.indexOf(newWrong.samples.missing[x]) === -1) {
                    return true;
                }
            }

            return false;
        },
        
        processInputRecord () {
            let x = 0,
                y = 0,
                earliestValue = Infinity,
                earliest = [],
                bird = null,
                processedInput = null,
                aRecord = null,
                newEntry = null,
                inputDef = null,
                direction = null,
                sample = null,
                character = null,
                characterInput = null;
                
            const indexes = {
                    "red-bird": 0,
                    "purple-bird": 0,
                    "teal-bird": 0,
                    "yellow-bird": 0
                },
                record = this.inputRecord,
                log = {
                    input: null,
                    processedInput: []
                };

            log.input = record;
            processedInput = log.processedInput;

            //Remove meaningless inputs from input record (when the player releases and then presses the same input again before anything else happens).
            // eslint-disable-next-line guard-for-in
            for (character in record) {
                characterInput = record[character];

                for (x = characterInput.length - 2; x > 0; x--) {
                    if (characterInput[x].region === 0 &&
                        (characterInput[x - 1].region === characterInput[x + 1].region && characterInput[x - 1].rotation === characterInput[x + 1].rotation) &&
                        characterInput[x].progress === characterInput[x + 1].progress) {
                        //If current region is 0, the region/rotation before and after this were the same, and the progress didn't change between the 0 and next region,
                        //we collapse the array by deleting the 0 and the next input because on the playback it will sound like there was never a pause.
                        characterInput.splice(x, 2);
                    }
                }
            }

            while (indexes['red-bird'] < record['red-bird'].length ||
                    indexes['purple-bird'] < record['purple-bird'].length ||
                    indexes['teal-bird'] < record['teal-bird'].length ||
                    indexes['yellow-bird'] < record['yellow-bird'].length) {
                
                earliestValue = Infinity;
                
                for (bird in record) {
                    if (indexes[bird] >= record[bird].length) {
                        continue;
                    }

                    if (record[bird][indexes[bird]].progress <= earliestValue) {

                        if (record[bird][indexes[bird]].progress === earliestValue) {
                            earliest.push(bird);
                        } else {
                            earliest = [bird];
                        }
                        earliestValue = record[bird][indexes[bird]].progress;
                    }
                }

                if (processedInput.length) {
                    newEntry = [...processedInput[processedInput.length - 1]];
                } else {
                    newEntry = [];
                }

                for (x = 0; x < earliest.length; x++) {
                    aRecord = record[earliest[x]][indexes[earliest[x]]];
                    inputDef = REGION_TO_INPUT[aRecord.region] || null;

                    if (inputDef) {
                        direction = INPUT_DEF[inputDef].direction;
                        sample = INPUT_DEF[inputDef].sample;
                    }

                    for (y = 0; y < newEntry.length; y++) {
                        if (newEntry[y].character === earliest[x]) {
                            newEntry.splice(y, 1);
                            break;
                        }
                    }

                    if (inputDef) {
                        newEntry.push({
                            character: earliest[x],
                            sample: sample,
                            direction: direction
                        });
                    }

                    indexes[earliest[x]] += 1;
                }
                
                if (newEntry.length) {
                    processedInput.push(newEntry);
                }
                
            }

            this.inputRecord = {
                "red-bird": [],
                "purple-bird": [],
                "teal-bird": [],
                "yellow-bird": []
            };

            return log;
        },

        unlockInput: function () {
            let character = null;
            this.inputLocked = false;

            // eslint-disable-next-line guard-for-in
            for (character in this.preservedPuckInput) {
                // this.inputRecord[character].push({
                //     region: this.preservedPuckInput[character].region,
                //     rotation: this.preservedPuckInput[character].rotation,
                //     progress: this.courseProgress
                // });

                this.applyInput(character, this.preservedPuckInput[character].region, this.preservedPuckInput[character].rotation);
                this.preservedPuckInput[character].region = 0;
                this.preservedPuckInput[character].rotation = 0;
            }
        },
        lockInput: function (preserveInput) {
            let character = null;

            if (this.inputLocked) {
                return;
            }

            if (preserveInput) {
                // eslint-disable-next-line guard-for-in
                for (character in this.preservedPuckInput) {
                    this.preservedPuckInput[character].region = this.lastPuckInput[character].region;
                    this.preservedPuckInput[character].rotation = this.lastPuckInput[character].rotation;

                    this.applyInput(character, 0, 0);
                }
            }

            this.owner.triggerEvent('stop-soundtrack');
            this.prevWrongHow = null;
            this.owner.state.set('good', false);
            this.owner.state.set('bad', false);
            this.arrow.state.set('good', false);
            this.arrow.state.set('bad', false);

            this.inputLocked = true;
        },

        handlePuckButton: function () {
            //Button was pressed.
        },

        handlePuckMovement: function (character, region, rotation) {
            const lastRegion = this.lastPuckInput[character].region,
                lastRotation = this.lastPuckInput[character].rotation;
                //record = this.inputRecord[character];
            let inputKey = REGION_TO_INPUT[region] || null;

            
            if (!inputKey || INPUT_DEF[inputKey].character !== character) {
                //The player has put their puck on someone else's card, treat it like they've put it on a null region (aka 0).
                region = 0;
                inputKey = null;
            }

            if (this.inputLocked) {
                //We save input that occurs while input is locked, and apply it once input is unlocked
                this.preservedPuckInput[character].region = region;
                this.preservedPuckInput[character].rotation = rotation;
                return;
            }

            if (lastRegion !== region) {
                this.inputChanged = true;
            }

            if (lastRotation !== rotation) {
                this.inputChanged = true;
            }

            if (this.inputChanged) {
                // record.push({
                //     region: region,
                //     rotation: rotation,
                //     progress: this.courseProgress
                // });

                this.applyInput(character, region, rotation);
            }

        },

        applyInput: function (character, region, rotation, skipRecord) {
            const lastRegion = this.lastPuckInput[character].region,
                lastRotation = this.lastPuckInput[character].rotation,
                inputKey = REGION_TO_INPUT[region] || null,
                lastInputKey = REGION_TO_INPUT[lastRegion] || null;

            if (!skipRecord) {
                this.inputRecord[character].push({
                    region: region,
                    rotation: rotation,
                    progress: this.courseProgress
                });
            }
            
            if (lastRegion !== region) {
                if (lastRegion !== 0) {
                    this.removeInputEffects(lastInputKey);
                }
    
                if (region !== 0) {
                    this.addInputEffects(inputKey);
                }

                this.lastPuckInput[character].region = region;

                if (region === 0) {
                    this.owner.parent.triggerEvent('update-bird-state', character, {"direction": null, "sample": null, "isPlaying": false});
                } else {
                    this.owner.parent.triggerEvent('update-bird-state', character, {"direction": INPUT_DEF[inputKey].direction, "sample": INPUT_DEF[inputKey].sample});
                }
            }

            if (lastRotation !== rotation) {
                this.lastPuckInput[character].rotation = rotation;
            }
        },

        handleKeyboardInputs: function (inputKey, isPress) {
            let region = null;

            if (isPress) {
                for (region in REGION_TO_INPUT) {
                    if (REGION_TO_INPUT[region] === inputKey) {
                        break;
                    }
                }

                this.handlePuckMovement(INPUT_DEF[inputKey].character, Number(region), 0);
            } else {
                this.handlePuckMovement(INPUT_DEF[inputKey].character, 0, 0);
            }

        },

        addInputEffects: function (inputKey) {
            if (!inputKey) {
                return;
            }

            this.rawInput[0] += DIRECTION_TO_VECTOR[INPUT_DEF[inputKey].direction][0];
            this.rawInput[1] += DIRECTION_TO_VECTOR[INPUT_DEF[inputKey].direction][1];

            if (INPUT_DEF[inputKey].sample) {
                this.inputSamples.push(INPUT_DEF[inputKey].sample);

                this.owner.triggerEvent('add-sample-to-mix', INPUT_DEF[inputKey].sample);
            }
        },

        removeInputEffects: function (inputKey) {
            let sampleIndex = -1;

            if (!inputKey) {
                return;
            }

            this.rawInput[0] -= DIRECTION_TO_VECTOR[INPUT_DEF[inputKey].direction][0];
            this.rawInput[1] -= DIRECTION_TO_VECTOR[INPUT_DEF[inputKey].direction][1];

            if (INPUT_DEF[inputKey].sample) {
                sampleIndex = this.inputSamples.indexOf(INPUT_DEF[inputKey].sample);
                this.inputSamples.splice(sampleIndex, 1);

                this.owner.triggerEvent('remove-sample-from-mix', INPUT_DEF[inputKey].sample);
            }
        },

        resetCourseProgress: function () {
            this.owner.triggerEvent('changed-edge', this.currentEdgeIndex, 0);
            this.owner.triggerEvent('restart-song');

            this.inputRecord = {
                "red-bird": [],
                "purple-bird": [],
                "teal-bird": [],
                "yellow-bird": []
            };

            this.playbackInput = null;
            this.playbackIndexes = {
                "red-bird": 0,
                "purple-bird": 0,
                "teal-bird": 0,
                "yellow-bird": 0
            };

            this.lockInput();
            this.countInComplete = false;
            this.portionCorrect = 0;
            this.courseProgress = 0;
            this.edgeProgress = 0;
            this.currentEdgeIndex = 0;
            this.currentEdge = this.courseData.vectors[this.currentEdgeIndex];
            this.edgeLength = this.courseData.vectorLengths[this.currentEdgeIndex] * this.lengthUnit;

            this.owner.x = this.startX;
            this.owner.y = this.startY;
        },

        hasSameContents: function (arrayA, arrayB) {
            let x = 0,
                y = 0,
                found = false;
    
            if (arrayA === arrayB) {
                return true;
            }
    
            if (arrayA.length !== arrayB.length) {
                return false;
            }
    
            for (x = 0; x < arrayA.length; x++) {
                found = false;
                for (y = 0; y < arrayB.length; y++) {
                    if (arrayA[x] === arrayB[y]) {
                        found = true;
                        break;
                    }
                }
    
                if (!found) {
                    return false;
                }
            }
    
            return true;
        },

        diffInContents: function (model, imitator) {
            const unused = [...imitator],
                diff = {
                    extra: [],
                    missing: []
                };
            let x = 0,
                y = 0,
                unfound = true;

            for (x = 0; x < model.length; x++) {
                unfound = true;
                for (y = 0; y < imitator.length; y++) {

                    if (model[x] === imitator[y]) {
                        unused[y] = null;
                        unfound = false;
                        break;
                    }
                }

                if (unfound) {
                    diff.missing.push(model[x]);
                }
            }

            for (x = 0; x < unused.length; x++) {
                if (unused[x]) {
                    diff.extra.push(unused[x]);
                }
            }

            return diff;
        },

        setupPlayerControls: function (character, puck) {
            this.controller.on(`PuckButton${puck}`, ({value}) => {
                if (value !== 0) { // 0 = off, 1 = press, 2 = double-press, 3 = long press
                    this.handlePuckButton();
                }
            });

            this.controller.on(`PuckPosition${puck}`, ({value}) => {
                this.handlePuckMovement(character, value, this.lastPuckInput[character].rotation);
            });

            ///////////////////////////////////////////////////////////////////////////////////////////////TML - For now, we ignore puck rotation!
            // this.controller.on(`PuckRotation${puck}`, ({value}) => {
            //     this.handlePuckMovement(character, this.lastPuckInput[character].region, value);
            // });

            this.controller.on(`PuckConnection${puck}`, () => {

            });


        }
        
    },
    
    publicMethods: {
        getCourseResults: function () {
            // let character = null,
            //     x = 0,
            //     y = 0,
            //     input = null;

            const results = {
                log: null,
                score: this.portionCorrect / this.courseLengthSansCountIn
            };

            results.log = this.processInputRecord();

            //console.log('==> INPUT <==');
            // eslint-disable-next-line guard-for-in
            // for (character in results.log.input) {
            //     console.log("Input " + character + ":");
            //     input = results.log.input[character];
            //     for (x = 0; x < input.length; x++) {
            //         if (input[x].region && REGION_TO_INPUT[input[x].region] && INPUT_DEF[REGION_TO_INPUT[input[x].region]].sample) {
            //             console.log('region: ' + input[x].region + " toInput: " + REGION_TO_INPUT[input[x].region] + " toSample: " + INPUT_DEF[REGION_TO_INPUT[input[x].region]].sample);
            //         } else if (input[x].region && REGION_TO_INPUT[input[x].region]) {
            //             console.log('region: ' + input[x].region + " toInput: " + REGION_TO_INPUT[input[x].region]);
            //         } else {
            //             console.log('region: ' + input[x].region);
            //         }
            //     }
            // }

            // console.log('==> PROCESSED INPUT <==');
            // for (x = 0; x < results.log.processedInput.length; x++) {
            //     input = results.log.processedInput[x];
            //     console.log("Input " + x + ":");
            //     for (y = 0; y < input.length; y++) {
            //         console.log(input[y].character + "  :  " + input[y].sample + "  :  " + input[y].direction);
            //     }
            // }

            return results;
        }
        
    }
});
