import Band from '../../components/Band';
import SoundTrack from '../../components/SoundTrack';

export default {
    "id": "band",
    "components": [{
        "type": Band,
        "inputs": {
            "input-red-0": "red-0",
            "input-red-1": "red-1",
            "input-red-2": "red-2",
            "input-red-3": "red-3",

            "input-purple-0": "purple-0",
            "input-purple-1": "purple-1",
            "input-purple-2": "purple-2",
            "input-purple-3": "purple-3",

            "input-teal-0": "teal-0",
            "input-teal-1": "teal-1",
            "input-teal-2": "teal-2",
            "input-teal-3": "teal-3",

            "input-yellow-0": "yellow-0",
            "input-yellow-1": "yellow-1",
            "input-yellow-2": "yellow-2",
            "input-yellow-3": "yellow-3",

            "input-red-up": "red-up",
            "input-red-right": "red-right",
            "input-red-down": "red-down",

            "input-purple-up": "purple-up",
            "input-purple-right": "purple-right",
            "input-purple-down": "purple-down",

            "input-teal-up": "teal-up",
            "input-teal-right": "teal-right",
            "input-teal-down": "teal-down",
            
            "yellow-input-up": "yellow-up",
            "yellow-input-right": "yellow-right",
            "yellow-input-down": "yellow-down"
        }
    }, {
        "type": SoundTrack
    }, {
        "type": "RenderSprite",
        "spriteSheet": "band",
        "animationMap": {
            "good": "mtb_music-mode_speaker_playing_glow",
            "bad": "mtb_music-mode_speaker_playing-issue_glow",
            "default": "none"
        }
    }, {
        "type": "RenderSprite",
        "spriteSheet": "band",
        "animationMap": {
            "good": "mtb_music-mode_speaker_playing",
            "bad": "mtb_music-mode_speaker_playing-issue",
            "default": "mtb_music-mode_speaker"
        }
    }, {
        "type": "EntityController",
        "controlMap": {
            
            //P1 Controls
            "KeyQ": {"triggered": ["input-red-0-triggered"],    "released": ["input-red-0-released"]},
            "KeyW": {"triggered": ["input-red-1-triggered"], "released": ["input-red-1-released"]},
            "KeyE": {"triggered": ["input-red-2-triggered"],    "released": ["input-red-2-released"]},
            "KeyR": {"triggered": ["input-red-3-triggered"], "released": ["input-red-3-released"]},
            "KeyT": {"triggered": ["input-red-up-triggered"], "released": ["input-red-up-released"]},
            
            //P2 Controls
            "KeyY": {"triggered": ["input-purple-0-triggered"], "released": ["input-purple-0-released"]},
            "KeyU": {"triggered": ["input-purple-1-triggered"], "released": ["input-purple-1-released"]},
            "KeyI": {"triggered": ["input-purple-2-triggered"], "released": ["input-purple-2-released"]},
            "KeyO": {"triggered": ["input-purple-3-triggered"], "released": ["input-purple-3-released"]},
            "KeyP": {"triggered": ["input-purple-down-triggered"], "released": ["input-purple-down-released"]},
            

            //P3 Controls
            "KeyZ": {"triggered": ["input-teal-0-triggered"], "released": ["input-teal-0-released"]},
            "KeyX": {"triggered": ["input-teal-1-triggered"], "released": ["input-teal-1-released"]},
            "KeyC": {"triggered": ["input-teal-2-triggered"], "released": ["input-teal-2-released"]},
            "KeyV": {"triggered": ["input-teal-3-triggered"], "released": ["input-teal-3-released"]},
            "KeyB": {"triggered": ["input-teal-right-triggered"], "released": ["input-teal-right-released"]},
            

            //P4 Controls
            "KeyN": {"triggered": ["input-yellow-0-triggered"], "released": ["input-yellow-0-released"]},
            "KeyM": {"triggered": ["input-yellow-1-triggered"], "released": ["input-yellow-1-released"]},
            "Comma": {"triggered": ["input-yellow-2-triggered"], "released": ["input-yellow-2-released"]},
            "Period": {"triggered": ["input-yellow-3-triggered"], "released": ["input-yellow-3-released"]},
            "Slash": {"triggered": ["yellow-input-down-triggered"], "released": ["yellow-input-down-released"]},
            
            "Backspace": {"triggered": ["reset-band"]},
            "Delete": {"triggered": ["reset-band"]}
        }
    }, {
        "type": "RelayLinker",
        "linkId": "band",
        "events": {
            "focus-camera": "focus-on",
            "changed-edge": "change-highlighted-indicator"
        }
    }, {
        "type": "RelayParent",
        "events": {
            "play-correctly": "input-correct",
            "play-poorly": "input-incorrect",
            "stop-soundtrack": "input-none"
        }
    }],
    "properties": {
        id: "the-band",
        interactive: true,
        alwaysOn: true,
        x: 0,
        y: 0,
        z: 1000,
        scaleX: 0.75,
        scaleY: 0.75
    }
};