import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'Scroller',
    
   
    properties: {
        
    },
    
   
    publicProperties: {
        
    },
    
    initialize: function (/*definition, callback*/) {
        this.dragOrigin = {
            'x': 0,
            'y': 0
        };
    },

    events: {
        "pointerdown": function (event) {

            this.dragOrigin.x = event.x;
            this.dragOrigin.y = event.y;
            this.owner.triggerEvent('rl-drag-start');
        },

        "pressup": function () {
            this.owner.triggerEvent('rl-drag-stop');
        },

        "pressmove": function (event) {
            let offsetX = 0,
                offsetY = 0;

            offsetX = event.x - this.dragOrigin.x;
            offsetY = event.y - this.dragOrigin.y;

            this.owner.triggerEvent('rl-drag-offset', {"x": offsetX, "y": offsetY});
        }/*,

        'layer-live': function () {
            let container = this.owner.container;

            while (container) {
                container.interactiveChildren = true;
                if (!Object.prototype.hasOwnProperty.call(container, 'buttonMode')) {
                    Object.defineProperty(container, 'buttonMode', {
                        get: function () {
                            return this.cursor === 'pointer';
                        },
                        set: function (value) {
                            if (value) {
                                this.cursor = 'pointer';
                            } else if (this.cursor === 'pointer') {
                                this.cursor = null;
                            }
                        }
                    });
                }
                if (!Object.prototype.hasOwnProperty.call(container, 'trackedPointers')) {
                    Object.defineProperty(container, 'trackedPointers', {
                        get: function () {
                            if (this._trackedPointers === undefined) this._trackedPointers = {};
                
                            return this._trackedPointers;
                        }
                    });
                }
                
                container = container.parent;
            }
        }*/
    },
    
    methods: {
       
        
    },
    
    publicMethods: {
        
        
    }
});
