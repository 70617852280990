import SuccessMeter from '../../components/SuccessMeter';

export default {
    id: "success-meter",
    components: [
        SuccessMeter,
        {
            type: "RenderSprite",
            spriteSheet: "success-meter",
            "animationMap": {
                "default": "meter"
            }
        }, {
            type: "RenderSprite",
            spriteSheet: "success-meter",
            "animationMap": {
                "very-good": "face-very-good",
                "good": "face-good",
                "bad": "face-bad",
                "very-bad": "face-very-bad",
                "default": "face-very-bad"
            }
        }, {
            "type": "RelativePosition"
        }, {
            "type": "RelayLinker",
            "linkId": "results",
            "events": {
            }
        }
    ],
    properties: {
        id: "the-success-meter",
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
        top: -300
    }
};