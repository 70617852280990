import Lakitu from '../../components/Lakitu';

export default {
    "id": "lakitu",
    "components": [
        {
            "type": Lakitu
        }, {
            "type": "Tween"
        }, {
            "type": "RelayLinker",
            "linkId": "band"
        }, {
            "type": "RelayLinker",
            "linkId": "camera-movement"
        }
    ],
    "properties": {
        x: 0,
        y: 0,
        z: 0
    }
};