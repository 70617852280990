<template>
    <article class="assign-player-container" :class="{'present': assignedPlayer && activePlayer}">
        <button class="assign-panel-trigger" @click="assignPlayer()">
            <em>Pick Character</em>
        </button>

        <Transition name="show">
            <div class="assigned-player" :class="{'is-leader': isReferee, 'inactive': !activePlayer}" v-if="assignedPlayer">
                <header v-if="isReferee" class="leader-label">
                    <h3>Player Has The Controller.</h3>
                    <button class="inline-info-btn" title="What does this mean?">&#x24D8;</button>
                </header>

                <PlayerAvatar class="avatar" :me="assignedPlayer"></PlayerAvatar>

                <b class="ready-flag">Player Is Ready!</b>

                <em>
                    <span v-if="!activePlayer">Waiting on </span>
                    {{ assignedPlayer.name }}
                    <span class="me" v-if="assignedPlayer.personId === myId">(you)</span>
                </em>

                <button v-if="assignedPlayer.personId === myId" @click="removePlayer" class="remove-btn mtb-btn action">X</button>
                <!--<b>Is <span class="charater-avatar"></span></b>-->
            </div>
        </Transition>
    </article>
</template>

<script>
    import PlayerAvatar from '../PlayerAvatar.vue';
    import {
        mapGetters
    } from 'vuex';

    export default {
        name: 'AssignPlayer',
        components: {
            PlayerAvatar
        },
        props: {
            character: String,
            characterProperties: Object,
            isReferee: Boolean
        },
        computed: {
            ...mapGetters({
                playersHere: 'getPlayersHere',
                players: 'getPlayers'
            }),
            assignedPlayer() {
                const
                    playerId = this.characterProperties?.player;

                if (playerId) {
                    return this.playthroughData.players.filter((player) => player.personId === playerId)[0];
                } else {
                    return null;
                }
            },
            activePlayer() {
                return this.playersHere[this.assignedPlayer?.personId] ?? false;
            }
        },
        data() {
            return {
                myId: this.$store.getters.getPlayerId,
                playthroughData: this.$store.getters.getActivePlaythrough
            };
        },
        mounted() {},
        methods: {
            assignPlayer() {
                this.$store.dispatch('updatePlayerCharacter', {
                    character: this.character,
                    player: this.myId
                });
            },
            removePlayer() {
                this.$store.dispatch('updatePlayerCharacter', {
                    character: this.character,
                    player: null
                });
            }
        }
    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.assign-player-container {
    position: absolute;
    bottom: 0;
    left: 0.5em;
    right: 0.5em;
    pointer-events: none;

    .assign-panel-trigger {
        display: block;
        width: 100%;
        border: 0.25em dashed rgba(255, 255, 255, 0.5);
        background-color: transparent;
        color: $mtb-white;
        font-weight: bold;
        padding: 1em 0;
        border-radius: 0.75em 0.75em 0 0;
        border-width: 0.25em 0.25em 0 0.25em;
        outline: none;
        position: absolute;
        bottom: 0;

        pointer-events: all;

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            cursor: pointer;
        }
    }

}

.assigned-player {
    position: absolute;
    padding: 1em 0.5em 1.25em 4.5vw;
    display: block;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    background: $mtb-black;
    border-radius: 0.5em 0.5em 0 0;
    box-shadow: 0 0 0.3em 0.1em rgb(0 0 0 / 30%);

    transition: transform 0.5s;

    pointer-events: all;

    &.inactive {
        background: $mtb-white;
        color: $mtb-black;
        padding: 1em 0.5em 1.25em;
        text-align: center;

        .avatar {
            display: none;
        }
    }

    .leader-label {
        position: absolute;
        z-index: 1;
        bottom: -2.5vw;
        left: -0.7em;
        right: -0.7em;

        background: #1b204b;
        color: #fee888;
        font-size: 0.75em;
        padding: 0.75em 0;
        font-weight: bold;

        display: flex;
        justify-content: center;
        font-weight: bold;

        .inline-info-btn {
            background: transparent;
            padding: 0 0.5em;
            color: #fee888;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }

    }

    .ready-flag {
        font-size: 0.75em;
        font-weight: bold;
        position: absolute;
        top: -1em;
        display: block;
        background: #719e24;
        padding: 0.5em 1em;
        border-radius: 1.5em;
        left: 50%;
        transform: translateX(-50%);

        opacity: 0;
        pointer-events: none;
    }

    .avatar {
        width: 5vw;
        height: 5vw;
        position: absolute;
        top: -0.5vw;
        left: -0.5vw;
        border: 0.25em solid $mtb-black;
    }

    em {
        font-size: 0.75em;
        font-weight: bold;
        padding: 0.5em;
        flex-grow: 2;

        .me {
            font-size: 0.75em;
            color: #9F9E9E;
        }
    }

    .remove-btn {
        width: 3vw;
        height: 3vw;
        margin: auto;
        border-radius: 100%;
        background: #ffff;
        border: none;
        color: $mtb-black;
        font-size: 1.5em;
        padding: 0;
        position: absolute;
        top: -0.25em;
        right: -0.25em;

        &:hover {
            background-color: #CC2662;
            color: $mtb-white;
        }

    }

}

// Presence Blinker    
.assign-player-container:before {
    content: "";
    display: block;
    width: 1.3vw;
    height: 1.3vw;
    background-color: transparent;
    position: fixed;
    z-index: 1;
    top: 1.6vw;
    right: 1vw;
    border-radius: 100%;
    transform-origin: center center;
    
    background-position: 50% 50%;
    background-size: 100%;

    animation: blink 2s linear infinite;
}

.assign-player-container:after {
    content: "";
    display: block;
    position: absolute;
    width: 1.3vw;
    height: 1.3vw;
    position: fixed;
    top: 1.6vw;
    right: 1vw;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border: 0.1vw solid $mtb-black;
    transform-origin: center center;

    animation: pulse 2s linear infinite;
}

.assign-player-container.present {
    &:before,
    &:after {
        animation: none;
    }
}

.assign-player-container {
    &:before,
    &:after {
        visibility: hidden;
    }
}
    
.connected .assign-player-container {
    &:before,
    &:after {
        visibility: visible;
    }
}

.yellow-bird .assign-player-container:before {
    background-image: url(~@/assets/ui/mtb-blinkers_yellow.svg);
}

.purple-bird .assign-player-container:before {
    background-image: url(~@/assets/ui/mtb-blinkers_purple.svg);
}

.red-bird .assign-player-container:before {
    background-image: url(~@/assets/ui/mtb-blinkers_red.svg);
}

.teal-bird .assign-player-container:before {
    background-image: url(~@/assets/ui/mtb-blinkers_teal.svg);
}

@keyframes blink {
    0%,
    24%,
    76%,
    100% {
        opacity: 0;
        transform: scale(1);
    }

    33%,
    66% {
        transform: scale(1.1);
        opacity: 1;
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }

    33%,
    66% {
        transform: scale(1.1);
    }
}
</style>
