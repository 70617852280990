/* global platypus */
import {Entity, createComponentClass} from 'platypus';

const
    //IS_IN_IOS_CORDOVA_APP = (window.location.protocol === 'file:') || (window.location.protocol === 'ionic:') || (window.location.host === 'localhost:8080'), // These are different tests that handle a few different ways that the app can be set up with UIWebView or Ionic's WKWebView.
    BAR_X = 0,
    BAR_Y = 50,
    LoadNextScene = createComponentClass({
        id: 'LoadNextScene',
        initialize: function () {
            this.backdrop = this.owner.addEntity({
                type: 'loading-backdrop'
            });
            this.barBack = this.owner.addEntity({
                type: 'loading-bar',
                properties: {
                    state: {
                        blue: true
                    },
                    x: BAR_X,
                    y: BAR_Y,
                    z: 9
                }
            });
            this.bar = this.owner.addEntity({
                type: 'loading-bar',
                properties: {
                    state: {
                        red: true
                    },
                    mask: {
                        x: BAR_X - 105,
                        y: BAR_Y - 11,
                        width: 210,
                        height: 22
                    },
                    x: BAR_X,
                    y: BAR_Y,
                    z: 10
                }
            });

            if (needsInteractionForAudio) {
                this.activateAudio = this.owner.addEntity({
                    type: 'activate-audio-button',
                    properties: {
                        persistentData: {
                            scene: 'title-scene',
                        }
                    }
                });
                this.backdrop.opacity = 0;
                this.bar.opacity = 0;
                this.barBack.opacity = 0;
            }

            this.time = 500;
            this.bouncer = 0;
        },
        events: {
            'layer-live': function (data) {
                const loadLayer = () => {
                    this.addEventListener('unload-layer', function (hold, release) {
                        hold();
                        closeLoader = release;
                    });
    
                    this.bar.container.mask.x = -210;
                    platypus.game.loadScene(data.scene, data.data, (progress) => {
                        this.bar.container.mask.x = progress * 210 - 210;
                        if (progress === 1) {
                            // Give a little time for next scene to settle in.
                            window.setTimeout(() => {
                                closeLoader();
                            }, 750);
                        }
                    });
                };
                let closeLoader = null;

                if (this.activateAudio) {
                    this.activateAudio.on('pointerup', () => {
                        if (needsInteractionForAudio) { // prevent double-clicks
                            this.activateAudio.opacity = 0;
                            needsInteractionForAudio = false;
                            this.backdrop.opacity = 1;
                            this.bar.opacity = 1;
                            this.barBack.opacity = 1;
                            loadLayer();
                        }
                    });
                } else {
                    loadLayer();
                }
            },

            "tick": function () {
                
            }
        },
        getAssetList: function () {
            return [
                ...Entity.getAssetList({
                    type: 'loading-bar'
                }),
                ...Entity.getAssetList({
                    type: 'loading-backdrop'
                }),
                ...Entity.getAssetList({
                    type: 'activate-audio-button'
                })
            ];
        }
    });
let needsInteractionForAudio = false;//!IS_IN_IOS_CORDOVA_APP || (window.navigator.userAgent.toLowerCase().indexOf('os 9_') >= 0); // iOS 9.3.5's webview still needs the initial tap, even in the app.

try {
    if (needsInteractionForAudio && (!window.AudioContext || (new window.AudioContext().state === 'running')) && (!window.webkitAudioContext || (new window.webkitAudioContext().state === 'running'))) {
        needsInteractionForAudio = false;
    }
} catch (e) {
    platypus.debug.warn('Unlocking audio error: ' + e.message);
}

export default {
    "id": "loading-layer",
    "components": [{
        "type": "HandlerLogic",
        "alwaysOn": true
    }, {
        "type": "HandlerRender"
    }, {
        "type": "Camera",
        "width": 600,
        "height": 600,
        "overflow": true
    }, {
        "type": "HandlerController"
    }, {
        "type": "EntityContainer",
        "entities": [],
        "childEvents": ["layer-live", "unload-layer"]
    }, LoadNextScene],
    "properties": {
        "unloadDelay": 1000,
        "z": 1
    }
};