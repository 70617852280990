<template>
    <div
        id="apploading"
        :class="{
            'loading-container': true,
            'starting': $store.getters.getIsStarting,
            'busy-or-missing': busyOrMissing,
        }"
    >
        <button v-if="busy?.cancellable" class="mtb-btn action close cancel-action" @click="exitLoader">Cancel Loader</button>
        
        <button v-if="missing" class="mtb-btn action top-nav left left-arrow" @click="exitGame">Back to Main Menu</button>
        
        <Transition name="circle-wipe">
          <div
               class="start-up-indicator"
               v-if="$store.getters.getIsStarting && $store.getters.getPlayerInfo?.name"
          >
            <h2>Welcome {{$store.getters.getPlayerInfo.name}}</h2>
            <img class="app-logo" :src="app_logo" />
              
            <lottie-animation ref="anim" class="waiting-animation" :animationData="inline_loader_anim" :loop="true" :autoPlay="true" :speed="2" />
          </div>
        </Transition>
        
        <Transition name="slide-fade">
          <div
               class="busy-indicator"
               v-if="busyOrMissing"
          >
            <lottie-animation
                ref="anim"
                :animationData="missing_anim"
                :loop="true"
                :autoPlay="true"
                v-if="missing"
            />
            <lottie-animation
                ref="anim"
                :animationData="loader_anim"
                :loop="true"
                :autoPlay="true"
                v-else
            />
            <progress
                class="busy-progress"
                ref="progress"
                max="100"
                v-if="busy?.progress"
            >
              {{ busy?.progress + '%' }}
            </progress>
            <p v-if="busy?.message" class="message">{{ busy.message }}</p>
            <p v-if="missing" class="message">Waiting on another player to reconnect...</p>
          </div>
        </Transition>
  </div>
</template>
<script setup>
import loader_anim from '@/assets/ui/animations/loader-anim.json';
import inline_loader_anim from '@/assets/ui/animations/inline-loader-anim.json';
import missing_anim from '@/assets/ui/animations/disconnected.json';
import app_logo from '@/assets/ui/MTB_FPO-Logo.svg';
</script>


<script>
import {mapGetters} from 'vuex';

export default {
    name: 'AppLoader',
    computed: {
        ...mapGetters({
            isDecisionLead: 'getIsReferee',
            busy: 'getBusy',
            missing: 'getMissing'
        }),
        busyOrMissing () {
            return this.busy || this.missing;
        }
    },
    mounted () {
    },
    methods: {
        exitLoader () {
            this.$store.dispatch('appReady', true);
        },

        async exitGame () {
            await this.$store.dispatch('leaveGame', true);
            this.$router.push({
                name: 'Start'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 20000;
    backdrop-filter: blur(20px);
  
    opacity: 0;
    pointer-events: none;
    transition: all 1s ease-out;
  
    &.busy-or-missing, &.starting {
        opacity: 1;
        pointer-events: all;
    }
}
  
.busy-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
  
    font-size: 4em;
    color: $mtb-white;
}

.busy-message {
    font-size: 0.5em;
}
  
.start-up-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1c204b;

    h2{
        font-size: 1.5em;
        white-space: nowrap;
        text-align: center;
        background: #060A33;
        color: #FFE888;
        padding: 0.5em 1.5em;
        border-radius: 1.5em;
        width: fit-content;
        margin: auto;
        position: absolute;
        bottom: 3vw;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .app-logo {
        width: 75vw;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-60%);
    }
    
    .waiting-animation {
        width: 20vw;
        height: 20vw;
        position: absolute;
        bottom: -5vw;
        right: -5vw;
    }
}

.message {    
    font-size: 0.3em;
    white-space: nowrap;
    text-align: center;
    background: #0c0c0c;
    padding: 0.5em 1.5em;
    border-radius: 1.5em;
    width: fit-content;
    margin: auto;
}
    
.cancel-action {
    position: absolute;
    right: 2vw;
    top: 2vw;
}
</style>