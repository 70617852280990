<template>
    <div v-if="$store.getters.getActiveSession" class="state-saver">
        <form>
            <span class="input-group">
                <label>
                    Save as...
                </label>
                <input type="text" v-model="filename" />
                <button class="state-saver" :disabled="!enabled" type="submit" @click="saveState">{{ enabled ? 'Save State' : 'Saving...' }}</button>
            </span>
        </form>
    </div>
</template>

<script>
import dpeService from '@/shared/dpeService';
import names from '@/shared/data/names.json';

export default {
    name: "StateSaver",
    components: {
    },
    data () {
        return {
            enabled: true,
            localStates: {},
            filename: this.getName()
        };
    },
    props: {
    },
    computed: {
    },
    mounted () {
        this.$peerService.on('game-started', ({otherPlayers}) => {
            const
                offAfterOn = (event, update) => {
                    this.$peerService.on(event, update);

                    return () => {
                        this.$peerService.off(event, update);
                    };
                },
                teardownEvents = () => {
                    this.$peerService.off('game-ended', teardownEvents);
                    this.getLocalStates = null;
                    teardowns.forEach((teardown) => teardown());
                    teardowns.length = 0;
                },
                teardowns = [];

            this.$peerService.on('game-ended', teardownEvents);
            this.getLocalStates = async () => {
                const
                    getAll = otherPlayers.map((connectedPeer) => new Promise ((resolve) => {
                        const
                            done = offAfterOn(`${connectedPeer}.gameState.localStateRelay.${dpeService.state.me}`, ({value}) => {
                                if (typeof value === 'object') {
                                    this.localStates[connectedPeer] = value;
                                    dpeService.updateGameState('localStateRelay', connectedPeer, 'accepted-state');
                                    done();
                                    resolve();
                                }
                            });

                        dpeService.updateGameState('localStateRelay', connectedPeer, 'requesting-state');
                    }));

                await Promise.all(getAll);
            };

            otherPlayers.forEach((connectedPeer) => {
                teardowns.push(
                    offAfterOn(`${connectedPeer}.gameState.localStateRelay.${dpeService.state.me}`, ({value}) => {
                        if (value === 'requesting-state') {
                            dpeService.updateGameState('localStateRelay', connectedPeer, dpeService.state[dpeService.state.me].localState);
                        } else if (value === 'accepted-state') {
                            dpeService.updateGameState('localStateRelay', connectedPeer, null);
                        }
                    })
                );
            });
        });
    },
    methods: {
        getName () {
            return `${names[(Math.random() * names.length) >> 0].adj1} ${names[(Math.random() * names.length) >> 0].adj2} ${names[(Math.random() * names.length) >> 0].noun}`.trim();
        },

        async saveState (e) {
            let saves = localStorage.getItem('mtb-saves') || '{}';

            e.preventDefault();

            this.enabled = false;

            try {
                saves = JSON.parse(saves);
            } catch (err) {
                console.warn('Error reading saves.', err);
                saves = {};
            }

            if (!this.filename) {
                this.filename = this.getName();
                while (saves[this.filename]) {
                    this.filename = this.getName();
                }
            }

            saves[this.filename] = {
                playthrough: this.$store.getters.getActivePlaythrough,
                characters: this.$store.getters.getCharacters,
                state: JSON.parse(JSON.stringify(dpeService.state))
            };

            await this.getLocalStates();

            for (const key in this.localStates) {
                if (Object.prototype.hasOwnProperty.call(this.localStates, key)) {
                    saves[this.filename].state[key].localState = this.localStates[key];
                }
            }

            localStorage.setItem('mtb-saves', JSON.stringify(saves));

            this.enabled = true;
        }
    }
};

</script>
<style lang="scss">
.state-saver {
    button:disabled {
        color: darkgray;
        border-color: darkgray;
    }
}
</style>