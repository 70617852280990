// Panel 0
import ASSETS_COMICS_2_CHAPTER_1_PANEL0_BKGRND from '@/assets/comics/2_chapter-1/panel-0/background.png';

// Panel 1
import ASSETS_COMICS_2_CHAPTER_1_PANEL1_BKGRND from '@/assets/comics/2_chapter-1/panel-1/background.png';

// Panel 2
import ASSETS_COMICS_2_CHAPTER_1_PANEL2_BKGRND from '@/assets/comics/2_chapter-1/panel-2/background.png';

// Panel 3
import ASSETS_COMICS_2_CHAPTER_1_PANEL3_BKGRND from '@/assets/comics/2_chapter-1/panel-3/background.png';

// Panel 4
import ASSETS_COMICS_2_CHAPTER_1_PANEL4_BKGRND from '@/assets/comics/2_chapter-1/panel-4/background.png';

// Panel 5
import ASSETS_COMICS_2_CHAPTER_1_PANEL5_BKGRND from '@/assets/comics/2_chapter-1/panel-5/background.png';


export default {
    "name": "Chapter 1",
    "panels": [
        {
            'name': 'chapter-1-panel-0',
            'width': 750, // in pixels
            'height': 563, // in pixels
            'left': 100, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 0 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL0_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 563 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-1-panel-1',
            'width': 750, // in pixels
            'height': 750, // in pixels
            'left': 950, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 1 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL1_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 750 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-1-panel-2',
            'width': 1024, // in pixels
            'height': 612, // in pixels
            'left': 1810, // pixel offset from left (x)
            'top': 150, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 2 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL2_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 612 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-1-panel-3',
            'width': 1770, // in pixels
            'height': 588, // in pixels
            'left': 1810, // pixel offset from left (x)
            'top': 850, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 3 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL3_BKGRND,
                    'size': {
                        'width': 1770, // pixels
                        'height': 588 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-1-panel-4',
            'width': 750, // in pixels
            'height': 750, // in pixels
            'left': 1870, // pixel offset from left (x)
            'top': 1520, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 4 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL4_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 750 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-1-panel-5',
            'width': 850, // in pixels
            'height': 508, // in pixels
            'left': 2720, // pixel offset from left (x)
            'top': 1575, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 1 - Panel 5 Art',
                    'asset': ASSETS_COMICS_2_CHAPTER_1_PANEL5_BKGRND,
                    'size': {
                        'width': 850, // pixels
                        'height': 508 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
    ] // panels

};