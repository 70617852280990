<template>
    <Transition name="peak-up">
        <article v-show="showMe" class="setup-box">
            <h3 class="header-blob chat-label"><em>Match Starting Position...</em></h3>
            <p class="instructions">{{instructionText()}}</p>

            <button class="mtb-btn action bottom-ready" :class="playButtonClasses" :onClick="() => {theGame.playerButtonPressed();}">{{readyButtonText()}}</button>
        </article>
    </Transition>
</template>

<script>


export default {
    name: 'InitialPlacement',
    props: {
        theGame: Object,
        vueData: Object
    },
    computed: {
        playButtonClasses () {
            const result = {};
            if (this.vueData.setup.inPosition && this.vueData.player.locked) {
                result.visible = true;
                result.locked = true;
            } else if (this.vueData.setup.inPosition) {
                result.visible = true;
            }
            return result;
        }
        // setupInstructions () {
        //     let character = null,
        //         startRegion = null,
        //         regionName = null;
        //     character = this.gameData.localPlayer.character;
        //     startRegion = this.gameData.startRegions[character];
        //     regionName = this.gameData.regionData[startRegion].name;

        //     return "Place Your Character at the " + regionName + ".";
        // }
    },
    data () {
        return {
            showMe: false
        };
    },
    beforeMount () {
    },
    mounted () {
        this.showMe = true;
        this.$store.dispatch('appReady');
    },
    beforeUnmount () {
        this.showMe = false;
    },
    methods: {
        instructionText () {
            if (this.vueData.setup.inPosition && this.vueData.player.locked) {
                return "Great! You're all set!";
            } else if (this.vueData.setup.inPosition) {
                return "You're in the right spot, now ready up!";
            } else {
                return "Place Your Character at the " + this.vueData.setup.regionName + ".";
            }
        },
        readyButtonText () {
            if (this.vueData.setup.inPosition && this.vueData.player.locked) {
                return "WAITING ON OTHERS...";
            } else {
                return "I'M READY TO PLAY";
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .setup-box {
        height: 10vw;
        left: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgb(21,23,40);
        background: linear-gradient(90deg, rgba(21,23,40,1) 0%, rgba(21,23,40,1) 60%, rgba(21,23,40,0) 100%);

        & .chat-label {
            position: absolute;
            left: 4vw;
            top: -1.5em;
            text-align: center;
            font-size: 1.3em;
            
            em {
                transform: translateY(-0.1em) rotate(-1deg);
            }
            
        }

        & .instructions {
            display: flex;
            height: 100%;
            align-items: center;
            padding: 2.25em 5em 0.75em;
        }
    }
</style>