import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'Stage',
    
   
    properties: {
        
    },
    
   
    publicProperties: {
        
    },
    
    initialize: function (/*definition, callback*/) {
        this.owner.state.set(this.owner.parent.parent.settings.level.stage, true);
    },

    events: {
       
    },
    
    methods: {
       
        
    },
    
    publicMethods: {
        
        
    }
});
