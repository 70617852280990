import ASSET_MTB_HEADFACE_REDBIRD from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_red-bird_default.png';
import ASSET_MTB_HEADFACE_REDBIRD_BANDSHIRT from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_red-bird_band-shirt.png';

import ASSET_MTB_HEADFACE_YELLOWBIRD from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_yellow-bird_default.png';
import ASSET_MTB_HEADFACE_YELLOWBIRD_BANDSHIRT from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_yellow-bird_band-shirt.png';

import ASSET_MTB_HEADFACE_TEALBIRD from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_teal-bird_default.png';
import ASSET_MTB_HEADFACE_TEALBIRD_BANDSHIRT from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_teal-bird_band-shirt.png';

import ASSET_MTB_HEADFACE_PURPLEBIRD from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_purple-bird_default.png';
import ASSET_MTB_HEADFACE_PURPLEBIRD_BANDSHIRT from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_purple-bird_band-shirt.png';

import ASSET_MTB_HEADFACE_RACCOON from '@/components/gamemodes/mission/assets/headfaces/mtb_head-faces_raccoon.png';


 export default {
    "red-bird": {
        "default": ASSET_MTB_HEADFACE_REDBIRD,
        "band-shirt": ASSET_MTB_HEADFACE_REDBIRD_BANDSHIRT
    },
    "purple-bird": {
        "default": ASSET_MTB_HEADFACE_PURPLEBIRD,
        "band-shirt": ASSET_MTB_HEADFACE_PURPLEBIRD_BANDSHIRT
    },
    "teal-bird": {
        "default": ASSET_MTB_HEADFACE_TEALBIRD,
        "band-shirt": ASSET_MTB_HEADFACE_TEALBIRD_BANDSHIRT
    },
    "yellow-bird": {
        "default": ASSET_MTB_HEADFACE_YELLOWBIRD,
        "band-shirt": ASSET_MTB_HEADFACE_YELLOWBIRD_BANDSHIRT
    }, 
    "raccoon": {
        "default": ASSET_MTB_HEADFACE_RACCOON
    },
    "baker": {
        "default": ASSET_MTB_HEADFACE_RACCOON
    },
    "future-yellow-bird": {
        "default": ASSET_MTB_HEADFACE_YELLOWBIRD_BANDSHIRT
    }
 };