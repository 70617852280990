import ASSETS_GAMEMODES_PRACTICE_BOARDS_BOWIES_HOUSE_PNG from '@/assets/gamemodes/practice/boards/bowies-house.png';
import ASSETS_GAMEMODES_PRACTICE_BOARDS_KITS_GARAGE_PNG from '@/assets/gamemodes/practice/boards/kits-garage.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_MAPS_MISSION_BOWIES_HOUSE_PNG from '@/components/gamemodes/mission/assets/maps/mission-bowies-house.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_MAPS_MISSION_TOWN_MAP_PNG from '@/components/gamemodes/mission/assets/maps/mission-town-map.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_BOWIES_HOUSE_PACKED_0_JSON from '@/components/gamemodes/mission/assets/zones/bowies-house/packed-0.json';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_BOWIES_HOUSE_PACKED_0_PNG from '@/components/gamemodes/mission/assets/zones/bowies-house/packed-0.png';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_TOWN_MAP_PACKED_0_JSON from '@/components/gamemodes/mission/assets/zones/town-map/packed-0.json';
import COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_TOWN_MAP_PACKED_0_PNG from '@/components/gamemodes/mission/assets/zones/town-map/packed-0.png';

export default [
    {
        "id": "bowies-house",
        "asset": ASSETS_GAMEMODES_PRACTICE_BOARDS_BOWIES_HOUSE_PNG,
        "zoneData": "",
        "zoneArt": "",
        "scaleX": "",
        "scaleY": "",
        "birdScaleX": "",
        "birdScaleY": ""
    },
    {
        "id": "kits-garage",
        "asset": ASSETS_GAMEMODES_PRACTICE_BOARDS_KITS_GARAGE_PNG,
        "zoneData": "",
        "zoneArt": "",
        "scaleX": "",
        "scaleY": "",
        "birdScaleX": "",
        "birdScaleY": ""
    },
    {
        "id": "town-map",
        "asset": COMPONENTS_GAMEMODES_MISSION_ASSETS_MAPS_MISSION_TOWN_MAP_PNG,
        "zoneData": COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_TOWN_MAP_PACKED_0_JSON,
        "zoneArt": COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_TOWN_MAP_PACKED_0_PNG,
        "scaleX": "1.5",
        "scaleY": "1.5",
        "birdScaleX": "1",
        "birdScaleY": "1"
    },
    {
        "id": "bowies-house-map",
        "asset": COMPONENTS_GAMEMODES_MISSION_ASSETS_MAPS_MISSION_BOWIES_HOUSE_PNG,
        "zoneData": COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_BOWIES_HOUSE_PACKED_0_JSON,
        "zoneArt": COMPONENTS_GAMEMODES_MISSION_ASSETS_ZONES_BOWIES_HOUSE_PACKED_0_PNG,
        "scaleX": "1",
        "scaleY": "1",
        "birdScaleX": "2",
        "birdScaleY": "2"
    }
];