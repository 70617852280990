import img_success_meter from '@/components/gamemodes/performance/assets/images/success-meter.png';
import img_success_meter_back from '@/components/gamemodes/performance/assets/images/success-meter-back.png';
import img_success_meter_bar from '@/components/gamemodes/performance/assets/images/success-meter-bar.png';
import img_success_meter_faces from '@/components/gamemodes/performance/assets/images/success-meter-faces.png';



export default {
    "images": [
        img_success_meter,
        img_success_meter_faces,
        img_success_meter_bar,
        img_success_meter_back
    ],
    "frames": [
        [ 0, 0, 314, 53, 0, 55, 14],
        [ 0, 0, 48, 48, 1, 52, 12],
        [ 48, 0, 48, 48, 1, 52, 12],
        [ 96, 0, 48, 48, 1, 52, 12],
        [ 144, 0, 48, 48, 1, 52, 12],
        [ 0, 0, 1, 26, 2, 0, 0],
        [ 0, 0, 314, 53, 3, 55, 14]

    ],
    "animations": {
        "meter": 0,
        "face-very-bad": 1,
        "face-bad": 2,
        "face-good": 3,
        "face-very-good": 4,
        "bar": 5,
        "back": 6
    }
};