// Panel 0
import ASSETS_COMICS_5_PRACTICE_KIT_PANEL0_BKGRND from '@/assets/comics/5_transition-practice/kits-garage/background.png';


export default {
    "name": "Transition Practice - Kit's Garage",
    "panels": [
        {
            'name': 'practice-kit-panel-0',
            'width': 750, // in pixels
            'height': 563, // in pixels
            'left': 100, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': "Transition Practice - Kit's Garage Art",
                    'asset': ASSETS_COMICS_5_PRACTICE_KIT_PANEL0_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 563 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
    ] // panels

};