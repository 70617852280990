<template>
    <div class="piece" :class="classObject">
        <em v-if="turn <= 0 && mode !== 'setup'" class="turn-label">
            Setup
            <span class="start-flag"></span>
        </em>
        <em v-else-if="mode !== 'setup'" class="turn-label" :class="correctnessObject">
            Turn {{ turn }}
            <span class="correctness-mark"></span>
        </em>
        
        
    </div>
</template>

<script>
export default {
    name: 'PlayerPiece',
    props: {
        boardDimensions: null,
        showCorrectness: null,
        turn: null,
        mode: null,

    },
    data () {
        return {
            name: '',
            character: '',
            region: 0,
            rotation: 0,
            row: -1,
            col: -1,
            state: '',
            correctness: false,
            visible: false
        };
    },
    computed: {
        classObject () {
            const result = {};
                

            if (this.row !== -1 && this.col !== -1) {
                result["row-" + this.row] = true;
                result["col-" + this.col] = true;
                result.visible = this.visible;
            }

            switch (this.character) {
            case 'red-bird':
                result["character-red-bird"] = true;
                break;
            case 'purple-bird':
                result["character-purple-bird"] = true;
                break;
            case 'teal-bird':
                result["character-teal-bird"] = true;
                break;
            case 'yellow-bird':
                result["character-yellow-bird"] = true;
                break;
            }

            switch (this.state) {
            case 'icon':
                result["image-icon"] = true;
                break;
            case 'puck':
                result["image-puck"] = true;
                break;
            }
            
            if (this.mode === 'setup') {
                result['on-setup'] = true;
            }
            
            return result;
        },

        correctnessObject () {
            const result = {};

            result.correct = this.correctness;
            result["show-correctness"] = this.showCorrectness;
            //result["show-correctness"] = true;

            return result;
        }
    },
    mounted () {
        
    },
    methods: {
        setName (name) {
            this.name = name;
        },

        setCharacter (character) {
            this.character = character;
        },

        setPosition (positionData) {
            let cellPosition = 0;
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            /// HOW DO I DERIVE THE CELL NUMBER FROM THE REGION NUMBER!?!?!
            /// SET THE this.row and this.col values based on the results
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            //const numberOfCells = this.boardDimensions[0] * this.boardDimensions[1];
            
            this.region = positionData.region;
            this.rotation = positionData.rotation;

            cellPosition = this.region % 100;                                                              /////THIS IS A HACK
        
            this.row = Math.floor((cellPosition - 1) / this.boardDimensions[1]);
            this.col = (cellPosition - 1) % this.boardDimensions[1];
        },

        setState (state) {
            switch (state) {
            case 'puck':
            case 'icon':
                this.state = state;
                break;
            default:
                this.state = "";
            }
        },

        setCorrectness (isCorrect) {
            this.correctness = isCorrect;
        },

        setVisible (isVisible) {
            this.visible = isVisible;
        },

        reset () {
            this.position = 0;
            this.rotation = 0;
            this.state = '';
            this.correctness = false;
            this.visible = false;
        }
    }
};
</script>

<style scoped lang="scss">
    .piece {
        width: 10vw;
        height: 10vw;
        position: absolute;
        z-index: 1;
        display: none;

        &.row-0 {
            top: 0vw;
        }
        &.row-1 {
            top: 10vw;
        }
        &.row-2 {
            top: 20vw;
        }
        &.row-3 {
            top: 30vw;
        }

        &.col-0 {
            left: 0vw;
        }
        &.col-1 {
            left: 10vw;
        }
        &.col-2 {
            left: 20vw;
        }
        &.col-3 {
            left: 30vw;
        }

        &.visible {
            display: block;
        }

        &.character-red-bird {
            background-image: url('~@/assets/character/pucks/mtb_bird-pucks_red-bird.png');
        }
        &.character-purple-bird {
            background-image: url('~@/assets/character/pucks/mtb_bird-pucks_purple-bird.png');
        }
        &.character-teal-bird {
            background-image: url('~@/assets/character/pucks/mtb_bird-pucks_teal-bird.png')
        }
        &.character-yellow-bird {
            background-image: url('~@/assets/character/pucks/mtb_bird-pucks_yellow-bird.png')
        }
        &.image-icon {
            background-image: url(~@/assets/character/icons/mtb_bird-icons_red-bird.svg);
            background-position: 55% 36%;
            background-repeat: no-repeat;
            background-size: 70%;
                        
            &.character-teal-bird   { background-image: url(~@/assets/character/icons/mtb_bird-icons_teal-bird.svg); }
            &.character-purple-bird { background-image: url(~@/assets/character/icons/mtb_bird-icons_purple-bird.svg); }
            &.character-yellow-bird { background-image: url(~@/assets/character/icons/mtb_bird-icons_yellow-bird.svg); }
            
        }
        &.image-puck {
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            position: absolute;
            z-index: 1;
            transition: transform 0.3s;
            
            &.on-setup {
                margin-top: -1.5vw;
            }
            
            &.mini-puck {
                transform: scale(0.6) translate(15%, 0);
                transform-origin: top right;
            }
        }
        
        .turn-label {
            background: #1b204b;
            font-size: 0.625em;
            color: #fee888;
            padding: 0.5em 1em 0.5em 1em;
            border-radius: 1.5em;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            
            &.show-correctness {
                padding-right: 1.5em;
                
                 &.correct .correctness-mark {
                    background-image: url(~@/assets/character/icons/correct-icon_solo.svg);
                }
                
                .correctness-mark {
                    display: block;
                }

            }
            
            .correctness-mark {
                position: absolute;
                width: 2.5vw;
                height: 2.5vw;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 100%;
                top: 0;
                right: -1.5vw;
                margin-top: -0.25em;
                background-image: url(~@/assets/character/icons/wrong-icon_solo.svg);
                display: none;
            }
            
            .start-flag {
                position: absolute;
                width: 2.5vw;
                height: 2.5vw;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 100%;
                top: 0;
                right: -1.5vw;
                margin-top: -0.25em;
                background-image: url('~@/assets/gamemodes/practice/ui/mtb-ui_start-flag.svg')
                
            }
        }
    }

</style>