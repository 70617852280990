export default {
    "id": "sample-arrows",
    "components": [
        {
            "type": "RenderSprite",
            "spriteSheet": "sample-indicator",
            "animationMap": {
                "default": "mtb_music-mode_node-arrows"
            }
        }, {
            "type": "Tween"
        }],
    "properties": {
        x: 0,
        y: 0,
        z: 30,
        scaleX: 1,
        scaleY: 1
        
    }
};