import store from '../store/index';

const
    wait = (time) => new Promise((resolve) => setTimeout(resolve, time)),
    CANCEL_COUNTDOWN = 1000,
    CONFIRMATION_MODELS = ['automatic', 'first', 'leader', 'turns', 'all', 'poll'],
    ASYNC = -1, //ie, normal button that doesn't wait for others
    AUTOMATIC = 0,
    FIRST = 1,
    LEADER = 2,
    TURNS = 3,
    //ALL = 4, not used below, but is the default behavior
    POLL = 5;

class Decider {
    constructor ({
        cancellable = 0,
        decision = true,
        decisionId = 'default',
        type = 'all',
        onAgreement,
        onVote,
        milestone = false // determines whether decision should stay alive for entire session.
    }) {
        this.cancellable = cancellable === true ? CANCEL_COUNTDOWN : cancellable;
        this.clearAgreement = 0;
        this.model = CONFIRMATION_MODELS.indexOf(type);
        this.me = store.getters.getPlayerId;
        this.notMyDecision = this.me && !this.isInCharge();
        this.decision = decision;
        this.decisionId = decisionId;
        this.onDecision = null;
        this.resetting = false;
        this.destroyed = false;
        this.milestone = milestone;
        this.increment = milestone ? 'M' : 0;// needs to update to latest using reset path maybe

        this.awaitingAgreement = [];
        this.handleAgreement = onAgreement;
        this.onAgreement = this.onAgreement.bind(this);
        this.onVote = onVote;

        this.votes = {};
        this.myDecision = null;
        this.waiting = this.notMyDecision;

        this.initialize(); // Need async for picking next player for TURNS confirmation model
    }

    async initialize () {
        this.resetting = false;
        this.onDecision = null;
        this.awaitingAgreement.length = 0;
        if (this.handleAgreement) {
            this.awaitingAgreement.push(this.handleAgreement);
        }
        this.votes = {};
        this.myDecision = null;
        this.waiting = this.notMyDecision;
        this.voters = store.getters.getPlayers;
        if (!this.milestone) {
            this.increment = this.getCurrentIncrement(this.decisionId);
        }

        if (!this.voters) {
            // Not a real playthrough
            this.model = ASYNC;
            return;
        }

        if (this.model === TURNS) {
            console.warn('TURNS no longer supported.');
        }

        this.deciders = this.voters.filter((player) => this.isInCharge(player));
        this.votesNeeded = this.model === FIRST ? 1 : this.deciders.length;
        if (this.deciders.length === 0) {
            console.warn('No leader found. Waiting for a leader.');
            await wait(3000); // temp due to race after leader chosen
            this.deciders = this.voters.filter((player) => this.isInCharge(player));
            this.votesNeeded = this.model === FIRST ? 1 : this.deciders.length;
        }

        {
            const
                {cleanUp, onDecision} = this.gatherConsensus(`${this.decisionId}${this.increment}`, this.votes, this.deciders, this.votesNeeded, this.onAgreement, this.onVote, this.cancellable, this.model === POLL);

            this.cleanUp = cleanUp;
            this.onDecision = onDecision;
        }

        if (this.model === AUTOMATIC) {
            this.decide();
        }
    }

    onAgreement (...args) {
        this.awaitingAgreement.forEach((func) => {
            func(...args);
        });
        this.awaitingAgreement.length = 0;
    }

    async decide (decision = this.decision) {
        if (this.notMyDecision) {
            throw new Error('This is not my decision to make.');
        }

        if (this.destroyed) {
            throw new Error('This decision is already finished.');
        }

        if (this.resetting) {
            throw new Error('This decision is being reset.');
        }

        const
            model = this.model;

        this.myDecision = decision;
        this.waiting = true;

        // Act like a regular button if we don't have a valid confirmation model.
        if (model === ASYNC) {
            this.onAgreement(this.myDecision);
            return this.myDecision;
        }

        await this.sendDecision(`${this.decisionId}${this.increment}`, decision);

        return await this.allAgreed();
    }

    sendDecision (id, vote) {
        return this.sendDecisions({
            [id]: vote
        });
    }

    sendDecisions (decisions) {
        return store.dispatch('updateDecisions', decisions);
    }

    cancel () {
        if (this.notMyDecision) {
            throw new Error('This is not my decision to make.');
        }

        if (this.destroyed) {
            throw new Error('This decision is already finished.');
        }

        if (this.resetting) {
            throw new Error('This decision is being reset.');
        }

        if (!this.cancellable) {
            throw new Error('This decision is not cancellable.');
        }

        this.myDecision = null;
        this.waiting = false;
        clearTimeout(this.clearAgreement);

        return this.sendDecision(`${this.decisionId}${this.increment}`, null);
    }

    getCurrentIncrement (decisionId) {
        const
            decisions = store.getters.getDecisions?.[this.me];
        let increment = 0;

        if (decisions) {
            while (decisions[`un${decisionId}${increment}`]) {
                increment += 1;
            }
        }

        return increment;
    }

    gatherConsensus (decisionId, votes, deciders, votesNeeded, onAgreement, onVote, cancellable, poll = false) {
        const
            checkDecisions = () => {
                const
                    decisions = store.getters.getDecisions;
                let value = null,
                    matching = true,
                    changed = false,
                    count = 0;

                deciders.forEach((player) => {
                    const
                        vote = decisions?.[player]?.[decisionId] ?? null;

                    if (votes[player] !== vote) {
                        changed = true;
                        votes[player] = vote;
                    }

                    if (vote !== null) {
                        count += 1;
                        if (value === null) {
                            value = vote;
                        } else if (value !== vote) {
                            matching = false;
                        }
                    }
                });

                if (changed) {
                    if (cancellable) {
                        clearTimeout(this.clearAgreement);
                    }
                    if (onVote) {
                        onVote(votes);
                    }
                    if (count === votesNeeded) {
                        if (matching || poll) {
                            const
                                response = poll ? votes : value;

                            if (cancellable) {
                                this.clearAgreement = setTimeout(onAgreement.bind(null, response), cancellable);
                            } else {
                                onAgreement(response);
                            }
                        }
                    }
                }
            },
            unwatch = store.watch((state, getters) => getters.getDecisions, checkDecisions);

        // Get check outside of this run
        setTimeout(checkDecisions);

        return {
            onDecision: checkDecisions,
            cleanUp: unwatch
        };
    }

    isInCharge (player = this.me) {
        const
            getWhoIsInCharge = this.model === LEADER ? () => store.getters.getReferee : null;

        return !getWhoIsInCharge || (getWhoIsInCharge() === player);
    }

    allAgreed () {
        return new Promise((resolve) => {
            if (this.awaitingAgreement.length) { // still more waiting.
                this.awaitingAgreement.push(resolve);
            } else { // the wait is over
                resolve();
            }
        });
    }

    async destroy () {
        if (!this.destroyed && this.cleanUp) {
            this.destroyed = true;

            if (!this.milestone && store.getters.getActivePlaythrough) { // clean up
                await this.sendDecision(`un${this.decisionId}${this.increment}`, true);

                const
                    cleanUp = this.gatherConsensus(`un${this.decisionId}${this.increment}`, {}, this.voters, this.voters.length, async () => {
                        cleanUp();
                        this.cleanUp();
                        this.onDecision = null;
                    }).cleanUp;
            }
        }
    }

    reset ({decisionId, onReset} = {}) {
        const
            originalId = this.decisionId,
            originalIncrement = this.increment,
            decisionIdChange = decisionId && decisionId !== originalId;

        if (this.resetting) {
            throw new Error('This decision is already being reset.');
        }

        if (!decisionIdChange && this.milestone) {
            throw new Error('Milestones cannot be reset.');
        }

        this.resetting = true;

        if (decisionIdChange) {
            this.increment = 0;// updated by initialize below.
            this.decisionId = decisionId;
        } else {
            this.increment += 1;
        }

        return new Promise((resolve) => {
            clearTimeout(this.clearAgreement); // in case we're awaiting an outstanding cancellable agreement

            if (decisionIdChange) {
                this.cleanUp();
                this.initialize().then(() => {
                    if (onReset) {
                        onReset();
                    }
                    resolve();
                });
            } else {
                this.sendDecision(`un${originalId}${originalIncrement}`, true);

                const
                    cleanUp = this.gatherConsensus(`un${originalId}${originalIncrement}`, {}, this.voters, this.voters.length, async () => {
                        cleanUp();
                        this.cleanUp();

                        await this.initialize();

                        if (onReset) {
                            onReset();
                        }
                        resolve();
                    }).cleanUp;
            }
        });
    }
}

export default Decider;