import ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_1_PNG from '@/assets/gamemodes/practice/boards/solutions/solution_lvl-1.png';

export default {
    "board": {
        "dimensions": [
            3,
            4
        ],
        "art": null
    },
    "initialPositions": [
        {
            "region": 20007,
            "rotation": 0
        },
        {
            "region": 20010,
            "rotation": 0
        },
        {
            "region": 20002,
            "rotation": 0
        },
        {
            "region": 20004,
            "rotation": 0
        }
    ],
    "solutions": [
        [
            [
                20008,
                0
            ],
            [
                20011,
                0
            ],
            [
                20006,
                0
            ],
            [
                20003,
                0
            ]
        ],
        [
            [
                20012,
                0
            ],
            [
                20007,
                0
            ],
            [
                20010,
                0
            ],
            [
                20002,
                0
            ]
        ],
        [
            [
                20011,
                0
            ],
            [
                20003,
                0
            ],
            [
                20009,
                0
            ],
            [
                20006,
                0
            ]
        ]
    ],
    "clues": {
        "team": [
            ASSETS_GAMEMODES_PRACTICE_BOARDS_SOLUTIONS_SOLUTION_LVL_1_PNG
        ],
        "individual": [
            [
                "On Turn 1, move towards the couch.",
                "On Turn 2, Ziggy will be between you and Lee."
            ],
            [
                "On Turn 1 and 2, you will be on the purple rug.",
                "On Turn 2, move towards the fireplace."
            ],
            [
                "On Turn 1, you and Ziggy share a rug.",
                "On Turn 2, move away from the fireplace."
            ],
            [
                "On Turn 1, move towards the desk.",
                "On Turn 2, you will be in front of the fireplace."
            ]
        ]
    },
    "players": null
};