const
    practice = {
        state: () => ({
            puckPositionHistory: {
                starts: [],
                ends: []
            },
            puckPositionLocks: []
        }),

        actions: {
            initializePractice ({commit, state}) {
                return {
                    'practice.puckPositionLock': ({value, player}) => {
                        if (value) { // could be `null` on clearPractice.
                            const
                                ppl = state.puckPositionLocks.slice(),
                                index = value[1];

                            ppl[index] = {
                                ...state.puckPositionLocks[index],
                                [player]: value[0]
                            };

                            commit('setPuckPositionLocks', ppl);
                        }
                    },
                    'practice.puckPositionHistory': ({value}) => {
                        if (value) { // could be `null` on clearPractice.
                            commit('setPuckPositionHistory', value);
                        }
                    }
                };
            },

            updatePuckPositionLock ({dispatch, state}, puckPositionLock) {
                dispatch('proposeUpdate', {
                    'practice.puckPositionLock': [puckPositionLock, state.puckPositionHistory.ends.length]
                });
            },

            togglePuckPositionLock ({dispatch, rootState, state}, puckPositionLock) {
                const
                    locking = state.puckPositionLocks[state.puckPositionHistory.ends.length]?.[rootState.player.playerId]?.region ? null : puckPositionLock;

                dispatch('updatePuckPositionLock', locking);

                return locking;
            },

            setStartPositions ({dispatch, getters, rootState}, startPositions) {
                if (getters.getIsReferee) { // only need this from one player.
                    const
                        characters = rootState.characters.characters,
                        map = {};

                    characters.forEach((character, index) => {
                        map[character.player] = startPositions[index];
                    });

                    dispatch('proposeUpdate', {
                        'practice.puckPositionHistory': {
                            starts: [map],
                            ends: []
                        }
                    });
                }
            },

            savePositionLocks ({dispatch, getters}) {
                if (getters.getIsReferee) { // only need this from one player.
                    const
                        locks = getters.getPuckPositionLocks,
                        previousStarts = getters.getPuckPositionStarts,
                        previousEnds = getters.getPuckPositionEnds,
                        starts = (previousStarts.length === previousEnds.length) ? [
                            ...previousStarts,
                            locks
                        ] : previousStarts,
                        ends = [
                            ...previousEnds,
                            locks
                        ];

                    dispatch('proposeUpdate', {
                        'practice.puckPositionHistory': {
                            starts,
                            ends
                        }
                    });
                }
            },

            undoPositionLocks ({commit, dispatch, getters}, index) {
                commit('setPuckPositionLocks', []);
                if (getters.getIsReferee) { // only need this from one player.
                    dispatch('proposeUpdate', {
                        'practice.puckPositionHistory': {
                            starts: getters.getPuckPositionStarts.slice().splice(0, index),
                            ends: getters.getPuckPositionEnds.slice().splice(0, index - 1)
                        }
                    });
                }
            },

            clearPractice ({commit, dispatch}, allDone) {
                commit('setPuckPositionHistory', {
                    starts: [],
                    ends: []
                });
                commit('setPuckPositionLocks', []);
                if (allDone) {
                    dispatch('proposeUpdate', {
                        practice: {}
                    });
                }
            }
        },

        mutations: {
            setPuckPositionHistory (state, value) {
                state.puckPositionHistory = value;
            },
            
            setCurrentPuckPositionLocks (state, value) {
                state.puckPositionLocks[state.puckPositionHistory.ends.length] = value;
                //state.puckPositionLocks = [...state.puckPositionLocks];
            },

            setPuckPositionLocks (state, value) {
                state.puckPositionLocks = value;
            }
        },

        getters: {
            getPuckPositionLocks (state) {
                return state.puckPositionLocks[state.puckPositionHistory.ends.length];
            },

            getMyPuckPositionLock (state, rootState) {
                return state.puckPositionLocks[state.puckPositionHistory.ends.length]?.[rootState.player.playerId];
            },

            getPuckPositionEnds (state) {
                return state.puckPositionHistory.ends;
            },

            getPuckPositionStarts (state) {
                return state.puckPositionHistory.starts;
            },

            getPuckPositionLocksByCharacters (state, getters, rootState) {
                return rootState.characters.characters.map((character) => state.puckPositionLocks[state.puckPositionHistory.ends.length]?.[character.player] ?? {});
            },

            getPuckPositionHistoryByCharacters (state, getters, rootState) {
                const
                    characters = rootState.characters.characters;

                return {
                    starts: characters.map((character) => state.puckPositionHistory.starts.map((history) => history[character.player] ?? {})),
                    ends: characters.map((character) => state.puckPositionHistory.ends.map((history) => history[character.player] ?? {}))
                };
            },

            allPucksAreLocked (state, getters) {
                return getters.getPuckPositionLocksByCharacters.reduce((prev, lock) => prev && !!lock?.region, true);
            }
        }
    };

export {practice};