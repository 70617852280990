<template>
    <div class="replay-bar">
        <div class="log-container scrollable horizontal" ref="logContainer">
            <LogBar :theGame="theGame" :logChunks="logData.log" :selected="logData.selected" :showSelected="true" :feedback="logData.input" ></LogBar>
        </div>
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';
import LogBar from './LogBar.vue';

export default {
    name: 'ReplayBar',
    props: {
        theGame: Object,
        logData: Object
    },
    computed: {
        
    },
    data () {
        return {
        };
    },
    beforeMount () {
    },
    mounted () {
        
    },
    methods: {
        scrollToSelected (location) {
            this.$refs.logContainer.scrollLeft = location;
        }
    },
    watch: {
        
    },
    components: {LogBar}
};
</script>

<style scoped lang="scss">
    .replay-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 9vw;
        z-index: 1;
    }

   .log-container {
        position: absolute;
        bottom: 0;
        height: 9vw;
        background: #ffffff url(~@/assets/ui/mtb-ui_logo-grid-pattern.svg) top left repeat;
        box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.3);
        border: 0.4em solid #fff;
        border-bottom: none;
        border-radius: 1.5em 1.5em 0 0;
        width: 97%;
        left: 1.5%;
        
        &::-webkit-scrollbar {
            display: block;
            height: 0.75vw;
            margin-bottom: 1em;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(76, 86, 147, 0.3);
            border-radius: 2vw;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #4C5693;
            background: rgba(76, 86, 147, 1);
            cursor: pointer;
        }
    }

</style>