<template>
    <div id="landscape-modal">
        <img id="rotate-device-icon" src="~@/assets/ui/rotate-device.svg" alt="Please Turn Device" />
    </div>
</template>

<style lang="scss">
    #landscape-modal {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        display: none;
        z-index: 2147483647;
    }

    #landscape-modal h1 {
        display: block;
        margin: 29% auto;
        color: $mtb-white;
        font-size: 46px;
        text-align: center;
    }

    @keyframes turnandsnap {
        0% {
            transform: rotate(0deg);
        }

        75% {
            transform: rotate(-90deg);
        }

        94% {
            transform: rotate(4deg);
        }

        97% {
            transform: rotate(-4deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    #rotate-device-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        margin: -40% 0 0 -42.5%;

        animation: turnandsnap 5s infinite;
    }

</style>
