import ReplayBird from '../../components/ReplayBird';

export default {
    id: "replay-red-bird",
    components: [
        ReplayBird,
        {
            "type": "RenderSpine",
            "atlas": "red-bird-atlas",
            "skeleton": "red-bird-skeleton",
            "animationMap": {
                "playing-correctly-a": "whistle_1",
                "playing-correctly-b": "whistle_2",
                "playing-poorly": "frustrated",
                "success": "excited",
                "failure": "disappointed",
                "default": "idle"
            },
            skinMap: {
                band: 'band',
                default: 'original'
            }
        }, {
            type: "RenderSprite",
            spriteSheet: "performer-bubbles",
            "animationMap": {
                "playing-correctly-a": "good",
                "playing-correctly-b": "good",
                "playing-poorly": "bad",
                "default": "default"
            },
            localScaleX: -2,
            localScaleY: 2,
            offsetY: -900
        }, {
            "type": "RelayLinker",
            "linkId": "band",
            "events": {
            }
        }, {
            "type": "RelayLinker",
            "linkId": "stage",
            "events": {
            }
        }
    ],
    properties: {
        sampleAnimationMap: {
            "red-a": "playing-correctly-a",
            "red-b": "playing-correctly-b"
        },
        birdId: "red-bird",
        renderParent: "stage-container",
        scaleX: -0.5,
        scaleY: 0.5,
        x: 0,
        y: 0
    }
};