export default {
    "id": "loading-backdrop",
    "components": [{
        "type": "RenderText",
        "style": {
            "fontFamily": "Atma, sans-serif",
            "fontSize": "36px",
            "fill": "#2A4191",
            "align": "center",
            "verticalAlign": "center"
        },
        text: "Loading...",
        offsetX: 0,
        offsetY: 100
    }],
    "properties": {
        "x": 0,
        "y": 0,
        "z": 6.7
    }
};