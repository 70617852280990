<template>
    <div class="group-button-container"
         :class="[consensus.notMyDecision ? 'not-my-decision' : 'my-decision', { show: enabled }]"
         v-if="consensus"
    >
        <button
            v-if="!consensus.waiting"
            :class="buttonClasses"
            @click="vote"
            :disabled = "!enabled"
        >
            <em>{{title}}</em>
        </button>
        
        <p v-if="showInfo" id="info-area">
            {{waitingMessage}}
        </p>
    </div>
</template>

<script>
/**
 * Confirmation Models:
 * "automatic" - Automatic Check-in. We decide they're ready to move on, not them.
 * "first" - First tap gets it. But also allow backing out.
 * "leader" - Someone is in charge of all menus.
 * "turns" - Round-robin. Each player gets a turn.
 * "all" - Everyone makes a selection. Once there is a unanimous selection, advance.
 * "poll" - Everyone makes a selection, but they don't have to match.
 */
import Decider from '@/shared/Decider';
import {mapGetters} from 'vuex';

export default {
    name: 'GroupButton',
    components: {
    },
    props: {
        cancellable: {
            type: [Boolean, Number],
            default: 0
        },
        confirmationModel: {
            type: String,
            default: 'all'
        },
        decision: {
            type: [Boolean, String, Object],
            default: 'true'
        },
        decisionId: String,
        enabled: {
            type: Boolean,
            default: true
        },
        title: String,
        onAgreement: Function,
        decider: Object,
        milestone: Boolean,
        buttonClasses: {
            type: String,
            default: 'mtb-btn action right-arrow'
        },
        showInfo: {
            type: Boolean,
            default: false
        }
    },
    computed: mapGetters({
        referee: 'getReferee',
        isReferee: 'getIsReferee'
    }),
    data () {
        return {
            consensus: null,
            decisionMade: false,
            waitingMessage: 'Waiting on everyone.'
        };
    },
    async mounted () {
        this.consensus = this.decider || this.createConsensus();
    },
    methods: {
        vote () {
            if (this.enabled) {
                this.consensus.decide(this.decision);
                this.$emit('voted');
            }
        },
        createConsensus () {
            return new Decider({
                cancellable: this.cancellable,
                type: this.confirmationModel,
                decision: this.decision,
                decisionId: this.decisionId,
                onAgreement: (value) => {
                    this.consensus.destroy();
                    this.decisionMade = true;

                    // GroupButton only triggers passed-in onAgreement method if the decision matches the button (so multiple buttons can be different decisions for the same decision id).
                    if (this.onAgreement && value === this.decision) {
                        this.onAgreement(value);

                        // if we get a new leader, we still want to remember this decision.
                        if (this.milestone && this.confirmationModel === 'leader' && !this.isReferee) {
                            this.$store.dispatch('updateDecisions', {
                                [`${this.decisionId}M`]: value
                            });
                        }
                    }
                },
                onVote: (vote) => {
                    const
                        players = this.$store.getters.getActivePlaythrough.players,
                        waits = Object.keys(vote).filter((key) => vote[key] === null),
                        names = waits.map((key) => players.filter((player) => player.personId === key)[0].name);

                    if (waits.length > 1) {
                        this.waitingMessage = `Waiting on: ${names.join(', ')}...`;
                    } else if (waits.length === 1) {
                        if (waits[0] === this.consensus.me) {
                            this.waitingMessage = 'Waiting on you...';
                        } else {
                            this.waitingMessage = `Waiting on: ${names[0]}...`;
                        }
                    } else {
                        this.waitingMessage = "Let's go!";
                    }
                    this.$emit('voteUpdate', waits);
                },
                milestone: this.milestone
            });
        }
    },
    beforeUnmount () { // Needed to clean up server-side voting.
        if (this.consensus) {
            this.consensus.destroy();
        }
    },
    watch: {
        async referee (value) {
            if (!this.decisionMade && !this.decider && this.confirmationModel === 'leader') {
                if (this.consensus && !this.consensus.destroyed) {
                    await this.consensus.destroy();
                }
                if (value) { // new referee.
                    this.consensus = this.decider || this.createConsensus();
                }
            }
        },
        enabled (value) {
            if (!value && this.cancellable) {
                this.consensus.cancel();
            }
        },
        decisionId (value) {
            this.consensus.reset({
                decisionId: value
            });
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.group-button-container {
    position: absolute;
    top: 5.3em;
    right: 1.3em;
}

#info-area {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    font-size: 0.6em;
    line-height: 2em;
    text-align: center;
    pointer-events: none;
    background-color: #4C5693;
}

.not-my-decision button {
    display: none;
}
</style>
