<template>
    <AppLoader></AppLoader>
    <RotateDevice></RotateDevice>
    <Settings :class="currentRouteName" :development="development"></Settings>

    <main class="view-wrapper" :class="currentRouteName" @click="activateDevMode">
        <router-view></router-view>
    </main>
</template>

<script>
import AppLoader from "./components/AppLoader.vue";
import RotateDevice from "@/components/RotateDevice.vue";
import Settings from "@/components/Settings.vue";

const
    DEV_MIN_WAIT = 500,
    DEV_TAPS_TO_ACTIVATE = 8;
let count = 0,
    timeout = 0;

export default {
    name: 'App',
    data () {
        return {
            // eslint-disable-next-line no-process-env
            development: process.env.NODE_ENV === 'development',
        };
    },
    components: {
        AppLoader,
        RotateDevice,
        Settings
    },
    computed: {
        currentRouteName () {
            let route = this.$route.name || '';
            route = route.toLowerCase() + '-view';
            return route;
        }
    },
    async mounted () {
        await this.$store.dispatch('initializeAuthentication');
        await this.$store.dispatch('initializeReferee');
    },
    methods: {
        activateDevMode: function () {
            clearTimeout(timeout);
            count += 1;

            if (count >= DEV_TAPS_TO_ACTIVATE) {
                this.$connectionConsole.log('--- DEV MODE ACTIVATED ---');
                this.development = true;
                count = 0;
            } else {
                timeout = setTimeout(() => {
                    count = 0;
                }, DEV_MIN_WAIT);
            }
        }
    }
};

</script>

<style lang="scss">
    @import 'assets/styles/style';

</style>
