<template>
    <div class="log-entry feedback-nodes">
        <div class="owner" :class="determineOwner"></div>
        <div class="sample feedback-node" :class="determineSample" v-if="entryData.sample">{{sampleName}}</div>
        <div class="direction feedback-node" :class="determineDirection">{{directionName}}</div>
    </div>
</template>

<script>

//import {mapGetters} from 'vuex';

export default {
    name: 'LogEntry',
    props: {
        entryData: Object
    },
    computed: {
        determineOwner () {
            const result = {
            };
            result[this.entryData.character] = true;
            return result;
        },
        determineSample () {
            const result = {
            };
            result[this.entryData.sample] = true;
            return result;
        },
        determineDirection () {
            const result = {
            };
            result[this.entryData.direction] = true;
            return result;
        },
        sampleName () {
            return this.entryData.sample;
        },
        directionName () {
            return this.entryData.direction;
        }
    },
    data () {
        return {
        };
    },
    beforeMount () {
    },
    mounted () {
    },
    methods: {

    },
    watch: {
        
    }
};
</script>

<style scoped lang="scss">
    .log-entry {
        float: left;
        width: 15vw;
        height: 5vw
    }

    .owner {
        width: 5vw;
        height: 5vw;
        border-radius: 3vw;
        float: left;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        
        &.red-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_red-bird.svg);
        }
        &.purple-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_purple-bird.svg);
        }
        &.teal-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_teal-bird.svg);
        }
        &.yellow-bird{
            background-image: url(~@/assets/character/icons/mtb_bird-icons_yellow-bird.svg);
        }
    }

    .sample {
        width: 5vw;
        height: 5vw;
        float: left;
        background-color: transparent !important;
    }

    .direction {
        width: 5vw;
        height: 5vw;
        float: left;
    }

</style>