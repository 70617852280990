import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'ReplayBird',
    
   
    properties: {
        birdId: null,
        sampleAnimationMap: null
    },
    
   
    publicProperties: {
        
    },
    
    initialize: function (/*definition, callback*/) {
        this.owner.state.set("playing-correctly-a", false);
        this.owner.state.set("playing-correctly-b", false);
        this.owner.state.set("playing-poorly", false);
        this.owner.state.set("success", false);
        this.owner.state.set("failure", false);

        this.respondToFeedback = false;

        this.wasSuccess = false;
    },

    events: {
        "setup-birds": function (wasSuccess) {
            this.respondToFeedback = true;
            this.clearState();
            this.wasSuccess = wasSuccess;
        },
        "hide-stage": function () {
            this.respondToFeedback = false;
            this.clearState();
        },
        // "band-feedback": function (isGood, whoIsPlaying) {
        //     let bird = null,
        //         amPlaying = false;

        //     if (!this.respondToFeedback) {
        //         return;
        //     }
            
        //     for (bird in whoIsPlaying) {
        //         if (bird === this.birdId && whoIsPlaying[bird]) {
        //             amPlaying = true;
        //             break;
        //         }
        //     }

        //     this.clearState();
        //     if (amPlaying) {
        //         if (isGood) {
        //             this.owner.state.set("playing-correctly-a", true);
        //         } else {
        //             this.owner.state.set("playing-poorly", true);
        //         }
        //     }
        // },
        "update-replay-bird": function (who, birdState) {
            let playAnimation = null;

            if (who !== this.owner.birdId) {
                return;
            }

            if (!this.respondToFeedback) {
                return;
            }

            this.clearState();
            if (birdState.isPlaying) {
                if (birdState.correctness) {
                    playAnimation = this.sampleAnimationMap[birdState.sample];
                    this.owner.state.set(playAnimation, true);
                } else {
                    this.owner.state.set("playing-poorly", true);
                }

            }
        },
        "finished-song": function () {
            this.clearState();
            if (this.wasSuccess) {
                this.owner.state.set("success", true);
            } else {
                this.owner.state.set("failure", true);
            }
        }
        
    },
    
    methods: {
        clearState: function () {
            let sample = null;
            // this.owner.state.set("playing-correctly-a", false);
            // this.owner.state.set("playing-correctly-b", false);
            this.owner.state.set("playing-poorly", false);
            this.owner.state.set("success", false);
            this.owner.state.set("failure", false);

            // eslint-disable-next-line guard-for-in
            for (sample in this.sampleAnimationMap) {
                this.owner.state.set(this.sampleAnimationMap[sample], false);
            }
        }
        
    },
    
    publicMethods: {
        
        
    }
});
