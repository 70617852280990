<template>
    <section :class="[{'is-leader': isDecisionLead}, modeClass, stateClass]" class="continue-playthrough-container">
        <header v-if="isDecisionLead" class="leader-bar">
            <h2>You Have The Controller.</h2> 
            <button class="inline-info-btn" title="What does this mean?">&#x24D8;</button>
        </header>
        <div v-if="!quickPlay && !starting" class="continue-bar">
            <GroupButton
                :buttonClasses="'mtb-btn action top-nav left'"
                confirmationModel="leader"
                :onAgreement="onQuit"
                title="Save & Quit"
                :milestone="true"
                :decisionId="`minigame-${progress}`"
                decision="quit"
            />
        </div>
        <GroupButton
            confirmationModel="leader"
            v-if="quickPlay && !starting"
            :onAgreement="onQuit"
            title="Save & Quit"
            decisionId="next-minigame"
            decision="quit"
        />
        <aside v-if="quickPlay && !starting" class="quick-play-menu">
            <h1>Keep Playing:</h1>
            <QuickPlayGameChooser :onChoiceMade="onContinue" confirmationModel="leader"></QuickPlayGameChooser>
        </aside>
        <aside v-if="!quickPlay" class="story-progress scrollable">
            <h3>Story Mode Progress</h3>
            <ol>
                <li v-for="(chapter, index) in chapters" :key="index" :class="chapter.className">
                    <b>{{chapter.chapter || 'Intro'}}</b>
                    <span class="state-icon locked" v-if="chapter.locked && !chapter.next">locked</span>
                    <span class="state-icon completed" v-if="chapter.completed">completed</span>
                    <span class="state-icon next"  v-if="chapter.next">next up</span>
                </li>
            </ol>
        </aside>
        <article v-if="minigame" class="centerpiece">
            <h2 class="header-blob" v-if="minigame">
                <i v-if="quickPlay">Quick Play</i>
                <em>Chapter {{minigame.chapter}}: {{minigame.title}}</em>
            </h2>
            <img class="thumbnail" :src="minigame.thumbnail" :alt="minigame.title" />
            <ul class="tracks" v-if="minigame.myChapter">
                <li 
                    v-for="(game, index) in minigame.myChapter" 
                    :key="index" 
                    class="track"
                    :class="[game.mode, game.className]" 
                >
                    <em>
                        Part {{game.part}}:
                        <b>{{game.mode}}</b>
                    </em>
                    <span class="state-icon locked" v-if="game.locked">Locked</span>
                    <span class="state-icon completed" v-if="game.completed">Completed</span>
                    
                    
                    <span class="state-icon lets-go" v-if="game.current"></span>
                    <GroupButton
                        confirmationModel="leader"
                        v-if="game.current"
                        :onAgreement="onContinue"
                        title="Play!"
                        :milestone="true"
                        :decisionId="`minigame-${progress}`"
                        decision="continue"
                    />
                </li>
            </ul>
        </article>
    </section>

    <Transition name="letsgo">
        <div v-if="showLetsGo" class="action-overlay lets-go">
            <h2>Let's Go!</h2>
            <img src="~@/assets/character/mtb_characters_lets-go.svg" />
        </div>
    </Transition>
</template>

<script>
import GroupButton from '@/components/GroupButton.vue';
import QuickPlayGameChooser from '@/components/QuickPlayGameChooser.vue';
import dpeService from '@/shared/dpeService';
import {mapGetters} from 'vuex';
import story from '@/shared/data/story';

export default {
    name: 'ContinuePlaythrough',
    components: {
        GroupButton,
        QuickPlayGameChooser
    },
    data () {
        const
            activePlaythrough = this.$store.getters.getActivePlaythrough,
            lastPlayedState = activePlaythrough.lastPlayedState;

        return {
            starting: this.$route.query.entrance === 'start',
            showLetsGo: false,
            story,
            quickPlay: lastPlayedState?.quickPlay || false
        };
    },
    computed: {
        ...mapGetters({
            isDecisionLead: 'getIsReferee',
            progress: 'getProgress'
        }),
        minigame () {
            const
                me = this.story[this.progress],
                myChapter = me ? this.story.filter((value) => value.chapter === me.chapter).map((value, index) => {
                    const
                        locked = value.order > me.order,
                        current = value === me,
                        completed = value.order < me.order;

                    return {
                        ...value,
                        part: index + 1,
                        current,
                        locked,
                        completed,
                        className: `${completed ? 'completed' : locked ? 'locked' : 'current'}`
                    };
                }).filter((minigame) => !this.quickPlay || (minigame.order === me.order)) : [];

            return me ? {
                ...me,
                myChapter, // v-- below isn't correct once a chapter is complete... need way to specify finished chapter
                completed: myChapter.reduce((prev, value) => prev && value.completed, true)
            } : null;
        },
        chapters () {
            const
                story = this.story,
                currentChapter = +(story.filter((minigame) => +minigame.order === +this.progress)[0]?.chapter);

            return story.filter((minigame, index, array) => minigame.chapter !== array[index - 1]?.chapter).map((minigame) => {
                const
                    chapter = +minigame.chapter,
                    completed = chapter < currentChapter,
                    locked = chapter > currentChapter,
                    next = locked && (chapter === (currentChapter + 1)) && !this.starting;

                return {
                    chapter,
                    completed,
                    locked,
                    next,
                    className: `${completed ? 'completed' : next ? 'next-up' : locked ? 'locked' : 'current'} ${chapter ? 'number' : 'word'}`
                };
            });
        },
        modeClass () {
            return this.quickPlay ? 'quick-play' : 'story-mode';
        },
        stateClass () {
            return this.starting ? 'is-starting' : 'is-continuing';
        }
    },
    async mounted () {
        this.$store.dispatch('appReady');
    },
    beforeUnmount () { // Needed to clean up server-side voting.
    },
    methods: {
        onContinue (value) {
            this.showLetsGo = true;
            
            if (!this.starting && this.quickPlay) { // chose next game
                this.$store.dispatch('stampProgress', value);
            }

            dpeService.updateLocalState('progression', `began-minigame-${this.progress}`); // potential analytics data
            
            setTimeout(() => {
                this.showLetsGo = false;
                this.$router.push({
                    name: 'Play'
                });

            }, 3000);
        },
        async onQuit () {
            await this.$store.dispatch('leaveGame', this.isDecisionLead);
            this.$router.push({
                name: 'Start'
            });
        }
    },
    watch: {
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.continue-playthrough-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0A0A0A url('~@/assets/ui/mtb-ui_global-background.png') 50% 50% no-repeat;
    background-size: cover;
    z-index: 2;
    
    &.is-leader {
        top: 3vw;
    }
    
    &.is-continuing {
        &.is-leader {
            top: 6vw;
        }
    }

    .continue-bar {
        height: 4em;
    }

    .continue-bar :deep .group-button-container {
        position: absolute;
        top: -6vw;
        right: initial;
        left: 0;
        z-index: 3;
    
        .mtb-btn {
            width: max-content !important;
        }
    }

    .story-progress {
        width: 10vw;
        text-align: center;
        font-size: 2em;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        
        &:after {
            content: "";
            display: block;
            position: fixed;
            z-index: -1;
            top: 0;
            bottom: 0;
            width: 1vw;
            left: 4.5vw;
            background: #1B204B;
        }

        h3 {
            font-size: 0.375em;
            font-weight: bold;
            line-height: 1.2;
            margin: 1em 0.25em;
            padding: 1em;
            border-radius: 45% 1.25em 2.75em 2em;
            color: #FFE888;
            background: #1B204B;
            font-weight: bold;
            display: block;
        }

        ol {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        li {
            width: 2em;
            height: 2em;
            line-height: 1.6em;
            background: #4C5693;
            border: 0.15em solid #2A2F5A;
            border-radius: 50%;
            color: #1B204B;
            font-weight: bold;
            margin: 0.5em auto;
            position: relative;

            .state-icon {
                position: absolute;
                bottom: -0.4em;
                right: -0.4em;
                background-color: #1B204B;
                text-indent: -999em;
                width: 3vw;
                height: 3vw;
                border-radius: 100%;
                
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 2.5vw;
                    height: 2.5vw;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: url('~@/assets/ui/mtb-ui_lock-icon.svg') 50% 50% no-repeat;  
                }
                
                &.completed {
                    &:after {
                        width: 3.1vw;
                        height: 3.1vw;
                        top: -0.3vw;
                        left: 0.25vw;
                        transform: none;
                        background: url('~@/assets/ui/mtb-ui_check-icon.svg') 50% 50% no-repeat;  
                    }
                }
            }
        }

        li.next-up {
            span {
                font-size: 0.33em;
                text-transform: uppercase;
                bottom: -1.2em;
                background: #1B204B;
                color: #FFE888;
                line-height: 1.6em;
                border-radius: 0.9em;
                text-indent: 0;
                display: block;
                width: max-content;
                height: auto;
                padding: 0.33em 0.66em;
                left: 50%;
                right: auto;
                transform: translateX(-50%);
                
                &:after {
                    display: none;
                }
            }
        }

        li.current {
            background: #FFE888;
        }

        li.completed {
            color: #FFE888;
            background: #1B204B;
        }

        li.word {
            width: 2em;
            height: 1.6em;
            border-radius: 0.5em;
            line-height: 1em;

            b {
                font-size: 0.6em;
            }
            
            span {
                bottom: -0.6em;
                right: -0.6em;
            }
        }
    }

    .centerpiece {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75vw;
        transform: translate(-46%, -56%);
        pointer-events: none;
        
        @media (min-aspect-ratio: 7/3) {
            top: 0;
            transform: translate(-46%, 0);
        }
        
        .header-blob {
            margin: 1em 0;
        }

        img {
            width: 60%;
            border: 0.8em solid $mtb-white;
            border-radius: 0.4em;
        }

        .tracks {
            position: absolute;
            top: 40%;
            left: 59.5%;
            width: 38%;
            z-index: -1;
            pointer-events: all;

            .track {
                padding: 1em 2em 1em 5.25em;
                margin: 2em 0;
                background: #1B204B;
                display: block;
                border-radius: 0 2em 2em 0;
                position: relative;
                color: #4C5693;
                font-size: 0.75em;
                width: max-content;
                
                b {
                    display: block;
                    font-size: 1.675em;
                    font-weight: bold;
                }
                
                &:before {
                    content: "";
                    display: block;
                    width: 4em;
                    height: 2.6666em;
                    position: absolute;
                    left: 1em;
                    top: 1em;
                    
                    background-image: url('~@/assets/ui/mtb-ui_game-type-icons_mission.svg'); 
                    background-repeat: no-repeat;
                    background-position: 50% 0;
                    background-size: cover;
                }

                &.current {
                    background: #FFE888;
                    color: #1B204B;
                    
                    &:before {
                        background-position: 50% 50%;
                    }
                    
                    .state-icon {
                        border-radius: 0;
                        background: url(' ~@/assets/character/mtb_characters_lets-go.svg') 50% 50% no-repeat;
                        width: 12vw;
                        height: 12vw;
                        right: -95%;
                        top: 18%;
                        z-index: 1;
                        
                        &:after {
                            display: none;
                        }
                       
                    }
                    
                    :deep .group-button-container {
                        top: 4.5em;
                        right: -84%;
                        width: max-content;
                        z-index: 2;
                    }
                    
                    &.performance {
                        .state-icon {
                           right: -75%; 
                        }
                        
                        :deep .group-button-container {
                            right: -65%;
                        }  
                    }
                }
                
                &.practice {
                    &:before {
                        background-image: url('~@/assets/ui/mtb-ui_game-type-icons_practice.svg'); 
                    }
                }
                
                &.performance {
                    &:before {
                        background-image: url('~@/assets/ui/mtb-ui_game-type-icons_performance.svg'); 
                    }
                }

                .state-icon {
                    position: absolute;
                    top: 50%;
                    right: -40%;
                    background-color: #1B204B;
                    text-indent: -999em;
                    width: 4.5vw;
                    height: 4.5vw;
                    border-radius: 100%;
                    transform: translateY(-50%);

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 4vw;
                        height: 4vw;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: url('~@/assets/ui/mtb-ui_lock-icon.svg') 50% 50% no-repeat;  
                    }

                    &.completed {
                        &:after {
                            width: 4.75vw;
                            height: 4.75vw;
                            top: -0.5vw;
                            left: 0.4vw;
                            transform: none;
                            background: url('~@/assets/ui/mtb-ui_check-icon.svg') 50% 50% no-repeat;  
                        }
                    }
                }
                
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: -1;
                    top: 50%;
                    right: -30%;
                    left: 0;
                    height: 0.75vw;
                    background: #1B204B;
                    transform: translateY(-50%);
                }

            }
        }
    }

    &.quick-play {
        .centerpiece {
            ul {
                position: relative;

                li.current {
                    display: block;
                    background: #1B204B;
                    border-radius: 2em;
                    position: relative;
                    color: #4C5693;
                }
            }
        }
    }
}

aside.quick-play-menu {
    background: #4C5693;
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    text-align: left;
    z-index: 1;
    font-size: 2em;

    h1 {
        font-size: 0.6em;
        line-height: 3em;
        background: #37417C;
        padding: 0 2em 0 0.5em;
    }
}
    
.action-overlay {
    display: block;
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.7);
    backdrop-filter: blur(20px);


    img {
        width: 50vw;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    h2 {
        pointer-events: none;
        text-indent: -999em;
    }
}
</style>
