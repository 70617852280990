export default {
    id: "interface-layer",
    components: [{
        type: "HandlerController"
    }, {
        type: "HandlerLogic",
        alwaysOn: true
    }, {
        type: "HandlerRender"
    }, {
        type: "Camera",
        width: 1024,
        height: 768,
        overflow: true,
        z: 2
    }, {
        type: "EntityContainer",
        entities: [
            {type: 'stage-container', properties: {}},
            {type: 'stage', properties: {}},
            {type: 'replay-red-bird', properties: {'x': -450, 'y': 250}},
            {type: 'replay-purple-bird', properties: {'x': -150, 'y': 250}},
            {type: 'replay-teal-bird', properties: {'x': 150, 'y': 250}},
            {type: 'replay-yellow-bird', properties: {'x': 450, 'y': 250}},
            {type: 'success-meter', properties: {}},
            {type: 'success-meter-back', properties: {}},
            {type: 'success-meter-bar', properties: {}}
        ],
        childEvents: ["unload-layer", "layer-loaded", "layer-live"]
    }],
    properties: {
       
    }
};