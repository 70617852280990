import {characters} from './modules/characters';
import {comics} from './modules/comics';
import {createStore} from 'vuex';
import {decisions} from './modules/decisions';
import dpeService from "@/shared/dpeService";
import {logs} from './modules/logs';
import {mission} from './modules/mission';
import {player} from './modules/player';
import {practice} from './modules/practice';
import {puck} from './modules/puck';
import {referee} from './modules/referee';
import router from '@/router/';

let leaveEvent = null;

export default createStore({
    state: {
        isStarting: false,
        busy: false,
        unhandledCancel: false,
        boardCode: '',
        players: null,
        playthrough: null,
        playthroughConfiguration: null, // Used in initial set-up phases to compose the type of playthrough to start: QP, saved, etc.
        progress: 0
    },
    getters: {
        getIsStarting (state) {
            return state.isStarting;
        },
        getBusy (state) {
            return state.busy;
        },
        getUnhandledCancel (state) {
            return state.unhandledCancel;
        },
        getBoardCode (state) {
            return state.boardCode;
        },
        getPlaythroughConfiguration (state) {
            return state.playthroughConfiguration;
        },
        getActivePlaythrough (state) {
            return state.playthrough;
        },
        getPlayerInfo (state) {
            const
                characters = state.characters.characters,
                mine = characters.filter((value) => value.player === state.player.playerId)[0];

            return {
                ...state.player.player, // includes player info atm.
                ...mine
            };
        },

        // Typically you want to use "getters.getCharacters". This is primarily used by the Decider due to the character selection scene not having characters assigned to a particular player.
        getPlayers (state) {
            return state.players;
        },

        getProgress (state) { // Teacher configuration of progress overrides student progression.
            return state.progress;
        }
    },
    mutations: {
        setIsStartingValue (state, value) {
            state.isStarting = value;
        },
        setBusy (state, value) {
            state.busy = value;
        },
        setUnhandledCancel (state, value) {
            state.unhandledCancel = value;
        },
        setBoardCode (state, value) {
            state.boardCode = value;
        },
        setPlayers (state, value) {
            state.players = value;
        },
        setPlaythroughConfiguration (state, value) {
            state.playthroughConfiguration = value;
        },
        setActivePlaythrough (state, value) {
            state.playthrough = value;
        },
        setProgress (state, value) {
            state.progress = value;
        }
    },
    actions: {
        appReady ({commit}, cancelled = false) {
            commit('setIsStartingValue', false);
            commit('setBusy', false);
            commit('setUnhandledCancel', cancelled);
        },
        appStart ({commit, dispatch}) {
            commit('setIsStartingValue', true);
            setTimeout(() => {
                dispatch('appReady');
                router.push({
                    name: 'Start'
                });
            }, 5000);
        },
        appBusy ({commit}, isBusy = true) {
            commit('setUnhandledCancel', false);
            commit('setBusy', isBusy);
        },

        async joinGame ({commit, dispatch, state}, {boardCode, cancel, peerService}) {
            const
                config = state.playthroughConfiguration,
                gameData = await dpeService.joinGame({
                    boardCode,
                    me: state.player.playerId,
                    playthrough: config,
                    cancel
                });

            // get full playthrough data if we can. If we can't, refresh list.
            let playthrough = state.player.playthroughs.filter((playthrough) => playthrough.playthroughId === gameData.playthroughId)[0];

            if (!playthrough) {
                console.log('Reloading playthrough list.');
                await dispatch('loadPlaythroughs');
                playthrough = state.player.playthroughs.filter((playthrough) => playthrough.playthroughId === gameData.playthroughId)[0];
                if (!playthrough) {
                    throw new Error(`Playthrough "${gameData.playthroughId}" not found!`);
                } else {
                    console.log('Acquired newly-created playthrough.');
                }
            }

            dpeService.currentPlaythrough = playthrough; // May need to find a better way to handle this, but need to do it here since new games don't start with an existing playthrough.

            if (config?.quickPlay) {
                // probably happening 4 times
                await dpeService.playthroughs.setLastPlayedState(gameData.playthroughId, config);
                playthrough.lastPlayedState = config;
            }

            console.log('room joined', dpeService.state);

            dispatch('clearGameLog');
            commit('setBoardCode', boardCode);
            commit('setActivePlaythrough', playthrough);
            commit('setProgress', playthrough.config?.progress ?? playthrough.lastPlayedState?.progress ?? 0);
            commit('setPlayers', gameData.players);

            // Restores local state if client has disconnected and is reloading game.
            await dispatch('restoreLastPlayedState');
            await dispatch('startReferee', {peerService});

            // to capture browser shutdown / tab close.
            leaveEvent = () => {
                dispatch('leaveGame');
            };
            window.addEventListener('beforeunload', leaveEvent);

            return gameData;
        },

        stampProgress ({commit, getters}, progress) {
            commit('setProgress', progress);
            if (getters.getIsReferee) {
                dpeService.updateLastPlayedState('progress', progress);
            } else if (dpeService.currentPlaythrough) { // update locally instead of reloading all playthroughs
                dpeService.currentPlaythrough.lastPlayedState = dpeService.currentPlaythrough.lastPlayedState ?? {};
                dpeService.currentPlaythrough.lastPlayedState.progress = progress;
            }
        },

        leaveGame ({commit, dispatch}, allDone = false) {
            if (leaveEvent) {
                window.removeEventListener('beforeunload', leaveEvent);
                leaveEvent = null;
            }

            if (dpeService.currentPlaythrough) {
                dpeService.leaveGame(allDone);
                dispatch('stopReferee');

                commit('setPlayers', null);
                dispatch('clearCharacters');
                dispatch('clearDecisions');

                dispatch('clearMission', false);
                dispatch('clearPractice', false);
                //dispatch('clearPerformance');

                dispatch('clientsReady'); // in case missing screen is showing.
            }
        }
    },
    modules: {
        characters,
        comics,
        decisions,
        logs,
        mission,
        player,
        practice,
        puck,
        referee
    }
});
