<template>
    <article v-if="scene !== null" class="dialogue-box">
        <div :class="showStageLeft" class="stage left">
            <h3 class="label">{{leftLabel}}</h3>
            <figure :class="leftFace" class="face">
                <img :src="leftFaceAsset" />
            </figure>
            <p class="dialogue">{{leftDialogue}}</p>
        </div>
        <div :class="showStageRight" class="stage right">
            <h3 class="label">{{rightLabel}}</h3>
            <figure :class="rightFace" class="face">
                <img :src="rightFaceAsset" />
            </figure>
            <p class="dialogue">{{rightDialogue}}</p>
        </div>
        
        <button class="mtb-btn action next-button" @click="advanceScene()">{{advanceButtonText}}</button>
        
        <p class="counter">{{(sequenceIndex + 1) + " of " +  scene.sequence.length}}</p>
    </article>
</template>

<script>
/* eslint-disable */  
import * as PIXI from 'pixi.js';
import {
    TextureAtlas,
    Spine
} from "pixi-spine";
import {
    AtlasAttachmentLoader,
    SkeletonJson
} from "@pixi-spine/runtime-3.8";
    
import headfaces from '@/shared/data/headfaces';


export default {
    name: 'CutscenePlayer',
    props: {
        theGame: Object,
        vueData: Object
    },
    computed: {
        showStageLeft () {
            const result = {};

            if (this.scene && this.scene.sequence && this.scene.sequence[this.sequenceIndex].who === this.scene.stageLeft) {
                result.show = true;
            }

            return result;
        },
        showStageRight () {
            const result = {};

            if (this.scene && this.scene.sequence && this.scene.sequence[this.sequenceIndex].who === this.scene.stageRight) {
                result.show = true;
            }
            
            return result;
        }
    },
    data () {
        return {
            sequenceIndex: 0,
            scene: null,
            showLeft: false,
            showRight: false,
            leftLabel: "",
            rightLabel: "",
            leftDialogue: "",
            rightDialogue: "",
            advanceButtonText: "Next",
            leftFace: null,
            rightFace: null,
            leftFaceAsset: null,
            rightFaceAsset: null,
        };
    },
    beforeMount () {
        this.initializeScene();
    },
    mounted () {
        this.makeCharacters(this.scene.stageLeft, "left");
        this.makeCharacters(this.scene.stageRight, "right");
        
        //for when the app reloads
        this.$parent.$refs.characterbar.hideBar();
        this.$store.dispatch('appReady');
    },
    beforeUnmount() {
        this.$store.dispatch('appBusy', {message: 'Leaving Location...'});
        
        if (this.leftRig) {
            this.leftRig.destroy(true);
        }
        if (this.rightRig) {
            this.rightRig.destroy(true); 
        } 
    },
    watch: {
    },
    methods: {
        initializeScene () {
            console.log('Initializing Scene');
            this.$parent.$refs.characterbar.hideBar();
            
            this.sequenceIndex = -1;
            this.advanceButtonText = "Next";
            this.scene = this.vueData.scene.sceneToPlay;
            console.log('Scene is for: ' + this.scene.for);
            this.advanceScene();
        },
        advanceScene () {
            let sequenceMoment = null;
            this.sequenceIndex += 1;

            console.log('Attempting to advance scene');

            if (this.sequenceIndex === this.scene.sequence.length) {
                //Close the scene, we're done!
                //Send a message to the game to let it know!

                this.theGame.completeReward();
                this.scene = null;
            } else {

                if (this.sequenceIndex === this.scene.sequence.length - 1) {
                    //Last part of the sequence
                    this.advanceButtonText = "Done";
                }
                //Ready the next dialogue
                sequenceMoment = this.scene.sequence[this.sequenceIndex];

                if (sequenceMoment.who === this.scene.stageLeft) {
                    this.leftLabel = this.vueData.nameMap[sequenceMoment.who] || sequenceMoment.who;
                    this.leftDialogue = sequenceMoment.text;
                    this.leftFace = {};
                    this.leftFace[sequenceMoment.who] = true;

                    this.showLeft = true;
                    this.showRight = false;
                } else {
                    this.rightLabel = this.vueData.nameMap[sequenceMoment.who] || sequenceMoment.who;
                    this.rightDialogue = sequenceMoment.text;
                    this.rightFace = {};
                    this.rightFace[sequenceMoment.who] = true;
                    
                    this.showLeft = false;
                    this.showRight = true;
                }
            }

            console.log('Advanced the scene +');
            
        },
        makeCharacters(character, side) {
            let outfit = "default";
                  
            if (this.$store.getters.getPlayerInfo === character) {
                 outfit = this.$store.getters.getPlayerInfo.outfit != "" ? outfit : this.$store.getters.getPlayerInfo.outfit;  
            }
            
            if (side === 'left') {
                this.leftFaceAsset = headfaces[character][outfit];
                console.log("left face is: " + headfaces[character][outfit]);
            } else {
                this.rightFaceAsset = headfaces[character][outfit]; 
                console.log("right face is: " + headfaces[character][outfit]);
            }
        } 
    }
};
</script>

<style scoped lang="scss">
    .dialogue-box {
        width: 100%;
        height: 12vw;
        position: absolute;
        bottom: 0;
        left: 0;
        background: rgb(21,23,40);
        background: linear-gradient(90deg, rgba(21,23,40,1) 0%, rgba(21,23,40,1) 60%,rgba(21,23,40,0.6) 80%, rgba(21,23,40,0) 100%);
        z-index: 100;
    }

    .mtb-btn.action.next-button {
        position: absolute;
        right: 0;
        bottom: 1vw;
        text-align: center;
        line-height: 1.5em;
        width: 12vw;
        border-radius: 1.5em 0 0 1.5em;
        border-right-width: 0;
    }

    .counter {
        position: absolute;
        right: 1em;
        top: 1em;
        padding: 0.5em 1em;
        background-color: $mtb-ui-dark-purp;
        color: $mtb-ui-yellow;
        text-align: center;
        border-radius: 1.5em;
    }
    
    .stage {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        
        &.show {
            display: block;
        }

        &.left {

            .face{
                left: 1vw;
                transform: scaleX(-1);
            }

            .label {
                left: 29vw;
            }

            .dialogue {
                left: 29vw;
            }

        }

        &.right {
            
            .face{
                right: 13vw;
            }

            .label {
                right: 41vw;
                padding: 1em 4em 1em 1.5em;
                transform: translate(4em, -56%) rotate(2deg);
            }

            .dialogue {
                right: 41vw;
            }
        }
        
        .face {
            width: 28vw;
            position: absolute;
            top: -11vw;
            bottom: 0;
            
            img {
                width: 80%;
                overflow: hidden;
                position: absolute;
                bottom: 0;
            }
        }

        .label {
            position: absolute;
            top: 0;
            background-color: $mtb-ui-dark-purp;
            color: $mtb-ui-yellow;
            text-align: center;
            padding: 1em 1.5em 1em 4em;
            transform: translate(-4em, -56%) rotate(-2deg);
            font-size: 1.4em;
            font-weight: bold;

        }
        
        .dialogue {
            width: 45vw;
            height: 70%;
            position: absolute;
            bottom: 0;
            color: #f4f4f4;
            line-height: 1.4em;

        }
    }
</style>