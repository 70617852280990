import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'SuccessMeter',
    
   
    properties: {
        
    },
    
   
    publicProperties: {
        
    },
    
    initialize: function (/*definition, callback*/) {
        this.bar = null;
        this.barLength = 0;
        this.barMaxActualLength = 239;

        this.owner.state.set('very-good', false);
        this.owner.state.set('good', false);
        this.owner.state.set('bad', false);
        this.owner.state.set('very-bad', false);

        this.cameraHalfWidth = 0;
        this.centered = false;
        this.centerOffset = 102;
    },

    events: {
        "peer-entity-added": function (entity) {
            if (entity.type === 'success-meter-bar') {
                this.bar = entity;
            }

        },
        "camera-update": function (cameraData) {
            this.cameraHalfWidth = cameraData.viewport.width / 2;

            if (this.centered) {
                this.owner.right = this.cameraHalfWidth + this.centerOffset;
            }
        },

        "handle-logic": function () {
            
        },
        "reset-meter": function () {
            this.owner.triggerEvent('set-meter', 0, 'very-bad');
        },
        "set-meter": function (newLength, successLevel) {
            //Length is a value between 0 and 1
            this.barLength = newLength;
            this.bar.scaleX = this.barLength * this.barMaxActualLength;

            this.owner.state.set('very-good', false);
            this.owner.state.set('good', false);
            this.owner.state.set('bad', false);
            this.owner.state.set('very-bad', false);

            this.owner.state.set(successLevel, true);
        },
        "hide-meter": function () {
            this.owner.opacity = 0;
        },
        "show-meter": function (position) {
            this.owner.opacity = 1;
            if (position === 'middle') {
                this.centered = true;
                this.owner.right = this.cameraHalfWidth + this.centerOffset;
                this.owner.top = 100;
            } else {
                this.owner.right = 300;
                this.owner.top = 75;
            }
        }


    },
    
    methods: {
       
        
    },
    
    publicMethods: {
        
        
    }
});
