const
    OURS = 0,
    MINE = 1,
    mission = {
        state: () => ({
            theirMissions: {},
            myMission: {},
            missionClues: {},
            missionMessages: {},
            missionSounds: {}
        }),

        actions: {
            initializeMission ({commit, getters, state}) {
                return {
                    'mission.progress': ({value, player}) => {
                        //handle "any's"
                        const
                            fullState = {
                                ...getters.getMission,
                                ...value
                            },
                            anyBirdStates = Object.keys(fullState).filter((key) => key.indexOf('any-bird') === -1 && (key.indexOf('-@-') >= 0 || key.indexOf('-#-') >= 0)).reduce((obj, key) => {
                                const
                                    splitter = key.indexOf('-@-') >= 0 ? '-@-' : '-#-',
                                    location = key.split(splitter)[1];

                                obj[`any-bird${splitter}${location}`] ||= fullState[key];

                                return obj;
                            }, {});


                        if (player === getters.getPlayerId) {
                            commit('setMission', {
                                ...state.myMission,
                                ...value,
                                ...anyBirdStates
                            });
                        } else {
                            commit('setTheirMissions', {
                                ...state.theirMissions,
                                [player]: {
                                    ...state.theirMissions[player],
                                    ...value
                                }
                            });
                            commit('setMission', {
                                ...state.myMission,
                                ...anyBirdStates
                            });
                        }
                    },
                    'mission.clues': ({value, player}) => { // Move this to localState
                        if (player === getters.getPlayerId) {
                            commit('setMissionClues', value);
                        }
                    },
                    'mission.messages': ({value, player}) => { // Move this to localState
                        if (player === getters.getPlayerId) {
                            commit('setMissionMessages', value);
                        }
                    },
                    'mission.sounds': ({value, player}) => { // Move this to localState
                        if (player === getters.getPlayerId) {
                            commit('setMissionSounds', value);
                        }
                    }
                };
            },

            updateMissionGame ({dispatch}, newState) {
                const
                    update = {};
                    
                Object.keys(newState).forEach((key) => {
                    update[`mission.progress.${key}`] = newState[key];
                });

                return dispatch('proposeUpdate', update);
            },

            updateMissionLocal ({dispatch}, newState) {
                const
                    update = {};
                    
                Object.keys(newState).forEach((key) => {
                    update[`mission.progress.${key}`] = newState[key];
                });

                return dispatch('makeLocalUpdate', update);
            },

            giveMissionClue ({dispatch}, {clueId, mine}) {
                return dispatch('makeLocalUpdate', {
                    [`mission.clues.${clueId}`]: mine ? MINE : OURS
                });
            },
            
            sendMissionMessage ({dispatch}, {messageId, mine}) {
                return dispatch('makeLocalUpdate', {
                    [`mission.messages.${messageId}`]: mine ? MINE : OURS
                });
            },

            giveMissionSound ({dispatch}, soundId) {
                dispatch('addPlayerCollectibleSound', soundId);
                return dispatch('makeLocalUpdate', {
                    [`mission.sounds.${soundId}`]: MINE
                });
            },

            clearMission ({commit, dispatch}, allDone) {
                commit('setTheirMissions', {});
                commit('setMission', {});
                commit('setMissionClues', {});
                commit('setMissionMessages', {});
                commit('setMissionSounds', {});
                if (allDone) {
                    dispatch('makeLocalUpdate', {
                        mission: {}
                    });
                    dispatch('proposeUpdate', {
                        mission: {}
                    });
                }
            }
        },

        mutations: {
            setMission (state, value) {
                state.myMission = value;
            },

            setTheirMissions (state, value) {
                state.theirMissions = value;
            },

            setMissionClues (state, value) {
                state.missionClues = value;
            },
            
            setMissionMessages (state, value) {
                state.missionMessages = value;
            },

            setMissionSounds (state, value) {
                state.missionSounds = value;
            }
        },

        getters: {
            getMission (state) {
                const
                    theirMission = Object.keys(state.theirMissions).reduce((prev, current) => ({
                        ...prev,
                        ...state.theirMissions[current]
                    }), {});

                return {
                    ...theirMission,
                    ...state.myMission
                };
            },

            getMyMissionClues (state) {
                return state.missionClues && Object.keys(state.missionClues).filter((key) => state.missionClues[key] === MINE);
            },
            
            getMyMissionMessages (state) {
                return state.missionMessages && Object.keys(state.missionMessages).filter((key) => state.missionMessages[key] === MINE);
            },

            getMyMissionSounds (state) {
                return Object.keys(state.missionSounds);
            },

            getOurMissionClues (state) {
                return state.missionClues && Object.keys(state.missionClues).filter((key) => state.missionClues[key] === OURS);
            },
            
            getOurMissionMessages (state) {
                return state.missionMessages && Object.keys(state.missionMessages).filter((key) => state.missionMessages[key] === OURS);
            },

            getMissionClues (state) {
                return state.missionClues;
            },
            
            getMissionMessages (state) {
                return state.missionMessages;
            }
        }
    };

export {mission};