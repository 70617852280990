import {Controller} from '@makefully/engagefully';
import Decider from '@/shared/Decider';
import EventEmitter from 'eventemitter3';
import Player from './Player';
import VuexStorePuck from '@/shared/VuexStorePuck';
import store from '@/store/index';

class Choreography extends EventEmitter {
    constructor ({vueData, setupData, me, vue}) {
        super();

        this.setupData = setupData;
        this.vue = vue;
        this.vueData = vueData;

        this.vueData.state = '';

        //These were moved into vue
        this.board = null;
        this.timeline = null; //new Timeline(this, this.vue, this.vueData.turnsInGame, this.vueData.timeline);
        
        this.controller = new Controller([
            new VuexStorePuck('Puck')
        ]);

        this.players = setupData.players.map((player, index) => new Player(this, player, index, this.controller, this.vueData));
        this.vueData.localPlayerIndex = setupData.players.reduce((prev, player, index) => prev || (player.character === me.character ? index : 0), 0);
        this.localPlayer = {
            obj: this.players[this.vueData.localPlayerIndex],
            playerId: me.player,
            character: me.character,
            characterName: me.characterName
        };
        
        // set up puck position locking
        this.controller.on(`PuckButton${setupData.players[this.vueData.localPlayerIndex].puck}`, ({value}) => {
            if (value !== 0) { // 0 = off, 1 = press, 2 = double-press, 3 = long press
                this.togglePositionLock();
            }
        });
        

        this.vueData.clueset.mode = '';
        this.vueData.clueset.team = setupData.clues.team;
        this.vueData.clueset.individual = setupData.clues.individual[this.vueData.localPlayerIndex];
        
        this.myEmote = {
            "type": "",
            "text": "",
            "timeout": "default"
        };

        
        this.vueData.turnsInGame = setupData.solutions.length;
        this.vueData.turn = -1;

        this.gameReviewData = null;
        this.vueData.levelComplete = false;

        this.proposedRewindTo = -1;
        this.vueData.rewindTo = -1;

        this.rewindDecidersReady = true;

        this.considerRewinding = new Decider({
            decisionId: "consider-rewinding",
            type: 'first',
            onAgreement: async (decision) => {
                let doRewind = false;

                this.rewindDecidersReady = false;

                this.vueData.rewindTo = decision;
                if (this.proposedRewindTo === this.vueData.rewindTo) {
                    this.confirmRewind.decide(this.vueData.rewindTo);
                    doRewind = true;
                }
                this.proposedRewindTo = -1;

                setTimeout(() => this.vue.updateRewindState({
                    rewindStarting: true,
                    rewindProposed: decision !== -1,
                    rewindAccepted: doRewind
                }));
            }
        });

        this.confirmRewind = new Decider({
            decisionId: "confirm-rewind",
            type: 'all',
            onVote: async (votes) => {
                await store.dispatch('appReady');
                
                let id = null,
                    cancelVote = false;

                // eslint-disable-next-line guard-for-in
                for (id in votes) {
                    if (votes[id] === 'no') {
                        cancelVote = true;
                        break;
                    }
                }

                if (cancelVote) {
                    this.proposedRewindTo = -1;
                    this.vueData.rewindTo = -1;
                    setTimeout(() => this.vue.updateRewindState({
                        rewindStarting: false
                    }));

                    //Reset the decisions!
                    await this.considerRewinding.reset();
                    await this.confirmRewind.reset();
                    this.rewindDecidersReady = true;
                }
            },
            onAgreement: async () => {
                store.dispatch('undoPositionLocks', this.vueData.rewindTo);

                this.proposedRewindTo = -1;
                this.vueData.rewindTo = -1;
                setTimeout(() => this.vue.updateRewindState({
                    rewindStarting: false
                }));

                await this.considerRewinding.reset();
                await this.confirmRewind.reset();
                this.rewindDecidersReady = true;
            }
        });

        this.vueData.gameReady = true;
    }

    async togglePositionLock () {
        const
            player = this.localPlayer.obj,
            lock_text = ["I'm Ready!", "I Got This!", "Feels Right."],
            unlock_text = ["Nevermind.", "Maybe Not.", "Oops..."],
            random_unlock_text = unlock_text[Math.floor(Math.random() * unlock_text.length)];

        if (this.vueData.state !== 'turn-play' && this.vueData.state !== 'waiting-on-game-setup') {
            console.warn('You cannot lock in at this point in the game.');
            return;
        }
        
        let random_lock_text = lock_text[Math.floor(Math.random() * lock_text.length)];

        

        if (!player.myData.locked && player.myData.validPosition) {
            const
                position = {
                    ...player.myData.position
                };
            
            player.myData.isWaiting = true;
            if (await store.dispatch('togglePuckPositionLock', position)) {
                
                random_lock_text = this.vueData.state === 'waiting-on-game-setup' ? lock_text[0] : random_lock_text;
                
                this.myEmote = {
                    type: "Happy",
                    text: random_lock_text,
                    timeout: "2000"
                };

                this.vue.$gameConsole.log(`Practice: Position Locked`, position);
            } else {
                this.myEmote = {
                    type: "Anxious",
                    text: random_unlock_text,
                    timeout: "2000"
                };
            }

        } else {
            if (player.myData.locked) {
                // unlock no matter what. I shouldn't care if position is valid when unlocking.
                player.myData.isWaiting = true;
                if (await store.dispatch('togglePuckPositionLock', {
                    ...player.myData.position
                }) === null) {
                    this.myEmote = {
                        type: "Anxious",
                        text: random_unlock_text,
                        timeout: "2000"
                    };
                }
                
                return;
            }
            
            console.log(`You tried to lock into region ${player.myData.position.region}, but it is NOT valid`);
            
            if (this.vueData.state === 'waiting-on-game-setup') {
                this.myEmote = {
                    type: "Anger",
                    text: "Umm...wrong spot.",
                    timeout: "default"
                };
            } else {
                this.myEmote = {
                    type: "Annoyed",
                    text: "Did I forget to move?",
                    timeout: "default"
                };
            }
        }
    }

    submitPlayerPosition (playerIndex) {
        if (playerIndex === this.vueData.localPlayerIndex) {
            this.togglePositionLock();
        } else {
            console.warn('You cannot submit a position for another player.');
        }
    }
    
    // updateCanSubmitState (playerIndex, val) {
    //     if (playerIndex === this.vueData.localPlayerIndex) {
    //         this.vue.canSubmitLock = val;
    //     }
    // }

    //==============================================================================
    //State - Game Setup
    //==============================================================================

    gameSetup (setupPositions) {
        this.solutions = this.setupData.solutions;

        this.board.prepareForSetup(setupPositions);

        for (let x = 0; x < this.players.length; x++) {
            this.players[x].reset(setupPositions[x]);
        }
        
        this.setCluesetMode('');
        this.timeline.hide();
    }

    //==============================================================================
    //State - Turn Play
    //==============================================================================
    turnPlaySetup () {
        this.timeline.showGameplay();

        for (let x = 0; x < this.players.length; x++) {
            this.players[x].reset();
        }

        this.setCluesetMode('normal');
    }

    //==============================================================================
    //State - Game Review
    //==============================================================================
    gameReviewSetup () {
        let allCorrect = false;

        this.generateGameReviewData();
        allCorrect = this.gameReviewData.lastCorrectTurn === this.vueData.turnsInGame;

        this.timeline.setResults(this.gameReviewData.turnSuccess, allCorrect);
        this.timeline.showReview();

        this.setCluesetMode('');
    }

    endGame () {
        

    }

    //==============================================================================
    //Debugger
    //==============================================================================

    showDebugger (isEndOfGame, turnToHighlight) {
        let characterToHighlight = this.localPlayer.character; //this.players[this.vueData.localPlayerIndex].character;
        this.setCluesetMode('stacked');
        this.timeline.hide();

        if (isEndOfGame) {
            //do things

        } else {
            //do things
            characterToHighlight = 'all';
            this.generateGameReviewData();
        }

        this.board.prepareForReview(this.vueData.turn - 1, characterToHighlight, turnToHighlight, this.gameReviewData.piecePositions, this.gameReviewData.pieceCorrectness, this.gameReviewData.lastCorrectTurn);
    }

    hideDebugger () {
        this.board.hide();
        if (this.vueData.state === "game-review") {
            this.timeline.showReview();
            this.setCluesetMode();
        } else {
            this.timeline.showGameplay();
            this.setCluesetMode('normal');
        }

        //hide debugger
        //show other things
    }

    //==============================================================================
    //Rewind
    //==============================================================================

    async rewind (turn) {
        if (this.rewindDecidersReady && (turn !== -1)) {
            this.proposedRewindTo = turn;
            
            await store.dispatch('appBusy', {message: 'Rewinding Turn...'});
            this.considerRewinding.decide(this.proposedRewindTo);
        }
    }

    voteYesRewind () {
        this.confirmRewind.decide(this.vueData.rewindTo);
        setTimeout(() => this.vue.updateRewindState({
            rewindAccepted: true
        }));
    }

    voteNoRewind () {
        this.confirmRewind.decide('no');
    }

    //==============================================================================
    //Helper Functions
    //==============================================================================
    finishGame (goingToNextLevel) {
        this.controller.removeAllListeners();
        if (goingToNextLevel) {
            this.considerRewinding.destroy();
            this.confirmRewind.destroy();
        }
        store.dispatch('clearPractice', goingToNextLevel);
    }

    generateGameReviewData () {
        const solutions = this.setupData.solutions;
        let x = 0,
            y = 0,
            turnCorrect = true;

        /*
            Explanation of gameReviewData
            - overallSuccess: bool, Was the team's answer completely correct?
            - turnSuccess: [bool], Was the team's answer correct at each turn?
            - piecePositions: [[[int, int]]], The positions of the players over the course of the game.
                The data structure is [players ordered by index [array of player moves [region, rotation]]].
            - pieceCorrectness: [[bool]], The correctness of each player move.
                The data structure is [players ordered by index [boolean for whether each move is correct/incorrect]].
        */

        this.gameReviewData = {
            overallSuccess: true,
            turnSuccess: [],
            piecePositions: [],
            pieceCorrectness: [],
            lastCorrectTurn: 0
        };

        for (x = 0; x < this.vueData.turn; x++) {
            turnCorrect = true;
            for (y = 0; y < this.players.length; y++) {
                if (!this.gameReviewData.piecePositions[y]) {
                    this.gameReviewData.piecePositions[y] = [];
                }
                this.gameReviewData.piecePositions[y][x] = {};
                this.gameReviewData.piecePositions[y][x].region = this.players[y].prevPositions[x].region;
                this.gameReviewData.piecePositions[y][x].rotation = this.players[y].prevPositions[x].rotation;
                
                if (!this.gameReviewData.pieceCorrectness[y]) {
                    this.gameReviewData.pieceCorrectness[y] = [];
                }
                
                if (x === 0) {
                    //Turn 0 is the setup. The setup position is always correct;
                    this.gameReviewData.pieceCorrectness[y][x] = true;
                } else if (this.players[y].prevPositions[x].region !== solutions[x - 1][y][0] ||
                            this.players[y].prevPositions[x].rotation !== solutions[x - 1][y][1]) {
                    
                    this.gameReviewData.pieceCorrectness[y][x] = false;
                    turnCorrect = false;
                } else {
                    this.gameReviewData.pieceCorrectness[y][x] = true;
                }
            }

            this.gameReviewData.overallSuccess = this.gameReviewData.overallSuccess && turnCorrect;
            this.gameReviewData.turnSuccess.push(turnCorrect);
            if (this.gameReviewData.overallSuccess) {
                this.gameReviewData.lastCorrectTurn = x;
                this.vueData.lastCorrectTurn = this.gameReviewData.lastCorrectTurn;
            }
        }

        if (this.gameReviewData.lastCorrectTurn === this.vueData.turnsInGame) {
            this.vueData.levelComplete = true;
        } else {
            this.vueData.levelComplete = false;
        }
    }

    playerOutOfPlace (how, who) {
        switch (how) {
        case 'wrong-setup-position':
            console.log('Player ' + who + ' is in the wrong starting location.');
            break;
        case 'locked':
            console.log('Player ' + who + ' moved after their position was locked.');
            break;
        case 'same-region':
            console.log('Player ' + who + ' is in the same position as another piece.');
            break;
        }
    }

    setTurn (turn, needsSetup) {
        if (!needsSetup && this.vueData.turn === turn - 1) {
            this.vue.$gameConsole.log(`Practice: Ending Turn`, turn - 1);
        }
        this.vueData.turn = turn;
        this.timeline.setActiveNode(this.vueData.turn);

        if (needsSetup) {
            this.gameSetup(needsSetup);
            this.vueData.state = 'waiting-on-game-setup';
            this.vue.$gameConsole.log(`Practice: Setting Up`, turn);
        } else if (this.vueData.turn > this.vueData.turnsInGame) {
            this.gameReviewSetup();
            this.vueData.state = 'game-review';
            this.vue.$gameConsole.log(`Practice: End ${this.gameReviewData.lastCorrectTurn === this.vueData.turnsInGame ? 'Success' : 'Review'}`, this.gameReviewData);
        } else {
            this.board.hide();
            this.turnPlaySetup();
            this.vueData.state = 'turn-play';
            this.vue.$gameConsole.log(`Practice: Starting Turn`, turn);
        }
    }

    setCluesetMode (newMode) {
        switch (newMode) {
        case 'normal':
        case 'stacked':
            this.vueData.clueset.mode = newMode;
            break;
        default:
            this.vueData.clueset.mode = 'hidden';
            break;
        }
    }

    lockPlayerPosition (index, position) {
        if (index === this.vueData.localPlayerIndex) {
            this.players[index].myData.isWaiting = false;
        }
        this.players[index].lockPosition(position);
    }

    unlockPlayerPosition (index, position) {
        if (index === this.vueData.localPlayerIndex) {
            this.players[index].myData.isWaiting = false;
        }
        this.players[index].unlockPosition(position);
    }

    fixPlayerHistory (index, newPrevPositions) {
        this.players[index].setPrevPositions(newPrevPositions);
        this.players[index].reset();
    }

    setBoard (board) {
        this.board = board;

        if (this.timeline) {
            this.vue.readyForHistoryAndLocks();
        }
    }

    setTimeline (timeline) {
        this.timeline = timeline;
        if (this.board) {
            this.vue.readyForHistoryAndLocks();
        }
    }
}

export default Choreography;