<template>
    <article class="board-container" :class="{setup: mode === 'setup', review: mode === 'review' || mode === 'final-review'}">
        <h3
            class="board-header header-blob"
            :class="{headerVisible: mode === 'setup'}"
        >
            Match Starting Positions...
        </h3>
        
        <div
            class="filters"
            :class="{filterVisible: mode === 'review' || mode === 'final-review'}">

            <FilterDropdown
                ref="playerFilter"
                class="player-filter"
                :boardComponent="this"
                filterType="player"
                label="Showing Path for:">
            </FilterDropdown>
            
            <FilterDropdown
                ref="turnFilter"
                class="turn-filter"
                :boardComponent="this"
                filterType="turn"
                label="On:">
            </FilterDropdown>

        </div>
        <div
             class="board"
             :class="{
                review: mode === 'review',
                finalReview: mode === 'final-review',
              }">
            <ol
                class="turns"
                :id="[ 'turns-for_' + player.character ]"
                v-for="(player, index) in setupData.players"
                :key="index"
            >
                <li
                    class="turn"
                    
                    v-for="indexx in (setupData.solutions.length + 1)"
                    :key="indexx"
                >
                    <PlayerPiece
                        :boardDimensions="setupData.board.dimensions"
                        :showCorrectness="mode === 'final-review'"
                        :mode="mode"
                        :turn="(indexx - 1)"
                        :ref="'piece' + index + (indexx - 1)"
                    />
                </li>
            </ol>
            
            <table class="regions">
                <tr class="row"
                    v-for="(row, r_index) in setupData.board.dimensions[0]"
                    :key="r_index"
                    :id="'row_' + (r_index + 1)"
                >
                    <td
                        v-for="(col, c_index) in setupData.board.dimensions[1]"
                        :key="c_index"
                        :id="'region_' + regionCalc(setupData.board.dimensions[0], r_index, setupData.board.dimensions[1], c_index)"
                        class="col">
                    </td>
                </tr>
            </table>
            
            <img class="board-img" :src="art" alt="Board For Practice Game" />
        </div>
    </article>
</template>

<script>
import FilterDropdown from "@/components/gamemodes/practice/FilterDropdown.vue";
import PlayerPiece from "@/components/gamemodes/practice/PlayerPiece.vue";

export default {
    name: 'GameBoard',
    components: {
        PlayerPiece,
        FilterDropdown
    },
    props: {
        parentComponent: Object,
        art: String,
        setupData: Object,
        theGame: Object
    },
    data () {
        return {
            mode: "hidden",
            selectedPlayer: "",
            selectedTurn: "",
            pieces: [],
            characterFilterIs: '',
            playerFilter: null,
            turnFilter: null
        };
    },
    computed: {
    },
    mounted () {
        let x = 0,
            y = 0;

        this.setMode(this.mode);

        this.playerFilter = this.$refs.playerFilter;
        this.turnFilter = this.$refs.turnFilter;

        this.playerFilter.addOption('all');
        this.playerFilter.addLabel('all');
        this.playerFilter.addDisabled(false);

        for (x = 0; x < this.setupData.players.length; x++) {
            this.pieces[x] = [];
            for (y = 0; y <= this.setupData.solutions.length; y++) { //We make this one longer so we can have a piece to mark the start location.
                this.pieces[x].push(this.$refs['piece' + x + y][0]);
                this.pieces[x][y].setName(this.setupData.players[x].characterName);
                this.pieces[x][y].setCharacter(this.setupData.players[x].character);
            }

            this.playerFilter.addOption(this.setupData.players[x].character);
            this.playerFilter.addLabel(this.setupData.players[x].character);
            this.playerFilter.addDisabled(false);
        }

        for (x = 0; x <= this.setupData.solutions.length; x++) { //Offset to skip the setup turn
            if (x === 0) {
                this.turnFilter.addLabel('Setup');
            } else {
                this.turnFilter.addLabel('Turn ' + x);
            }

            this.turnFilter.addOption(String(x));
            this.turnFilter.addDisabled(false);
        }
        //this.lastCorrectTurn = 0;

        this.playerFilter.init();
        this.turnFilter.init();

        this.theGame.setBoard(this);
    },
    methods: {
        regionCalc (rows, row, cols, col) {
            const
                row_count = row * cols,
                region = (row_count + col) + 1;
            
            return region;
        },

        //////////////////////////////////////////////////////////
        ///BOARD METHODS
        //////////////////////////////////////////////////////////
        prepareForSetup (setupPositions) {
            let x = 0;

            this.clearPieces();

            for (x = 0; x < setupPositions.length; x++) {
                this.pieces[x][0].setPosition(setupPositions[x]);
                this.pieces[x][0].setState('puck');
                this.pieces[x][0].setVisible(true);
            }

            this.setMode('setup');
        },

        prepareForReview (turnsPlayed, highlightedCharacter, highlightedTurn, piecePositions, pieceCorrectness/*, lastCorrectTurn*/) {
            let x = 0,
                y = 0;

            this.clearPieces();
            //this.lastCorrectTurn = lastCorrectTurn;

            for (x = 0; x < piecePositions.length; x++) {
                for (y = 0; y < piecePositions[x].length; y++) {
                    this.pieces[x][y].setPosition(piecePositions[x][y]);
                    this.pieces[x][y].setCorrectness(pieceCorrectness[x][y]);
                }
            }

            this.turnFilter.setDisabledPastIndex(turnsPlayed);

            //This is a little janky, need to know what the filters are in advance, but also set them inside the functions.
            //This only happens here where both are being set at once.
            this.characterFilterIs = highlightedCharacter;

            this.setReviewTurn(highlightedTurn);
            this.filterReviewByCharacter(highlightedCharacter);

            if (turnsPlayed === this.setupData.solutions.length) {
                this.setMode('final-review');
            } else {
                this.setMode('review');
            }
            
        },

        setReviewTurn (turn) {
            let x = 0,
                y = 0;

            this.$parent.updateRewindText(turn);  //I think this goes here.
            
            this.turnFilter.setSelected(turn);

            for (x = 0; x < this.pieces.length; x++) {
                for (y = 0; y < this.pieces[x].length; y++) {
                    if (y === turn) {
                        this.pieces[x][y].setState('puck');
                        if (this.characterFilterIs === 'all') {
                            this.pieces[x][y].setVisible(true);
                        }
                    } else {
                        this.pieces[x][y].setState('icon');
                        if (this.characterFilterIs === 'all') {
                            this.pieces[x][y].setVisible(false);
                        }
                    }
                }
            }
        },

        filterReviewByCharacter (character) {
            let x = 0,
                y = 0,
                selectedIndex = -1;

            this.characterFilterIs = character;

            if (character === 'all') {
                this.playerFilter.setSelected(0);

                for (x = 0; x < this.pieces.length; x++) {
                    for (y = 0; y < this.pieces[x].length; y++) {
                        if (y === this.turnFilter.selected) {                           ////////////////////////////////// THIS WORKS, RIGHT?
                            this.pieces[x][y].setVisible(true);
                        } else {
                            this.pieces[x][y].setVisible(false);
                        }
                    }
                }

            } else {
                
                for (x = 0; x < this.playerFilter.options.length; x++) {                ////////////////////////////////// THIS WORKS, RIGHT?
                    if (this.playerFilter.options[x] === character) {                   ////////////////////////////////// THIS WORKS, RIGHT?
                        selectedIndex = x;
                        break;
                    }
                }

                this.playerFilter.setSelected(selectedIndex);

                for (x = 0; x < this.pieces.length; x++) {
                    for (y = 0; y < this.pieces[x].length; y++) {
                        if (this.pieces[x][y].character === character) {                    ////////////////////////////////// THIS WORKS, RIGHT?
                            this.pieces[x][y].setVisible(true);
                        } else {
                            this.pieces[x][y].setVisible(false);
                        }
                    }
                }
            }
        },

        selectedFilterOption (filterType, option) {
            if (filterType === 'player') {
                this.filterReviewByCharacter(option);
                this.$gameConsole.log('Practice: Character Filter', option);
            } else if (filterType === 'turn') {
                this.setReviewTurn(Number(option));
                this.$gameConsole.log('Practice: Turn Filter', option);
            }
        },

        clearPieces (clearStartingAtTurn) {
            let y = 0,
                z = 0;
        
            clearStartingAtTurn = clearStartingAtTurn || 0;

            for (y = 0; y < this.pieces.length; y++) {
                for (z = clearStartingAtTurn; z < this.pieces[y].length; z++) {
                    this.pieces[y][z].reset();
                }
            }
        },

        closeDebug () {
            this.theGame.hideDebugger();
        },

        hide () {
            this.setMode();
        },

        setMode (newMode) {
            switch (newMode) {
            case 'final-review':
            case 'review':
            case 'setup':
                this.mode = newMode;
                break;
            default:
                this.mode = 'hidden';
                break;
            }

            this.parentComponent.setBoardMode(this.mode); //This is a little janky, but I need a way for PracticeGame.vue to know what mode I'm in.
        }

    }
};
</script>

<style scoped lang="scss">
    .board-container {
        position: absolute;
        display: block;
        width: 57vw;
        height: 43.25vw;
        background: #2a2f5a;
        border-radius: 1.5em;
        padding: 1vw;
        
        top: 50%;
        left: 50%;
        transform: translate(110%, -50%);
        transform-origin: center;
        
        transition: transform 0.5s;
        
        &.setup {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.review {
            transform: translate(-16%, -50%);
            
            .board {
                transform: scale(1);
            }
        }
    }
    .board {
        position: relative;
        width: 55vw;
        height: 41.25vw;
        padding: 10vw 7.5vw 0 7.5vw;
        transform: scale(1.25);
        
        @media (min-aspect-ratio: 16/9) {
            transform: scale(1);
        }
        
        @media (min-aspect-ratio: 7/3) {
            transform: scale(0.8);
        }
        
        .regions {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            
            .row {
                flex: 0 0 auto;
                color: aqua;
                display: flex;
                width: 100%;;
                
                .col {
                    flex: 0 0 auto;
                    display: block;
                    position: relative;
                    width: 9vw;
                    height: 9vw;
                    margin: 0.5vw;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 1em;
                    border: 0.25em solid rgba(0,0,0, 0.2);
                    
                    &.highlight {
                        border-color: $mtb-white;
                        background-color: rgba(255, 255, 255, 0.1);
                        
                        background-image: url(~@/assets/character/icons/mtb_bird-icons_red-bird.svg);
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: 90%;
                        
                        &.teal-bird   { background-image: url(~@/assets/character/icons/mtb_bird-icons_teal-bird.svg); }
                        &.purple-bird { background-image: url(~@/assets/character/icons/mtb_bird-icons_purple-bird.svg); }
                        &.yellow-bird { background-image: url(~@/assets/character/icons/mtb_bird-icons_yellow-bird.svg); }
                        
                        &.wrong {
                            border-color: #d83318;
                            
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: -1vw;
                                right: -1vw;
                                width: 3vw;
                                height: 3vw;
                                background-image: url(~@/assets/character/icons/wrong-icon_solo.svg);
                                background-position: 50% 50%;
                                background-repeat: no-repeat;
                                background-size: 100%;
                            }
                        }
                        
                        &.correct {
                            border-color: $mtb-ui-green;
                            
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: -1vw;
                                right: -1vw;
                                width: 3vw;
                                height: 3vw;
                                background-image: url(~@/assets/character/icons/correct-icon_solo.svg);
                                background-position: 50% 50%;
                                background-repeat: no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                }
            }
        }
        
        .board-img {
            width: 100%;
            position: absolute;
            left:0;
            top: 0;
            z-index: -1;
            
            border-radius: 1.5em;
            
        }
        
        .turns {
            position: relative;
        }
    }

    .board-header {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        transform: translate(-50%, -115%);
        pointer-events: none;
        
        display: none;  
        
        @media (min-aspect-ratio: 16/9) {
            transform: translate(-50%, -50%) scale(0.75);
        }
        
        @media (min-aspect-ratio: 7/3) {
            transform: translate(-50%, 0) scale(0.6);
        }

        &.headerVisible {
            display: block;
        }
    }

    .filters {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        pointer-events: none;
        overflow: hidden;
        
        &.filterVisible {
            display: block;
        }
    }

    .rewind-button {
        cursor: pointer;
        position: absolute;
        top: 36vw;
        width: 15vw;
        height: 4vw;
        background: red;
        text-align: center;
        right: 10vw;
        display: none;

        &.rewindVisible {
            display: block;
        }
    }

    button.close {
        cursor: pointer;
        position: absolute;
        right: -1vw;
        bottom: -2vw;
        display: none;

        &.closeVisible {
            display: block;
        }
    }

</style>