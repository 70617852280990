import dpeService from "@/shared/dpeService";

/**
 * Logs can be simple strings, but to add additional data, use an object with the following format:
 *
 * {
 *     type, // Can be "info", "warn", "error", etc
 *     log, // Text description
 *     data, // Anything
 *     list // Multiple datas
 * }
 */
const
    MAX_LOG_COUNT = 50,
    formatLog = (data) => {
        if (typeof data === 'string') {
            return {
                type: 'info',
                log: data
            };
        } else if (data.type && data.log) { // Appears to be a formatted log.
            return data;
        } else if (Array.isArray(data)) {
            return {
                type: 'info',
                log: 'Information list...',
                list: data
            };
        } else {
            return {
                type: 'info',
                log: 'Information...',
                data
            };
        }
    },
    logs = {
        state: () => ({
            connectionLog: [],
            dpeLog: [],
            gameLog: [],

            logStream: null
        }),

        actions: {
            clearConnectionLog ({commit}) {
                commit('setConnectionLog', []);
            },

            clearDPELog ({commit}) {
                commit('setDPELog', []);
            },

            clearGameLog ({commit}) {
                commit('setGameLog', []);
            },

            updateConnectionLog ({commit}, data) {
                const
                    log = formatLog(data);

                commit('addConnectionLog', log);
                commit('setLogStream', log);
            },

            updateDPELog ({commit}, data) {
                const
                    log = formatLog(data);

                commit('addDPELog', log);
                commit('setLogStream', log);
            },

            updateGameLog ({commit}, data) {
                const
                    log = formatLog(data);

                dpeService.analytics.emit(log.log, log.data ?? log.list);
                    
                commit('addGameLog', log);
                commit('setLogStream', log);
            }
        },

        mutations: {
            addConnectionLog (state, data) {
                state.connectionLog.push(data);
                if (state.connectionLog.length > MAX_LOG_COUNT) {
                    state.connectionLog.shift();
                }
            },

            addDPELog (state, data) {
                state.dpeLog.push(data);
                if (state.dpeLog.length > MAX_LOG_COUNT) {
                    state.dpeLog.shift();
                }
            },

            addGameLog (state, data) {
                state.gameLog.push(data);
                if (state.gameLog.length > MAX_LOG_COUNT) {
                    state.gameLog.shift();
                }
            },

            setConnectionLog (state, data) {
                state.connectionLog = data;
            },

            setDPELog (state, data) {
                state.dpeLog = data;
            },

            setGameLog (state, data) {
                state.gameLog = data;
            },

            setLogStream (state, data) {
                state.logStream = data;
            }
        },

        getters: {
            getGameLog (state) {
                return state.gameLog;
            },

            getDPELog (state) {
                return state.dpeLog;
            },

            getConnectionLog (state) {
                return state.connectionLog;
            },

            getLogStream (state) {
                return state.logStream;
            }
        }
    };

export {
    logs
};