<template>
    <div class="countdown-container">
        <div class="countdown">
            <lottie-animation
                ref="anim"
                :animationData="countdown_anim"
                :autoPlay="true"
                @complete="countdownDone"
                @enterFrame="enterFrame"
                :speed="speedFactor"
            />
        </div>
        <p>{{counter}}</p>
  </div>
</template>

<script setup>
import countdown_anim from '@/assets/ui/animations/countdown_321.json';
</script>

<script>
export default {
    name: 'CountdownOverlay',
    props: {
      duration: {
          type: Number,
          required: true
      }  
    },
    computed: {
        speedFactor() {
            let new_duration = 3.5 / this.duration;

            return new_duration;
        }   
    },
    mounted () {   
    },
    methods: {
        countdownDone() {
            this.$emit('countdown-done');
        }
    }
};
</script>

<style lang="scss" scoped>
.countdown-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99997;
    pointer-events: all;
}
  
.countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
}
</style>