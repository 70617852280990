<template>
    <Transition name="dock-slide-right">
        <CharacterBar
            ref="characterbar"
            :layout="'mission-bar'"
            @action-btn-event="game.playerButtonPressed()"
        />
    </Transition>

    <canvas :class="isCutscenePlaying" id="map" width="800" height="600"></canvas>
    
    <div v-if="vueData.ready">
        <!--<div class="player-region" :class="isPositionLocked">{{vueData.player.region + ": " + vueData.player.regionId}}</div>-->
        
        <InitialPlacement v-if="vueData.mode === 'setup'" :vueData="vueData" :theGame="game"></InitialPlacement>
        
        <MainMenu
            v-if="vueData.mode === 'waiting-on-input' || vueData.mode === 'waiting-on-chain'"
            :vueData="vueData"
            :clueData="clueData"
            :messageData="messageData"
            :theGame="game"
            :minigameData="minigameData"
            :missionObjective="setupData.objective"
            @show-comic="showComic"
            @show-menu-tab="showCharacterMenu"
            ></MainMenu>
        
        <CutscenePlayer v-if="vueData.rewardsMode === 'scene' && vueData.scene.sceneToPlay" :vueData="vueData" :theGame="game"></CutscenePlayer>
        
        <RewardSound v-if="vueData.rewardsMode === 'sound' && game.newSounds" :vueData="vueData" :theGame="game"></RewardSound>
        
        <GameOver
            v-if="vueData.mode === 'game-over'"
            :vueData="vueData"
            :theGame="game"
            :minigameData="minigameData"
            :decisionId="`complete-mission-${minigameData.chapter}`"
            @finish-game="finishGame"
        ></GameOver>
    </div>
</template>

<script>

import CharacterBar from '@/components/character-ui/CharacterBar.vue';
import CutscenePlayer from './CutscenePlayer.vue';
import GameOver from './GameOver.vue';
import InitialPlacement from './InitialPlacement.vue';
import MainMenu from './MainMenu.vue';
//import MapOverlays from "@/components/gamemodes/mission/MapOverlays.vue";
import Mission from './Mission';
import RewardSound from './RewardSound.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'MissionGame',
    components: {
        CharacterBar,
        GameOver,
        InitialPlacement,
        MainMenu,
        CutscenePlayer,
        RewardSound
    },
    props: {
        minigameData: Object
    },
    computed: {
        ...mapGetters({
            sharedMission: 'getMission',
            isEveryonePresent: 'isEveryonePresent'
        }),
        isPositionLocked () {
            const result = {"is-locked": false};
            if (this.vueData.player.locked) {
                result["is-locked"] = true;
            }
            return result;
        },
        isCutscenePlaying () {
            const result = {"map-to-front": false};
            if (this.vueData?.rewardsMode === 'scene') {
                result["map-to-front"] = true;
            }
            return result;
        }
    },
    data () {
        return {
            game: null,
            restoreData: null,
            vueData: {
                "ready": false,
                "mode": null,
                "rewardsMode": null,
                "nameMap": null,
                "player": {
                    "region": 0,
                    "regionId": null,
                    "regionName": null,
                    "locked": false
                },
                "locations": {
                    "red-bird": {"regionId": null, "regionName": null},
                    "purple-bird": {"regionId": null, "regionName": null},
                    "teal-bird": {"regionId": null, "regionName": null},
                    "yellow-bird": {"regionId": null, "regionName": null}
                },
                "setup": {
                    "isRestore": false,
                    "inPosition": false,
                    "regionName": null
                },
                "scene": {
                    "sceneToPlay": null
                },
                "sound": {
                    "newSounds": null
                }
            },
            clueData: this.minigameData.level.clues,
            messageData: this.minigameData.level.messages,
            playthroughData: this.$store.getters.getActivePlaythrough,
            me: this.$store.getters.getPlayerInfo,
            players: this.$store.getters.getCharacters,
            setupData: {
                ...this.minigameData.level
            }
        };
    },
    emits: ['show-comic', 'finish-game'],
    mounted () {
        // These are set outside of the session if their values are needed here...
        console.log('Teacher Configuration', this.playthroughData.config);
        console.log('Last Play State', this.playthroughData.lastPlayedState);

        this.game = new Mission(this, this.minigameData, this.vueData, this.setupData, this.me, this.players, document.getElementById('map'), this.restoreData);
          
        // send game data to ports and trigger render
        this.$refs.characterbar.renderPorts(this.game);

        // get characterbar porthole info so you can talk to them
        this.portholes = this.$refs.characterbar.getPortholesData();
                
        this.$refs.characterbar.hideBar();
    },
    unmounted () {
        this.game.cleanUp();
        this.game = null;
    },
    watch: {
        sharedMission: {
            handler (newValues, oldValues) {
                if (this.game) {
                    this.game.progressUpdate(newValues, oldValues);
                } else {
                    if (newValues && Object.keys(newValues).length !== 0) {
                        this.restoreData = newValues;
                    } else {
                        this.restoreData = null;
                    }
                }
            },
            immediate: true
        },
        'vueData.mode': {
            handler (mode) {
                const
                    characterbar = this.$refs.characterbar;
                
                if (mode === 'waiting-on-input' || mode === 'game-over') {
                    setTimeout(() => {
                        this.$store.dispatch('appReady');
                    }, 2000);
                    
                    characterbar.showBar();
                    
                    if (mode === 'game-over') {
                        characterbar.updateBirdState('red-bird', 'emote type', 'excited');
                        characterbar.updateBirdState('yellow-bird', 'emote type', 'excited');
                        characterbar.updateBirdState('teal-bird', 'emote type', 'excited');
                        characterbar.updateBirdState('purple-bird', 'emote type', 'excited');
                    } else {
                        characterbar.updateBirdState('red-bird', 'emote type', 'idle');
                        characterbar.updateBirdState('yellow-bird', 'emote type', 'idle');
                        characterbar.updateBirdState('teal-bird', 'emote type', 'idle');
                        characterbar.updateBirdState('purple-bird', 'emote type', 'idle');
                    }
                } else if (mode === 'waiting-on-chain') {
                    this.$store.dispatch('appBusy');
                } else if (mode === 'setup') {
                    characterbar.hideBar();
                }
            }
        },
        'vueData.rewardsMode': {
            handler (mode) {
                if (mode === 'scene') {
                    this.$store.dispatch('appReady');
                    this.$refs.characterbar.hideBar();
                } else if (mode === 'sound') {
                    this.$store.dispatch('appReady');
                }
            }
        },
        isEveryonePresent (value) {
            if (value) {
                this.$store.dispatch('clientsReady');
            } else {
                this.$store.dispatch('clientsMissing');
            }
        }
        
    },
    methods: {
        showComic () {
            this.$emit('show-comic');
        },
        finishGame () {
            this.$emit('finish-game');
        },
        showCharacterMenu (tab) {
            this.$refs.characterbar.showCharacterMenu(this.me.character, tab);
        }
    }

};
</script>

<style scoped lang="scss">
    #map {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }

    .map-to-front {
        z-index: 99;
    }

    .player-region {
        position: absolute;
        right: 4vw;
        top: 1vw;
        width: 12vw;
        height: 2vw;
        color: white;
        background-color: green;
        z-index: 1000;

        &.is-locked {
            background-color: red;
        }

    }
</style>