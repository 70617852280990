import img_mtb_music_mode_player_state_issue from '@/components/gamemodes/performance/assets/images/mtb_music-mode_player-state-issue.png';
import img_mtb_music_mode_player_state_ok from '@/components/gamemodes/performance/assets/images/mtb_music-mode_player-state-ok.png';


export default {
    "images": [
        img_mtb_music_mode_player_state_ok,
        img_mtb_music_mode_player_state_issue
    ],
    "frames": [
        [ 0, 0, 100, 100, 0, 50, 50],
        [ 0, 0, 100, 100, 1, 50, 50],
        [ 0, 0, 0, 0, 0, 0, 0]
    ],
    "animations": {
        "good": 0,
        "bad": 1,
        "default": 2
    }
};