// Panel 0
import ASSETS_COMICS_4_CHAPTER_3_PANEL0_BKGRND from '@/assets/comics/4_chapter-3/panel-0/background.png';

// Panel 1
import ASSETS_COMICS_4_CHAPTER_3_PANEL1_BKGRND from '@/assets/comics/4_chapter-3/panel-1/background.png';

// Panel 2
import ASSETS_COMICS_4_CHAPTER_3_PANEL2_BKGRND from '@/assets/comics/4_chapter-3/panel-2/background.png';

// Panel 3
import ASSETS_COMICS_4_CHAPTER_3_PANEL3_BKGRND from '@/assets/comics/4_chapter-3/panel-3/background.png';

// Panel 4
import ASSETS_COMICS_4_CHAPTER_3_PANEL4_BKGRND from '@/assets/comics/4_chapter-3/panel-4/background.png';

// Panel 5
import ASSETS_COMICS_4_CHAPTER_3_PANEL5_BKGRND from '@/assets/comics/4_chapter-3/panel-5/background.png';


export default {
    "name": "Chapter 3",
    "panels": [
        {
            'name': 'chapter-3-panel-0',
            'width': 750, // in pixels
            'height': 563, // in pixels
            'left': 100, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Intructions Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL0_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 563 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-3-panel-1',
            'width': 1024, // in pixels
            'height': 576, // in pixels
            'left': 950, // pixel offset from left (x)
            'top': 100, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Panel 1 Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL1_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 576 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-3-panel-2',
            'width': 800, // in pixels
            'height': 610, // in pixels
            'left': 1000, // pixel offset from left (x)
            'top': 760, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Panel 2 Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL2_BKGRND,
                    'size': {
                        'width': 800, // pixels
                        'height': 610 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-3-panel-3',
            'width': 1024, // in pixels
            'height': 576, // in pixels
            'left': 1900, // pixel offset from left (x)
            'top': 770, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Panel 3 Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL3_BKGRND,
                    'size': {
                        'width': 1024, // pixels
                        'height': 576 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-3-panel-4',
            'width': 1150, // in pixels
            'height': 519, // in pixels
            'left': 1920, // pixel offset from left (x)
            'top': 1460, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Panel 4 Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL4_BKGRND,
                    'size': {
                        'width': 1150, // pixels
                        'height': 519 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
        {
            'name': 'chapter-3-panel-5',
            'width': 750, // in pixels
            'height': 708, // in pixels
            'left': 3150, // pixel offset from left (x)
            'top': 1420, // pixel offset from top (y)
            'layers': [
                {
                    'type': 'background',
                    'text': 'Chapter 3 - Panel 5 Art',
                    'asset': ASSETS_COMICS_4_CHAPTER_3_PANEL5_BKGRND,
                    'size': {
                        'width': 750, // pixels
                        'height': 708 // pixels
                    },
                    'position': {
                        'left': 0, // percent
                        'top': 0 // percent
                    },
                    animation: "",
                    delay: "none"
                },
            ]
   
        },
    ] // panels

};