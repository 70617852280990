<template>
    <ul class="my-sounds content-grid">
        <li
            v-for="sound in availableSounds"
            :key="sound.id"
            class="my-sound content-grid-item"
            @click="toggleSoundDiscovery(sound)"
            :class="{ 'discovered': sound.discovered }"
        >
            <em>{{ sound.title }}</em>
        </li>
    </ul>
</template>

<script>
import all_sounds from '@/shared/data/sounds.json';
import {mapGetters} from 'vuex';

export default {
    name: 'SoundsGrid',
    components: {
    },
    props: {
        characterData: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            allSounds: all_sounds,
            playthrough: this.$store.getters.getActivePlaythrough
        };
    },
    computed: {
        ...mapGetters({
            characters: 'getCharacters'
        }),
        availableSounds () {
            return Object.keys(this.mySounds).map((key) => ({
                ...all_sounds[key],
                discovered: !!this.mySounds[key]
            }));
        },
        mySounds () {
            return this.characterData.collectibles?.sounds ?? {};
        }
    },
    created () {
    },
    mounted () {
    },
    beforeUnmount () {
    },
    methods: {
        toggleSoundDiscovery (sound) {
            if (this.mySounds[sound.id]) {
                this.$store.dispatch('removePlayerCollectibleSound', sound.id);
            } else {
                this.$store.dispatch('addPlayerCollectibleSound', sound.id);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.content-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .content-grid-item {
        width: 15.5vw;
        height: 15.5vw;
        flex: 0 0 auto;
        margin: 1vw;
        border-radius: 1.5vw;
        display: flex;
        align-items: center;
        justify-content: center;

        background: $mtb-red-bird-color;
        
        &.discovered {
            border: 0.5em solid white;
        }
    }
}
</style> 