<template>
    <aside ref="log-panel" class="log-panel">
        <h3>{{title}}</h3>
        <ol class="logs" ref="logs" v-if="logs && logs.length > 0">
            <li
                :key="idx"
                v-for="(log, idx) in logs"
                :class="log.type"
            >{{log.log}}</li>
        </ol>
    </aside>
</template>

<script>
export default {
    name: "LogPanel",
    props: {
        logs: Array,
        title: String
    },
    watch: {
        logs () {
            const
                logs = this.$refs.logs,
                last = logs?.children ? logs.children[logs.children.length - 1] : null;

            if (last) {
                last.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
};

</script>

<style scoped lang="scss">
.log-panel {
    
    h3 {
        text-align: center;
        margin: 1em 0 0.5em 0;
        border-bottom: 0.1em solid $mtb-white;
    }
    
    .logs {
        max-height: 25em;
        overflow: auto;
        text-align: left;
        max-width: 20em;
        margin: auto;

        li {
            line-height: 1.5em;
            padding: 0.1em 0.7em;
            border: 0.1em solid rgba(255,255,255,0.2);
            border-radius: 0.4em;
            margin: 0.3em 0;
            font-family: monospace;
            font-size: 0.8em;
            font-weight: normal;
            
            
            &.info:before{
              content: '▶️ ';
            }

            &.warn:before{
              content: '⚠️ ';
            }

            &.error:before{
              content: '🚨 ';
            }
        }
    }
}
</style>