
<template>
    <!--<img src="" alt="" /> what is this here for? -->
    <div v-if="amReferee" id="frame">
        <VueStartUp  v-if="vueData.mode === 'start-up'" :theGame="game" :chapterNumber="minigameData.chapter" :chapterTitle="minigameData.title"></VueStartUp>
        <div class="debug-button" :class="{inDebugMode: vueData.mode === 'debug'}" v-if="(vueData.mode === 'debug' || vueData.mode === 'performing') && !vueData.countingIn" :onClick="() => {toggleDebug();}">
            <em class="debug-text">DEBUG TOOLS</em>
            <button class="debug-x-button mtb-btn action close">X</button>
        </div>
        
        <VueDebugBar v-if="vueData.mode === 'debug'" :startingRetry="vueData.startingRetry" :logData="vueData.debugLogData" :theGame="game"></VueDebugBar>
        
        <VueReplayBar v-if="vueData.mode === 'replay-prompt' || vueData.mode === 'replay-watched' || vueData.mode === 'replay'" :logData="vueData.replayLogData" :theGame="game"></VueReplayBar>
        
        <VueReplayPrompt
            v-if="vueData.mode === 'replay-prompt' || vueData.mode === 'replay-watched'"
            :vueData="vueData"
            :theGame="game"
            :onComplete="onComplete"
            :decisionId="`complete-performance-${minigameData.chapter}`"
        ></VueReplayPrompt>
        
        <div class="input-blind" :class="isBlindVisible"></div>
        <div id="content">
            <canvas id="stage"></canvas>
            <button v-if="vueData.mode === 'debug' || vueData.mode === 'start-up'" class="pan-btns pan-backward" v-on:pointerdown="beginScroll(-1)" v-on:pointerup="endScroll(-1)">Pan Back</button>
            <button v-if="vueData.mode === 'debug' || vueData.mode === 'start-up'" class="pan-btns pan-forward" v-on:pointerdown="beginScroll(1)" v-on:pointerup="endScroll(1)">Pan Forward</button>
        </div>
        
        <Transition name="dock-slide-right">
            <CharacterBar
                ref="characterbar"
                :layout="'performance-bar'"
            />
        </Transition>
        
        <Transition name="circle-wipe-fast">
            <VueCountdown
                v-if="countdownActive"
                :duration="countdownDuration"
                @countdown-done="closeCountdown"
            />
        </Transition>

    </div>

    <div v-if="!amReferee">
        <lottie-animation
            class="block-animation"
            ref="anim"
            :animationData="block_anim"
            :loop="true"
            :autoPlay="true"
            v-if="!amReferee"
        />
        
        <h3 class="screen-note-header">You will only need one player device for this game.</h3>
        <p class="screen-note"><b>Grab your sound boards, find the player with an active screen, and get ready to play!</b></p>
        <GroupButton
            confirmationModel="leader"
            :onAgreement="onComplete"
            title="Next Level"
            :milestone="true"
            :decisionId="`complete-performance-${minigameData.chapter}`"
            decision="continue"
        />
    </div>

    <button v-if="vueData.mode === 'debug' || vueData.mode === 'start-up'" @click="readComic" class="reread-intro-button">
        <em>RE-READ INTRO</em>
    </button>
</template>

<script setup>
import block_anim from '@/assets/ui/animations/block-screen.json';
</script>

<script>

import "platypus/node_modules/pixi-spine";

import {Game} from 'platypus';
import GroupButton from '@/components/GroupButton.vue';
import CharacterBar from '@/components/character-ui/CharacterBar.vue';

import VueCountdown from "@/components/CountdownOverlay.vue";
import VueDebugBar from "./DebugBar.vue";
import VueReplayBar from "./ReplayBar.vue";
import VueReplayPrompt from './ReplayPrompt.vue';
import VueStartUp from './StartUp.vue';

import aud_fill_track from "./assets/audio/MTB_FILL_TRACK.mp3";
import aud_perc_1 from "./assets/audio/MTB_PERC_1.mp3";
import aud_perc_2 from "./assets/audio/MTB_PERC_2.mp3";
import aud_tap_1 from "./assets/audio/MTB_TAP_1.mp3";
import aud_tap_2 from "./assets/audio/MTB_TAP_2.mp3";
import aud_whistle_1 from "./assets/audio/MTB_WHISTLE_1.mp3";
import aud_whistle_2 from "./assets/audio/MTB_WHISTLE_2.mp3";
import aud_xylo_1 from "./assets/audio/MTB_XYLO_1.mp3";
import aud_xylo_2 from "./assets/audio/MTB_XYLO_2.mp3";

import characterRigs from '@/shared/data/character_rigs';

import ent_action_layer from "./entities/action/action-layer";
import ent_activate_audio_button from "./entities/loading/activate-audio-button";
import ent_background from "./entities/action/background";
import ent_band from "./entities/action/band";
import ent_band_arrow from "./entities/action/band-arrow";
import ent_course from "./entities/action/course";
import ent_debug_camera from "./entities/action/debug-camera";
import ent_interface_layer from "./entities/interface/interface-layer";
import ent_lakitu from "./entities/action/lakitu";
import ent_loading_backdrop from "./entities/loading/loading-backdrop";
import ent_loading_bar from "./entities/loading/loading-bar";
import ent_loading_layer from "./entities/loading/loading-layer";
import ent_replay_purple_bird from "./entities/interface/replay-purple-bird";
import ent_replay_red_bird from "./entities/interface/replay-red-bird";
import ent_replay_teal_bird from "./entities/interface/replay-teal-bird";
import ent_replay_yellow_bird from "./entities/interface/replay-yellow-bird";
import ent_sample_arrows from "./entities/action/sample-arrows";
import ent_sample_background from "./entities/action/sample-background";
import ent_sample_icons from "./entities/action/sample-icons";
import ent_sample_indicator from "./entities/action/sample-indicator";
import ent_stage from "./entities/interface/stage";
import ent_stage_container from "./entities/interface/stage-container";
import ent_stage_thumbnail from "./entities/action/stage-thumbnail";
import ent_success_meter from "./entities/interface/success-meter";
import ent_success_meter_back from "./entities/interface/success-meter-back";
import ent_success_meter_bar from "./entities/interface/success-meter-bar";

import {mapGetters} from 'vuex';
import packageData from '@/../package.json';

import scn_game_scene from "./scenes/game-scene";
import scn_loading_scene from "./scenes/loading-scene";

import ss_background from "./assets/images/background";
import ss_band from "./assets/images/band";
import ss_loading_bar from "./assets/images/loading-bar";
import ss_perf_stage_thumbnails from "./assets/images/perf-stage-thumbnails";
import ss_perf_stages from "./assets/images/perf-stages";
import ss_performer_bubbles from "./assets/images/performer-bubbles";
import ss_play_button from "./assets/images/play-button";
import ss_sample_indicator from "./assets/images/sample-indicator";
import ss_samples from "./assets/images/samples";
import ss_success_meter from "./assets/images/success-meter";


export default {
    name: 'PerformanceGame',
    components: {
        CharacterBar,
        GroupButton,
        VueCountdown,
        VueDebugBar,
        VueReplayBar,
        VueReplayPrompt,
        VueStartUp
    },
    props: {
        minigameData: Object
    },
    computed: {
        ...mapGetters({
            referee: 'getReferee'
        }),
        isBlindVisible () {
            const result = {"visible": false};
            if (this.vueData.mode === 'replay-prompt' || this.vueData.mode === 'replay-watched') {
                result.visible = true;
            }
            return result;
        },
        countdownDuration () {
            return ((this.minigameData.level.musicalUnitTime + 300) / 1000);
        },
        amReferee () {
            return (this.referee || this.lastReferee) === this.me.player;
        }
    },
    data () {
        return {
            game: null,
            lastReferee: this.$store.getters.getReferee,
            playthroughData: this.$store.getters.getActivePlaythrough,
            me: this.$store.getters.getPlayerInfo,
            players: this.$store.getters.getCharacters,
            actionLayer: null,
            vueData: {
                mode: null,
                startingRetry: false,
                countingIn: false,
                debugLogData: [],
                replayLogData: {
                    log: null,
                    selected: -1,
                    input: 'none'
                },
                results: {
                    levelComplete: false,
                    lastRunRank: null
                },
                birdStates: {
                    "red-bird": {"direction": null, "sample": null, "correctness": null, "isPlaying": false},
                    "purple-bird": {"direction": null, "sample": null, "correctness": null, "isPlaying": false},
                    "teal-bird": {"direction": null, "sample": null, "correctness": null, "isPlaying": false},
                    "yellow-bird": {"direction": null, "sample": null, "correctness": null, "isPlaying": false}
                }
            },
            countdownActive: false
        };
    },
    emits: ['show-comic', 'finish-game'],
    mounted () {
        if (this.amReferee) {
            this.startGame();
        } else {
            console.log('You are not the referee. No game for you!!');
        }
    },
    beforeUnmount () {
        this.game = null;
    },
    methods: {
        startGame () {
            setTimeout(() => { // allow doc reflow before starting up game
                const
                    level = {
                        // Mostly directly from level data
                        ...this.minigameData.level,

                        stage: this.minigameData.board
                    },
                    platypusGame = new Game({
                        me: this.me,
                        players: this.players,
                        vueData: this.vueData,
                        scenes: {
                            "loading-scene": scn_loading_scene,
                            "game-scene": scn_game_scene
                        },
                        entities: {
                            "action-layer": ent_action_layer,
                            "activate-audio-button": ent_activate_audio_button,
                            "background": ent_background,
                            "band": ent_band,
                            "band-arrow": ent_band_arrow,
                            "course": ent_course,
                            "debug-camera": ent_debug_camera,
                            "interface-layer": ent_interface_layer,
                            "lakitu": ent_lakitu,
                            "loading-backdrop": ent_loading_backdrop,
                            "loading-bar": ent_loading_bar,
                            "loading-layer": ent_loading_layer,
                            "replay-purple-bird": ent_replay_purple_bird,
                            "replay-red-bird": ent_replay_red_bird,
                            "replay-teal-bird": ent_replay_teal_bird,
                            "replay-yellow-bird": ent_replay_yellow_bird,
                            "sample-arrows": ent_sample_arrows,
                            "sample-background": ent_sample_background,
                            "sample-icons": ent_sample_icons,
                            "sample-indicator": ent_sample_indicator,
                            "stage": ent_stage,
                            "stage-container": ent_stage_container,
                            "stage-thumbnail": ent_stage_thumbnail,
                            "success-meter": ent_success_meter,
                            "success-meter-back": ent_success_meter_back,
                            "success-meter-bar": ent_success_meter_bar
                        },
                        level,
                        spriteSheets: {
                            "background": ss_background,
                            "band": ss_band,
                            "loading-bar": ss_loading_bar,
                            "stage-thumbnails": ss_perf_stage_thumbnails,
                            "stages": ss_perf_stages,
                            "performer-bubbles": ss_performer_bubbles,
                            "play-button": ss_play_button,
                            "samples": ss_samples,
                            "sample-indicator": ss_sample_indicator,
                            "success-meter": ss_success_meter
                        },
                        audio: {
                            "red-a": aud_whistle_1,
                            "red-b": aud_whistle_2,

                            "purple-a": aud_tap_1,
                            "purple-b": aud_tap_2,

                            "teal-a": aud_perc_1,
                            "teal-b": aud_perc_2,

                            "yellow-a": aud_xylo_1,
                            "yellow-b": aud_xylo_2,

                            "fill": aud_fill_track
                        },
                        atlases: {
                            "purple-bird-atlas": characterRigs.purple.atlas.replace('PurpleBird.png', characterRigs.purple.ss),
                            "red-bird-atlas": characterRigs.red.atlas.replace('RedBird.png', characterRigs.red.ss),
                            "teal-bird-atlas": characterRigs.teal.atlas.replace('TealBird.png', characterRigs.teal.ss),
                            "yellow-bird-atlas": characterRigs.yellow.atlas.replace('YellowBird.png', characterRigs.yellow.ss)
                        },
                        skeletons: {
                            "purple-bird-skeleton": characterRigs.purple.data,
                            "red-bird-skeleton": characterRigs.red.data,
                            "teal-bird-skeleton": characterRigs.teal.data,
                            "yellow-bird-skeleton": characterRigs.yellow.data
                        }
                    }, {
                        canvasId: 'stage',
                        display: {
                            aspectRatio: "4:3-7:3",
                            backgroundColor: 0x141628,
                            clearView: true
                        },
                        images: '', //'assets/images/',
                        name: packageData.name,
                        version: packageData.version
                    }, (game) => {
                        console.log('GAME', game);
                        game.vueData = this.vueData;
                        game.gameConsole = this.$gameConsole;
                    });

                platypusGame.loadScene('loading-scene', {
                    scene: 'game-scene'
                });

                platypusGame.on('game-manager-ready', (gameManager) => {
                    this.game = gameManager;
                    
                    // send game data to ports and trigger render
                    this.$refs.characterbar.renderPorts(this.vueData);

                    // get characterbar porthole info so you can talk to them
                    this.portholes = this.$refs.characterbar.getPortholesData();
                });
            }, 1);
        },

        stopGame () {
            if (this.game) {
                this.game.triggerEvent('end-game'); // in case Platypus cares.
                this.game = null;
            }
        },

        toggleDebug () {
            this.game.triggerEvent('toggle-debug');
        },

        beginScroll (value) {
            this.game.triggerEvent('begin-pan', value);
        },

        endScroll (value) {
            this.game.triggerEvent('end-pan', value);
        },

        onComplete () {
            this.stopGame();
            this.$parent.finishGame();
        },
        showCountdown () {
            this.countdownActive = true;
        },
        closeCountdown () {
            this.countdownActive = false;
        },
        readComic () {
            this.$emit('show-comic');
        }
    },
    watch: {
        amReferee (am, was) {
            const
                newRef = this.$store.getters.getReferee;

            if (newRef && newRef !== this.lastReferee) {
                this.lastReferee = newRef;
            }

            if (am && !was) {
                this.startGame();
            } else if (!am && was) {
                this.stopGame();
            }
        },
        'vueData.countingIn': {
            handler (newValue) {
                if (newValue) {
                    this.showCountdown();
                }
            },
            immediate: true
        },
        'vueData.mode': {
            handler (newValue) {
                if (newValue === 'performing') {
                    this.$refs.characterbar.showBar();
                } else {
                    this.$refs.characterbar.hideBar();
                }  
            } 
        }
    }
};
</script>

<style scoped lang="scss">
div#frame {
    top: 0;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    background: #000;
}

div#content {
    position: relative;
}

canvas#stage {
    width: 100%;
    height: 100%;
}

#framerate {
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    font-family: "Lucida Console", Monaco, monospace;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: default;
}

.input-blind {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    
    background-color: rgba(0,0,0,0.8);
    z-index: 1;
    backdrop-filter: blur(20px);
    
    transition: opacity 0.5s ease-out;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }
}

.debug-button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 3vw;
    margin-left: -10vw;
    width: 20vw;
    height: 5vw;
    background-color: #CC2662;
    border-bottom-left-radius: 3vw;
    border-bottom-right-radius: 3vw;
    z-index: 1;

    &.inDebugMode {
        background-color: #1b204b;
        
        > em {
            color: #FFE888;
            
            &:before {
                background-image: url(~@/assets/ui/mtb-ui_debug-icon_yellow.svg);
            }
        }
    }
    
    > em {
        text-align: center;
        font-size: 1em;
        line-height: 5vw;
        display: block;
        
        padding-left: 2em;
            
        &:before {
            content: "";
            width: 2em;
            height: 2em;
            display: inline-block;
            position: absolute;
            left: 1.5em;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(~@/assets/ui/mtb-ui_debug-icon.svg);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
        }
        
    }

    .debug-x-button {
        position: absolute;
        width: 4vw !important;
        height: 4vw !important;
        right: -1.5vw;
        bottom: -1.5vw;
        padding: 0;
        border-width: 0.3vw;
        display: none;
    }
}

.inDebugMode .debug-x-button {
    display: block;
}

.pan-btns {
    position: absolute;
    left: 0.2em;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4em;
    text-indent: -999em;
    background: none;
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 0.4em solid transparent;
    border-bottom: 0.4em solid transparent;
    border-right: 0.6em solid #f4f4f4;
    opacity: 0.3; 
    
    transition: transform 0.3s, opacity 0.3s;
    
    &.pan-forward {
        right: 0.2em;
        left: auto;
        border-top: 0.4em solid transparent;
        border-bottom: 0.4em solid transparent;
        border-left: 0.6em solid $mtb-white;
        border-right: none;
    }
    
    &:hover {
        transform: translateY(-50%) scale(1.2);
        cursor: pointer;
        opacity: 1;
    }
}

.reread-intro-button {
    position: absolute;
    right: 4vw;
    top: 3.5vw;
    padding-top: 5.5vw;
    width: fit-content;
    background: url('~@/assets/ui/mtb-ui_comic-icon.svg') 50% top no-repeat;
    background-size: 70%;
    
    cursor: pointer;

    em {
        color: $mtb-white;
        font-size: 0.75em;
        text-align: center;
        white-space: nowrap;
    }
    
    &:hover {
        animation: jelly 0.75s ease 0s 1 normal forwards;
    }
}

.block-animation {
    position: absolute;
    left: 50%;
    width: 50vw;
    transform: translateX(-50%);
}
    
.screen-note-header {
    position: absolute;
    bottom: 5.5em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5em;
    width: max-content;
}
    
.screen-note {
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
    background: #1b204b;
    color: #6c78c4;
    padding: 0.75em 1.5em;
    border-radius: 1.5em;
    width: 44vw;
    margin: auto;
    z-index: 1;
    font-style: italic;
    
    b {
        color: #fee888;
    }
}

</style>