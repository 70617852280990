import App from './App.vue';
import LottieAnimation from "lottie-web-vue";
import Toast from "vue-toastification";
import {createApp} from 'vue';
import {createLoggerPlugin} from './shared/logService';
import peerService from './shared/peerService';
import router from './router';
import store from './store';

createApp(App)
    .use(peerService)
    .use(store)
    .use(router)
    .use(createLoggerPlugin({
        key: 'connection'
    }))
    .use(createLoggerPlugin({
        key: 'game'
    }))
    .use(LottieAnimation)
    .use(Toast)
    .mount('#app');
