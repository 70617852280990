import DebugCamera from "../../components/DebugCamera";

export default {
    "id": "debug-camera",
    "components": [
        DebugCamera, {
            "type": "RelayLinker",
            "linkId": "camera-movement",
            "events": {
            }
        }
    ],
    "properties": {
        x: 0,
        y: 0,
        z: 0
    }
};