import img_perf_stage_0 from '@/components/gamemodes/performance/assets/images/perf-stage-0.png';
import img_perf_stage_1 from '@/components/gamemodes/performance/assets/images/perf-stage-1.png';

export default {
    "images": [
        img_perf_stage_0,
        img_perf_stage_1
    ],
    "frames": [
        [ 0, 0, 1300, 867, 0, 650, 433.5],
        [ 0, 0, 1300, 867, 1, 650, 433.5]
    ],
    "animations": {
        "stage-basic": 0,
        "stage-lights": 1
    }
};