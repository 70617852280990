import Course from '../../components/Course';

export default {
    "id": "course",
    "components": [
        {
            "type": "RenderContainer"
        }, {
            "type": Course
        }, {
            "type": "RelayLinker",
            "linkId": "band",
            "events": {
                "rl-course-info": "course-info"
            }
        }, {
            "type": "RelayLinker",
            "linkId": "course",
            "events": {
            }
        }],
    "properties": {
        "renderStyle": 'line',
        x: 0,
        y: 0,
        z: 100
        
    }
};