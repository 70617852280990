<template>
    <button
        class="timeline-node"
        :class="nodeClass"
        :onClick="() => {showDebugger(this.turn)}"
    >
        <i class="label" v-if="state === 'active'">Current Turn</i>
        <i class="review-deco" v-if="state === 'rewind'"><span>Review</span></i>
        
        <em><span>Turn: </span> {{turn}}</em>
    </button>
</template>

<script>


export default {
    name: 'TimelineNode',
    components: {

    },
    props: {
        timelineMode: String,
        parentComponent: Object
    },
    data () {
        return {
            turn: -1,
            state: '',
            correctness: '',

        };
    },
    computed: {
        nodeClass () {
            const result = {};
            if (this.timelineMode === 'review') {
                result['game-over'] = true;
            }

            if (this.state === 'active') {
                result.active = true;
            } else if (this.state === 'rewind') {
                result.rewind = true;
            }

            if (this.correctness === 'correct') {
                result.correct = true;
            } else if (this.correctness === 'incorrect') {
                result.incorrect = true;
            }

            if (this.clickable) {
                result.clickable = true;
            }

            return result;
        }
    },
    mounted () {
        
    },
    methods: {
        showDebugger (turn) {
            if (!this.parentComponent.allCorrect && this.state === 'rewind') {              //// CAN I DO THIS???????????????????????????????????????????????????????
                this.parentComponent.showDebugger(turn);
            }
        },
        setTurn (turn) {
            this.turn = turn;
        },
        setState (state) {
            this.state = state;
        },
        setCorrectness (correctness) {
            this.correctness = correctness;
        }
    }
};
</script>

<style scoped lang="scss">
.timeline-node {
    width: 100%;
    height: 100%;
    background: #4C5693;
    border: 0.15em solid #2A2F5A;
    border-radius: 50%;
    position: relative;

    font-size: 1.5em;
    color: #1B204B;
    font-weight: bold;
    pointer-events: none;

    &.clickable {
        cursor: pointer;
        pointer-events: all;
    }

    &.active {
        background-color: $mtb-white;
    }

    &.rewind {
        color: #c5c6d1;
        background-color: $mtb-white;
        cursor: pointer;
        pointer-events: all;
    }

    &.game-over {
        background-repeat: no-repeat;
        background-size: 100%;
        
        em {
            opacity: 0;
        }

        &.correct{
            background-image: url(~@/assets/character/icons/correct-icon_solo.svg);
        }
        &.incorrect{
            background-image: url(~@/assets/character/icons/wrong-icon_solo.svg);
        }
        
        .review-deco {
            display: none;
        }
    }

    > em {
        span {
            display: block;
            position: absolute;
            left: -999vw;
        }
    }

    .label {
        position: absolute;
        top: -3em;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        line-height: 1;

        color: $mtb-white;
        font-size:  0.4em;
        background: $mtb-ui-green;
        padding: 0.5em 1em;
        border-radius: 1.5em;

         &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;

            border-left: 0.75em solid transparent;
            border-right: 0.75em solid transparent;
            border-top: 2em solid $mtb-ui-green;
            transform: translate(-50%, 80%);
            z-index: -1;

            pointer-events: none;
        }

    }

    .review-deco {
        position: absolute;
        left: 50%;
        bottom: -1em;
        transform: translateX(-50%);
        font-size:  0.4em;
        color: $mtb-white;

        span {
            background-color: #CC2662;
            border-radius: 1.5em;
            padding: 0.15em 0.6em 0.3em;
            border: 0.25em solid #A50C43;
        }

        &:after {
            content: '';
            display: block;
            width: 2.75em;
            height: 4em;
            background: url('~@/assets/gamemodes/practice/ui/mtb-ui_review-icon.svg') 50% 50% no-repeat;
            position: absolute;
            transform: translate(-45%, -115%);
            left: 50%;
            z-index: -1;
        }
    }

    &:hover {
        .review-deco {
            animation: jelly 0.75s ease 0s 1 normal forwards;
        }
    }

    @keyframes jelly {
        0% {
            transform: translateX(-50%) scale3d(1, 1, 1) scale(1);
        }

        30% {
            transform: translateX(-50%) scale3d(1.1, 0.85, 1) scale(1.1);
        }

        40% {
            transform: translateX(-50%) scale3d(0.85, 1.1, 1) scale(1.1);
        }

        50% {
            transform: translateX(-50%) scale3d(1.05, 0.90, 1) scale(1.1);
        }

        65% {
            transform: translateX(-50%) scale3d(0.975, 1.05, 1) scale(1.1);
        }

        75% {
            transform: translateX(-50%) scale3d(1.02, 0.95, 1) scale(1.1);
        }

        100% {
            transform: translateX(-50%) scale3d(1, 1, 1) scale(1.1);
        }
    }
}
</style>