<template>
    <section class="section-wrapper board-picker">
        <header class="section-nav-header">
            <button class="mtb-btn nav-action back-btn" @click="goback">Back</button>
            <h2>
                <i>
                    <span v-if="playthroughData">Continue </span>
                    <span v-else>Start New </span>
                    Playthrough (step 1/2):
                </i>
                <em>Connect Your Board Set</em>
            </h2>
            <button class="mtb-btn support-btn">What Do I Do?</button>
        </header>
        
        <article id="board-code-input">
            <span class="input-group" :class="{ valid: isValid }" >
                <label :class="{ show: hasLabel }">Enter Set Code</label>
                <input
                    ref="boardCodeInput"
                    required
                    type="text"
                    pattern="[A-Fa-f0-9]{6}"
                    @input="hexCheck($event)"
                    @blur="showLabel($event)"
                    @focus="hideLabel"
                />
            </span>
        </article>
        
        <p class="connect-note">Place any four character pieces on the startup board...<i>(you will assign players on the next step)</i></p>
        <button
            class="mtb-btn nav-action start-btn"
            :class="{ active: boardCode }"
            @click="startSession"
        >
            <em>Start Session</em>
        </button>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";

export default {
    name: 'ConnectBoard',
    props: {
    },
    computed: {
        ...mapGetters({
            activePlaythrough: 'getActivePlaythrough',
            me: 'getPlayerInfo',
            playthroughData: 'getPlaythroughConfiguration',
            cancelled: 'getUnhandledCancel'
        }),
        playerData () {
            return this.activePlaythrough?.players.reduce((obj, player) => {
                obj[player.personId] = player;
                return obj;
            }, {});
        }
    },
    data () {
        return {
            boardCode: this.$store.getters.getBoardCode,
            isValid: false,
            hasLabel: true,
            toast: useToast()
        };
    },
    mounted () {
        // backing out will have a pre-filled board code.
        if (this.boardCode) {
            this.$refs.boardCodeInput.value = this.boardCode;
            this.isValid = true;
            this.hideLabel();
        }
    },
    methods: {
        hexCheck (e) {
            this.isValid = e.target.checkValidity();
            
            if (this.isValid) {
                this.boardCode = e.target.value;
            } else {
                this.boardCode = null;
            }
        },
        showLabel (e) {
            if (e.target.value !== '') {
                return false;
            }
            this.hasLabel = true;
            return true;
        },
        hideLabel () {
            this.hasLabel = false;
        },
        goback () {
            this.$store.commit('setPlaythroughConfiguration', null);
            this.$router.back();
        },
        async startSession () {
            if (this.playthroughData?.playthroughId) {
                await this.$store.dispatch('appBusy', {message: 'Connecting Board...'});
            } else {
                await this.$store.dispatch('appBusy', {message: 'Waiting for everyone to connect their boards...', cancellable: true}); 
            }

            let cancel = false;

            this.onCancel = () => {
                cancel = true;
            };

            try {
                const
                    {inOtherRooms} = await this.$store.dispatch('joinGame', {
                        boardCode: this.boardCode,
                        cancel: () => cancel,
                        peerService: this.$peerService
                    });

                Object.keys(inOtherRooms).forEach((key, index) => {
                    const
                        players = inOtherRooms[key].map((playerId) => this.playerData[playerId].name);

                    setTimeout(() => this.toast.error(`${players.length > 1 ? `${players.join(', ')} are` : `${players[0]} is`} playing on board "${key}", but you're on "${this.boardCode}"`, {
                        timeout: 9000
                    }), index * 1000);
                });

                this.$router.push({
                    name: 'CharacterSelection'
                });

                this.$gameConsole.log('Using Board', this.boardCode);
            } catch (e) {
                this.$connectionConsole.error(e);
            }
            this.onCancel = null;
        }
    },
    watch: {
        cancelled (value) {
            if (value && this.onCancel) {
                this.onCancel();
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#board-code-input {
    .input-group {
        width: 45vw;
        height: 14.5vw;
        position: absolute;
        z-index: auto;
        right: 0;
        top: 50%;
        margin-top: -7.25vw;
        border: 1.5vw solid #ccc;
        border-right: none;
        border-radius: 1.5vw 0 0 1.5vw;
        background-color: #ccc;

        &.valid {
            border-color: #96C93D;
            background: #96C93D;
        }

        label{
            color: #E4E4E4;
            text-transform: uppercase;
            font-size: 4vw;
            line-height: 11.5vw;
            font-weight: bold;
            position: absolute;
            pointer-events: none;
            opacity: 0;
            z-index: 10;

            &.show {
                opacity: 1;
            }
        }

        input {
            font-size: 7vw;
            line-height: 1;
            text-align: center;
            padding: 1vw;
            border-radius: 1.5vw 0 0 1.5vw;
            position: absolute;
            z-index: 1;

            &:invalid {
                //background: yellow;
            }
            &:valid {
                //background: green;
            }
        }
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            background: url(~@/assets/ui/mtb_sample-connect-board.png) 50% 50% no-repeat;
            background-size: contain;
            width: 58vw;
            height: 32.5vw;
            left: -56vw;
            top: 0;
            margin-top: -2.25vw;
            z-index: -1;
        }

    }
}
    
.connect-note {
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em;
    white-space: nowrap;
    text-align: center;
    background: #0c0c0c;
    padding: 0.5em 1.5em;
    border-radius: 1.5em;
    width: fit-content;
    margin: auto;
    
    i {
        position: absolute;
        font-size: 0.6em;
        font-style: italic;
        left: 50%;
        bottom: -50%;
        transform: translateX(-50%);
    }
}
    
</style>
