/* eslint-disable no-process-env */
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import CharacterSelection from '@/views/connect-ux/CharacterSelection.vue';
import ConnectBoard from '@/views/connect-ux/ConnectBoard.vue';
import ContinuePlaythrough from '../views/ContinuePlaythrough.vue';
import GameView from '../views/GameView.vue';
import Lander from '../views/Lander.vue';
import PlayerLogin from '@/views/PlayerLogin.vue';
import store from '@/store/';

const
    ifAuthenticated = (to, from, next) => {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next('/login');
    },
    ifAuthenticatedOnLogin = (to, from, next) => {
        if (store.getters.isAuthenticated) {
            next('/start');
            return;
        }
        next();
    },
    history = window.top.cordova ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes = [
        {
            path: '/',
            name: 'Home',
            redirect: '/start',
            children: [
                {
                    path: '/login',
                    name: 'Login',
                    component: PlayerLogin,
                    beforeEnter: ifAuthenticatedOnLogin
                },
                {
                    path: '/start',
                    name: 'Start',
                    component: Lander,
                    beforeEnter: ifAuthenticated
                },
                {
                    path: '/start/connect-board',
                    name: 'ConnectBoard',
                    component: ConnectBoard,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/start/pick-character',
                    name: 'CharacterSelection',
                    component: CharacterSelection,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/play',
                    name: 'Play',
                    component: GameView,
                    beforeEnter: ifAuthenticated,
                    props: true
                },
                {
                    path: '/play/continue',
                    name: 'Continue',
                    component: ContinuePlaythrough,
                    beforeEnter: ifAuthenticated,
                    props: true
                }
                
                
            ]
        }
    ],
    router = createRouter({
        history,
        routes
    });

export default router;
