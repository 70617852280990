import dpeService from "@/shared/dpeService";
import router from '@/router/';

const
    PRESENCE_TIME = 10000, // check for presence every 10 seconds.
    CHECK_SESSION_STATUS_TIME = 5 * 60 * 1000, // after more than 5 minutes with no presence check, make sure the session is still alive.
    allByMyself = [],
    detectRefereeSpeed = [],
    getLastReferee = () => {
        const
            possiblyRef = dpeService.players.slice().sort(function (a, b) {
                return (dpeService.state[a]?.gameState?.heartbeat ?? 0) - (dpeService.state[b]?.gameState?.heartbeat ?? 0); // since all referees will have posted a heartbeat this session. We want to make sure the last heartbeat is what we use for the final merge below.
            }).pop();

        if (dpeService.state[possiblyRef]?.gameState?.heartbeat) {
            return possiblyRef;
        } else {
            return null;
        }
    },
    isEqual = (x, y) => {
        const
            ok = Object.keys,
            tx = typeof x,
            ty = typeof y;

        return x && y && tx === 'object' && tx === ty ? (ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key]))) : (x === y);
    },
    propertyChanged = (original, incoming, ...crumbs) => {
        let last = original?.[crumbs[0]],
            current = incoming?.[crumbs[0]];

        for (let i = 1; i < crumbs.length; i++) {
            last = last?.[crumbs[i]];
            current = current?.[crumbs[i]];
        }

        if (!isEqual(last, current)) {
            return {
                last,
                value: current
            };
        } else {
            return null;
        }
    },
    referee = {
        state: () => ({
            missing: false,
            referee: null,
            session: null,
            activity: {},
            progression: {},
            speed: {
                time: 0,
                transport: ''
            }
        }),

        actions: {
            async initializeReferee ({dispatch}) {
                relayHandlers = {
                    ...await dispatch('initializeCharacters'),
                    ...await dispatch('initializeComics'),
                    ...await dispatch('initializeDecisions'),
                    ...await dispatch('initializeMission'),
                    ...await dispatch('initializePractice'),
                    ...await dispatch('initializePuck'),
                    'heartbeat': () => {}, // We don't do anything with the heartbeat: we just detect `lastUpdated` for presence checks.
                    'id': () => {}, // We don't do anything with the id directly: it just helps keep older states overwriting newer states. (from DPE/P2P dupes, identical clients, or referee swaps) Also running this last so the id doesn't change until the end so other updates are compared first in the relay handling. (After this, ids will match.)
                };
            },

            async startReferee ({commit, dispatch, getters, rootState, state}, {peerService}) { // Set up 2-way communication with DPE and store.
                const
                    lastReferee = getLastReferee(),
                    playerId = rootState.player.playerId,
                    onFieldChange = async (value) => {
                        const
                            onceUpdated = await dispatch('updateFromDPE', value);

                        dispatch('updatePlayerActivity', {
                            [value.player]: Date.now(),
                        });
                        commit('setActiveSession', JSON.parse(JSON.stringify(dpeService.state)));
                        onceUpdated.forEach((value) => value());
                    },
                    onPeerUpdate = async ({field, state, peer}) => {
                        const
                            onceUpdated = await dispatch('updateFromDPE', {
                                field,
                                value: state,
                                player: peer,
                                p2p: true
                            });
                            
                        dispatch('updatePlayerActivity', {
                            [peer]: Date.now(),
                        });
                        onceUpdated.forEach((value) => value());
                    },
                    checkDPESpeed = (state) => {
                        detectRefereeSpeed.forEach((item) => {
                            if (item.dpe === -1) {
                                item.dpe = state.id;
                            }
                        });
                    },
                    checkP2PSpeed = (state) => {
                        detectRefereeSpeed.forEach((item) => {
                            if (item.p2p === -1) {
                                item.p2p = state.id;
                            }
                        });
                    },
                    updates = [];

                dpeService.on('fieldChange', onFieldChange);
                dpeService.on('update-game-state', checkDPESpeed);
                dpeService.on("sessionEnded", (/*{sessionId}*/) => {
                    console.warn("Another client ended the game; leaving session.");
                    dispatch('leaveGame');
                    router.push({
                        name: 'Start'
                    });
                });
        
                if (peerService) {
                    peerService.on('peer-updated', onPeerUpdate);
                    dpeService.on('transitional-game-state', checkP2PSpeed);
                }
                off = () => {
                    dpeService.off('fieldChange', onFieldChange);
                    dpeService.off('update-game-state', checkDPESpeed);
                    if (peerService) {
                        peerService.off('peer-updated', onPeerUpdate);
                        dpeService.off('transitional-game-state', checkP2PSpeed);
                    }
                    off = null;
                };

                if (state.referee === playerId) {
                    dispatch('setMeAsRefereeOnDPE');
                } else if (dpeService.state[playerId]?.gameState?.referee) {
                    dpeService.updateGameState('referee', false);

                    // setting this to `false` so updates below don't incorrectly post this player as the referee.
                    dpeService.state[playerId].gameState.referee = false;
                }

                // Need to get the current state of things and update game's state to match. This isn't important for a new session, but is for restoring a dropped client connection.
                for (let i = 0; i < dpeService.players.length; i++) {
                    const
                        player = dpeService.players[i],
                        state = dpeService.state[player],
                        keys = Object.keys(state);

                    for (let j = 0; j < keys.length; j++) {
                        const
                            field = keys[j];

                        updates.push(...await dispatch('updateFromDPE', {
                            field,
                            value: state[field],
                            player,
                            fromReferee: lastReferee === player,
                            wasReferee: lastReferee === playerId
                        }));
                    }
                }

                commit('setActiveSession', JSON.parse(JSON.stringify(dpeService.state)));
                updates.forEach((value) => value());

                // We also need to restore local state
                dispatch('syncStoreWithLocalState');

                // Keep presence updated while connected to a session
                updatePresence = setInterval(async () => {
                    const
                        stayAlive = dpeService.state[playerId].localState.sa,
                        lastPresenceCheck = Date.now();

                    // Make sure we're not trying to reactivate an old session (like if the browser tab was left open and we're returning)
                    if (stayAlive && ((lastPresenceCheck - stayAlive) > CHECK_SESSION_STATUS_TIME)) {
                        const
                            sessionStatus = await dpeService.presence.getSession(dpeService.currentSessionId);

                        if (!sessionStatus.isActive) {
                            console.warn("We've been disconnected too long; leaving session.");
                            dispatch('leaveGame', true);
                            router.push({
                                name: 'Start'
                            });

                            return;
                        }
                    }

                    // update stay-alive (side-effect keeps session active without updating everyone)
                    dpeService.updateLocalState('sa', lastPresenceCheck);

                    await dispatch('updatePlayerActivity', {
                        [playerId]: lastPresenceCheck // Since this player doesn't receive server updates, we auto-update. This also serves to update dependencies on activity.
                    });

                    // Am I the only one here? If so, include immediateUpdate relays.
                    if (Object.keys(getters.getPlayersHere).reduce((prev, key) => prev + getters.getPlayersHere[key], 0) <= 1) {
                        while (allByMyself.length) {
                            allByMyself.shift()();
                        }
                    } else {
                        allByMyself.length = 0;
                    }

                    // Send heartbeat
                    if (state.referee === playerId) {
                        dispatch('proposeUpdate', {
                            heartbeat: Date.now()
                        });
                    }
                }, PRESENCE_TIME / 2.1);
            },

            stopReferee ({commit}) {
                clearInterval(updatePresence);
                commit('setReferee', null);
                commit('setActiveSession', null);
                commit('setPlayerActivity', {});
                commit('setPlayerProgression', {});
                commit('setMissing', false);
                if (off) { // close DPE game connection.
                    off();
                }
            },
    
            async updateFromDPE ({commit, rootState, state}, {field, value, player, p2p = false, fromReferee = false, wasReferee = false}) {
                const
                    onceUpdated = [];

                if (field === 'gameState') {
                    const
                        me = rootState.player.playerId,
                        getIsNewState = (current, incoming) => (typeof incoming?.id === 'number') && (incoming.id > (current?.id ?? -1)),
                        sendNewState = getIsNewState(state.progression[player], value),
                        check = (property, ifChanged) => {
                            const
                                crumbs = property.split('.'),
                                change = propertyChanged(state.session?.[player]?.[field], value, ...crumbs);
    
                            if (change) {
                                ifChanged(change);
                            }

                            return change;
                        },
                        relay = (property, ifRelayed) => {
                            // Drop incoming changes into the reference bucket.
                            if (property !== 'id' || needsRelay) { // "id" is checked last. Lets not update *just* the id if there are no other updates.
                                check(property, (change) => {
                                    const
                                        crumbs = property.split('.');

                                    // Relay to other clients...
                                    dpeService.updateGameState('reference', player, ...crumbs, change.value);
                                    needsRelay = true;

                                    // Update store (since the change won't come in below)
                                    onceUpdated.push(ifRelayed.bind(null, {
                                        ...change,
                                        player
                                    }));
                                });
                            }
                        },
                        accept = (player, property, ifAccepted) => check(`reference.${player}.${property}`, (change) => {
                            onceUpdated.push(ifAccepted.bind(null, {
                                ...change,
                                player
                            }));
                        }),
                        updateStoreForPlayer = (playerId) => {
                            if (value.reference[playerId]) { // has a reference for this player
                                const
                                    id = value.reference[playerId].id;
                                let updated = false;

                                commit('setPlayerProgression', {
                                    ...state.progression,
                                    [playerId]: {
                                        id
                                    }
                                });
                                for (const key in relayHandlers) {
                                    if (Object.prototype.hasOwnProperty.call(relayHandlers, key)) {
                                        updated = accept(playerId, key, relayHandlers[key]) || updated;
                                    }
                                }
                                if (playerId === me) {
                                    const
                                        refSpeed = detectRefereeSpeed.filter((item) => (p2p ? item.p2p <= id : item.dpe <= id)),
                                        count = refSpeed.length;

                                    if (count) {
                                        commit('setRefereeSpeed', {
                                            time: Date.now() - refSpeed[count - 1].began,
                                            transport: p2p ? 'P2P' : 'DPE'
                                        });
                                        detectRefereeSpeed.length = 0;
                                    }
                                }
                            }
                        };
                    let needsRelay = false; // Don't want Referee to stall and make own gameState changes before relaying
    
                    // Doing the REFEREE thing...
                    check('referee', ({value}) => {
                        if ((state.referee === player) && !value) { // no longer the referee
                            commit('setReferee', null);
                        } else if (!state.referee && value) { // set up the referee
                            commit('setReferee', player);
                        }
                    });

                    // If I'm the referee or if it's from the referee
                    if ((me === state.referee) || (player === state.referee) || fromReferee || wasReferee) {
                        if (sendNewState) {
                            commit('setPlayerProgression', {
                                ...state.progression,
                                [player]: {
                                    id: value.id
                                }
                            });
                            
                            for (const key in relayHandlers) {
                                if (Object.prototype.hasOwnProperty.call(relayHandlers, key)) {
                                    relay(key, relayHandlers[key]);
                                }
                            }
                        } else {
                            console.warn(`Has ${state.progression[player]?.id} but incoming reported to be ${value?.id}`);
                        }

                        // If this is coming from the referee, update the store.
                        if (player === state.referee || fromReferee) {
                            dpeService.players.filter((playerId) => getIsNewState(state.progression[playerId], value.reference?.[playerId])).forEach(updateStoreForPlayer);
                        
                        // or a reference change from a non-referee for the referee, update the store.
                        } else if ((me === state.referee || wasReferee) && getIsNewState(state.progression[me], value.reference?.[me])) {
                            updateStoreForPlayer(me);
                        }
                    }

                    if (needsRelay) {
                        await dpeService.forceUpdate();
                    }
                }

                return onceUpdated;
            },

            updatePlayerActivity ({commit, state}, newActivity) {
                commit('setPlayerActivity', {
                    ...state.activity,
                    ...newActivity
                });
            },
    
            async immediateUpdate ({dispatch}, updates) {
                await dispatch('proposeUpdate', updates);

                allByMyself.push(() => {
                    dispatch('makeLocalUpdate', updates);
                });

                return dpeService.forceUpdate();
            },
    
            proposeUpdate (store/*{dispatch, rootState, state}*/, updates) {
                for (const key in updates) {
                    if (Object.prototype.hasOwnProperty.call(updates, key)) {
                        dpeService.updateGameState(...key.split('.'), updates[key]);
                    }
                }

                detectRefereeSpeed.push({
                    began: Date.now(),
                    p2p: -1,
                    dpe: -1
                });
            },
    
            makeLocalUpdate ({rootState}, updates) {
                const
                    player = rootState.player.playerId,
                    snapshot = JSON.stringify(dpeService.state?.[player]?.localState),
                    oldState = JSON.parse(snapshot),
                    newState = JSON.parse(snapshot),
                    check = (property, ifChanged) => {
                        const
                            crumbs = property.split('.'),
                            change = propertyChanged(oldState, newState, ...crumbs);

                        if (change) {
                            ifChanged({
                                ...change,
                                player
                            });
                        }
                    };

                for (const key in updates) {
                    if (Object.prototype.hasOwnProperty.call(updates, key)) {
                        dpeService.updateLocalState(...key.split('.'), updates[key]);
                    }
                }

                if (dpeService.localStateUpdates.reduce((previous, mutation) => mutation(newState) || previous, false)) {
                    for (const key in relayHandlers) {
                        if (Object.prototype.hasOwnProperty.call(relayHandlers, key)) {
                            check(key, relayHandlers[key]);
                        }
                    }
                }
            },

            syncStoreWithLocalState  ({rootState}) {
                const
                    player = rootState.player.playerId,
                    snapshot = JSON.stringify(dpeService.state?.[player]?.localState),
                    oldState = {}, // Check against nothing
                    newState = JSON.parse(snapshot),
                    check = (property, ifChanged) => {
                        const
                            crumbs = property.split('.'),
                            change = propertyChanged(oldState, newState, ...crumbs);

                        if (change) {
                            ifChanged({
                                ...change,
                                player
                            });
                        }
                    };

                for (const key in relayHandlers) {
                    if (Object.prototype.hasOwnProperty.call(relayHandlers, key)) {
                        check(key, relayHandlers[key]);
                    }
                }
            },
    
            setMeAsReferee ({commit, dispatch, rootState, state}, value) {
                const
                    me = rootState.player.playerId;
    
                if (value) {
                    commit('setReferee', me);
                    if (state.session) {
                        dispatch('setMeAsRefereeOnDPE');
                    }
                } else if (state.referee === me) {
                    commit('setReferee', null);
                    if (state.session) {
                        dpeService.updateGameState('referee', false);
                        dpeService.forceUpdate(); // Need to know asap if referee is no longer referee.
                    }
                } else {
                    console.warn('You cannot remove another referee');
                }
            },

            setMeAsRefereeOnDPE () {
                const
                    state = dpeService.state,
                    mergedReferences = dpeService.players.slice().sort(function (a, b) {
                        return (state[a]?.gameState?.heartbeat ?? 0) - (state[b]?.gameState?.heartbeat ?? 0); // since all referees will have posted a heartbeat this session. We want to make sure the last heartbeat is what we use for the final merge below.
                    }).reduce((reference, playerId) => ({
                        ...reference,
                        ...state[playerId]?.gameState?.reference ?? {}
                    }), {});
                    
                dpeService.updateGameState('referee', true);

                // Now, just in case there was a previous referee, let's grab reference state from the other players and give it to ourself.
                delete mergedReferences[state.me]; // remove mine since I'm authoritative for myself already.
                dpeService.updateGameState('reference', mergedReferences);
            },

            clientsReady ({commit}) {
                commit('setMissing', false);
            },

            clientsMissing ({commit}) {
                commit('setMissing', true);
            }
        },

        mutations: {
            setActiveSession (state, value) {
                state.session = value;
            },
    
            setReferee (state, value) {
                state.referee = value;
            },

            setPlayerActivity (state, value) {
                state.activity = value;
            },

            setPlayerProgression (state, value) {
                state.progression = value;
            },

            setRefereeSpeed (state, value) {
                state.speed = value;
            },

            setMissing (state, value) {
                state.missing = value;
            }
        },

        getters: {
            getActiveSession (state) {
                return state.session;
            },
            getIsReferee (state, getters, rootState) {
                return state.referee === rootState.player.playerId;
            },
            getReferee (state) {
                return state.referee;
            },

            getRefereeSpeed (state) {
                return state.speed;
            },

            getPlayersActivity (state) {
                return state.activity;
            },

            getPlayersHere (state) {
                const
                    now = Date.now(),
                    keys = Object.keys(state.activity);

                return keys.reduce((prev, key) => ({
                    ...prev,
                    [key]: (now - state.activity[key]) < PRESENCE_TIME
                }), {});
            },

            isEveryonePresent (state, {getPlayersHere}) {
                const
                    presence = Object.keys(getPlayersHere);

                return presence.reduce((prev, key) => prev + getPlayersHere[key], 0) === presence.length;
            },

            getMissing (state) {
                return state.missing;
            }
        }
    };
let off = null,
    relayHandlers = {},
    updatePresence = 0;

export {referee};