/* eslint-disable no-prototype-builtins */
const
    puck = {
        state: () => ({
            // This is puck positions acquired via BLE.
            authoritativePositions: [],

            // This is puck positions acquired via relay.
            relayedPositions: [],

            puckLEDInstructions: null,

            puckStateInstructions: null,

            puckA: null,

            puckB: null,

            puckC: null,

            puckD: null
        }),

        actions: {
            initializePuck ({commit, dispatch}) {
                return {
                    'puck.data': ({value, player}) => dispatch('updateRelayedPositions', {
                        player,
                        value
                    }),
                    'puck.led': ({value}) => commit('setPuckLEDInstructions', value),
                    'puck.state': ({value}) => commit('setPuckStateInstructions', value)
                };
            },

            updateAuthoritativePositions ({commit, dispatch, getters, state}, data) {
                if (getters.getIsReferee && (JSON.stringify(state.authoritativePositions) !== JSON.stringify(data))) {
                    const
                        copy = data.slice();

                    commit('setAuthoritativePositions', copy);
                    dispatch('immediateUpdate', {
                        'puck.data': copy
                    });
                }
            },

            updateRelayedPositions ({commit, getters}, positions) {
                if (!positions) {
                    console.warn('Tried to update pucks without position value.');
                    return;
                }

                const
                    characters = getters.getCharacters,
                    value = positions.value,
                    data = value.buffer ? new Int16Array(value.buffer) : value;

                commit('setRelayedPositions', data);
                
                characters.forEach((character, index) => {
                    const
                        extra = data[(index * 2) + 1],
                        value = {
                            ...character,
                            button: (extra >> 13) & 3,
                            connected: !!(extra >> 15),
                            position: data[(index * 2) + 0],
                            rotation: extra & 0x1ff
                        };
        
                    commit(`setPuck${character.puck}`, value);
                });
    
            },

            updatePuckLEDInstructions ({dispatch}, data) {
                dispatch('proposeUpdate', {
                    'puck.led': data
                });
            },

            updatePuckStateInstructions ({dispatch}, data) {
                dispatch('proposeUpdate', {
                    'puck.led': data
                });
            }
        },

        mutations: {
            setAuthoritativePositions (state, data) {
                state.authoritativePositions = data;
            },

            setRelayedPositions (state, data) {
                state.relayedPositions = data;
            },

            setPuckA (state, data) {
                state.puckA = data;
            },

            setPuckB (state, data) {
                state.puckB = data;
            },

            setPuckC (state, data) {
                state.puckC = data;
            },

            setPuckD (state, data) {
                state.puckD = data;
            },

            setPuckLEDInstructions (state, data) {
                state.puckLEDInstructions = data;
            },

            setPuckStateInstructions (state, data) {
                state.puckStateInstructions = data;
            }
        },

        getters: {
            getAuthoritativePositions (state) {
                return state.authoritativePositions;
            },

            getRelayedPositions (state) {
                return state.relayedPositions;
            },

            getPuckA (state) {
                return state.puckA;
            },

            getPuckB (state) {
                return state.puckB;
            },

            getPuckC (state) {
                return state.puckC;
            },

            getPuckD (state) {
                return state.puckD;
            },

            getPuckLEDInstructions (state) {
                return state.puckLEDInstructions;
            },

            getPuckStateInstructions (state) {
                return state.puckStateInstructions;
            }
        }
    };

export {puck};