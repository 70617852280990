class Player {
    constructor (game, playerData, playerIndex, controller, vueData) {
        this.game = game;
        this.vueData = vueData;

        this.playerIndex = playerIndex;
        this.name = playerData.player;
        //this.character = playerData.character;

        this.myData = vueData.players[this.playerIndex];
        this.myData.character = playerData.character;
        // this.myData = {
        //     character: playerData.character,
        //     validPosition: false,
        //     locked: false,
        //     position: {
        //         region: 0,
        //         rotation: 0
        //     },
        //     lockedPosition: {
        //         region: 0,
        //         rotation: 0
        //     },
        //     setupPosition: {
        //         region: 0,
        //         rotation: 0
        //     }
        // };
        
        //this.vueData.players.push(this.myData);


        // this.position = {
        //     "region": 0,
        //     "rotation": 0
        // };
        // this.locked = false;
        // this.lockedPosition = {
        //     "region": 0,
        //     "rotation": 0
        // };
        //this.validPosition = false;

        this.prevPositions = [];
        // this.setupPosition = {
        //     "region": 0,
        //     "rotation": 0
        // };
        
        controller.on(`PuckPosition${playerData.puck}`, ({value}) => {
            this.updatePosition({
                "region": value,
                "rotation": this.myData.position.rotation
            });
        });
        controller.on(`PuckRotation${playerData.puck}`, (/*{value}*/) => {
            //TML - TEMPORARY HACK TO IGNORE THE ROTATION VALUE! RESTORE THIS IF/WHEN WE NEED TO PAY ATTENTION TO ROTATION!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            this.updatePosition({
                "region": this.myData.position.region,
                "rotation": 0 //value
            });
        });
        controller.on(`PuckConnection${playerData.puck}`, () => {
        });

    }

    updatePosition ({region, rotation}) {
        console.log(`${this.name} (${this.myData.character}) moved to ${region}`);
        this.myData.position.region = region;
        this.myData.position.rotation = rotation;
        
        this.checkPositionValidity();
        //this.game.updateCanSubmitState(this.playerIndex, this.myData.validPosition);
    }

    lockPosition ({region, rotation}) {
        this.myData.locked = true;
        this.myData.lockedPosition.region = region;
        this.myData.lockedPosition.rotation = rotation;

        console.log(`${this.name} (${this.myData.character}) locked into region ${region}`);
    }

    unlockPosition () {
        this.myData.locked = false;
        this.checkPositionValidity();
    }

    reset (setupPosition) {
        this.myData.validPosition = false;
        this.myData.locked = false;

        if (setupPosition) {
            this.myData.setupPosition.region = setupPosition.region;
            this.myData.setupPosition.rotation = setupPosition.rotation;
        }
    }

    checkPositionValidity () {
        this.myData.validPosition = true;

        if (this.vueData.state === 'waiting-on-game-setup') {
            if (this.myData.position.region !== this.myData.setupPosition.region || this.myData.position.rotation !== this.myData.setupPosition.rotation) {
                this.myData.validPosition = false;
                this.game.playerOutOfPlace('wrong-setup-position', this.playerIndex);
                return;
            }

        } else if (this.vueData.state === 'turn-play') {
            if (this.myData.locked) {
                if (this.myData.position.region !== this.myData.lockedPosition.region) {
                    //You can't move your piece after you've locked its position.
                    this.myData.validPosition = false;
                    this.game.playerOutOfPlace('locked', this.playerIndex);
                    return;
                }
            } else {
                //You have to move to a new position!
                let toCompare = null;
                if (this.prevPositions.length === 0) {
                    toCompare = this.myData.setupPosition;
                } else {
                    toCompare = this.prevPositions[this.prevPositions.length - 1];
                }

                if (this.myData.position.region === toCompare.region && this.myData.position.rotation === toCompare.rotation) {
                    this.myData.validPosition = false;
                    this.game.playerOutOfPlace('same-region', this.playerIndex);
                    return;
                }

                if (this.myData.position.region === 0 || this.myData.position.region === -1) {
                    this.myData.validPosition = false;
                    return;
                }
            }

        }

        //OTHER VALIDITY CHECKS NEEDED:
        //-Can you move from your previous position to this new position?
        //-Is someone else in this spot already?
        //-Is there an obstacle in your way?

        
        
    }

    setPrevPositions (newPrevPositions) {
        this.prevPositions = newPrevPositions;
    }
}

export default Player;