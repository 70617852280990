export default {
    "id": "band-arrow",
    "components": [
        {
            "type": "RenderSprite",
            "spriteSheet": "band",
            "animationMap": {
                "good": "mtb_music-mode_speaker-arrow_playing",
                "bad": "mtb_music-mode_speaker-arrow_playing-issue",
                "default": "none"
            },
            "rotate": true
        }],
    "properties": {
        renderParent: "the-band",
        x: 0,
        y: 0,
        z: -1,
        scaleX: 1,
        scaleY: 1
        
    }
};