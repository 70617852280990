import {devices} from '@makefully/engagefully';
import pilotIdMap from '@/shared/data/pilot-id-map.json';
import serviceDefinition from './service.json';

const
    characteristicMap = (() => {
        const
            map = {};

        serviceDefinition.characteristics.forEach((characteristic) => {
            map[characteristic.uuid] = characteristic;
        });

        return map;
    })(),
    createFilter = (boardCode) => {
        if (boardCode) {
            const
                filter = (code) => {
                    const
                        c = (typeof code === 'string') ? +`0x${code}` : code,
                        boardCodeArray = new Uint8Array(3);

                    boardCodeArray[0] = c >> 16 & 0xff;
                    boardCodeArray[1] = c >> 8 & 0xff;
                    boardCodeArray[2] = c & 0xff;
            
                    return {
                        companyIdentifier: 65535,
                        dataPrefix: boardCodeArray,
                        mask: boardCodeArray
                    };
                },
                manufacturerData = [];

            if (pilotIdMap[boardCode]) {
                manufacturerData.push(filter(pilotIdMap[boardCode]));
            } else {
                manufacturerData.push(filter(boardCode));
            }

            //filter.acceptAllDevices = false;
            return {
                acceptAllDevices: false,
                filters: [{
                    manufacturerData
                }],
                optionalServices: [
                    'battery_service',
                    serviceDefinition.uuid
                ]
            };
        } else {
            return {
                acceptAllDevices: false,
                filters: [{
                    services: [serviceDefinition.uuid]
                }],
                optionalServices: [
                    'battery_service'
                ]
            };
        }
    };

class MakefullyPuck extends devices.BLEDevice {
    constructor (boardCode) {
        super(createFilter(boardCode), 'MakefullyPuck', characteristicMap);

        this.connections = [0, 0, 0, 0];
        this.writing = false;
        this.writingQueue = [];

        this.on('updated', ({control, device, value}) => {
            if (control === 'MakefullyPuckConnection') {
                // Need to receive first full puck notification before we know who is connected apart from the MP, so if we get this event, default all child pucks to connected of `0`.
                const
                    data = new Int16Array(8);

                data[1] = value << 15;

                this.emit('updated', {
                    control: `PuckNotification`,
                    device,
                    type: 'Data',
                    value: data
                });

                if (value === 0) {
                    this.device = null; // force a reconnect to require getting a new device - causes same flow as original connect instead of auto-connecting, esp. useful if a new puck needs to be used.
                } else { // make sure on restart that main puck is activated.
                    this.setState({
                        state: 'ready',
                        pucks: [0]
                    });
                }
            } else if (control === 'PuckNotification') {
                const
                    pucks = ['A', 'B', 'C', 'D'],
                    data = value.buffer ? new Int16Array(value.buffer) : value;
            
                for (let i = 0; i < pucks.length; i++) {
                    const
                        extra = data[(i * 2) + 1],
                        rotation = extra & 0x1ff,
                        connected = i === 0 ? 1 : !!(extra >> 15),
                        button = (extra >> 13) & 3;

                    this.emit('updated', {
                        control: `PuckRotation${pucks[i]}`,
                        device,
                        type: 'Dial',
                        value: rotation
                    });
                    this.emit('updated', {
                        control: `PuckConnection${pucks[i]}`,
                        device,
                        type: 'Button',
                        value: connected
                    });
                    this.emit('updated', {
                        control: `PuckButton${pucks[i]}`,
                        device,
                        type: 'Amount',
                        value: button
                    });
                    this.emit('updated', {
                        control: `PuckPosition${pucks[i]}`,
                        device,
                        type: 'Amount',
                        value: data[(i * 2) + 0]
                    });

                    // If it's a new connection...
                    if (connected !== this.connections[i]) {
                        this.connections[i] = connected;
                        if (connected) {
                            const
                                pucks = [i];

                            this.setState({
                                state: 'ready',
                                pucks
                            });
                        }
                    }
                }
            }
        });
    }

    initialize (boardCode) {
        return super.initialize(createFilter(boardCode));
    }

    waitOnWrite (cmd, value) {
        const
            tryWrite = async () => {
                try {
                    await this.write(cmd, value);
                } catch (e) {
                    if (e.name !== 'NetworkError') { // gatt disconnected while waiting
                        console.warn('Retrying puck state update after error... ', e);
                        setTimeout(tryWrite, 1000);
                        return;
                    } else {
                        console.warn('Puck state update error... ', e);
                    }
                }
                if (this.writingQueue.length) {
                    setTimeout(this.writingQueue.shift(), 250); // adding a delay due to rapid-fire writes not functioning.
                } else {
                    this.writing = false;
                }
            };

        if (this.writing) {
            this.writingQueue.push(tryWrite);
        } else {
            this.writing = true;
            tryWrite();
        }
    }

    setState (...args) {
        const
            cmd = new Uint8Array(16);

        for (let i = 0; i < args.length; i++) {
            const
                pucksDefault = args.length === 1 ? [0, 1, 2, 3] : [i],
                {state, pucks = pucksDefault} = args[i],
                modes = {
                    initial: 1,
                    ready: 2,
                    start: 3,
                    sleep: 4
                },
                selection = modes[state];

            if (pucks.length) {
                if (selection) {
                    pucks.forEach((value) => {
                        cmd[(value * 4) + 3] = (selection << 4) & 0xf0;
                    });
                } else {
                    throw new Error(`"${state}" is not a defined command. Available options are "${Object.keys(modes).join('", "')}".`);
                }
            } else {
                throw new Error('One or more pucks must be chosen to send a command.');
            }
        }

        this.waitOnWrite('PuckState', cmd);
    }

    setLED (...args) {
        const
            cmd = new Uint8Array(16);

        for (let i = 0; i < args.length; i++) {
            const
                pucksDefault = args.length === 1 ? [0, 1, 2, 3] : [i],
                {state = 'static', color = 0xffffff, pucks = pucksDefault, luminosity = 1} = args[i],
                modes = {
                    static: 1,
                    'slow-blink': 2,
                    'fast-blink': 3,
                    'slow-breathe': 4,
                    'fast-breathe': 5
                },
                selection = modes[state];
    
            if (pucks.length) {
                if (selection) {
                    const
                        c = (typeof color === 'string') ? +`0x${color.replace('#', '')}` : color;
                    let r = (c >> 16) & 0xff,
                        g = (c >> 8) & 0xff,
                        b = c & 0xff;
                    
                    if ((luminosity >= 0) && (luminosity <= 1)) {
                        const
                            mag = Math.sqrt(r * r + g * g + b * b);
                        
                        if (mag > 0) {
                            r = ((r / mag) * luminosity * 255) >> 0;
                            g = ((g / mag) * luminosity * 255) >> 0;
                            b = ((b / mag) * luminosity * 255) >> 0;
                        }
                    }
                    
                    pucks.forEach((value) => {
                        const
                            offset = value * 4;
    
                        cmd[offset + 0] = b;
                        cmd[offset + 1] = g;
                        cmd[offset + 2] = r;
                        cmd[offset + 3] = selection & 0xf;
                    });
                } else {
                    throw new Error(`"${state}" is not a defined state. Available options are "${Object.keys(modes).join('", "')}".`);
                }
            } else {
                throw new Error('One or more pucks must be chosen to send a command.');
            }
        }

        this.waitOnWrite('PuckState', cmd);
    }
}

export default MakefullyPuck;
