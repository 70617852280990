import {createComponentClass} from 'platypus';

export default createComponentClass({
    
    id: 'DebugCamera',
    
   
    properties: {
        
    },
    
   
    publicProperties: {
        courseProgress: 0,
        speed: 0.5,
        x: 0,
        y: 0
    },
    
    initialize: function (/*definition, callback*/) {
        this.pans = {};
    },

    events: {
        "reset": function (band) {
            this.courseProgress = band.courseProgress;
            this.courseData = band.courseData;
            this.x = band.x;
            this.y = band.y;
        },
        "begin-pan": function (value) {
            this.pans[`pan${value}`] = true;
        },
        "end-pan": function (value) {
            this.pans[`pan${value}`] = false;
        },

        "handle-logic": function ({delta}) {
            const
                panB = this.pans['pan-1'] ? -1 : 0,
                panF = this.pans.pan1 ? 1 : 0,
                pan = panB + panF;

            if (pan) {
                const
                    cd = this.courseData,
                    movement = pan * this.speed * delta,
                    lengthUnit = this.owner.parent.parent.settings.level.musicalUnitLength;
                let edgeIndex = 0,
                    currentEdge = cd.vectors[edgeIndex],
                    edgeLength = cd.vectorLengths[edgeIndex] * lengthUnit,
                    edgeProgress = this.courseProgress = this.courseProgress + movement;

                while (currentEdge && (edgeProgress > edgeLength)) {
                    edgeProgress -= edgeLength;
                    edgeIndex += 1;
                    currentEdge = cd.vectors[edgeIndex];
                    edgeLength = cd.vectorLengths[edgeIndex] * lengthUnit;
                }
                
                if (currentEdge && edgeProgress >= 0) {
                    this.owner.x = cd.origin.x + cd.points[edgeIndex].x + currentEdge.x * edgeProgress;
                    this.owner.y = cd.origin.y + cd.points[edgeIndex].y + currentEdge.y * edgeProgress;
                } else {
                    this.courseProgress -= movement;
                }
            }
        }
    },
    
    methods: {
       
        
    },
    
    publicMethods: {
        
        
    }
});
