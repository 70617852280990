import {Logger} from './logService';
import Peer from 'simple-peer';

/**
 *
 */
export default class PeerConnection extends Peer {
    //constructor (room, myId, myVideo, otherVideo) {
    constructor (onSignal, onError, recipient, incomingSignal) {

        super(incomingSignal ? {
            initiator: false,
            objectMode: true,
            trickle: false
        } : {
            initiator: true,
            objectMode: true,
            trickle: false
        });

        this.console = new Logger('connection');
        this.on('signal', onSignal);
        this.on('error', (err) => {
            this.console.warn(`${err.code} (with ${recipient})`, err);

            switch (err.code) { // In case we eventually want to handle these in a particular way automatically.
            case "ERR_WEBRTC_SUPPORT":
            case "ERR_CREATE_OFFER":
            case "ERR_CREATE_ANSWER":
            case "ERR_SET_LOCAL_DESCRIPTION":
            case "ERR_SET_REMOTE_DESCRIPTION":
            case "ERR_ADD_ICE_CANDIDATE":
            case "ERR_ICE_CONNECTION_FAILURE":
            case "ERR_SIGNALING":
            case "ERR_DATA_CHANNEL":
            case "ERR_CONNECTION_FAILURE":
            }

            onError(err);
        });
        this.on('data', (data) => {
            let processedData = data;

            if (typeof data === 'string') {
                try {
                    processedData = JSON.parse(data);
                } catch (e) {
                    //
                }
            }
            if (processedData.event) {
                this.emit(processedData.event, processedData);
            }
        });

        if (incomingSignal) {
            this.signal(incomingSignal);
        }

        this.id = recipient;
    }

    send (data) {
        const processedData = (data instanceof Object) ? JSON.stringify(data) : data;

        try {
            super.send(processedData);
        } catch (e) {
            this.console.warn('Error sending to peer.', e);
        }
    }
}