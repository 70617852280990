<template>
    <article 
        class="panel" 
        :id="panelData.name"
        :style=" { 
            width: panelData.width + 'px', 
            height: panelData.height + 'px',
            top: panelData.top + 'px',
            left: panelData.left + 'px'
        }"
    >
        <figure class="layer" v-for="layer in panelData.layers" :key="layer.name">
            <img 
                 :width="layer.size.width" 
                 :height="layer.size.height" 
                 :class="[layer.type, layer.animation]" 
                 class="asset"
                 :src="layer.asset" 
                 alt=""
                 :style=" { 
                    top: layer.position.top + '%',
                    left: layer.position.left + '%',
                    'animation-delay': layer.delay
                }"
            />
            
            <figcaption v-if="!layer.type === 'zap'">{{ layer.text }}</figcaption>
        </figure>
    </article>
</template>

<script>

export default {
    name: "ComicPanel",
    components: {
    },
    
    props: {
        panelData: Object,
        index: Number
    },
    data () {
        return {
        };
    },
    
    created () {
    },

    mounted () {
    },
    
    methods: {
        addBubbles () {
        },
    }
};
</script>

<style scoped lang="scss">
.panel {
    display: block;
    background: #fff;
    position: absolute;
    opacity: 0.25;
    
    &.active {
        opacity: 1;
        
        .layer {
            .asset {

                &.pop-in {
                    animation: bubble-pop 0.5s ease 0s 1 normal forwards;
                }
                &.zap-in {
                    animation: zap-pulse 0.8s ease infinite;
                }
            }
        }
    }
    
    .layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        .asset {
            position: absolute;
            
            &.pop-in {
                transform: scale(0);
                opacity: 0;
                transform-origin: bottom left;
            }
            
            &.zap-in {
                opacity: 0;
                transform-origin: center;
                
            }
        }
        
        figcaption {
            display: block;
            opacity: 0;
            pointer-events: none;
        }
    }
}
    
 @keyframes bubble-pop {
    0% {
        opacity: 1;
    }
     
    40% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes zap-pulse{
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
     
    50% {
        transform: scale(1.1);
    }
}
</style>
