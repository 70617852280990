import img_loading_bar_blue from '@/components/gamemodes/performance/assets/images/loading-bar-blue.png';
import img_loading_bar_red from '@/components/gamemodes/performance/assets/images/loading-bar-red.png';


export default {
    "images": [
        img_loading_bar_blue,
        img_loading_bar_red
    ],
    "frames": [
        [ 0, 0, 209, 22, 0, 104.5, 11],
        [ 0, 0, 209, 22, 1, 104.5, 11]
    ],
    "animations": {
        "blue": 0,
        "red": 1
    }
};