<template>
    <Transition name="circle-wipe">
        <CharacterMenu 
            v-if="characterMenuOpen"
            :isPlayer="true"
            :activeTab="menuTab"
            :whichCharacter="menuCharacter"
            @close-event="closeCharacterMenu"
        />
    </Transition>
    <aside id="character-bar-ui" class="side-panel left" :class="[{'show': barVisible}, layout]">
        <ol class="char-portholes">  
            <CharacterPort
                v-for="porthole in portholes" 
                :key="porthole.index"
                :isMine="porthole.is_mine" 
                :state="porthole.state"
                :layout="layout"
                @action-btn-event="actionPressed"
                @show-menu-event="showCharacterMenu"
                @map-lock-pressed="actionPressed"
                :ref="'porthole_' + porthole.state.birdColor.replace(/-/g, '_')"
            />
        </ol>
        
        <CharacterBarTester 
            :portholes="portholes"
            @update-portholes-event="updatePortholes"
        />
        
    </aside> 
</template>

<script>
/* eslint-disable */
import CharacterPort from './CharacterPort.vue';
import CharacterMenu from "./CharacterMenu.vue";
import CharacterBarTester from './CharacterBarTester.vue';
    
export default {
    name: 'CharacterBar',
    components: {
        CharacterPort,
        CharacterMenu,
        CharacterBarTester
    },
    props: {
        layout: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            portholes: {},
            characterMenuOpen: false,
            barVisible: false,
            menuCharacter: "",
            menuTab: "details",
            gameData: null
        };
    },
    emits: ['action-btn-event'],
    mounted () {
    },
    methods: {
        actionPressed() {
            this.$emit('action-btn-event');
        },
        updatePortholes(portholes) {
            this.portholes = portholes; // a wholesale update to the portholes object. 
        },
        getPortholesData() {
            return this.portholes; 
        },
        hideBar () {
            this.barVisible = false;
            
        },
        showBar () {
            this.barVisible = true;  
        },
        showCharacterMenu(bird, tab) {
            this.menuCharacter = bird;
            this.menuTab = tab ? tab: 'details';
            
            this.characterMenuOpen = true;
            this.$gameConsole.log('Character Viewed', {bird, tab});
        },
        closeCharacterMenu() {
            this.characterMenuOpen = false;
        },
        getPortholeState(bird) {
            let result = {};
            
            switch (bird) {
                
                case "purple-bird":
                    result = this.portholes.purple_bird.state;
                    break;
                case "yellow-bird":
                    result = this.portholes.yellow_bird.state;

                    break;
                case "red-bird":
                    result = this.portholes.red_bird.state;

                    break;
                case "teal-bird":
                    result = this.portholes.teal_bird.state;
                    
                    break;
            }
            
            return result;
        },
        updateBirdState(bird, option, value) {
            const portholeState = this.getPortholeState(bird);
            
            if (option.includes('actionBTN')) {
                if (option.includes('show')) {
                    portholeState.actionBTN.show = value; 
                } else if (option.includes('text')) {
                    portholeState.actionBTN.text = value; 
                }
            } else if (option.includes('undoLabel')) {
                if (option.includes('show')) {
                    portholeState.undoLabel.show = value;
                } else if (option.includes('text')) {
                    portholeState.undoLabel.text = value;
                }
            } else if (option.includes('emote')) {
                if (option.includes('timeout')) {
                    portholeState.emote.timeout = value; 
                } else if (option.includes('text')) {
                    portholeState.emote.text = value; 
                } else {
                    portholeState.emote.type = value; 
                }
            } else if (option.includes('ready')) { 
                portholeState.ready = value; 
            } else if (option.includes('waiting')) {
                portholeState.waitingOnRef = value; 
            } else {
                 portholeState[option] = value;
            } 
            //console.log('updating: '+ option + ' as ' + value);
        },
        renderPorts(game_data) {
            let characters_store = this.$store.getters.getCharacters,
            me = this.$store.getters.getPlayerInfo;
            
            this.gameData = game_data;
        
            characters_store.forEach((item, index) => {

                if (me.player === item.player) {
                    // initially set this to my character (will override from port clicks)
                    this.menuCharacter = item.character;
                }

                this.portholes[item.character.replace(/-/g, '_')] = {
                    is_mine: me.player === item.player ? true : false,  
                    state: {
                        birdColor: item.character,
                        ready: false,
                        waitingOnRef: false,
                        emote: {
                            type: "idle",
                            text: "",
                            timeout: "default"
                        },
                        actionBTN: {
                            text: "Done",
                            show: false
                        },
                        undoLabel: {
                            text: "Undo",
                            show: false
                        },
                        gameData: game_data
                    }
                }
            });  
        }
    }
};
</script>

<style scoped lang="scss">
#character-bar-ui {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: -20vw;
    bottom: 0;
    width: 10vw;
    background-color: #4C5693;
    opacity: 0;
    pointer-events: none;
    
    transition: left 0.5s;
    
    @media (min-aspect-ratio: 7/3) {
        width: 7.5vw;
    }
    
    &.show {
        left: 0;
        opacity: 1;
        pointer-events: all; 
    }

    .char-portholes {
        position: absolute;
        bottom: 1vw;
        padding-bottom: 14.25vw;
        
        @media (min-aspect-ratio: 7/3) {
            transform: scale(0.75);
            transform-origin: bottom left;
        }
    }
    
    &.performance-bar {
        width: 100%;
        height: 10vw;
        top: auto;
        left: 0;
        right: 0;
        
        transform: translateY(110%);
        transition: transform 0.3s;
        
        @media (min-aspect-ratio: 7/3) {
            width: 100%;
        }
        
        &.show {
            transform: translateY(0);
        }
        
        .char-portholes {
            padding: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-evenly;
        }
    }

}

#state-tester {
    display: block;
    position: absolute;
    left: 10vw;
    width: 90vw;
    bottom: 0;
    background: #161a38;
    z-index: -1;
    padding: 1vw 2vw 1vw 9vw;
    height: 16vw;
    overflow: scroll;
    
    .test-set {
        background: #0e122a;
        padding: 3vw 3vw 2vw;
        border-radius: 3vw;
        color: #a1adf5;
        margin-bottom: 1em;
        
        h3 {
            font-size: 1.25em;
            display: block;
            margin-bottom: 1em;
            
        }
        
        fieldset {
            display: flex;
            
            > * {
                margin: 0 1vw 0 0;
                flex: 0 0 30%;
            }
            
            > .skinny {
                flex: 0 0 20%;
                
                select {
                    width: 50%;
                }
            }
            
             > .thicc {
                flex: 0 0 50%;
                margin-top: -2.05em;
                
                input[type=text] {
                    width: 37%;
                    margin-right: 1em;
                }
            }
            
            .option-head {
                display: block;
                margin-bottom: 1em;
                text-align: left;
                text-transform: uppercase;
                
                &.inline {
                    display: inline-block;
                    width: fit-content;
                }
            }
            
            .sub-head {
                display: inline-block;
                margin-bottom: 1em;
                width: fit-content;
                text-align: left;
            }
            
            .push {
                margin-bottom: 1em;
            }
        }
        
        input[type=text] {
            padding: 0.5em;
            border-radius: 1.5em;
            text-align: center;
            width: 65%;
            margin-left: 1em;
        }
        
        select {
            width: 40%;
            margin-left: 1em;
            padding: 0.5em;
            max-width: 8vw;
        }
    }
}
</style> 