<template>
    <article class="outer-bar-container" :class="mode">
        <h2
            class="header-blob"
            v-if="mode === 'review'"
        >
            Checking Final Solution...
        </h2>
        <h3 v-else class="header-blob little">Group Progress</h3>
        
        <div class="timeline has-flags">
            <ol class="timeline-items">
                <li class="timeline-item" v-for="(solution, index) in setupData.solutions" :key="index">
                    <TimelineNode
                        :ref="'node' + index"
                        :timelineMode="mode"
                        :parentComponent="this"
                    />
                </li>
            </ol>
        </div>
        
        <div class="review-instructions">
            <div class="instructions-text">{{reviewInstructions}}</div>
            <div class="instructions-subtext">{{reviewInstructionsSubtext}}</div>
        </div>
    </article>
</template>

<script>
import TimelineNode from "@/components/gamemodes/practice/TimelineNode.vue";

export default {
    name: 'TimelineBar',
    components: {
        TimelineNode
    },
    props: {
        // timelineData: Object,
        // theGameTimeline: Object,

        parentComponent: Object,
        theGame: Object,
        setupData: Object
    },
    data () {
        return {
            nodes: [],
            activeNode: -1,
            mode: '',
            allCorrect: false,
            reviewInstructions: "",
            reviewInstructionsSubtext: ""
        };
    },
    computed: {

    },
    mounted () {
        let x = 0;
        this.setMode();

        for (x = 0; x < this.setupData.solutions.length; x++) {
            this.nodes.push(this.$refs['node' + x][0]);
            if (x === 0) {
                this.nodes[x].setState('active');
            }

            this.nodes[x].setTurn(x + 1);
        }

        this.theGame.setTimeline(this);
    },
    methods: {
        // debugTurnSelected (turn) {
        //     this.$parent.updateRewindText(turn);
        //     this.theGameTimeline.showDebugger(turn);
        // },

        ///////////////////////////////////////////////////////////////////////////////////////
        ///New Methods
        ///////////////////////////////////////////////////////////////////////////////////////

        setActiveNode (turn) {
            let x = 0;

            if (turn <= 0) {
                return;
            }

            this.activeNode = turn - 1;

            for (x = 0; x < this.nodes.length; x++) {
                if (x < this.activeNode) {
                    this.nodes[x].setState("rewind");
                } else if (x === this.activeNode) {
                    this.nodes[x].setState("active");
                } else {
                    this.nodes[x].setState("");
                }
            }
        },

        setResults (results, allCorrect) {
            let x = 0;

            //The first index of the results is the setup positions, so it's one index longer than the timeline
            for (x = 0; x < this.nodes.length; x++) {
                if (results.length - 1 > x) {
                    if (results[x + 1]) {
                        this.nodes[x].setCorrectness("correct");
                    } else {
                        this.nodes[x].setCorrectness("incorrect");
                    }

                } else {
                    this.nodes[x].setCorrectness("");
                }
            }

            if (allCorrect) {
                this.allCorrect = true;
                this.reviewInstructions = "Perfect! Nice work!";
                this.reviewInstructionsSubtext = "I knew you could do it.";
            } else {
                this.allCorrect = false;
                this.reviewInstructions = "Not Complete - Problems Found";
                this.reviewInstructionsSubtext = "Tap the timeline to review in the debugger, or press the button to retry.";
            }
        },

        showGameplay () {
            this.setMode('gameplay');
        },

        showReview () {
            this.setMode('review');
        },

        showDebugger (turn) {
            if (this.mode === 'review') {
                this.theGame.showDebugger(true, turn);
            } else if (this.mode === 'gameplay') {
                if (turn > this.activeNode) {
                    return;
                }
                this.theGame.showDebugger(false, turn);
            }
            this.$parent.updateRewindText(turn);
        },

        hide () {
            this.setMode();
        },

        setMode (newMode) {
            switch (newMode) {
            case 'review':
            case 'gameplay':
                this.mode = newMode;
                break;
            default:
                this.mode = 'hidden';
                break;
            }
        }

    }
};
</script>

<style scoped lang="scss">

.outer-bar-container {
    display: block;
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, 0);
    
    display: flex;
    align-items: center;
    
    &.hidden {
        display: none;
    }
    
    
    .header-blob {
        height: fit-content;
    }
    
    &.gameplay {
        
        .header-blob {
        }
    }
    
    &.review {
        top: 50%;
        display: block;
        transform: translate(-42%, -50%);
        height: 26vw;
        
        .header-blob {
            transform: translateY(-50%);
        }
        
        .timeline {
            margin: 0 auto;
            width: max-content;
            transform: scale(2);
            transform-origin: top center;
        }
    }
}

.bar-container {
    position: absolute;
    display: block;
    width: 70vw;
    left: 14vw;
}
    
    
.timeline { 
    display: flex;
    align-items: center;
    margin: 0 1vw;
    
    .timeline-items {
        display: flex;
        align-items: center;
        margin: 0 1vw;
        position: relative;

        .timeline-item {
            width: 4.25vw;
            height: 4.25vw;
            margin: 0 1vw;
        }
        
        &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 10%;
            right: 10%;
            height: 1vw;
            background: #1B204B;
            transform: translateY(-50%);
        }
    }
    
    &.has-flags {  
        &:before, &:after {
            content: '';
            display: block;
            width: 7vw;
            height: 8vw;
        }
        
        &:before {
            background: url('~@/assets/gamemodes/practice/ui/mtb-ui_start-flag.svg') 50% 50% no-repeat;  
        }
        
        &:after {
            background: url('~@/assets/gamemodes/practice/ui/mtb-ui_finish-flag.svg') 50% 50% no-repeat;  
            
            &:after {
                content: '';
                display: block;
            }
        }
        
    }
}

.review-instructions {
    display: none;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: -12vw;
    background: #fee888;
    color: #1b204b;
    padding: 1em;
    line-height: 1.3;
    border-radius: 1.5em;

    .instructions-text {
        font-size: 2em;
    }
    .instructions-subtext {
        font-size: 1em;
    }
    .review & {
        display: block;
    }
}
    

</style>