<template>
    <article class="my-player-info">
        <span class="text-group">
            <h3><i>Welcome Back</i> <em>{{ me.name }}</em></h3>
            <button class="inline-action" @click="logout">
                Logout
            </button>
        </span>
        <PlayerAvatar class="my-avatar" :me="me"></PlayerAvatar>
    </article>
</template>

<script>
import PlayerAvatar from '@/components/PlayerAvatar.vue';

export default {
    name: 'MyInfo',
    components: {
        PlayerAvatar
    },
    data () {
        return {
            me: this.$store.getters.getPlayerInfo
        };
    },
    methods: {
        logout () {
            sessionStorage.clear();
            this.$store.dispatch('logoutPlayer');
        }
    },
    created () {
    },
    async mounted () {}
};

</script>


<style scoped lang="scss">
    .my-player-info {
        position: absolute;
        right: 2vw;
        top: 2vw;
        width: 36vw;
        z-index: 1;

        .text-group {
            display: block;
            width: 23.4vw;
            left: 0;
            text-align: right;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }

        .inline-action {
            background: none;
            font-size: 0.6em;
            color: $mtb-white;
            padding: 0;
            text-decoration: underline;

            &:hover {
                color: aqua;
                cursor: pointer;
            }
        }

        h3 {
            font-size: 3vw;
            display: block;

            i {
                font-size: 0.4em;
                display: block;
            }

            em {
                display: block;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .my-avatar {
            width: 10.8vw;
            height: 10.8vw;
            float: right;
        }
    }

</style>
