<template>
    <div id="comic-viewer" class="modal-wrapper">
        <button
                class="mtb-btn action replay"
                :disabled="panelIndex > 0 ? 0 : 1"
                @click="restartComic"
        >
            Restart Comic
        </button>
        <button class="mtb-btn action close" @click="closeComic()">Close Comic</button>
        
        <button
            v-if="panelIndex > 0"
            class="big-comic-btn back"
            @click="prevPanel"
        >
            Previous Panel
        </button>
        
        <div
            id="comic-viewer-stage"
        >
            <ComicPanel
                v-for="(panel, index) in comic.panels"
                :key="index"
                :panelData="panel"
                :index="index"
                :class="{'active': index === panelIndex}"
            />
        </div>
        <button
            v-if="panelIndex < comic.panels.length - 1"
            class="big-comic-btn next"
            @click="nextPanel"
        >
            Next Panel
        </button>
        
        <button 
            class="mtb-btn action big-ready" 
            :class="{'show': panelIndex === comic.panels.length - 1}"
            @click="closeComic()"
            v-if="!wasViewed"
        >
            Start Playing
        </button>
    </div>
</template>

<script>
import ComicPanel from "@/components/comic/ComicPanel.vue";
import {Howl} from 'howler';
    
// UI audio
import SWIPE_SFX from '@/assets/audio/mtb-sfx_comic_page-flip.mp3';

export default {
    name: "ComicViewer",
    components: {
        ComicPanel
    },
    props: {
        comic: Object,
        wasViewed: Boolean
    },
    data () {
        return {
            panelIndex: 0,
            scale: 1,
            swipeSFX: null,
            debounceListener: null
        };
    },
    
    computed: {
        currentPanel () {
            return this.comic.panels[this.panelIndex];
        }
    },

    mounted () {
        this.debounceListener = this.resizeDebounce(() => {
            this.updateStage();
        });
        
        window.addEventListener("resize", this.debounceListener);
        
        this.swipeSFX  = new Howl({
            src: SWIPE_SFX,
            autoplay: false,
            loop: false,
            volume: 0.5
        });
    
        this.updateStage();
    },
    
    unmounted () {
        window.removeEventListener("resize", this.debounceListener);
    },

    methods: {
        updateStage () {
            const
                stage = document.getElementById('comic-viewer-stage'),
                scale = this.scale = this.checkScale(),
                centerX = this.currentPanel.left + this.currentPanel.width / 2,
                centerY = this.currentPanel.top + this.currentPanel.height / 2,
                top = -centerY * scale + (window.innerHeight / 2),
                left = -centerX * scale + (window.innerWidth / 2);
            
            stage.style.transform = 'translate(' + left + 'px, ' + top + 'px) scale(' + this.scale + ')';
        },
        
        checkScale () {
            const
                _winHeight = window.innerHeight - (window.innerWidth / 10),
                _winWidth = window.innerWidth - (window.innerWidth / 10);
            let widthDiff = 0,
                heightDiff = 0,
                scale = 1;

            if (this.currentPanel.width > _winWidth) {
                widthDiff = this.currentPanel.width - _winWidth;
            }

            if (this.currentPanel.height > _winHeight) {
                heightDiff = this.currentPanel.height - _winHeight;
            }

            if (widthDiff > heightDiff) {
                scale = _winWidth / this.currentPanel.width;
            } else if (heightDiff > widthDiff) {
                scale = _winHeight / this.currentPanel.height;
            } else {
                scale = 1;
            }

            scale = Math.round(scale * 100) / 100;
            
            return scale;
        },
        
        nextPanel () {
            this.panelIndex = this.panelIndex + 1;
            this.updateStage();
            this.swipeSFX.play();
        },
        prevPanel () {
            this.panelIndex = this.panelIndex - 1;
            this.updateStage();
            this.swipeSFX.play();
        },
        
        restartComic () {
            this.panelIndex = 0;
            this.updateStage();
        },
        
        closeComic () {
            this.$emit('close-event');
        },
        resizeDebounce (func) {
            let timer = 0;
            
            return function (event) {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(func, 100, event);
            };
        }
    }
};
</script>

<style scoped lang="scss">
    
#comic-viewer-stage {
    transition: transform 0.4s;
    position:absolute;
    top: 0;
    left: 0;
}
    
.big-comic-btn{
    position: absolute;
    width: 50vw;
    top: 0;
    bottom: 0;
    background: transparent;
    text-indent: -999em;
    cursor: pointer;
    z-index: 10;
    
    &.back {
        left: 0;
    }
    
    &.next {
        right: 0;
    }

}
    
.mtb-btn {
    position: absolute;
    top: 1.5vw;
    z-index: 11;
    
    &.close {
        right: 1.5vw;
    }
    
    &.big-ready.action {
        top: auto;
        bottom: 5vw;
        right: -200%;
        
        transition: right 0.5s;
        
        &.show {
            right: 0;
        }
    }
    
    &.replay {
        right: 9vw;
        
        &:disabled {
            opacity: 0.2;
            animation: none;
            transform: none;
            cursor: default;
        }
    }
}
</style>
