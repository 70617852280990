import Scroller from "../../components/Scroller";

export default {
    id: "background",
    components: [{
        type: "RenderSprite",
        spriteSheet: "background"
    }, {
        "type": "RelayLinker",
        "linkId": "camera-movement",
        "events": {
            "rl-drag-start": "drag-start",
            "rl-drag-stop": "drag-stop",
            "rl-drag-offset": "drag-offset"
        }
    }, Scroller],
    properties: {
        interactive: true,
        scaleX: 1250,
        scaleY: 1250,
        x: 0,
        y: 0
    }
};